import { NgModule } from '@angular/core';
import { SuiCheckboxModule } from '../checkbox';
import { CheckboxListComponent } from './checkbox-list.component';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@sui/pipes';

const CHECKBOX_LIST_DIRECTIVES = [CheckboxListComponent];

@NgModule({
  imports: [
    CommonModule,
    SuiCheckboxModule,
    MatSelectModule,
    PipesModule,
    ReactiveFormsModule,
  ],
  declarations: [CHECKBOX_LIST_DIRECTIVES],
  exports: [CHECKBOX_LIST_DIRECTIVES],
})
export class SuiCheckboxListModule {}

export * from './checkbox-list.component';
