import { Component } from '@angular/core';

@Component({
  selector: 'sui-label',
  template: ` <label><ng-content></ng-content></label> `,
  styles: [
    `
      label:after {
        content: ':';
      }
    `,
  ],
})
export class LabelComponent {}
