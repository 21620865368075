import { Component } from '@angular/core';

@Component({
  selector: 'sui-form-control',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        margin: 20px 0;
      }
    `,
  ],
})
export class FormControlComponent {}
