import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sui-panel-overlay',
  template: '',
  styles: [
    `
      :host {
        z-index: -1;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        user-select: none;
        -webkit-touch-callout: none;
        visibility: hidden;
      }

      :host(.suiPanelOverlayVisible) {
        visibility: visible;
      }
    `,
  ],
})
export class PanelOverlayComponent {
  @Input() @HostBinding('class.suiPanelOverlayVisible') visible = false;
}
