import { Component, TrackByFunction } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { BigSelectOptionView } from './big-select.models';
import * as BigSelectActions from './big-select.actions';
import { BigSelectState } from './big-select.state';

@Component({
  selector: 'sui-big-select-options',
  template: `
    <cdk-virtual-scroll-viewport [itemSize]="64">
      <div
        class="bigSelectHiddenSelectionsWarning"
        *ngIf="state.numberOfCheckedOptionsRejectedByFilter$ | async as total"
        [ngPlural]="total"
      >
        <ng-template ngPluralCase="=1">
          1 selected option is hidden behind your filter
        </ng-template>
        <ng-template ngPluralCase="other">
          {{ total }} selected options are hidden behind your filter
        </ng-template>
      </div>
      <div
        *cdkVirtualFor="
          let option of state.filteredOptions$ | async;
          trackBy: trackByOptionValue
        "
        class="bigSelectOption"
        role="button"
        matRipple
        [attr.aria-pressed]="option.checked"
        [attr.data-option-name]="option.name"
        [attr.data-option-value]="option.value"
        [class.bigSelectOptionChecked]="option.checked"
        [class.bigSelectOptionDisabled]="option.disabled"
        (click)="onToggleOption(option)"
      >
        <mat-icon
          *ngIf="option.svgIcon; else fontIcon"
          [svgIcon]="option.svgIcon"
        ></mat-icon>
        <ng-template #fontIcon>
          <mat-icon>{{ option.icon }}</mat-icon>
        </ng-template>
        <span>{{ option.name }}</span>
        <mat-checkbox
          color="primary"
          [disabled]="option.disabled"
          [checked]="option.checked"
        ></mat-checkbox>
      </div>
    </cdk-virtual-scroll-viewport>
  `,
  styles: [
    `
      cdk-virtual-scroll-viewport {
        height: 300px;
        border-bottom: 1px solid var(--color-foreground-divider);
      }

      .bigSelectOption {
        display: grid;
        position: relative;
        height: 64px;
        align-items: center;
        grid-template-columns: 64px 1fr 64px;
        cursor: pointer;
        border-bottom: 1px solid var(--color-foreground-divider);
      }

      .bigSelectOptionChecked:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-500);
        opacity: 0.1;
      }

      mat-checkbox,
      mat-icon {
        justify-self: center;
      }

      .bigSelectOption.bigSelectOptionDisabled {
        cursor: default;
      }

      .bigSelectOptionDisabled span,
      .bigSelectOptionDisabled mat-icon {
        opacity: 0.38;
      }

      .bigSelectHiddenSelectionsWarning {
        display: flex;
        width: calc(100% - 16px);
        margin: 8px auto;
        height: 48px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-style: italic;
        background-color: var(--color-background-selected-button);
        border-radius: 2px;
        opacity: 0.82;
      }
    `,
  ],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } },
  ],
})
export class BigSelectOptionsComponent {
  trackByOptionValue: TrackByFunction<BigSelectOptionView> = (_, option) => option.value;

  constructor(readonly state: BigSelectState) {}

  onToggleOption(option: BigSelectOptionView) {
    this.state.dispatch(BigSelectActions.toggleOptionAction(option));
  }
}
