import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiButtonModule } from '../button';
import { SuiIconModule } from '../icon';
import { NavDrawerAccountComponent } from './nav-drawer-account.component';
import { NavDrawerBodyComponent } from './nav-drawer-body.component';
import { NavDrawerDividerComponent } from './nav-drawer-divider.component';
import { NavDrawerFooterComponent } from './nav-drawer-footer.component';
import { NavDrawerLinkComponent } from './nav-drawer-link.component';
import { NavDrawerLogoComponent } from './nav-drawer-logo.component';
import { NavDrawerComponent } from './nav-drawer.component';
import { NavSubmenuComponent } from './nav-submenu.component';

const NAV_DRAWER_DIRECTIVES = [
  NavDrawerAccountComponent,
  NavDrawerBodyComponent,
  NavDrawerDividerComponent,
  NavDrawerFooterComponent,
  NavDrawerLinkComponent,
  NavDrawerLogoComponent,
  NavDrawerComponent,
  NavSubmenuComponent,
];

@NgModule({
  imports: [CommonModule, SuiIconModule, SuiButtonModule],
  declarations: [NAV_DRAWER_DIRECTIVES],
  exports: [NAV_DRAWER_DIRECTIVES],
})
export class SuiNavDrawerModule {}
