import { Component } from '@angular/core';

@Component({
  selector: 'sui-nav-drawer-logo',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        padding: 20px 14px;
      }

      :host ::ng-deep img {
        max-width: 72%;
        height: auto;
      }
    `,
  ],
})
export class NavDrawerLogoComponent {}
