import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressBarComponent } from './progress-bar.component';

@NgModule({
  imports: [MatProgressBarModule],
  declarations: [ProgressBarComponent],
  exports: [ProgressBarComponent],
})
export class SuiProgressBarModule {}
