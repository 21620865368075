import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { SuiCardModule } from '../card';
import { EMPTY_STATE_DIRECTIVES } from './empty-state.component';

@NgModule({
  imports: [SuiCardModule, PortalModule],
  declarations: [EMPTY_STATE_DIRECTIVES],
  exports: [EMPTY_STATE_DIRECTIVES],
})
export class SuiEmptyStateModule {}
