// AUTO-GENERATED FILE - DO NOT MODIFY
/**
 * How frequently should the email group's users receive emails about alarm
 * status changes for the specified alarm type?
 */
export enum AlarmEmailFrequency {
  /** Send emails for this alarm type daily. */
  DAILY = 'DAILY',
  /** Send emails for this alarm type hourly. */
  HOURLY = 'HOURLY',
  /** Send emails for this alarm type monthly. */
  MONTHLY = 'MONTHLY',
  /** Send emails for this alarm type weekly. */
  WEEKLY = 'WEEKLY',
}

export enum Application {
  CLIMATE = 'CLIMATE',
  LIGHTING = 'LIGHTING',
}

export enum AstroEvent {
  DAWN = 'DAWN',
  DUSK = 'DUSK',
  SUNRISE = 'SUNRISE',
  SUNSET = 'SUNSET',
}

export enum BaselineType {
  FLAT = 'FLAT',
  VARIABLE = 'VARIABLE',
}

export enum BatteryPoweredIndustrialSensorBatteryLevel {
  EMPTY = 'EMPTY',
  FULL = 'FULL',
  LOW = 'LOW',
  PLUGGED_IN = 'PLUGGED_IN',
}

export enum BatteryPoweredIndustrialSensorUpgradeStatus {
  UPGRADE_IN_PROGRESS = 'UPGRADE_IN_PROGRESS',
  UPGRADE_PENDING = 'UPGRADE_PENDING',
}

export enum BillingFrequency {
  MONTHLY = 'MONTHLY',
}

export enum Capabilities {
  MAP_SITE_SELECT = 'MAP_SITE_SELECT',
  QUICK_SITE_SETUP = 'QUICK_SITE_SETUP',
  SITE_CONTROLLER_LIST = 'SITE_CONTROLLER_LIST',
}

/** Commissioning status */
export enum CommissioningStatus {
  /** All required configuration has been provided */
  COMMISSIONED = 'COMMISSIONED',
  /** All required configuration has been provided and the device has communicated with the system at least once */
  CONNECTED = 'CONNECTED',
  /** Some required configuration has not been provided */
  PRE_COMMISSIONED = 'PRE_COMMISSIONED',
}

export enum ConfigurationTemplateType {
  BRIDGE485_TEMPLATE = 'BRIDGE485_TEMPLATE',
}

/** Asset Type */
export enum DefinedAssetTypeEnum {
  BAGHOUSE = 'BAGHOUSE',
  BEARING = 'BEARING',
  CHILLER = 'CHILLER',
  CNC = 'CNC',
  COMPRESSOR = 'COMPRESSOR',
  CONDENSER = 'CONDENSER',
  COOLING_TOWER = 'COOLING_TOWER',
  CUPOLA = 'CUPOLA',
  EXHAUST_FAN = 'EXHAUST_FAN',
  EXTRUDER = 'EXTRUDER',
  FURNACE = 'FURNACE',
  MAINS = 'MAINS',
  MAU = 'MAU',
  MIXER = 'MIXER',
  MOTOR = 'MOTOR',
  MULLER = 'MULLER',
  PAINT_BOOTH = 'PAINT_BOOTH',
  PANEL = 'PANEL',
  PRESS = 'PRESS',
  RTU = 'RTU',
  SCRUBBER = 'SCRUBBER',
  SHOT_BLAST = 'SHOT_BLAST',
  STAMPING_MACHINE = 'STAMPING_MACHINE',
  TRANSFORMER = 'TRANSFORMER',
  WELDER = 'WELDER',
}

export enum DispositionLevel {
  CRITICAL = 'CRITICAL',
  INFO = 'INFO',
  NORMAL = 'NORMAL',
  UNKNOWN = 'UNKNOWN',
  WARNING = 'WARNING',
}

export enum IndustrialSensorDefinedDataEnum {
  CONCENTRATION = 'CONCENTRATION',
  CURRENT = 'CURRENT',
  DIFFERENTIAL_PRESSURE = 'DIFFERENTIAL_PRESSURE',
  FLOW = 'FLOW',
  HUMIDITY = 'HUMIDITY',
  MASS = 'MASS',
  PERCENTAGE = 'PERCENTAGE',
  POWER = 'POWER',
  PRESSURE = 'PRESSURE',
  TEMPERATURE = 'TEMPERATURE',
  TOTALIZED_FLOW = 'TOTALIZED_FLOW',
  VOLTAGE = 'VOLTAGE',
}

export enum Interval {
  AUTO = 'AUTO',
  DAY = 'DAY',
  FIFTEEN_MINUTES = 'FIFTEEN_MINUTES',
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  ONE_MINUTE = 'ONE_MINUTE',
  WEEK = 'WEEK',
}

export enum LightAlarmReason {
  /** Census Failure */
  CENSUS_FAILURE = 'CENSUS_FAILURE',
  /** Device Communication Failure */
  COMMUNICATION_FAILURE = 'COMMUNICATION_FAILURE',
  /** Device Configuration Failure */
  CONFIGURATION_FAILURE = 'CONFIGURATION_FAILURE',
  /** DALI Driver Error */
  DALI_DRIVER_ERROR = 'DALI_DRIVER_ERROR',
  /** DALI Driver Problem Report */
  DALI_DRIVER_PROBLEM_REPORT = 'DALI_DRIVER_PROBLEM_REPORT',
  /** Dimming Test Failure */
  DIMMING_TEST_FAILURE = 'DIMMING_TEST_FAILURE',
  /** Fixture Threshold Failure */
  FIXTURE_THRESHOLD_FAILURE = 'FIXTURE_THRESHOLD_FAILURE',
  /** Light Recruitment Failure */
  LIGHT_RECRUITMENT_FAILURE = 'LIGHT_RECRUITMENT_FAILURE',
  /** Light Tilted */
  LIGHT_TILTED = 'LIGHT_TILTED',
  /** Device Link Quality Warning */
  LINK_QUALITY_WARNING = 'LINK_QUALITY_WARNING',
  /** Missing DALI Driver */
  MISSING_DALI_DRIVER = 'MISSING_DALI_DRIVER',
  /** Device Script Missing */
  MISSING_SCRIPT_FAILURE = 'MISSING_SCRIPT_FAILURE',
  /** Network Change Failure */
  NETWORK_CHANGE_FAILURE = 'NETWORK_CHANGE_FAILURE',
  /** Unchanging Motion Sensor */
  UNCHANGING_MOTION_SENSOR = 'UNCHANGING_MOTION_SENSOR',
}

export enum LightMapLayerIconSize {
  /** Adjust icon size depending on density */
  AUTO = 'AUTO',
  /** Use big icons exclusively */
  BIG_ICONS = 'BIG_ICONS',
  /** Use small icons exclusively */
  SMALL_ICONS = 'SMALL_ICONS',
}

export enum ModbusFunctionCode {
  READ_HOLDING_REGISTERS = 'READ_HOLDING_REGISTERS',
  READ_INPUT_REGISTERS = 'READ_INPUT_REGISTERS',
}

export enum NodeSwapResponseStatus {
  ERROR = 'ERROR',
  OK = 'OK',
}

export enum NodeSwapStatusCode {
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
  WARNING = 'WARNING',
}

export enum OrganizationRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export enum PermissionGroupSiteRole {
  MEMBER = 'MEMBER',
  NONE = 'NONE',
  SCENE_AND_SCHEDULE_MEMBER = 'SCENE_AND_SCHEDULE_MEMBER',
  SCENE_USER = 'SCENE_USER',
  VIEWER = 'VIEWER',
}

export enum Products {
  ALL_DYNAMIC_EFFECTS = 'ALL_DYNAMIC_EFFECTS',
  CHASE_EFFECTS = 'CHASE_EFFECTS',
  CLIMATE = 'CLIMATE',
  COLOR = 'COLOR',
  COLOR_FADE = 'COLOR_FADE',
  DEPRECATED_CLIMATE = 'DEPRECATED_CLIMATE',
  ILLUMINATE = 'ILLUMINATE',
  ILLUMINATE_POWER = 'ILLUMINATE_POWER',
  MONO_DYNAMIC_EFFECTS = 'MONO_DYNAMIC_EFFECTS',
  REMOTE_ACCESS = 'REMOTE_ACCESS',
  SCENE_VIEWS = 'SCENE_VIEWS',
  SENSE = 'SENSE',
  SENSE_POWER = 'SENSE_POWER',
  THRESHOLD_DETECTION = 'THRESHOLD_DETECTION',
  TILT_DETECTION = 'TILT_DETECTION',
}

export enum QueryDataType {
  POWER = 'POWER',
}

export enum RunningState {
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  UNKNOWN = 'UNKNOWN',
}

export enum SerialSettingsParity {
  EVEN = 'EVEN',
  NONE = 'NONE',
  ODD = 'ODD',
}

export enum SiteAlarmReason {
  /** More than one site controller software version detected in site */
  SITE_CONTROLLER_VERSION_MISMATCH = 'SITE_CONTROLLER_VERSION_MISMATCH',
}

export enum SiteControllerAlarmReason {
  /** Unknown Industrial Sensor Report */
  UNKNOWN_INDUSTRIAL_SENSOR = 'UNKNOWN_INDUSTRIAL_SENSOR',
}

export enum SiteControllerResourceStatus {
  /** Service is known to be unavailable */
  DOWN = 'DOWN',
  /** Service initialization failed */
  INIT_FAILED = 'INIT_FAILED',
  /** Service is being initialized */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Service initialization passed - pending acknowledgement */
  PENDING_ACK = 'PENDING_ACK',
  /** Service status is unknown */
  UNKNOWN = 'UNKNOWN',
  /** Service is known to be available */
  UP = 'UP',
}

export enum SiteControllerResourceType {
  /** Site controller’s CouchDB connection */
  CONFIG_SYNC = 'CONFIG_SYNC',
  /** Site controller’s VPN connection */
  REMOTE_ACCESS = 'REMOTE_ACCESS',
  /** Site controller’s MQTT connection */
  STATE_SYNC = 'STATE_SYNC',
}

/** Source Type */
export enum SourceTypeEnum {
  ASSET = 'ASSET',
  ASSET_COLLECTION = 'ASSET_COLLECTION',
  BRIDGE485_METRIC = 'BRIDGE485_METRIC',
  DEPARTMENT = 'DEPARTMENT',
  ELECTRICITY_METER = 'ELECTRICITY_METER',
  GAS_METER = 'GAS_METER',
  LIGHTING_ZONE = 'LIGHTING_ZONE',
  SENSE420_SENSOR = 'SENSE420_SENSOR',
  THERMOSTAT = 'THERMOSTAT',
  VIRTUAL_SENSOR = 'VIRTUAL_SENSOR',
  WATER_METER = 'WATER_METER',
}

export enum SubscriptionType {
  DEMO = 'DEMO',
  EN_KIMCO = 'EN_KIMCO',
  EN_LSI = 'EN_LSI',
  EN_QUALITE = 'EN_QUALITE',
  EN_TECHLINE = 'EN_TECHLINE',
  EN_ULINE = 'EN_ULINE',
  EN_VISIONAIRE = 'EN_VISIONAIRE',
  INTERNAL = 'INTERNAL',
  LM_BASE = 'LM_BASE',
  LM_BASE_PLUS = 'LM_BASE_PLUS',
  LM_REMOTE = 'LM_REMOTE',
  LM_SPORT = 'LM_SPORT',
  SW_ENERGY_BASE = 'SW_ENERGY_BASE',
}

export enum ThermostatAlarmReason {
  /** Census Failure */
  CENSUS_FAILURE = 'CENSUS_FAILURE',
  /** Device Communication Failure */
  COMMUNICATION_FAILURE = 'COMMUNICATION_FAILURE',
  /** Device Configuration Failure */
  CONFIGURATION_FAILURE = 'CONFIGURATION_FAILURE',
  /** Device Link Quality Warning */
  LINK_QUALITY_WARNING = 'LINK_QUALITY_WARNING',
  /** Device Script Missing */
  MISSING_SCRIPT_FAILURE = 'MISSING_SCRIPT_FAILURE',
  /** Network Change Failure */
  NETWORK_CHANGE_FAILURE = 'NETWORK_CHANGE_FAILURE',
}

/** Enum to represent the fan’s control mode within a thermostat */
export enum ThermostatFanMode {
  AUTO = 'AUTO',
  ON = 'ON',
}

export enum TriggerStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  UNKNOWN = 'UNKNOWN',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  VIEWER = 'VIEWER',
}

export enum UtilityType {
  POWER = 'POWER',
}
