import { NgModule } from '@angular/core';
import { NOTICE_CARD_DIRECTIVES } from './notice-card.component';
import { SuiCardModule } from '../card';

@NgModule({
  imports: [SuiCardModule],
  declarations: [NOTICE_CARD_DIRECTIVES],
  exports: [NOTICE_CARD_DIRECTIVES],
})
export class SuiPageNoticeModule {}
