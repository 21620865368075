import {
  LightControlViewModel,
  LightGQLModel,
  LightInternalModel,
  LightSuiModel,
} from './light.model';

export function createMockLight(light?: Partial<LightInternalModel>): LightInternalModel {
  return {
    id: 'mock-light-01',
    name: 'Mock Light 01',
    level: 80,
    controllerId: 'mock-controller-id',
    snapaddr: 'DEADBEEF',
    floorPlanId: null,
    floorPlanX: null,
    floorPlanY: null,
    ...((light ?? {}) as any),
  };
}

export function createMockLightControlViewModel(
  updates?: Partial<LightControlViewModel>,
): LightControlViewModel {
  return {
    id: 'mock-light-01',
    name: 'Mock Light 01',
    level: 80,
    ...updates,
  };
}

export function createMockFreeformPositionerLight() {
  return {
    id: 'mock-freeform-light-position-id',
    x: 10,
    y: 20,
    lightId: 'mock-freeform-light-id',
  };
}

export function createMockSuiLight(updates?: Partial<LightSuiModel>) {
  return {
    id: 'mock-light-id',
    name: 'Mock Light',
    level: 80,
    ...updates,
  };
}

export function createMockGqlLight(): LightGQLModel {
  return {
    id: 'mock-light-id',
    name: 'Mock Light',
    level: 80,
    floorPlanLocation: {
      x: 10,
      y: 20,
      floorPlan: {
        id: 'mock-floor-plan-id',
      },
    },
    controller: {
      id: 'mock-controller-id',
      snapaddr: 'DEADBEEF',
      type: 'mock-controller-type',
      fixtureAssetData: '[]',
    },
    siteController: {
      id: 'mock-site-controller-id',
      name: 'mock-site-controller-naame',
    },
  };
}
