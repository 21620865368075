import { GraphModel } from './graph.model';

export function createMockGraph(): GraphModel {
  return {
    width: 1025,
    height: 550,
    margin: {
      top: 40,
      bottom: 40,
      left: 50,
      right: 20,
    },
  };
}
