import {
  ActiveSequenceSceneInternalModel,
  ActiveSequenceSceneGQLModel,
} from './active-sequence-scenes.model';

export function createMockActiveSequenceSceneModel(): ActiveSequenceSceneInternalModel {
  return {
    id: 'active-sequence-scene-id',
    currentStep: 2,
    currentStepCompletionTime: 1682114073,
  };
}

export function createMockActiveSequenceSceneGQLModel(): ActiveSequenceSceneGQLModel {
  return {
    sequenceSceneId: 'active-sequence-scene-id',
    currentStep: 2,
    currentStepCompletionTime: 1682114073,
  };
}
