import { Directive, HostBinding, Input } from '@angular/core';

export type TooltipDirections = 'north' | 'south' | 'east' | 'west';

@Directive({
  selector: '[suiTooltip]',
})
export class TooltipDirective {
  @Input()
  suiTooltip = '';
  @Input()
  suiTooltipDirection: TooltipDirections = 'north';
  @Input()
  suiTooltipDisabled = false;

  isDirection(direction: TooltipDirections) {
    return !this.suiTooltipDisabled && this.suiTooltipDirection === direction;
  }

  @HostBinding('class.tooltipped')
  get showTooltip() {
    return this.suiTooltipDisabled === false;
  }

  @HostBinding('attr.aria-label')
  get ariaLabel() {
    return this.suiTooltipDisabled ? '' : this.suiTooltip;
  }

  @HostBinding('class.tooltipped-n')
  get isNoth() {
    return this.isDirection('north');
  }

  @HostBinding('class.tooltipped-e')
  get isEast() {
    return this.isDirection('east');
  }

  @HostBinding('class.tooltipped-s')
  get isSouth() {
    return this.isDirection('south');
  }

  @HostBinding('class.tooltipped-w')
  get isWest() {
    return this.isDirection('west');
  }
}

export const TOOLTIP_DIRECTIVES = [TooltipDirective];
