import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckboxComponent } from './checkbox.component';

const CHECKBOX_DIRECTIVES = [CheckboxComponent];

@NgModule({
    imports: [MatCheckboxModule, CHECKBOX_DIRECTIVES],
    exports: [CHECKBOX_DIRECTIVES],
})
export class SuiCheckboxModule {}
