import { NgModule } from '@angular/core';
import { SuiSliderModule } from '../slider';
import { COLOR_SLIDER_DIRECTIVES } from './color-slider.component';

@NgModule({
  imports: [SuiSliderModule],
  declarations: [COLOR_SLIDER_DIRECTIVES],
  exports: [COLOR_SLIDER_DIRECTIVES],
})
export class SuiColorSliderModule {}
