import { Component } from '@angular/core';

@Component({
  selector: 'sui-form-header',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        padding: 20px 0;
      }

      :host ::ng-deep h1,
      :host ::ng-deep h2,
      :host ::ng-deep h3,
      :host ::ng-deep h4 {
        margin-top: 0;
        margin-bottom: 0;
        float: left;
      }

      :host ::ng-deep button,
      :host ::ng-deep a {
        float: right;
      }
    `,
  ],
})
export class FormHeaderComponent {}
