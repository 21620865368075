import {
  EmailGroupGQLModel,
  EmailGroupInternalModel,
  EmailGroupFormModel,
  EmailGroupWSModel,
} from './email-group.model';

export function createMockEmailGroupModel(
  updates?: Partial<EmailGroupInternalModel>,
): EmailGroupInternalModel {
  return {
    id: '12345',
    name: 'test email group',
    preferences: [],
    siteIds: [],
    userIds: [],
    ...updates,
  };
}

export function createMockGQLEmailGroupModel(
  updates?: Partial<EmailGroupGQLModel>,
): EmailGroupGQLModel {
  return {
    id: '12345',
    name: 'test email group',
    sites: {
      edges: [],
    },
    users: {
      edges: [],
    },
    ...updates,
  };
}

export function createMockEmailGroupFormModel(
  updates: Partial<EmailGroupFormModel> = {},
): EmailGroupFormModel {
  return {
    name: 'Mock EmailGroup',
    preferences: [],
    siteIds: [],
    userIds: [],
    ...updates,
  };
}

export function createMockEmailGroupWSModel(
  updates?: Partial<EmailGroupWSModel>,
): EmailGroupWSModel {
  return {
    id: '12345',
    organizationId: '54321',
    name: 'test email group',
    preferences: [],
    siteIds: [],
    userIds: [],
    createdAt: '2022-06-21T15:13:00-05:00',
    updatedAt: '2022-06-21T15:13:00-05:00',
    ...updates,
  };
}
