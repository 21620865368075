import { Knobs, getKnobs } from '@omni/knobs';
import { HttpMethod } from '@auth0/auth0-angular';

const apiDomain = 'spogforthe.win';
const authDomain = 'spogforthe.win';
const mapGeneratorServiceUrl = 'https://maps.simplysnapcloud.com/api/get-map-image';

export const environment = {
  production: false,
  // NB: In Javascript, bitwise 'and' is '|' (Shift + \)
  knobs: getKnobs(Knobs.SceneViews),
  apis: {
    gql: `https://api.${apiDomain}/graphql`,
    ws: `wss://ws.${apiDomain}`,
    mapGeneratorService: mapGeneratorServiceUrl,
  },
  auth: {
    clientId: 'EiWgnHAnyh9qFtgVWIsNlJuu713yR5cY',
    domain: `login.${authDomain}`,
    scope: 'openid profile email user_id',
    useRefreshTokens: true,
    redirectUri: `${location.origin}/user`,
    target: `${location.origin}/user/select-site`,
  },
  httpInterceptor: {
    allowedList: [
      `https://api.${apiDomain}/graphql`,
      {
        uri: mapGeneratorServiceUrl,
        httpMethod: HttpMethod.Post,
        allowAnonymous: true,
      },
    ],
  },
  firebase: {
    apiKey: 'AIzaSyClfolEiCmHKF6lsAcdUGM10JNzqOLdkJ0',
    authDomain: 'simplysnapcloud-dev.firebaseapp.com',
    projectId: 'simplysnapcloud-dev',
    storageBucket: 'simplysnapcloud-dev.appspot.com',
    messagingSenderId: '289687778292',
    appId: '1:289687778292:web:6783eaf8e8d97ad91e7fa1',
    measurementId: 'G-4R5QBJ6VPT',
    functionHost: 'https://us-central1-simplysnapcloud-dev.cloudfunctions.net',
  },
  microFrontends: {
    energyOverview: 'http://localhost:8080',
  },
};
