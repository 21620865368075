import { Component, HostBinding, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'sui-nav-submenu',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      :host ::ng-deep a {
        display: block;
        font-size: 13px;
        padding: 10px 0 10px 86px;
        text-decoration: none;
        transition: color 150ms, background-color 150ms;
        color: var(--color-foreground-text);
      }

      :host ::ng-deep a:active {
        background-color: rgba(0, 0, 0, 0.05);
      }

      :host ::ng-deep a:hover,
      :host ::ng-deep a.active {
        color: var(--color-500);
      }
    `,
  ],
  animations: [
    trigger('flyout', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1.0,
        }),
      ),
      state(
        'closed',
        style({
          height: 0,
          opacity: 0,
        }),
      ),
      transition('open <=> closed', animate(150)),
    ]),
  ],
})
export class NavSubmenuComponent {
  @Input()
  open: boolean;

  @HostBinding('@flyout')
  get flyoutState() {
    return this.open ? 'open' : 'closed';
  }
}
