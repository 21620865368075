import { createMockResourceGroupGQLModel } from '@spog-ui/shared/models/resource-groups';
import { createMockEnergyConsumptions } from '@spog-ui/shared/models/energy-consumptions';
import { createMockGqlControlZone } from '@spog-ui/shared/models/zones';
import { DepartmentFormModel, DepartmentWSModel } from './department.model';
import { DepartmentInternalModel } from '@spog-ui/shared/models/resource-groups';

export function createMockDepartmentFormModel(
  updates: Partial<DepartmentFormModel> = {},
): DepartmentFormModel {
  return {
    name: 'Mock Department',
    resourceGroupIds: [],
    zoneIds: [],
    energyProfileEquipmentIds: [],
    ...updates,
  };
}

export function createMockDepartments(): DepartmentInternalModel[] {
  return [
    {
      id: 'mock-department-id-1',
      name: 'Mock Department 1',
      zoneIds: ['mock-zone-id-1', 'mock-zone-id-2', 'mock-zone-id-3'],
      resourceGroupIds: ['mock-rg-id-1', 'mock-rg-id-2', 'mock-rg-id-3'],
      energyProfileEquipmentIds: ['mock-rg-id-1'],
      energyConsumption: createMockEnergyConsumptions()[0],
    },
    {
      id: 'mock-department-id-2',
      name: 'Mock Department 2',
      zoneIds: ['mock-zone-id-4', 'mock-zone-id-5', 'mock-zone-id-6'],
      resourceGroupIds: ['mock-rg-id-1', 'mock-rg-id-2', 'mock-rg-id-3'],
      energyProfileEquipmentIds: ['mock-rg-id-2'],
      energyConsumption: createMockEnergyConsumptions()[1],
    },
  ];
}

export function createMockDepartment(): DepartmentInternalModel {
  return {
    id: 'mock-department-id-1',
    name: 'Mock Department 1',
    zoneIds: ['mock-zone-id-1', 'mock-zone-id-2', 'mock-zone-id-3'],
    resourceGroupIds: ['mock-rg-id-1', 'mock-rg-id-2', 'mock-rg-id-3'],
    energyProfileEquipmentIds: ['mock-rg-id-3'],
    energyConsumption: createMockEnergyConsumptions()[0],
  };
}

export function createMockDepartmentWSModel(
  updates?: Partial<DepartmentWSModel>,
): DepartmentWSModel {
  return {
    id: 'mock-department-id',
    name: 'Test Department',
    siteId: 'mock-site-id',
    zoneIds: [createMockGqlControlZone()].map(zone => zone.id),
    resourceGroupIds: [createMockResourceGroupGQLModel()].map(rg => rg.id),
    energyProfileEquipmentIds: [],
    ...updates,
  };
}
