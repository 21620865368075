import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { ENTER, ESCAPE, TAB } from '@angular/cdk/keycodes';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sui-percent-slider',
  template: ` <span (click)="onInputClick()" *ngIf="!inputVisible || isDisabled">
      <em>{{ control.value }}%</em>
    </span>
    <mat-form-field *ngIf="inputVisible && !isDisabled">
      <input
        matInput
        type="number"
        min="{{ minValue }}"
        max="{{ maxValue }}"
        (keydown)="onKeydown($event)"
        (blur)="onBlur()"
      />
    </mat-form-field>
    <sui-slider
      [value]="control.value"
      (update)="onSlide($event)"
      [min]="minValue"
      [max]="maxValue"
      [isDisabled]="isDisabled"
    ></sui-slider>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PercentSliderComponent,
    },
  ],
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        height: 48px;
      }

      span {
        font-size: 16px;
        color: var(--color-foreground-text);
        cursor: pointer;
        display: block;
        width: 40px;
        text-align: right;
        margin-right: 10px;
      }

      span em {
        font-style: normal;
      }

      span:hover em {
        border-bottom: 2px solid var(--color-500);
      }

      mat-form-field {
        margin-right: 10px;
      }

      sui-slider {
        flex-grow: 2;
        transition: width 150ms;
      }

      :host ::ng-deep .mat-form-field-infix {
        width: 50px;
      }
    `,
  ],
})
export class PercentSliderComponent
  implements AfterViewInit, ControlValueAccessor, OnInit, OnDestroy
{
  private formControlSubscription = new Subscription();
  @Input() minValue = 0;
  @Input() maxValue = 100;
  @Input() isDisabled = false;
  inputVisible = false;
  control = new UntypedFormControl(this.minValue);
  @ViewChildren(MatInput) inputs: QueryList<MatInput>;

  private onChangeCallbackFn = (val: any): void => void 0;
  private onTouchedCallbackFn = (): void => void 0;

  ngOnInit(): void {
    const onChangeSubscription = this.control.valueChanges.subscribe(value =>
      this.onChangeCallbackFn(value),
    );

    this.formControlSubscription.add(onChangeSubscription);
  }

  ngOnDestroy(): void {
    this.formControlSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.inputs.changes.subscribe(() => {
      this.inputs.forEach(input => {
        input.focus();
      });
    });
  }

  onInputClick(): void {
    this.inputVisible = true;
  }

  onSlide(newValue: number): void {
    this.writeValue(newValue);
    this.inputVisible = false;
  }

  onKeydown($event: KeyboardEvent): void {
    if ($event.keyCode === ENTER) {
      $event.stopPropagation();
      this.inputVisible = false;
      this.parseInput(($event as any).target['value']);
    } else if ($event.keyCode === ESCAPE || $event.keyCode === TAB) {
      $event.stopPropagation();
      this.inputVisible = false;
    }
  }

  parseInput(value: string): void {
    const parsed = parseInt(value, 10);

    if (Number.isNaN(parsed)) {
      return;
    }

    const clamped = Math.max(Math.min(parsed, this.maxValue), this.minValue);

    this.writeValue(clamped);
  }

  onBlur(): void {
    this.inputVisible = false;
  }

  registerOnTouched(onTouchedCallbackFn: any): void {
    this.onTouchedCallbackFn = onTouchedCallbackFn;
  }

  registerOnChange(onChangeCallbackFn: any): void {
    this.onChangeCallbackFn = onChangeCallbackFn;
  }

  writeValue(value: number): void {
    this.control.patchValue(value);
  }
}
