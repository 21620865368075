import {
  SequenceSceneInternalModel,
  SequenceSceneWSModel,
  SequenceSceneGQLModel,
  SequenceSceneFormModel,
  SequenceSceneViewModel,
} from './sequence-scenes.model';

export function createMockSequenceSceneModel(
  updates: Partial<SequenceSceneInternalModel> = {},
): SequenceSceneInternalModel {
  return {
    id: 'mock-sequence-scene-id',
    name: 'Mock Sequence Scene',
    steps: [
      {
        stepNumber: 1,
        applySceneId: 'start-scene-id',
      },
      {
        stepNumber: 2,
        duration: 123,
      },
      {
        stepNumber: 3,
        applySceneId: 'end-scene-id',
      },
    ],
    loop: true,
    loopWaitHours: 4,
    loopWaitMinutes: 5,
    loopWaitSeconds: 6,
    ...updates,
  };
}

export function createMockSequenceSceneViewModel(
  updates: Partial<SequenceSceneViewModel> = {},
): SequenceSceneViewModel {
  return {
    id: 'mock-sequence-scene-id',
    name: 'Mock Sequence Scene',
    state: 'ready',
    steps: [
      {
        stepNumber: 1,
        applySceneId: 'start-scene-id',
      },
      {
        stepNumber: 2,
        duration: 123,
      },
      {
        stepNumber: 3,
        applySceneId: 'end-scene-id',
      },
    ],
    loop: true,
    loopWaitHours: 4,
    loopWaitMinutes: 5,
    loopWaitSeconds: 6,
    ...updates,
  };
}

export function createMockSequenceSceneGQLModel(
  update: Partial<SequenceSceneGQLModel> = {},
): SequenceSceneGQLModel {
  return {
    id: 'mock-sequence-scene-id',
    name: 'Mock Sequence Scene',
    steps: [
      {
        __typename: 'ApplySceneStep',
        stepNumber: 1,
        scene: {
          id: 'start-scene-id',
        },
      },
      {
        __typename: 'WaitStep',
        stepNumber: 2,
        duration: 123,
      },
      {
        __typename: 'ApplySceneStep',
        stepNumber: 3,
        scene: {
          id: 'end-scene-id',
        },
      },
    ],
    loop: false,
    ...update,
  };
}

export function createMockSequenceSceneWSModel(
  update: Partial<SequenceSceneWSModel> = {},
): SequenceSceneWSModel {
  return {
    id: 'mock-sequence-scene-id',
    name: 'Mock Sequence Scene',
    steps: [
      {
        stepNumber: 1,
        sceneId: 'start-scene-id',
      },
      {
        stepNumber: 2,
        duration: 123,
      },
      {
        stepNumber: 3,
        sceneId: 'end-scene-id',
      },
    ],
    loop: false,
    ...update,
  };
}

export function createMockSequenceSceneFormModel(): SequenceSceneFormModel {
  return {
    id: 'mock-sequence-scene-id',
    name: 'Mock Sequence Scene Form',
    startSceneId: 'start-scene-id',
    steps: [],
    loop: false,
    loopWaitHours: 0,
    loopWaitMinutes: 0,
    loopWaitSeconds: 0,
  };
}
