import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import { SiteMapPageActions } from '@spog-ui/site-selector/actions';
import * as mapboxgl from 'mapbox-gl';

const BLUE = '#00aff0';
const RED = '#f04b23';

class ClickableMarker extends mapboxgl.Marker {
  _handleClick: any;
  _element: any;

  // new method onClick, sets _handleClick to a function you pass in
  onClick(handleClick: CallableFunction) {
    this._handleClick = handleClick;
    return this;
  }

  // the existing _onMapClick was there to trigger a popup
  // but we are hijacking it to run a function we define
  _onMapClick(e: any) {
    const targetElement = e.originalEvent.target;
    const element = this._element;

    if (
      this._handleClick &&
      (targetElement === element || element.contains(targetElement))
    ) {
      this._handleClick();
    }
  }
}

@Component({
  selector: 'spog-site-map-page',
  template: `
    <sui-charm-filter
      type="Sites"
      [term]="searchTerm"
      [options]="siteNames"
      (filter)="onFilter($event)"
    ></sui-charm-filter>
    @if (loading$ | async) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    } @else {
      @if (siteFeatures.length === 0) {
        <spog-no-sites />
      }
    }
    <div class="map" id="map" class="map"></div>
    
    <router-outlet></router-outlet>
    <sui-wide-status-tray>
      <div>
        <spog-admin-link></spog-admin-link>
        <spog-eula-link></spog-eula-link>
      </div>
      <button
        class="cancelButton"
        type="button"
        mat-button
        color="accent"
        (click)="onCancel()"
      >
        {{ forChange() ? 'Cancel' : 'Log Out' }}
      </button>
    </sui-wide-status-tray>
  `,
  styles: [
    `
      h2 {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid var(--color-foreground-divider);
      }

      sui-spinner {
        width: 50px;
        display: block;
        margin: 60px auto 0;
      }

      .map {
        width: 100%;
        height: calc(100% - 64px);
      }

      spog-admin-link {
        padding-right: 16px;
      }

      spog-no-sites {
        position: absolute;
        z-index: 1;
        left: calc(50% - 150px);
        top: calc(50% - 125px - 64px);
        width: 300px;
        height: 250px;
      }
    `,
  ],
})
export class SiteMapPageComponent implements OnInit, AfterViewInit {
  constructor(
    private store: Store,
    readonly router: Router,
    readonly route: ActivatedRoute,
    private location: Location,
  ) {}

  map: mapboxgl.Map | undefined;
  style = 'mapbox://styles/synapse-wireless/ck0h5j5ay45g11cpmjien6ru1';
  lat = 34.65;
  lng = -86.7766;

  searchTerm = '';
  siteNames: string[] = [];
  siteFeatures: any[] = [];

  initialBounding = false;

  loading$ = this.store.select(CoreState.selectSitesLoading);
  sites$ = this.store.select(CoreState.selectSiteDetailsViewsForSiteMap);
  isSiteChange$ = this.store.select(CoreState.selectRouterData);

  onFilter(searchTerm: string) {
    this.searchTerm = searchTerm;

    this.updateSiteList(true);
  }

  ngOnInit() {
    this.store.dispatch(SiteMapPageActions.enterAction());
  }

  forChange() {
    const currentUrl = this.location.path();

    const changeIndex = currentUrl.lastIndexOf('/change');

    return changeIndex != -1;
  }

  onCancel() {
    if (this.forChange()) {
      // TODO: what does this really need to cause to happen, though?
      this.store.dispatch(SiteMapPageActions.cancelSiteChangeAction());
    } else {
      this.store.dispatch(SiteMapPageActions.cancelSiteSelectAction());
    }
  }

  updateSiteList(filterChanged = false) {
    const filteredFeatures = this.siteFeatures.filter(siteFeature =>
      siteFeature.properties.title
        .toLocaleLowerCase()
        .includes(this.searchTerm.toLocaleLowerCase()),
    );

    (this.map?.getSource('Sites') as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features: filteredFeatures,
    });

    // Don't keep fitting bounds as websocket and other updates occur
    if (!this.initialBounding || filterChanged) {
      this.map?.fitBounds(getBoundingBox(filteredFeatures), {
        padding: 96,
        maxZoom: 15,
      });

      this.initialBounding = true;
    }
  }

  ngAfterViewInit() {
    this.map = new mapboxgl.Map({
      accessToken:
        'pk.eyJ1Ijoic3luYXBzZS13aXJlbGVzcyIsImEiOiJjbHgzdXVucnowbnh0MmtvdDljbm1oczVrIn0.g7DEHjieW1I-Idwgyzp2Aw',
      container: 'map',
      style: this.style,
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    const isAlarmed = ['all', ['get', 'alarmed'], true];
    const isNotAlarmed = ['!', ['get', 'alarmed']];

    this.map.on('load', () => {
      const map = this.map!;

      const sourceDataBase = {
        type: 'geojson',
        generateId: true,
        cluster: true,
        clusterRadius: 80,
        clusterProperties: {
          alarmed: ['+', ['case', isAlarmed, 1, 0]],
          unalarmed: ['+', ['case', isNotAlarmed, 1, 0]],
        },
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      };

      map.addSource('Sites', sourceDataBase as any);

      // Update geojson when sites state changes
      this.sites$.subscribe(sites => {
        this.siteFeatures = sites
          .filter(site => site.longitude != null && site.latitude != null)
          .map(site => {
            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [site.longitude, site.latitude],
              },
              properties: {
                title: site.name,
                alarmed: ['OFFLINE', 'DEGRADED', 'INIT FAILED'].includes(site.status),
                id: site.id,
              },
            };
          });

        this.siteNames = sites.map(site => site.name);

        this.updateSiteList();
      });

      map.addLayer({
        id: 'Sites_unclustered',
        interactive: true,
        type: 'circle', // circle marker types
        source: 'Sites', // reference the data source
        layout: {},
        filter: ['!=', 'cluster', true],
        paint: {
          'circle-color': ['case', isAlarmed, RED, BLUE],
          'circle-radius': 12,
          'circle-stroke-width': 2,
          'circle-stroke-color': 'white',
        },
      });

      map.addLayer({
        id: 'Sites_clustered',
        interactive: true,
        type: 'symbol',
        source: 'Sites',
        filter: ['!=', 'cluster', true],
        layout: {
          'text-field': ['get', 'title'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 14,
          'text-offset': [0, -2.5],
        },
        paint: {
          'text-color': 'black',
        },
      });

      // objects for caching and keeping track of HTML marker objects (for performance)
      const markers: any = {};
      let markersOnScreen: any = {};

      function updateMarkers(map: any) {
        const newMarkers: any = {};
        const features = map.querySourceFeatures('Sites');

        // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
        // and add it to the map if it's not there already
        for (const feature of features) {
          const coords = feature.geometry.coordinates;
          const props = feature.properties;

          if (props.cluster) {
            const id = props.cluster_id;

            let marker = markers[id];
            if (!marker) {
              const el = createDonutChart(props);
              marker = markers[id] = new ClickableMarker({
                element: el,
              })
                .setLngLat(coords)
                .onClick(() => {
                  // Zoom in just enough to make the cluster expand
                  (
                    map.getSource('Sites') as mapboxgl.GeoJSONSource
                  ).getClusterExpansionZoom(id, (err, zoom) => {
                    if (err) return;

                    map.easeTo({
                      center: (feature.geometry as any).coordinates,
                      zoom: zoom,
                    });
                  });
                });
            }
            newMarkers[id] = marker;

            if (!markersOnScreen[id]) marker.addTo(map);
          }
        }
        // for every marker we've added previously, remove those that are no longer visible
        for (const id in markersOnScreen) {
          if (!newMarkers[id]) markersOnScreen[id].remove();
        }
        markersOnScreen = newMarkers;
      }

      // after the GeoJSON data is loaded, update markers on the screen on every frame
      map.on('render', () => {
        if (!map.isSourceLoaded('Sites')) return;
        updateMarkers(map);
      });

      // Center the map on the coordinates of any clicked circle from the 'circle' layer.
      map.on('click', 'Sites_unclustered', e => {
        const feature = (e as any).features[0];

        map.flyTo({
          center: (e as any).features[0].geometry.coordinates,
        });

        this.router.navigate([`details/${feature.properties.id}`], {
          relativeTo: this.route,
        });
      });

      map.on('click', e => {
        // A click event includes all feature layers (roads, names, rail, etc) below
        // the click.  This checks that none of them are sites icons, which means
        // this click occured "off" a site.
        if (
          map
            .queryRenderedFeatures(e.point)
            .filter(feature => feature.layer.id === 'Sites_unclustered').length === 0
        ) {
          const currentUrl = this.location.path();

          const detailsIndex = currentUrl.lastIndexOf('/details');

          if (detailsIndex != -1) {
            this.router.navigateByUrl(currentUrl.slice(0, detailsIndex));
          }
        }
      });
    });
  }
}

function getBoundingBox(features: any) {
  const bounds: any = {};
  let coords, latitude, longitude;

  for (let i = 0; i < features.length; i++) {
    coords = features[i].geometry.coordinates;

    longitude = coords[0];
    latitude = coords[1];

    bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
    bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
    bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
    bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
  }

  return new mapboxgl.LngLatBounds([
    [bounds.xMin, bounds.yMin],
    [bounds.xMax, bounds.yMax],
  ]);
}

// code for creating an SVG donut chart from feature properties
function createDonutChart(props: any): any {
  const offsets = [];
  const counts = [props.alarmed, props.unalarmed];
  let total = 0;
  for (const count of counts) {
    offsets.push(total);
    total += count;
  }
  const fontSize = 16;
  const r = 36;
  const r0 = Math.round(r * 0.6);
  const w = r * 2;

  let html = `<div>
      <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

  for (let i = 0; i < counts.length; i++) {
    html += donutSegment(
      offsets[i] / total,
      (offsets[i] + counts[i]) / total,
      r,
      r0,
      i ? BLUE : RED,
    );
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
      <text dominant-baseline="central" transform="translate(${r}, ${
    r - 8
  })" font-size="12px"> Sites </text>
      <text dominant-baseline="central" transform="translate(${r}, ${r + 4})">
          ${total.toLocaleString()}
      </text>
      </svg>
      </div>`;

  const el = document.createElement('div');
  el.innerHTML = html;
  return el.firstChild;
}

function donutSegment(start: number, end: number, r: number, r0: number, color: string) {
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  // draw an SVG path
  return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
    r + r * y0
  } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1} ${
    r + r0 * y1
  } A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}" fill="${color}" />`;
}
