import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import {
  DimmerControlComponent,
  SuiFormsModule,
  SuiValidatorsModule,
} from '@spog-ui/shared/components';
import {
  CanAddLightDirective,
  ExceptPipe,
  LightFormComponent,
  UniqueLightNameDirective,
  ZoneLimitDirective,
} from './light-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatExpansionModule,
    MatDividerModule,
    DimmerControlComponent,
    SuiValidatorsModule,
    SuiFormsModule,
  ],
  declarations: [
    LightFormComponent,
    CanAddLightDirective,
    UniqueLightNameDirective,
    ZoneLimitDirective,
    ExceptPipe,
  ],
  exports: [LightFormComponent],
})
export class LightFormComponentModule {}
