import { DateTime } from 'luxon';
import {
  BillingFrequency,
  CreateScheduledUtilityRateInput,
  ScheduledUtilityRateFormModel,
  ScheduledUtilityRateGQLModel,
  ScheduledUtilityRateViewModel,
  ScheduledUtilityRateWSModel,
} from './scheduled-utility-rate.model';

export function createMockScheduledUtilityRateFormModel(
  updates: Partial<ScheduledUtilityRateFormModel> = {},
): ScheduledUtilityRateFormModel {
  return {
    startDate: DateTime.fromISO('2020-06-01T00:00:00Z').toJSDate(),
    energyUtilityRateType: 'Flat',
    costOfDemand: '12.34',
    costOfEnergy: '0.05',
    openEIUrl: 'https://apps.openei.org/USURDB/rate/view/5d24fc385457a3771cdc131e',
    ...updates,
  };
}

export function createMockScheduledUtilityRateViewModel(
  updates: Partial<ScheduledUtilityRateViewModel> = {},
): ScheduledUtilityRateViewModel {
  return {
    id: 'mock-scheduled-utility-rate-view-model',
    startDate: DateTime.local(),
    utilityServiceId: 'mock-utility-service-id',
    flatEnergyUtilityRate: {
      costOfDemand: '$0.231',
      costOfEnergy: '$0.00',
    },
    ...updates,
  };
}

export function createMockCreateScheduledUtilityRateInput(
  updates: Partial<CreateScheduledUtilityRateInput> = {},
): CreateScheduledUtilityRateInput {
  return {
    utilityServiceId: 'mock-utility-service',
    billingFrequency: BillingFrequency.MONTHLY,
    startDate: DateTime.local().toISO(),
    flatEnergyUtilityRate: {
      costOfDemand: '$0.12',
      costOfEnergy: '$2.39',
    },
    ...updates,
  };
}

export function createMockScheduledUtilityServiceGQLModel(
  updates: Partial<ScheduledUtilityRateGQLModel> = {},
): ScheduledUtilityRateGQLModel {
  return {
    id: 'mock-utility-service-id',
    scheduledUtilityRates: [
      {
        id: 'mock-scheduled-utility-rate-id',
        billingFrequency: BillingFrequency.MONTHLY,
        startDate: DateTime.local().toISO(),
        utilityRate: {
          costOfEnergy: '$0.12',
          costOfDemand: '$2.13',
        },
      },
    ],
    ...updates,
  };
}

export function createMockScheduledUtilityRateWSModel(
  updates: Partial<ScheduledUtilityRateWSModel> = {},
): ScheduledUtilityRateWSModel {
  return {
    id: 'mock-scheduled-utility-rate-id',
    siteId: 'mock-site-id',
    utilityServiceId: 'mock-utility-service-id',
    billingFrequency: BillingFrequency.MONTHLY,
    startDate: DateTime.local().toISO(),
    flatEnergyUtilityRate: {
      costOfEnergy: '$0.12',
      costOfDemand: '$2.13',
    },
    ...updates,
  };
}
