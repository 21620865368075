import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DIMMER_DIRECTIVES } from './dimmer.component';
import { SuiSliderModule } from '../slider';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    SuiSliderModule,
  ],
  declarations: [DIMMER_DIRECTIVES],
  exports: [DIMMER_DIRECTIVES],
})
export class SuiDimmerModule {}
