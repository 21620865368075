import {
  CommissioningStatus,
  ThermostatAlarmModelFragment,
  ThermostatAlarmReason,
  ThermostatFanMode,
} from '@spog-ui/graphql/types';
import { createMockGQLSiteController } from '@spog-ui/shared/models/site-controllers';
import {
  ThermostatAlarmInternalModel,
  ThermostatAlarmWSModel,
  ThermostatControlModeInternalModel,
  ThermostatFormModel,
  ThermostatGQLModel,
  ThermostatInternalModel,
  ThermostatViewModel,
  ThermostatWSModel,
} from './thermostat.model';

export function createMockThermostats(): ThermostatInternalModel[] {
  return [
    {
      id: '1',
      name: 'Commissioned Thermostat 1',
      controlMode: {
        coolF: 43,
        heatF: 73,
        fanMode: ThermostatFanMode.ON,
      },
      fallbackControlMode: {
        coolF: 42,
        heatF: 72,
        fanMode: ThermostatFanMode.AUTO,
      },
      floorPlanId: 'mock-floor-plain-id-1',
      floorPlanX: null,
      floorPlanY: null,
      commissioningStatus: CommissioningStatus.COMMISSIONED,
      snapaddr: '123abc',
      siteControllerId: '456ac',
      state: null,
      make: null,
      model: null,
      totalAlarms: 3,
    },
    {
      id: '2',
      name: 'Connected Thermostat 2',
      controlMode: {
        coolF: 40,
        heatF: 74,
        fanMode: ThermostatFanMode.AUTO,
      },
      fallbackControlMode: {
        coolF: 43,
        heatF: 73,
        fanMode: ThermostatFanMode.ON,
      },
      floorPlanId: '1324',
      floorPlanX: 40,
      floorPlanY: 55,
      commissioningStatus: CommissioningStatus.CONNECTED,
      snapaddr: '45678',
      siteControllerId: '456ac',
      state: {
        temperatureF: 73,
        humidity: 30,
        lastMessageReceived: '2019-03-10T02:00:00Z',
      },
      make: 'make',
      model: 'model',
    },
    {
      id: '3',
      name: 'Precommissioned Thermostat 3',
      controlMode: {
        coolF: 39,
        heatF: 75,
        fanMode: ThermostatFanMode.AUTO,
      },
      fallbackControlMode: {
        coolF: 35,
        heatF: 67,
        fanMode: ThermostatFanMode.AUTO,
      },
      commissioningStatus: CommissioningStatus.PRE_COMMISSIONED,
      snapaddr: null,
      floorPlanId: 'mock-floor-plain-id-2',
      floorPlanX: null,
      floorPlanY: null,
      state: null,
      make: null,
      model: null,
      totalAlarms: 1,
    },
  ];
}

export function createMockThermostatFormModel(
  updates: Partial<ThermostatFormModel> = {},
): ThermostatFormModel {
  return {
    id: 'mock-thermostat-id',
    name: 'Mock Resource Group',
    fallbackControlMode: {
      coolF: 85,
      heatF: 65,
      fanMode: ThermostatFanMode.AUTO,
    },
    snapaddr: '45678',
    siteControllerId: 'mock-site-controller-id',
    ...updates,
  };
}

export function createMockGqlThermostat(): ThermostatGQLModel {
  return {
    __typename: 'Thermostat',
    id: 'mock-thermostat-id',
    name: 'Mock Thermostat Name',
    snapaddr: '123456',
    commissioningStatus: CommissioningStatus.COMMISSIONED,
    siteController: createMockGQLSiteController(),
    controlMode: {
      __typename: 'ThermostatControlMode',
      fanMode: ThermostatFanMode.AUTO,
      cool: { __typename: 'Temperature', F: 75 },
      heat: { __typename: 'Temperature', F: 70 },
    },
    fallbackControlMode: {
      __typename: 'ThermostatControlMode',
      fanMode: ThermostatFanMode.AUTO,
      cool: { __typename: 'Temperature', F: 85 },
      heat: { __typename: 'Temperature', F: 65 },
    },
  };
}

export function createMockThermostatAlarmModelFragment(): ThermostatAlarmModelFragment {
  return {
    __typename: 'ThermostatAlarm',
    id: 'mock-thermostat-id',
    reason: ThermostatAlarmReason.COMMUNICATION_FAILURE,
    triggerAt: '2021-01-10T16:08:00',
    siteController: { __typename: 'SiteController', id: 'mock-site-controller-id' },
  };
}

export function createMockThermostatControlModeInternalModel(
  changes: Partial<ThermostatControlModeInternalModel> = {},
): ThermostatControlModeInternalModel {
  return {
    coolF: 75,
    heatF: 55,
    fanMode: ThermostatFanMode.AUTO,
    ...changes,
  };
}

export function createMockThermostatViewModel(
  changes: Partial<ThermostatViewModel> = {},
): ThermostatViewModel {
  return {
    id: 'mock-thermostat-id',
    name: 'Mock Thermostat',
    make: 'Honeywell',
    model: 'BACnet FF',
    controlMode: createMockThermostatControlModeInternalModel(changes.controlMode),
    fallbackControlMode: {
      coolF: 75,
      heatF: 55,
      fanMode: ThermostatFanMode.AUTO,
    },
    siteControllerId: 'mock-site-controller-id',
    siteControllerName: 'mock-site-controller-name',
    commissioningStatus: CommissioningStatus.COMMISSIONED,
    snapaddr: 'aabbcc',
    ...changes,
  };
}

export function createMockThermostatViewModels(): ThermostatViewModel[] {
  return [
    {
      id: 'mock-thermostat-id',
      name: 'Mock Thermostat',
      make: 'Honeywell',
      model: 'BACnet FF',
      controlMode: {
        coolF: 75,
        heatF: 55,
        fanMode: ThermostatFanMode.AUTO,
      },
      fallbackControlMode: {
        coolF: 75,
        heatF: 55,
        fanMode: ThermostatFanMode.AUTO,
      },
      siteControllerId: 'mock-site-controller-id',
      siteControllerName: 'mock-site-controller-name',

      snapaddr: 'aabbcc',
      commissioningStatus: CommissioningStatus.COMMISSIONED,
    },
    {
      id: 'mock-thermostat-id-2',
      name: 'Mock Thermostat 2',
      make: 'Honeywell',
      model: 'BACnet FF',
      controlMode: {
        coolF: 73,
        heatF: 59,
        fanMode: ThermostatFanMode.ON,
      },
      fallbackControlMode: {
        coolF: 71,
        heatF: 47,
        fanMode: ThermostatFanMode.ON,
      },
      siteControllerId: null,
      siteControllerName: null,

      snapaddr: 'ddeeff',
      commissioningStatus: CommissioningStatus.PRE_COMMISSIONED,
    },
  ];
}

export function createMockInternalThermostat(): ThermostatInternalModel {
  return {
    id: 'mock-thermostat-id',
    name: 'Mock Thermostat Name',
    snapaddr: '123456',
    controlMode: {
      coolF: 75,
      heatF: 70,
      fanMode: ThermostatFanMode.AUTO,
    },
    fallbackControlMode: {
      coolF: 85,
      heatF: 65,
      fanMode: ThermostatFanMode.AUTO,
    },
    floorPlanId: null,
    floorPlanX: null,
    floorPlanY: null,
    commissioningStatus: CommissioningStatus.COMMISSIONED,
    siteControllerId: '456ac',
    state: null,
    make: null,
    model: null,
  };
}

export function createMockThermostatWSModel(
  updates: Partial<ThermostatWSModel> = {},
): ThermostatWSModel {
  return {
    id: 'mock-thermostat-id',
    name: 'Mock Thermostat',
    snapaddr: 'abc123',
    siteControllerId: 'mock-site-controller-id',
    controlMode: {
      coolF: 75,
      heatF: 70,
      fanMode: ThermostatFanMode.AUTO,
    },
    fallbackControlMode: {
      coolF: 85,
      heatF: 65,
      fanMode: ThermostatFanMode.AUTO,
    },
    commissioningStatus: CommissioningStatus.COMMISSIONED,
    floorPlanLocation: {
      x: 123,
      y: 456,
      floorPlanId: 'floor-plan-id',
    },
    state: {
      temperatureF: 68,
      humidity: 43,
      lastMessageReceived: '2019-03-10T02:00:00Z',
    },
    make: 'make',
    model: 'model',

    ...updates,
  };
}

export function createMockThermostatAlarms(): ThermostatAlarmInternalModel[] {
  return [
    {
      id: 'mock-alarm-id-1',
      reason: ThermostatAlarmReason.COMMUNICATION_FAILURE,
      triggerAt: '2021-01-10T02:00:00Z',
      siteControllerId: 'mock-site-controller-id',
      thermostatId: 'mock-thermostat-id-1',
    },
    {
      id: 'mock-alarm-id-2',
      reason: ThermostatAlarmReason.CONFIGURATION_FAILURE,
      triggerAt: '2021-01-11T02:00:00Z',
      siteControllerId: 'mock-site-controller-id',
      thermostatId: 'mock-thermostat-id-2',
    },
    {
      id: 'mock-alarm-id-3',
      reason: ThermostatAlarmReason.LINK_QUALITY_WARNING,
      triggerAt: '2021-01-11T02:00:00Z',
      siteControllerId: 'mock-site-controller-id',
      thermostatId: 'mock-thermostat-id-3',
    },
    {
      id: 'mock-alarm-id-4',
      reason: ThermostatAlarmReason.MISSING_SCRIPT_FAILURE,
      triggerAt: '2021-01-12T02:00:00Z',
      siteControllerId: 'mock-site-controller-id',
      thermostatId: 'mock-thermostat-id-4',
    },
  ];
}

export function createMockWSThermostatAlarmStatusChange(
  updates: Partial<ThermostatAlarmWSModel> = {},
): ThermostatAlarmWSModel {
  return {
    siteControllerId: 'mock-site-controller-id',
    id: 'mock-thermostat-alarm-id',
    triggerAt: '2021-01-12T02:00:00Z',
    reason: ThermostatAlarmReason.COMMUNICATION_FAILURE,
    thermostatId: 'mock-thermostat-id',
    alarmCleared: false,
    ...updates,
  };
}
