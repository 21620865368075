import { Component, Input } from '@angular/core';

@Component({
  selector: 'sui-form-group-accordion',
  template: `
    <sui-accordion [open]="open" (toggle)="open = !open">
      <ng-template suiAccordionHeader>
        <div class="suiFormGroupAccordionName">
          {{ name }}
        </div>
      </ng-template>
      <ng-template suiAccordionBody>
        <div class="suiFormGroupAccordionContent">
          <ng-content></ng-content>
        </div>
      </ng-template>
    </sui-accordion>
  `,
  styles: [
    `
      .suiFormGroupAccordionContent {
        border-top: 1px solid var(--color-50);
        padding: 0 20px;
        display: flex;
      }

      :host sui-accordion {
        border-color: var(--color-50);
      }

      :host sui-accordion ::ng-deep .suiAccordionHeader {
        border-left: none;
      }

      :host sui-accordion ::ng-deep .suiAccordionHeader.open:after {
        border-left-color: var(--color-foreground-divider);
      }

      :host sui-accordion ::ng-deep .suiAccordionBody {
        padding: 0;
      }

      :host sui-accordion ::ng-deep .suiAccordionFooter {
        padding: 0;
      }
    `,
  ],
})
export class FormGroupAccordionComponent {
  @Input()
  name = '';
  public open = false;
}
