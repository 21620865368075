import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SettingsComponent, SettingsGroupComponent } from './settings.component';

@NgModule({
  imports: [CommonModule, MatButtonModule],
  declarations: [SettingsGroupComponent, SettingsComponent],
  exports: [SettingsGroupComponent, SettingsComponent],
})
export class SuiSettingsModule {}
