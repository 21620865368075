import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'suiFilterByName' })
export class FilterByNamePipe implements PipeTransform {
  transform<T extends { name: string }>(
    items: T[] | null | undefined,
    searchText: string,
  ): T[] {
    if (!items || items.length === 0) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();

    return items.filter(item => {
      return item.name.toLowerCase().includes(searchText);
    });
  }
}
