import { animate, state, style, transition, trigger } from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '../animation';

export type expandHeightAnimationStates = 'void' | 'hidden' | 'expanded' | 'contracted';

export function expandHeightAnimation(triggerName: string) {
  return trigger(triggerName, [
    state('void, hidden', style({ height: 0 })),
    state('expanded', style({ height: '*' })),
    state('contracted', style({ height: 0 })),
    transition('* <=> *', [
      animate(`${AnimationDurations.ENTERING} ${AnimationCurves.EASE_OUT_EXPONENTIAL}`),
    ]),
  ]);
}
