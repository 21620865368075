import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UntypedFormControl } from '@angular/forms';
import { CustomValidators } from '../services';

@Component({
  selector: 'sui-error-messages',
  template: `
    <div
      class="help-block"
      [@expanding]="errorMessage && touched ? 'expanded' : 'collapsed'"
    >
      {{ errorMessage }}
    </div>
  `,
  styles: [
    `
      .help-block {
        color: var(--color-warn-500);
        overflow: hidden;
        margin: 0;
      }
    `,
  ],
  animations: [
    trigger('expanding', [
      state(
        'collapsed',
        style({
          height: '0px',
        }),
      ),
      state(
        'expanded',
        style({
          height: 'auto',
        }),
      ),
      transition('collapsed <=> expanded', animate(250)),
    ]),
  ],
})
export class ErrorMessagesComponent {
  @Input() control: UntypedFormControl;

  get errorMessage() {
    if (!this.control.errors) return null;

    for (const propertyName in this.control.errors) {
      if (
        Object.prototype.hasOwnProperty.call(this.control.errors, propertyName) &&
        this.touched
      ) {
        return CustomValidators.getValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName],
        );
      }
    }

    return null;
  }

  get touched(): boolean {
    return this.control ? this.control.touched : false;
  }
}
