import {
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TemplatePortalDirective } from '@angular/cdk/portal';

@Directive({ selector: '[suiButtonToggleOption]' })
export class ButtonToggleOptionDirective<T> extends TemplatePortalDirective {
  @Input()
  value: T;

  constructor(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(templateRef, viewContainerRef);
  }
}

@Component({
  selector: 'sui-button-toggle',
  template: `
    <button
      *ngFor="let option of options"
      suiButton
      [class.suiButtonToggleDisabled]="disabled"
      [attr.color]="option.value === value ? 'primary' : 'default'"
      (click)="toggle.emit(option.value)"
      [disabled]="disabled"
    >
      <ng-template [cdkPortalOutlet]="option"></ng-template>
    </button>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      button[suiButton] {
        padding: 0;
      }
    `,
  ],
})
export class ButtonToggleComponent<T> {
  @Input()
  value: T;
  @HostBinding('class.suiToggleDisabled')
  @Input()
  disabled: boolean;
  @Output()
  toggle = new EventEmitter<T>();
  @ContentChildren(ButtonToggleOptionDirective)
  options: QueryList<ButtonToggleOptionDirective<T>>;
}

export const BUTTON_TOGGLE_DIRECTIVES = [
  ButtonToggleOptionDirective,
  ButtonToggleComponent,
];
