import * as DepartmentsPageActions from './departments-page.actions';
import * as DepartmentsApiActions from './departments-api.actions';
import * as AddDepartmentPageActions from './add-department-page.actions';
import * as EditDepartmentPageActions from './edit-department-page.actions';

export {
  DepartmentsPageActions,
  DepartmentsApiActions,
  AddDepartmentPageActions,
  EditDepartmentPageActions,
};
