import { DateTime } from 'luxon';
import {
  AstroTime,
  ScheduledSceneModel,
  ScheduledSceneOccurrenceModel,
  ScheduledSceneViewModel,
  RecurrenceModel,
  Frequency,
  Day,
  OnPremScheduledEventModel,
} from './parser.model';

export function createMockOnPremScheduledEventModel(
  updates?: Partial<OnPremScheduledEventModel>,
): OnPremScheduledEventModel {
  return {
    id: 'mock-scheduled-scene-id',
    description: 'Mock Scheduled Scene',
    scene_id: 'mock-scene-id',
    dtstart: '2018-07-28T03:24:00',
    astrotime: AstroTime.Dawn,
    offset: '10',
    rrule: null,
    ...updates,
  };
}

export function createMockScheduledSceneViewModel(
  updates: Partial<ScheduledSceneViewModel> = {},
): ScheduledSceneViewModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneId: 'mock-scene-id',
    start: DateTime.fromISO('2018-07-28T03:24:00'),
    astroTime: AstroTime.Dawn,
    astroTimeOffset: 10,
    recurrence: null,
    ...updates,
  };
}
export function createMockScheduledSceneViewModelWithSpecificTime(
  updates: Partial<ScheduledSceneViewModel> = {},
): ScheduledSceneViewModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneId: 'mock-scene-id',
    start: DateTime.fromISO('2018-07-28T03:24:00'),
    astroTime: null,
    astroTimeOffset: null,
    recurrence: null,
    ...updates,
  };
}

export function createMockRecurringScheduledSceneViewModel(
  updates: Partial<ScheduledSceneViewModel> = {},
): ScheduledSceneViewModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneId: 'mock-scene-id',
    start: DateTime.fromISO('2018-07-28T03:24:00'),
    astroTime: AstroTime.Dawn,
    astroTimeOffset: 10,
    recurrence: {
      frequency: Frequency.Weekly,
      days: [Day.Monday, Day.Tuesday],
      until: null,
      excludedDates: [DateTime.fromISO('2018-07-28T03:24:00')],
    },
    ...updates,
  };
}

export function createMockScheduledSceneModel(
  updates: Partial<ScheduledSceneModel> = {},
): ScheduledSceneModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneId: 'mock-scene-id',
    start: '2018-07-28T19:45:36+00:00',
    astroTime: AstroTime.Dawn,
    astroTimeOffset: 10,
    rrule: null,
    ...updates,
  };
}

export function createMockScheduledSceneOccurrenceModel(
  updates?: Partial<ScheduledSceneOccurrenceModel>,
): ScheduledSceneOccurrenceModel {
  return {
    ...createMockScheduledSceneViewModel(),
    occurrenceDateTime: DateTime.fromISO('2018-07-28T19:45:36+00:00'),
    ...updates,
  };
}

export function createMockRecurrenceModel(
  updates: Partial<RecurrenceModel> = {},
): RecurrenceModel {
  return {
    frequency: Frequency.Weekly,
    days: [Day.Friday],
    excludedDates: [],
    until: null,
    ...updates,
  };
}
