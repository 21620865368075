import { Component } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import * as BigSelectActions from './big-select.actions';
import { BigSelectState } from './big-select.state';

@Component({
  selector: 'sui-big-select-global-checkbox',
  template: `
    <mat-checkbox
      color="primary"
      [checked]="state.globalCheckboxIsChecked$ | async"
      [indeterminate]="state.globalCheckboxIsIndeterminate$ | async"
      [disabled]="state.globalCheckboxIsDisabled$ | async"
      (click)="onCheckAll()"
    >
    </mat-checkbox>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    `,
  ],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } },
  ],
})
export class BigSelectGlobalCheckboxComponent {
  constructor(readonly state: BigSelectState) {}

  onCheckAll() {
    this.state.dispatch(BigSelectActions.checkAllAction());
  }
}
