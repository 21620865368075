import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { Subscription } from 'rxjs';

export interface CheckboxListItem {
  name: string;
  value: any;
}

@Component({
  selector: 'sui-checkbox-list',
  template: `
    <mat-form-field floatPlaceholder="never">
      <mat-select
        class="suiCheckboxListSearch"
        *ngIf="items.length === 0"
        disabled="true"
        placeholder="No {{ type }}s Found"
      >
      </mat-select>

      <mat-select
        [value]="value"
        *ngIf="items.length > 0"
        class="suiCheckboxList"
        placeholder="Select {{ type }}s"
        multiple="true"
        (onChange)="onChange($event)"
        (onOpen)="onOpen()"
      >
        <mat-select-trigger [ngPlural]="value.length">
          <ng-template ngPluralCase="=1">
            {{ value.length }} {{ type }} Selected
          </ng-template>
          <ng-template ngPluralCase="other">
            {{ value.length }} {{ type }}s Selected
          </ng-template>
        </mat-select-trigger>

        <div class="suiCheckboxListSearch">
          <input
            matInput
            placeholder="Search {{ type }}s"
            [value]="searchTerm"
            (input)="searchInput($event)"
          />
        </div>

        <sui-checkbox
          class="suiCheckboxListSelectAll"
          [formControl]="selectAllControl"
          *ngIf="(items | suiFilterByName : searchTerm).length > 0"
        >
          Select All
        </sui-checkbox>

        <div
          class="suiCheckboxListSearchEmpty mat-typography"
          *ngIf="(items | suiFilterByName : searchTerm).length === 0"
        >
          No Results Found
        </div>

        <span *ngFor="let item of items" [hidden]="!isInSearchResults(item)">
          <mat-option [value]="item">
            {{ item.name }}
          </mat-option>
        </span>
      </mat-select>
    </mat-form-field>
  `,
  styles: [
    `
      sui-checkbox.suiCheckboxListSelectAll mat-checkbox label {
        padding: 0px 0px 10px 16px;
      }

      mat-input-container.suiCheckboxListSearch {
        padding: 10px 0px 0px 16px;
      }

      .suiCheckboxListSearch {
        display: block !important;
      }

      .suiCheckboxListSearchEmpty {
        display: block !important;
        font-size: 16px;
        padding: 10px 0px 10px 16px;
        font-style: italic;
      }

      .suiCheckboxListSelectAll {
        font-style: italic;
        display: block !important;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxListComponent,
    },
  ],
})
export class CheckboxListComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  type = '';
  @Input()
  items: CheckboxListItem[];
  @Input()
  value: CheckboxListItem[] = [];
  @Input()
  searchTerm = '';
  private selectAllValueChanges$: Subscription;

  @ViewChild(MatSelect, { static: true })
  select: MatSelect;
  @ViewChild(CheckboxComponent, { static: true })
  selectAll: CheckboxComponent;

  disabled: boolean;
  onChangeFn: (value: any) => void;
  onTouchedFn: () => void;

  selectAllControl = new UntypedFormControl(false);

  ngOnInit() {
    this.selectAllValueChanges$ = this.selectAllControl.valueChanges.subscribe(value =>
      this.selectAllClicked(value),
    );
  }

  ngOnDestroy() {
    this.selectAllValueChanges$?.unsubscribe();
  }

  selectAllClicked(checked: boolean) {
    this.select.options.toArray().map(option => {
      if (
        this.isElementVisible(option._getHostElement()) &&
        checked !== option.selected
      ) {
        option['_selectViaInteraction']();
      }
    });
  }

  isInSearchResults(item: CheckboxListItem) {
    if (!this.searchTerm) {
      return true;
    }
    const searchTermLower = this.searchTerm.toLowerCase();

    return item.name.toLowerCase().includes(searchTermLower);
  }

  isElementVisible(element: HTMLElement) {
    return element.offsetParent !== null;
  }

  onChange(event: any) {
    this.value = event.value;
    this.updateSelectAllChecked();
    if (this.onChangeFn) {
      this.onChangeFn(event);
    }
  }

  searchInput(event: any) {
    this.searchTerm = event.target.value;
    this.updateSelectAllChecked();
  }

  updateSelectAllChecked() {
    if (!this.selectAll) return;

    for (const item of this.items) {
      if (this.isInSearchResults(item) && this.value.indexOf(item) === -1) {
        this.selectAll.checked = false;
        return;
      }
    }
    this.selectAll.checked = true;
  }

  onOpen() {
    this.searchTerm = '';
  }

  touched() {
    if (this.onTouchedFn) {
      this.onTouchedFn();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
