import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { SpeedControlComponentModule } from '../speed-control';
import { BFColorPaparazziComponent } from './bf-color-paparazzi.component';
import { CommonModule } from '@angular/common';
import { ColorPickerModule } from '@sui/color-picker';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    SpeedControlComponentModule,
    ColorPickerModule,
  ],
  declarations: [BFColorPaparazziComponent],
  exports: [BFColorPaparazziComponent],
})
export class BFColorPaparazziComponentModule {}
