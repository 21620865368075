import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'suiStringContains' })
export class StringContainsPipe implements PipeTransform {
  transform(item: string, term: string | undefined): boolean {
    if (!term) {
      return true;
    }
    return item.toLowerCase().includes(term.toLowerCase());
  }
}
