import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { LightSuiModel as LightModel } from '@spog-ui/shared/models/lights';
import {
  ControllerTypeModel,
  DeviceStatusTable,
  FixtureTypeModel,
  LightUpdateProps,
} from '../../models';
import { EditLightActions } from '../../actions';
import { ZoneModel } from '@spog-ui/shared/models/zones';

export abstract class EditLightPageState {
  abstract activeLight$: Observable<LightUpdateProps | null>;
  abstract lights$: Observable<LightModel[]>;
  abstract controllerTypes$: Observable<ControllerTypeModel[]>;
  abstract fixtureTypes$: Observable<FixtureTypeModel[]>;
  abstract deviceStatusTable$: Observable<DeviceStatusTable>;
  abstract zones$: Observable<ZoneModel[]>;
  abstract pending$: Observable<boolean>;
  abstract loading$: Observable<boolean>;

  abstract dispatch(action: EditLightActions.Union): void;
}

@Component({
  selector: 'lit-edit-light-page',
  template: `
    @if (state.loading$ | async) {
      <div class="litEditLightPageLoader">
        <sui-spinner></sui-spinner>
      </div>
    } @else {
      @if (state.activeLight$ | async; as activeLight) {
        <lit-light-form
          [light]="activeLight"
          [lights]="state.lights$ | async"
          [controllerTypes]="state.controllerTypes$ | async"
          [fixtureTypes]="state.fixtureTypes$ | async"
          [deviceStatusTable]="state.deviceStatusTable$ | async"
          [zones]="state.zones$ | async"
          [pending]="state.pending$ | async"
          (update)="onSave($event)"
          (cancel)="onCancel()"
          >
        </lit-light-form>
      } @else {
        <sui-empty-state>
          <ng-template suiEmptyStateTitle>Light Not Found</ng-template>
          <ng-template suiEmptyStateMessage>
            This light cannot be edited because it doesn't seem to exist.
          </ng-template>
          <ng-template suiEmptyStateActions>
            <button suiButton (click)="onCancel()">Go Back</button>
          </ng-template>
        </sui-empty-state>
      }
    }
    
    `,
  styles: [
    `
      .litEditLightPageLoader {
        display: block;
        padding: 80px 0;
        margin: 0 auto;
      }

      sui-spinner {
        display: block;
        width: 50px;
        margin: 0 auto;
      }

      sui-empty-state {
        display: block;
        max-width: 560px;
        margin: 80px auto;
      }
    `,
  ],
})
export class EditLightPageComponent implements OnInit {
  constructor(public state: EditLightPageState, public routerState: ActivatedRoute) {}

  ngOnInit() {
    this.state.dispatch(
      EditLightActions.enterAction(this.routerState.snapshot.params.lightId),
    );
  }

  onSave(light: LightUpdateProps) {
    this.state.dispatch(EditLightActions.saveAction(light));
  }

  onCancel() {
    this.state.dispatch(EditLightActions.cancelAction());
  }
}
