import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[suiSelectOnFocus]',
})
export class SelectOnFocusDirective {
  @HostListener('focus') onMouseEnter(): void {
    this.ref.nativeElement.select();
  }

  constructor(private ref: ElementRef) {}
}

export const SELECT_ON_FOCUS_DIRECTIVES = [SelectOnFocusDirective];
