import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiCharmFilterModule, SuiSpinnerModule } from '@spog-ui/shared/components';
import { SiteMapPageComponent } from './site-map-page.component';
import { NoSitesComponentModule } from '../no-sites';
import { RouterModule } from '@angular/router';
import { SuiStatusTrayModule } from '@spog-ui/status-tray';
import { AdminLinkComponentModule, EulaLinkComponentModule } from '@spog-ui/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    SuiSpinnerModule,
    NoSitesComponentModule,
    RouterModule,
    SuiCharmFilterModule,
    SuiStatusTrayModule,
    AdminLinkComponentModule,
    EulaLinkComponentModule,
    MatButtonModule,
    MatProgressBarModule,
    NoSitesComponentModule,
  ],
  declarations: [SiteMapPageComponent],
  exports: [SiteMapPageComponent],
})
export class SiteMapPageComponentModule {}
