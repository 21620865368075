import { BehaviorType } from '@spog-ui/shared/models/behaviors';
import { createMockSceneViewModel, SceneViewModel } from '@spog-ui/shared/models/scenes';
import {
  SceneZoneApiModel,
  SceneZoneBehaviorInternalModel,
  SceneZoneBehaviorViewModel,
} from './scene-zone-behavior.model';

export function createMockSceneZoneBehaviorModel(
  updates: Partial<SceneZoneBehaviorInternalModel> = {},
): SceneZoneBehaviorInternalModel {
  return {
    id: 'mock-scene-zone-behavior-id',
    zoneId: 'mock-zone-id',
    sceneId: 'mock-scene-id',
    behaviorId: BehaviorType.Dimmer,
    behaviorParameters: JSON.stringify({ level: 30 }),
    ...updates,
  };
}

export function createMockSceneZoneApiModel(updates?: SceneViewModel): SceneZoneApiModel {
  const sceneViewModel = {
    ...createMockSceneViewModel(),
    ...updates,
  };

  return {
    id: sceneViewModel.id,
    name: sceneViewModel.name,
    sceneZoneBehaviors: sceneViewModel.linkedBehaviorList
      .map(linkedBehavior =>
        linkedBehavior.zoneList.map(zone => ({
          sceneId: sceneViewModel.id,
          zoneId: zone.id,
          behaviorId: linkedBehavior.id,
          behaviorParameters: linkedBehavior.behaviorParameters,
        })),
      )
      .flat(),
  };
}

export function createMockSceneZoneBehaviorViewModel(): SceneZoneBehaviorViewModel {
  return {
    ...createMockSceneZoneBehaviorModel(),
    name: 'All',
  };
}
