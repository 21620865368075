// AUTO-GENERATED FILE - DO NOT MODIFY
import { gql } from '@spog-ui/graphql-client';
import { GraphQLClient } from '@spog-ui/graphql-client';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ColorHex: { input: string; output: string };
  Cursor: { input: string; output: string };
  /**
   * ISO 8601 string
   *
   * See: [ISO 8601 Date and Time Format](https://www.iso.org/iso-8601-date-and-time-format.html) for details
   */
  Date: { input: string; output: string };
  /**
   * ISO 8601 string
   *
   * See: [ISO 8601 Date and Time Format](https://www.iso.org/iso-8601-date-and-time-format.html) for details
   */
  DateTime: { input: string; output: string };
  /**
   * Percent humidity as a decimal value
   *
   * Valid values are between 0.0 and 100.0
   */
  Humidity: { input: number; output: number };
  /**
   * A zone identified by an IANA identifier, like `America/New_York`
   *
   * See: [IANA Time Zone Database](https://www.iana.org/time-zones) for details
   */
  IANATimeZone: { input: string; output: string };
  Json: { input: string; output: string };
  /**
   * A local time string (i.e., with no associated timezone) in
   * 24-hr HH:mm[:ss[.SSS]] format, e.g. 14:25 or 14:25:06 or 14:25:06.123.
   * The seconds and milliseconds portions are optional. The value is
   * serialized and deserialized as a string as a signal to both clients and
   * resolvers that special care must be taken with the value when converting
   * it to any date/time type that includes a date or timezone, e.g., a
   * JavaScript Date.
   *
   * See https://the-guild.dev/graphql/scalars/docs/scalars/local-time for
   * more information.
   */
  LocalTime: { input: string; output: string };
  Minutes: { input: number; output: number };
  /**
   * Data format used to decode the raw measurement data. Uses the Python struct module format, with a few exceptions:
   * - "Native" sizes and alignments cannot be used, be explicit about the size and alignment of the format.
   * - Only one value can be decoded from the data, so something like ">BB" is not allowed.
   *
   * Some common examples:
   * - ">B": unsigned byte
   * - ">h": big-endian signed short (2 bytes)
   * - ">i": big-endian signed int (4 bytes)
   * - ">f": big-endian float (4 bytes)
   * - "<d": little-endian signed double (8 bytes)
   *
   * See https://docs.python.org/3/library/struct.html#format-characters for more information.
   */
  PythonStructFormat: { input: string; output: string };
  RRuleString: { input: string; output: string };
  Seconds: { input: number; output: number };
  TypelessObject: { input: { [key: string]: any }; output: { [key: string]: any } };
  /** Alias for string, represents US Dollars in the format $x.xx. */
  USDollars: { input: string; output: string };
};

/**
 * How frequently should the email group's users receive emails about alarm
 * status changes for the specified alarm type?
 */
export enum AlarmEmailFrequency {
  /** Send emails for this alarm type daily. */
  DAILY = 'DAILY',
  /** Send emails for this alarm type hourly. */
  HOURLY = 'HOURLY',
  /** Send emails for this alarm type monthly. */
  MONTHLY = 'MONTHLY',
  /** Send emails for this alarm type weekly. */
  WEEKLY = 'WEEKLY',
}

export type AnyAlarmReasonInput = {
  /** The lighting controller alarm reason (type). */
  lightAlarmReason?: InputMaybe<LightAlarmReason>;
  /** The thermostat controller alarm reason (type). */
  thermostatAlarmReason?: InputMaybe<ThermostatAlarmReason>;
};

export enum Application {
  CLIMATE = 'CLIMATE',
  LIGHTING = 'LIGHTING',
}

export type ApplicationFilterInput = {
  eq?: InputMaybe<Application>;
};

export type ApplyBehaviorToControlZoneInput = {
  /**
   * Mutation should validate that the application type of the
   * behavior matches the application type of the control zone
   */
  behaviorId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  parameters?: InputMaybe<Scalars['Json']['input']>;
  /** siteId is required because of "id_builtin_zone_all" */
  siteId: Scalars['ID']['input'];
};

export type ApplySceneInput = {
  id: Scalars['ID']['input'];
};

export type ApplySceneSequenceInput = {
  id: Scalars['ID']['input'];
};

/** Asset Collection */
export type AssetCollectionsFilterInput = {
  industrialSensorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyNonChildAssets?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssetDispositionInput = {
  level: DispositionLevel;
  timestamp: Scalars['Date']['input'];
};

export type AssetRunningStateInput = {
  state: RunningState;
  timestamp: Scalars['Date']['input'];
};

export type AssetTriggerDispositionChangeInput = {
  level: DispositionLevel;
  message: Scalars['String']['input'];
  timestamp: Scalars['Date']['input'];
  triggerId: Scalars['ID']['input'];
};

export type AssetsFilterInput = {
  industrialSensorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyNonChildAssets?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AstroEvent {
  DAWN = 'DAWN',
  DUSK = 'DUSK',
  SUNRISE = 'SUNRISE',
  SUNSET = 'SUNSET',
}

export type AstroEventStartTimeInput = {
  event: AstroEvent;
  offset: Scalars['Minutes']['input'];
  startsAt: Scalars['Date']['input'];
};

export enum BaselineType {
  FLAT = 'FLAT',
  VARIABLE = 'VARIABLE',
}

export enum BatteryPoweredIndustrialSensorBatteryLevel {
  EMPTY = 'EMPTY',
  FULL = 'FULL',
  LOW = 'LOW',
  PLUGGED_IN = 'PLUGGED_IN',
}

export enum BatteryPoweredIndustrialSensorUpgradeStatus {
  UPGRADE_IN_PROGRESS = 'UPGRADE_IN_PROGRESS',
  UPGRADE_PENDING = 'UPGRADE_PENDING',
}

export enum BillingFrequency {
  MONTHLY = 'MONTHLY',
}

export type Bridge485NetworkInput = {
  siteControllerId?: InputMaybe<Scalars['ID']['input']>;
  snapAddress?: InputMaybe<Scalars['String']['input']>;
};

export enum Capabilities {
  MAP_SITE_SELECT = 'MAP_SITE_SELECT',
  QUICK_SITE_SETUP = 'QUICK_SITE_SETUP',
  SITE_CONTROLLER_LIST = 'SITE_CONTROLLER_LIST',
}

/** Commissioning status */
export enum CommissioningStatus {
  /** All required configuration has been provided */
  COMMISSIONED = 'COMMISSIONED',
  /** All required configuration has been provided and the device has communicated with the system at least once */
  CONNECTED = 'CONNECTED',
  /** Some required configuration has not been provided */
  PRE_COMMISSIONED = 'PRE_COMMISSIONED',
}

export type ConfigurationTemplateInput = {
  type?: InputMaybe<ConfigurationTemplateType>;
};

export enum ConfigurationTemplateType {
  BRIDGE485_TEMPLATE = 'BRIDGE485_TEMPLATE',
}

export type ConnectSiteControllerInput = {
  id: Scalars['ID']['input'];
  remoteAccess: RemoteAccessConnectionInput;
  systemVersion?: InputMaybe<Scalars['String']['input']>;
  tunnelPort?: InputMaybe<Scalars['Int']['input']>;
};

export type ControlZoneFilterInput = {
  application?: InputMaybe<ApplicationFilterInput>;
};

export type ControlZoneSceneEdgeInput = {
  behaviorId: Scalars['ID']['input'];
  parameters?: InputMaybe<Scalars['Json']['input']>;
  zoneId: Scalars['ID']['input'];
};

export type ConversionInput = {
  /** List of polynomial coefficients for the measurement data conversion, in order of increasing power. */
  polynomial: Array<Scalars['Float']['input']>;
};

export type CreateAssetInput = {
  customType?: InputMaybe<CustomAssetTypeInput>;
  definedType?: InputMaybe<DefinedAssetTypeInput>;
  /** Base64-encoded bytes of an image that depicts the industrial sensor */
  image?: InputMaybe<Scalars['String']['input']>;
  industrialSensorIds: Array<Scalars['ID']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  /** Stringified representation of a JSON object */
  metadata?: InputMaybe<Scalars['Json']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  resourceGroupIds: Array<Scalars['ID']['input']>;
  siteId: Scalars['ID']['input'];
};

export type CreateBridge485Input = {
  metrics: Array<CreateBridge485MetricInput>;
  name: Scalars['String']['input'];
  /** Optional during Bridge485 creation to allow for pre-commissioning configuration. */
  network?: InputMaybe<Bridge485NetworkInput>;
  /** Time between measurements in seconds. */
  samplePeriod: Scalars['Int']['input'];
  serialSettings: CreateSerialSettingsInput;
  siteId: Scalars['ID']['input'];
};

export type CreateBridge485MetricInput = {
  conversion: ConversionInput;
  /**
   * Modbus data address to read. Valid range is 0-65535.
   *
   * Note: This is the Modbus memory address, not the data object number. For example,
   *       the data address of register 1 is 0.
   */
  dataAddress: Scalars['Int']['input'];
  dataFormat: Scalars['PythonStructFormat']['input'];
  dataType: IndustrialSensorDataTypeInput;
  functionCode: ModbusFunctionCode;
  name: Scalars['String']['input'];
  /** Unit ID of Modbus device that is being read. Valid range is 1-247. */
  unitId: Scalars['Int']['input'];
};

export type CreateControlZoneInput = {
  /** Application must be "CLIMATE" */
  application: Application;
  name: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
  thermostats?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CreateDepartmentInput = {
  controlZoneIds: Array<Scalars['ID']['input']>;
  energyProfileEquipmentIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  resourceGroupIds: Array<Scalars['ID']['input']>;
  siteId: Scalars['ID']['input'];
};

export type CreateEmailGroupInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  preferences?: InputMaybe<EmailGroupPreferencesInput>;
  siteIds: Array<Scalars['ID']['input']>;
  userIds: Array<Scalars['ID']['input']>;
};

export type CreateOrganizationInput = {
  addBasicPermissionGroups?: InputMaybe<Scalars['Boolean']['input']>;
  capabilities?: Array<Capabilities>;
  defaultSiteRole?: PermissionGroupSiteRole;
  name: Scalars['String']['input'];
  siteIds: Array<Scalars['ID']['input']>;
  temporary?: InputMaybe<Scalars['Boolean']['input']>;
  users: Array<OrganizationUserInput>;
};

export type CreatePermissionGroupInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  siteIds: Array<Scalars['ID']['input']>;
  siteRole: PermissionGroupSiteRole;
  userIds: Array<Scalars['ID']['input']>;
};

export type CreateResourceGroupInput = {
  industrialSensorIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  resourceGroupIds: Array<Scalars['ID']['input']>;
  siteId: Scalars['ID']['input'];
  zoneIds: Array<Scalars['ID']['input']>;
};

export type CreateSceneInput = {
  application: Application;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
  /**
   * The application type of the provided zones must
   * match the application type provided in the CreateSceneInput
   *
   * A zone can only appear one time in the list of zones
   */
  zones: Array<ControlZoneSceneEdgeInput>;
};

export type CreateSceneSequenceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  loop: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
  steps: Array<SceneSequenceStepInput>;
};

export type CreateSceneViewInput = {
  /** Optional UUID to assign to the new scene view. If none is specified a UUID will be generated for you. */
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  sceneGroups: Array<SceneViewSceneGroupEdgeInput>;
  /** Parent site for this SceneView */
  siteId: Scalars['ID']['input'];
};

export type CreateScheduledEventInput = {
  astroStartTime?: InputMaybe<AstroEventStartTimeInput>;
  customStartTime?: InputMaybe<FixedStartTimeInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  rrule?: InputMaybe<Scalars['RRuleString']['input']>;
  sceneIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  schedulableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteId: Scalars['ID']['input'];
};

/** When creating a ScheduledUtility Rate, you must provide either `openEIUtilityRate` or `flatEnergyUtilityRate` */
export type CreateScheduledUtilityRateInput = {
  billingFrequency: BillingFrequency;
  flatEnergyUtilityRate?: InputMaybe<FlatEnergyUtilityRateInput>;
  openEIUtilityRate?: InputMaybe<OpenEiUtilityRateInput>;
  startDate: Scalars['Date']['input'];
  utilityServiceId: Scalars['ID']['input'];
};

export type CreateSense420SensorInput = {
  dataType: IndustrialSensorDataTypeInput;
  /** Base64-encoded bytes of an image that depicts the industrial sensor */
  image?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  maxValue: Scalars['Float']['input'];
  /** Stringified representation of a JSON object */
  metadata?: InputMaybe<Scalars['Json']['input']>;
  minValue: Scalars['Float']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
  snapaddr: Scalars['String']['input'];
};

export type CreateSerialSettingsInput = {
  /** Serial baud rates supported are 9600, 19200, 38400, 57600, 76800, and 115200. */
  baudRate: Scalars['Int']['input'];
  parity: SerialSettingsParity;
  /** Number of stop bits supported are 1 and 2. */
  stopBits: Scalars['Int']['input'];
};

export type CreateSiteControllerInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  importOnPremMapImage?: InputMaybe<Scalars['Boolean']['input']>;
  importOnPremScenes?: InputMaybe<Scalars['Boolean']['input']>;
  importOnPremScheduledEvents?: InputMaybe<Scalars['Boolean']['input']>;
  macAddress: Scalars['String']['input'];
  name: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateSiteInput = {
  enabledProducts: Array<Products>;
  geoLocation: GeoLocationInput;
  hasCellLine?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** How to display light icons on the SimplySnap Cloud light map layer */
  lightMapLayerIconSize?: InputMaybe<LightMapLayerIconSize>;
  name: Scalars['String']['input'];
  organizationIds: Array<Scalars['ID']['input']>;
  siteControllerIds: Array<Scalars['ID']['input']>;
  subscriptionInfo?: InputMaybe<SiteSubscriptionInfoInput>;
};

export type CreateThermostatInput = {
  fallbackControlMode: ThermostatControlModeInput;
  name: Scalars['String']['input'];
  siteControllerId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  snapaddr?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTriggerInput = {
  condition: TriggerConditionInput;
  industrialSensorId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  notifications: TriggerNotificationInput;
  siteId: Scalars['ID']['input'];
  transitionConfig: TriggerTransitionConfigInput;
};

export type CreateUtilityServiceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  industrialSensorIds: Array<Scalars['ID']['input']>;
  lightIds: Array<Scalars['ID']['input']>;
  mainIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
  utilityType: UtilityType;
};

export type CreateVirtualIndustrialSensorInput = {
  dataType: IndustrialSensorDataTypeInput;
  make?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
};

export type CustomAssetTypeInput = {
  name: Scalars['String']['input'];
};

export type DateTimeRangeInput = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

/** Asset Type */
export enum DefinedAssetTypeEnum {
  BAGHOUSE = 'BAGHOUSE',
  BEARING = 'BEARING',
  CHILLER = 'CHILLER',
  CNC = 'CNC',
  COMPRESSOR = 'COMPRESSOR',
  CONDENSER = 'CONDENSER',
  COOLING_TOWER = 'COOLING_TOWER',
  CUPOLA = 'CUPOLA',
  EXHAUST_FAN = 'EXHAUST_FAN',
  EXTRUDER = 'EXTRUDER',
  FURNACE = 'FURNACE',
  MAINS = 'MAINS',
  MAU = 'MAU',
  MIXER = 'MIXER',
  MOTOR = 'MOTOR',
  MULLER = 'MULLER',
  PAINT_BOOTH = 'PAINT_BOOTH',
  PANEL = 'PANEL',
  PRESS = 'PRESS',
  RTU = 'RTU',
  SCRUBBER = 'SCRUBBER',
  SHOT_BLAST = 'SHOT_BLAST',
  STAMPING_MACHINE = 'STAMPING_MACHINE',
  TRANSFORMER = 'TRANSFORMER',
  WELDER = 'WELDER',
}

export type DefinedAssetTypeInput = {
  type: DefinedAssetTypeEnum;
};

export type DeleteAssetInput = {
  id: Scalars['ID']['input'];
};

export type DeleteBridge485Input = {
  id: Scalars['ID']['input'];
};

export type DeleteControlZoneInput = {
  id: Scalars['ID']['input'];
};

export type DeleteDepartmentInput = {
  id: Scalars['ID']['input'];
};

export type DeleteEmailGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOrganizationInput = {
  id: Scalars['ID']['input'];
};

export type DeletePermissionGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeleteResourceGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSceneInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSceneSequenceInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSceneViewInput = {
  /** The scene view ID */
  id: Scalars['ID']['input'];
};

export type DeleteScheduledEventInput = {
  id: Scalars['ID']['input'];
};

export type DeleteScheduledUtilityRateInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSiteControllerInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSiteInput = {
  id: Scalars['ID']['input'];
};

export type DeleteThermostatInput = {
  id: Scalars['ID']['input'];
};

export type DeleteTriggerInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUtilityServiceInput = {
  id: Scalars['ID']['input'];
};

export type DeviceAssetDataInput = {
  assetData: Scalars['Json']['input'];
  snapaddr: Scalars['String']['input'];
};

export enum DispositionLevel {
  CRITICAL = 'CRITICAL',
  INFO = 'INFO',
  NORMAL = 'NORMAL',
  UNKNOWN = 'UNKNOWN',
  WARNING = 'WARNING',
}

export type DispositionTriggerNotificationInput = {
  level: DispositionLevel;
  message: Scalars['String']['input'];
};

export type EmailGroupAlarmTypeSpecInput = {
  /** Which alarm type? */
  alarmType: AnyAlarmReasonInput;
  /**
   * How often should we send out emails about this kind of alarm?
   * Defaults to DAILY if not specified.
   */
  frequency?: InputMaybe<AlarmEmailFrequency>;
  /**
   * Optional time range during which alarm status changes are recorded for
   * subsequent email messages. Defaults to all day (i.e. we do not ignore
   * any status changes for alarms of this type) if not specified.
   */
  officeHours?: InputMaybe<OfficeHoursInput>;
};

export type EmailGroupPreferencesInput = {
  specs: Array<EmailGroupAlarmTypeSpecInput>;
};

export type FirstTimeLoginInput = {
  defaultRole?: InputMaybe<UserRole>;
  id: Scalars['ID']['input'];
  isSuperUser?: Scalars['Boolean']['input'];
};

export type FixedStartTimeInput = {
  startsAt: Scalars['Date']['input'];
};

export type FlatEnergyUtilityRateInput = {
  costOfDemand: Scalars['USDollars']['input'];
  costOfEnergy: Scalars['USDollars']['input'];
};

export type FloorPlanPositionInput = {
  /** ID of thing to be placed */
  id: Scalars['ID']['input'];
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
};

export type GeoLocationInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type IdFilterInput = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ImportDeviceAssetDataInput = {
  deviceAssetData: Array<DeviceAssetDataInput>;
  siteId: Scalars['ID']['input'];
};

export type ImportEntitiesFromSiteControllerInput = {
  id: Scalars['ID']['input'];
};

export type IndustrialSensorCustomDataTypeInput = {
  name: Scalars['String']['input'];
  units: Scalars['String']['input'];
};

export type IndustrialSensorDataTypeInput = {
  custom?: InputMaybe<IndustrialSensorCustomDataTypeInput>;
  type?: InputMaybe<IndustrialSensorDefinedDataEnum>;
};

export enum IndustrialSensorDefinedDataEnum {
  CONCENTRATION = 'CONCENTRATION',
  CURRENT = 'CURRENT',
  DIFFERENTIAL_PRESSURE = 'DIFFERENTIAL_PRESSURE',
  FLOW = 'FLOW',
  HUMIDITY = 'HUMIDITY',
  MASS = 'MASS',
  PERCENTAGE = 'PERCENTAGE',
  POWER = 'POWER',
  PRESSURE = 'PRESSURE',
  TEMPERATURE = 'TEMPERATURE',
  TOTALIZED_FLOW = 'TOTALIZED_FLOW',
  VOLTAGE = 'VOLTAGE',
}

export type InitializeSiteControllerResourcesInput = {
  id: Scalars['ID']['input'];
  importOnPremMapImage?: InputMaybe<Scalars['Boolean']['input']>;
  importOnPremScenes?: InputMaybe<Scalars['Boolean']['input']>;
  importOnPremScheduledEvents?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Interval {
  AUTO = 'AUTO',
  DAY = 'DAY',
  FIFTEEN_MINUTES = 'FIFTEEN_MINUTES',
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  ONE_MINUTE = 'ONE_MINUTE',
  WEEK = 'WEEK',
}

export type InviteOrganizationUserInput = {
  email: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  organizationRole?: OrganizationRole;
};

export enum LightAlarmReason {
  /** Census Failure */
  CENSUS_FAILURE = 'CENSUS_FAILURE',
  /** Device Communication Failure */
  COMMUNICATION_FAILURE = 'COMMUNICATION_FAILURE',
  /** Device Configuration Failure */
  CONFIGURATION_FAILURE = 'CONFIGURATION_FAILURE',
  /** DALI Driver Error */
  DALI_DRIVER_ERROR = 'DALI_DRIVER_ERROR',
  /** DALI Driver Problem Report */
  DALI_DRIVER_PROBLEM_REPORT = 'DALI_DRIVER_PROBLEM_REPORT',
  /** Dimming Test Failure */
  DIMMING_TEST_FAILURE = 'DIMMING_TEST_FAILURE',
  /** Fixture Threshold Failure */
  FIXTURE_THRESHOLD_FAILURE = 'FIXTURE_THRESHOLD_FAILURE',
  /** Light Recruitment Failure */
  LIGHT_RECRUITMENT_FAILURE = 'LIGHT_RECRUITMENT_FAILURE',
  /** Light Tilted */
  LIGHT_TILTED = 'LIGHT_TILTED',
  /** Device Link Quality Warning */
  LINK_QUALITY_WARNING = 'LINK_QUALITY_WARNING',
  /** Missing DALI Driver */
  MISSING_DALI_DRIVER = 'MISSING_DALI_DRIVER',
  /** Device Script Missing */
  MISSING_SCRIPT_FAILURE = 'MISSING_SCRIPT_FAILURE',
  /** Network Change Failure */
  NETWORK_CHANGE_FAILURE = 'NETWORK_CHANGE_FAILURE',
  /** Unchanging Motion Sensor */
  UNCHANGING_MOTION_SENSOR = 'UNCHANGING_MOTION_SENSOR',
}

export enum LightMapLayerIconSize {
  /** Adjust icon size depending on density */
  AUTO = 'AUTO',
  /** Use big icons exclusively */
  BIG_ICONS = 'BIG_ICONS',
  /** Use small icons exclusively */
  SMALL_ICONS = 'SMALL_ICONS',
}

export enum ModbusFunctionCode {
  READ_HOLDING_REGISTERS = 'READ_HOLDING_REGISTERS',
  READ_INPUT_REGISTERS = 'READ_INPUT_REGISTERS',
}

export type MoveSiteControllerInput = {
  id: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
};

export type NodeSwapInput = {
  /** SNAP address of the node being replaced (optional for node recruitment) */
  replacee?: InputMaybe<Scalars['String']['input']>;
  /** SNAP address of the new (replacement) node */
  replacement: Scalars['String']['input'];
  /** Optional SNAP address of the helper node. If not specified, a neighboring node will automatically be selected. */
  replacer?: InputMaybe<Scalars['String']['input']>;
  /** Site controller on which to perform node swap */
  siteControllerId: Scalars['ID']['input'];
};

export enum NodeSwapResponseStatus {
  ERROR = 'ERROR',
  OK = 'OK',
}

export enum NodeSwapStatusCode {
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
  WARNING = 'WARNING',
}

export type OfficeHoursInput = {
  /**
   * End time (in site's time zone) for the period during which we will track
   * alarm status changes for the specified alarm type. If the end time is
   * earlier than the start time, the end time is assumed to be for the following
   * day.
   */
  endTime: Scalars['LocalTime']['input'];
  /**
   * Start time (in site's time zone) for the period during which we will
   * track alarm status changes for the specified alarm type.
   */
  startTime: Scalars['LocalTime']['input'];
};

export type OpenEiUtilityRateInput = {
  openEIUtilityRateId: Scalars['ID']['input'];
};

export type OrganizationFilterInput = {
  name: Scalars['String']['input'];
};

export enum OrganizationRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export type OrganizationRoleFilterInput = {
  eq?: InputMaybe<OrganizationRole>;
};

export type OrganizationUserInput = {
  organizationRole: OrganizationRole;
  userId: Scalars['ID']['input'];
};

export enum PermissionGroupSiteRole {
  MEMBER = 'MEMBER',
  NONE = 'NONE',
  SCENE_AND_SCHEDULE_MEMBER = 'SCENE_AND_SCHEDULE_MEMBER',
  SCENE_USER = 'SCENE_USER',
  VIEWER = 'VIEWER',
}

export type PingSnapDeviceInput = {
  snapaddr: Scalars['String']['input'];
};

export type PlaceThingsOnFloorPlanInput = {
  floorPlanId: Scalars['ID']['input'];
  positions: Array<FloorPlanPositionInput>;
};

export enum Products {
  ALL_DYNAMIC_EFFECTS = 'ALL_DYNAMIC_EFFECTS',
  CHASE_EFFECTS = 'CHASE_EFFECTS',
  CLIMATE = 'CLIMATE',
  COLOR = 'COLOR',
  COLOR_FADE = 'COLOR_FADE',
  DEPRECATED_CLIMATE = 'DEPRECATED_CLIMATE',
  ILLUMINATE = 'ILLUMINATE',
  ILLUMINATE_POWER = 'ILLUMINATE_POWER',
  MONO_DYNAMIC_EFFECTS = 'MONO_DYNAMIC_EFFECTS',
  REMOTE_ACCESS = 'REMOTE_ACCESS',
  SCENE_VIEWS = 'SCENE_VIEWS',
  SENSE = 'SENSE',
  SENSE_POWER = 'SENSE_POWER',
  THRESHOLD_DETECTION = 'THRESHOLD_DETECTION',
  TILT_DETECTION = 'TILT_DETECTION',
}

export enum QueryDataType {
  POWER = 'POWER',
}

export type QuickSiteSetupInput = {
  geoLocation: GeoLocationInput;
  macAddress: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  siteName: Scalars['String']['input'];
};

export type RefreshSiteControllerResourceInput = {
  id: Scalars['ID']['input'];
  type: SiteControllerResourceType;
};

export type RemoteAccessConnectionInput = {
  ipAddress: Scalars['String']['input'];
  secretKey: Scalars['String']['input'];
  uiProxyUrl: Scalars['String']['input'];
};

export type RemoveOrganizationUserInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ReplaceSiteControllerInput = {
  id: Scalars['ID']['input'];
  macAddress: Scalars['String']['input'];
};

export type ResourceGroupFilterInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
};

export enum RunningState {
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  UNKNOWN = 'UNKNOWN',
}

export type RunningStateTriggerNotificationInput = {
  state: RunningState;
};

export type SceneFilterInput = {
  application?: InputMaybe<ApplicationFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
};

export type SceneGroupFilterInput = {
  name?: InputMaybe<StringFilterInput>;
};

export type SceneGroupSceneEdgeInput = {
  /** The scene ID */
  sceneId: Scalars['ID']['input'];
  /** Short name to be used for this scene */
  shortName: Scalars['String']['input'];
};

/**
 * A scene sequence step has a step number and either a scene or duration
 * (but not both).
 */
export type SceneSequenceStepInput = {
  duration?: InputMaybe<Scalars['Seconds']['input']>;
  sceneId?: InputMaybe<Scalars['ID']['input']>;
  stepNumber: Scalars['Int']['input'];
};

export type SceneViewFilterInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
};

export type SceneViewSceneGroupEdgeInput = {
  /** A name for this scene group */
  name: Scalars['String']['input'];
  /** Scenes for this SceneGroup */
  scenes: Array<SceneGroupSceneEdgeInput>;
};

export type ScheduledEventOccurrenceFilterInput = {
  end: Scalars['Date']['input'];
  start: Scalars['Date']['input'];
};

export type ScheduledSceneInput = {
  astroTime?: InputMaybe<Scalars['String']['input']>;
  astroTimeOffset?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  rrule?: InputMaybe<Scalars['String']['input']>;
  sceneId: Scalars['ID']['input'];
  start: Scalars['Date']['input'];
};

export type ScheduledSceneOccurrenceFilterInput = {
  end: Scalars['Date']['input'];
  start: Scalars['Date']['input'];
};

export enum SerialSettingsParity {
  EVEN = 'EVEN',
  NONE = 'NONE',
  ODD = 'ODD',
}

export enum SiteAlarmReason {
  /** More than one site controller software version detected in site */
  SITE_CONTROLLER_VERSION_MISMATCH = 'SITE_CONTROLLER_VERSION_MISMATCH',
}

export enum SiteControllerAlarmReason {
  /** Unknown Industrial Sensor Report */
  UNKNOWN_INDUSTRIAL_SENSOR = 'UNKNOWN_INDUSTRIAL_SENSOR',
}

export enum SiteControllerResourceStatus {
  /** Service is known to be unavailable */
  DOWN = 'DOWN',
  /** Service initialization failed */
  INIT_FAILED = 'INIT_FAILED',
  /** Service is being initialized */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Service initialization passed - pending acknowledgement */
  PENDING_ACK = 'PENDING_ACK',
  /** Service status is unknown */
  UNKNOWN = 'UNKNOWN',
  /** Service is known to be available */
  UP = 'UP',
}

/** Describes the status (UP, DOWN, or UNKNOWN) of a particular resource */
export type SiteControllerResourceStatusInput = {
  lastUpdateReceived?: InputMaybe<Scalars['DateTime']['input']>;
  status: SiteControllerResourceStatus;
  type: SiteControllerResourceType;
};

/** Describes the statuses of resources for a particular site controller */
export type SiteControllerResourceStatusesInput = {
  id: Scalars['ID']['input'];
  resourceStatuses: Array<SiteControllerResourceStatusInput>;
};

export enum SiteControllerResourceType {
  /** Site controller’s CouchDB connection */
  CONFIG_SYNC = 'CONFIG_SYNC',
  /** Site controller’s VPN connection */
  REMOTE_ACCESS = 'REMOTE_ACCESS',
  /** Site controller’s MQTT connection */
  STATE_SYNC = 'STATE_SYNC',
}

export type SiteFilterInput = {
  id?: InputMaybe<IdFilterInput>;
};

export type SiteSubscriptionInfoInput = {
  accessBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  expiresOn?: InputMaybe<Scalars['Date']['input']>;
  partner?: InputMaybe<Scalars['String']['input']>;
  salesPerson?: InputMaybe<Scalars['String']['input']>;
  startsOn?: InputMaybe<Scalars['Date']['input']>;
  subscriptionType: SubscriptionType;
};

export type SourceFilterInput = {
  typesFilter: Array<SourceTypeEnum>;
};

/** Source Type */
export enum SourceTypeEnum {
  ASSET = 'ASSET',
  ASSET_COLLECTION = 'ASSET_COLLECTION',
  BRIDGE485_METRIC = 'BRIDGE485_METRIC',
  DEPARTMENT = 'DEPARTMENT',
  ELECTRICITY_METER = 'ELECTRICITY_METER',
  GAS_METER = 'GAS_METER',
  LIGHTING_ZONE = 'LIGHTING_ZONE',
  SENSE420_SENSOR = 'SENSE420_SENSOR',
  THERMOSTAT = 'THERMOSTAT',
  VIRTUAL_SENSOR = 'VIRTUAL_SENSOR',
  WATER_METER = 'WATER_METER',
}

export type StringFilterInput = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum SubscriptionType {
  DEMO = 'DEMO',
  EN_KIMCO = 'EN_KIMCO',
  EN_LSI = 'EN_LSI',
  EN_QUALITE = 'EN_QUALITE',
  EN_TECHLINE = 'EN_TECHLINE',
  EN_ULINE = 'EN_ULINE',
  EN_VISIONAIRE = 'EN_VISIONAIRE',
  INTERNAL = 'INTERNAL',
  LM_BASE = 'LM_BASE',
  LM_BASE_PLUS = 'LM_BASE_PLUS',
  LM_REMOTE = 'LM_REMOTE',
  LM_SPORT = 'LM_SPORT',
  SW_ENERGY_BASE = 'SW_ENERGY_BASE',
}

/** User can specify temperature in Fahrenheit (`F`) */
export type TemperatureInput = {
  F: Scalars['Float']['input'];
};

export enum ThermostatAlarmReason {
  /** Census Failure */
  CENSUS_FAILURE = 'CENSUS_FAILURE',
  /** Device Communication Failure */
  COMMUNICATION_FAILURE = 'COMMUNICATION_FAILURE',
  /** Device Configuration Failure */
  CONFIGURATION_FAILURE = 'CONFIGURATION_FAILURE',
  /** Device Link Quality Warning */
  LINK_QUALITY_WARNING = 'LINK_QUALITY_WARNING',
  /** Device Script Missing */
  MISSING_SCRIPT_FAILURE = 'MISSING_SCRIPT_FAILURE',
  /** Network Change Failure */
  NETWORK_CHANGE_FAILURE = 'NETWORK_CHANGE_FAILURE',
}

export type ThermostatControlModeInput = {
  cool: TemperatureInput;
  fanMode: ThermostatFanMode;
  heat: TemperatureInput;
};

/** Enum to represent the fan’s control mode within a thermostat */
export enum ThermostatFanMode {
  AUTO = 'AUTO',
  ON = 'ON',
}

export type TriggerConditionInput = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
};

export type TriggerNotificationInput = {
  disposition?: InputMaybe<DispositionTriggerNotificationInput>;
  runningState?: InputMaybe<RunningStateTriggerNotificationInput>;
  webhooks?: InputMaybe<Array<WebhookTriggerNotificationInput>>;
};

export enum TriggerStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  UNKNOWN = 'UNKNOWN',
}

export type TriggerTransitionConfigInput = {
  activeTransitionDelayInMinutes: Scalars['Int']['input'];
  inactiveTransitionDelayInMinutes: Scalars['Int']['input'];
  unknownTransitionDelayInMinutes: Scalars['Int']['input'];
};

export type UpdateAssetInput = {
  customType?: InputMaybe<CustomAssetTypeInput>;
  definedType?: InputMaybe<DefinedAssetTypeInput>;
  disposition?: InputMaybe<AssetDispositionInput>;
  id: Scalars['ID']['input'];
  /** Base64-encoded bytes of an image that depicts the industrial sensor */
  image?: InputMaybe<Scalars['String']['input']>;
  industrialSensorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  make?: InputMaybe<Scalars['String']['input']>;
  /** Stringified representation of a JSON object */
  metadata?: InputMaybe<Scalars['Json']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  resourceGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  runningState?: InputMaybe<AssetRunningStateInput>;
  triggerDispositionChanges?: InputMaybe<Array<AssetTriggerDispositionChangeInput>>;
};

export type UpdateBridge485Input = {
  id: Scalars['ID']['input'];
  metricsToAdd?: InputMaybe<Array<CreateBridge485MetricInput>>;
  metricsToModify?: InputMaybe<Array<UpdateBridge485MetricInput>>;
  metricsToRemove?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  network?: InputMaybe<Bridge485NetworkInput>;
  /** Time between measurements in seconds. */
  samplePeriod?: InputMaybe<Scalars['Int']['input']>;
  serialSettings?: InputMaybe<UpdateSerialSettingsInput>;
};

export type UpdateBridge485MetricInput = {
  conversion?: InputMaybe<ConversionInput>;
  /**
   * Modbus data address to read. Valid range is 0-65535.
   *
   * Note: This is the Modbus memory address, not the data object number. For example,
   *       the data address of register 1 is 0.
   */
  dataAddress?: InputMaybe<Scalars['Int']['input']>;
  dataFormat?: InputMaybe<Scalars['PythonStructFormat']['input']>;
  dataType?: InputMaybe<IndustrialSensorDataTypeInput>;
  functionCode?: InputMaybe<ModbusFunctionCode>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  /** Unit ID of Modbus device that is being read. Valid range is 1-247. */
  unitId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateColorPaletteInput = {
  colors: Array<Scalars['ColorHex']['input']>;
  siteId: Scalars['ID']['input'];
};

export type UpdateControlZoneInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  thermostats?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateDepartmentInput = {
  controlZoneIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  energyProfileEquipmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  resourceGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateEmailGroupInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  preferences?: InputMaybe<EmailGroupPreferencesInput>;
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateMyself = {
  acceptedEula?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationDefaultSiteRoleInput = {
  defaultSiteRole: PermissionGroupSiteRole;
  organizationId: Scalars['ID']['input'];
};

export type UpdateOrganizationInput = {
  capabilities?: InputMaybe<Array<Capabilities>>;
  defaultSiteRole?: InputMaybe<PermissionGroupSiteRole>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  users?: InputMaybe<Array<OrganizationUserInput>>;
};

export type UpdateOrganizationUserInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  organizationRole?: InputMaybe<OrganizationRole>;
  userId: Scalars['ID']['input'];
};

export type UpdatePermissionGroupInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteRole?: InputMaybe<PermissionGroupSiteRole>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateResourceGroupInput = {
  id: Scalars['ID']['input'];
  industrialSensorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  resourceGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  zoneIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateSceneInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  /**
   * The application type of the provided zones must
   * match the application type of the scene being updated
   *
   * A zone can only appear one time in the list of zones
   */
  zones?: InputMaybe<Array<ControlZoneSceneEdgeInput>>;
};

export type UpdateSceneSequenceInput = {
  id: Scalars['ID']['input'];
  loop?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  steps?: InputMaybe<Array<SceneSequenceStepInput>>;
};

export type UpdateSceneViewInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sceneGroups?: InputMaybe<Array<SceneViewSceneGroupEdgeInput>>;
};

export type UpdateSceneZoneBehavior = {
  behaviorId: Scalars['ID']['input'];
  behaviorParameters?: InputMaybe<Scalars['String']['input']>;
  zoneId: Scalars['ID']['input'];
};

export type UpdateScheduledEventInput = {
  astroStartTime?: InputMaybe<AstroEventStartTimeInput>;
  customStartTime?: InputMaybe<FixedStartTimeInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  rrule?: InputMaybe<Scalars['RRuleString']['input']>;
  sceneIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  schedulableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateScheduledUtilityRateInput = {
  billingFrequency?: InputMaybe<BillingFrequency>;
  flatEnergyUtilityRate?: InputMaybe<FlatEnergyUtilityRateInput>;
  id: Scalars['ID']['input'];
  openEIUtilityRate?: InputMaybe<OpenEiUtilityRateInput>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateSense420SensorInput = {
  dataType?: InputMaybe<IndustrialSensorDataTypeInput>;
  id: Scalars['ID']['input'];
  /** Base64-encoded bytes of an image that depicts the industrial sensor */
  image?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  maxValue?: InputMaybe<Scalars['Float']['input']>;
  /** Stringified representation of a JSON object */
  metadata?: InputMaybe<Scalars['Json']['input']>;
  minValue?: InputMaybe<Scalars['Float']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  snapaddr?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSerialSettingsInput = {
  /** Serial baud rates supported are 9600, 19200, 38400, 57600, 76800, and 115200. */
  baudRate?: InputMaybe<Scalars['Int']['input']>;
  parity?: InputMaybe<SerialSettingsParity>;
  /** Number of stop bits supported are 1 and 2. */
  stopBits?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSiteControllerInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  /** Only possible if no site is assigned */
  siteId?: InputMaybe<Scalars['ID']['input']>;
  tunnelPort?: InputMaybe<Scalars['Int']['input']>;
};

/** Describes the statuses of resources for a collection of site controllers */
export type UpdateSiteControllersResourceStatusesInput = {
  siteControllersResourceStatuses: Array<SiteControllerResourceStatusesInput>;
};

export type UpdateSiteInput = {
  enabledProducts?: InputMaybe<Array<Products>>;
  geoLocation?: InputMaybe<GeoLocationInput>;
  hasCellLine?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  /** How to display light icons on the SimplySnap Cloud light map layer */
  lightMapLayerIconSize?: InputMaybe<LightMapLayerIconSize>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteControllerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  subscriptionInfo?: InputMaybe<SiteSubscriptionInfoInput>;
};

export type UpdateThermostatControlModeInput = {
  controlMode: ThermostatControlModeInput;
  id: Scalars['ID']['input'];
};

export type UpdateThermostatInput = {
  fallbackControlMode?: InputMaybe<ThermostatControlModeInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  siteControllerId?: InputMaybe<Scalars['String']['input']>;
  snapaddr?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTriggerInput = {
  condition?: InputMaybe<TriggerConditionInput>;
  id: Scalars['ID']['input'];
  industrialSensorId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<TriggerNotificationInput>;
  transitionConfig?: InputMaybe<TriggerTransitionConfigInput>;
};

export type UpdateUtilityServiceInput = {
  id: Scalars['ID']['input'];
  industrialSensorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  lightIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  mainIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVirtualIndustrialSensorInput = {
  dataType?: InputMaybe<IndustrialSensorDataTypeInput>;
  id: Scalars['ID']['input'];
  make?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['Json']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateInput = {
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isSuperUser?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
};

export type UserDeleteInput = {
  id: Scalars['ID']['input'];
};

export type UserOrganizationFilterInput = {
  organizationRole?: InputMaybe<OrganizationRoleFilterInput>;
};

export type UserQueryInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum UserRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  VIEWER = 'VIEWER',
}

export type UserTriggerPasswordReset = {
  id: Scalars['ID']['input'];
};

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isSuperUser?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
};

export type UtilityServiceAssetContributionsInput = {
  start: Scalars['DateTime']['input'];
};

export type UtilityServiceBillingPeriodsInput = {
  dateTimeRange?: InputMaybe<DateTimeRangeInput>;
};

export enum UtilityType {
  POWER = 'POWER',
}

export type WebhookTriggerNotificationInput = {
  url: Scalars['String']['input'];
};

export type GetColorPaletteQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetColorPaletteQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    colorPalette: {
      __typename?: 'ColorPalette';
      colors: Array<{ __typename?: 'Color'; hex: string }>;
    };
  } | null;
};

export type UpdateColorPaletteMutationVariables = Exact<{
  input: UpdateColorPaletteInput;
}>;

export type UpdateColorPaletteMutationResult = {
  __typename?: 'Mutation';
  updateColorPalette: Array<{ __typename?: 'Color'; hex: string }>;
};

export type CreateEmailGroupMutationVariables = Exact<{
  input: CreateEmailGroupInput;
}>;

export type CreateEmailGroupMutationResult = {
  __typename?: 'Mutation';
  createEmailGroup: { __typename?: 'EmailGroup'; id: string };
};

export type DeleteEmailGroupMutationVariables = Exact<{
  input: DeleteEmailGroupInput;
}>;

export type DeleteEmailGroupMutationResult = {
  __typename?: 'Mutation';
  deleteEmailGroup: string;
};

export type UpdateEmailGroupMutationVariables = Exact<{
  input: UpdateEmailGroupInput;
}>;

export type UpdateEmailGroupMutationResult = {
  __typename?: 'Mutation';
  updateEmailGroup: { __typename?: 'EmailGroup'; id: string };
};

export type CreatePermissionGroupMutationVariables = Exact<{
  input: CreatePermissionGroupInput;
}>;

export type CreatePermissionGroupMutationResult = {
  __typename?: 'Mutation';
  createPermissionGroup: { __typename?: 'PermissionGroup'; id: string };
};

export type DeletePermissionGroupMutationVariables = Exact<{
  input: DeletePermissionGroupInput;
}>;

export type DeletePermissionGroupMutationResult = {
  __typename?: 'Mutation';
  deletePermissionGroup: string;
};

export type UpdatePermissionGroupMutationVariables = Exact<{
  input: UpdatePermissionGroupInput;
}>;

export type UpdatePermissionGroupMutationResult = {
  __typename?: 'Mutation';
  updatePermissionGroup: { __typename?: 'PermissionGroup'; id: string };
};

export type GetOrganizationByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOrganizationByIdQueryResult = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    defaultSiteRole: PermissionGroupSiteRole;
    temporary: boolean;
    capabilities: Array<Capabilities>;
  } | null;
};

export type GetEmailGroupsByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type GetEmailGroupsByOrganizationQueryResult = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    emailGroups: {
      __typename?: 'OrganizationEmailGroupConnection';
      edges: Array<{
        __typename?: 'OrganizationEmailGroupEdge';
        node: {
          __typename?: 'EmailGroup';
          id: string;
          name: string;
          preferences?: {
            __typename?: 'EmailGroupPreferences';
            specs: Array<{
              __typename?: 'EmailGroupAlarmTypeSpec';
              frequency?: AlarmEmailFrequency | null;
              alarmType: {
                __typename?: 'AnyAlarmReason';
                lightAlarmReason?: LightAlarmReason | null;
                thermostatAlarmReason?: ThermostatAlarmReason | null;
              };
              officeHours?: {
                __typename?: 'OfficeHours';
                startTime: string;
                endTime: string;
              } | null;
            }>;
          } | null;
          sites: {
            __typename?: 'EmailGroupSiteConnection';
            edges: Array<{
              __typename?: 'EmailGroupSiteEdge';
              node: { __typename?: 'Site'; id: string };
            }>;
          };
          users: {
            __typename?: 'EmailGroupUserConnection';
            edges: Array<{
              __typename?: 'EmailGroupUserEdge';
              node: { __typename?: 'User'; id: string };
            }>;
          };
        };
      }>;
    };
  } | null;
};

export type GetUsersByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type GetUsersByOrganizationQueryResult = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    users: {
      __typename?: 'OrganizationUserConnection';
      edges: Array<{
        __typename?: 'OrganizationUserEdge';
        organizationRole: OrganizationRole;
        node: { __typename?: 'User'; id: string; name: string; email: string };
        sites: Array<{
          __typename?: 'OrganizationUserSite';
          site: { __typename?: 'Site'; name: string };
        }>;
      }>;
    };
  } | null;
};

export type RemoveOrganizationUserMutationVariables = Exact<{
  input: RemoveOrganizationUserInput;
}>;

export type RemoveOrganizationUserMutationResult = {
  __typename?: 'Mutation';
  removeOrganizationUser: {
    __typename?: 'Organization';
    id: string;
    name: string;
    defaultSiteRole: PermissionGroupSiteRole;
    capabilities: Array<Capabilities>;
    temporary: boolean;
    sites: {
      __typename?: 'OrganizationSiteConnection';
      edges: Array<{
        __typename?: 'OrganizationSiteEdge';
        node: { __typename?: 'Site'; id: string };
      }>;
    };
    users: {
      __typename?: 'OrganizationUserConnection';
      edges: Array<{
        __typename?: 'OrganizationUserEdge';
        node: { __typename?: 'User'; id: string };
      }>;
    };
  };
};

export type InviteOrganizationUserMutationVariables = Exact<{
  input: InviteOrganizationUserInput;
}>;

export type InviteOrganizationUserMutationResult = {
  __typename?: 'Mutation';
  inviteOrganizationUser: {
    __typename?: 'OrganizationUserEdge';
    organizationRole: OrganizationRole;
    node: { __typename?: 'User'; id: string; name: string; email: string };
    sites: Array<{
      __typename?: 'OrganizationUserSite';
      site: { __typename?: 'Site'; name: string };
    }>;
  };
};

export type UpdateOrganizationUserMutationVariables = Exact<{
  input: UpdateOrganizationUserInput;
}>;

export type UpdateOrganizationUserMutationResult = {
  __typename?: 'Mutation';
  updateOrganizationUser: {
    __typename?: 'OrganizationUserEdge';
    organizationRole: OrganizationRole;
    node: { __typename?: 'User'; id: string; name: string; email: string };
    sites: Array<{
      __typename?: 'OrganizationUserSite';
      site: { __typename?: 'Site'; name: string };
    }>;
  };
};

export type GetPermissionGroupsByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type GetPermissionGroupsByOrganizationQueryResult = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    permissionGroups: Array<{
      __typename?: 'PermissionGroup';
      id: string;
      name: string;
      siteRole: PermissionGroupSiteRole;
      sites: Array<{ __typename?: 'Site'; id: string }>;
      users: Array<{ __typename?: 'User'; id: string }>;
    }>;
  } | null;
};

export type GetSitesByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type GetSitesByOrganizationQueryResult = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    sites: {
      __typename?: 'OrganizationSiteConnection';
      edges: Array<{
        __typename?: 'OrganizationSiteEdge';
        node: { __typename?: 'Site'; id: string; name: string };
      }>;
    };
  } | null;
};

export type GetSitesWithUsersByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type GetSitesWithUsersByOrganizationQueryResult = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    sites: {
      __typename?: 'OrganizationSiteConnection';
      edges: Array<{
        __typename?: 'OrganizationSiteEdge';
        node: { __typename?: 'Site'; id: string; name: string };
        users: Array<{
          __typename?: 'OrganizationSiteUser';
          siteRole: PermissionGroupSiteRole;
          user: { __typename?: 'User'; name: string };
        }>;
      }>;
    };
  } | null;
};

export type QuickSiteSetupMutationVariables = Exact<{
  input: QuickSiteSetupInput;
}>;

export type QuickSiteSetupMutationResult = {
  __typename?: 'Mutation';
  quickSiteSetup: { __typename?: 'Site'; id: string };
};

export type GetAllSitesAsAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSitesAsAdminQueryResult = {
  __typename?: 'Query';
  allSites: {
    __typename?: 'SiteConnection';
    edges: Array<{
      __typename?: 'SiteEdge';
      site: {
        __typename?: 'Site';
        id: string;
        name: string;
        timeZone?: string | null;
        enabledProducts: Array<Products>;
        lightMapLayerIconSize: LightMapLayerIconSize;
        hasCellLine: boolean;
        geoLocation: { __typename?: 'GeoLocation'; latitude: number; longitude: number };
        siteControllers: Array<{ __typename?: 'SiteController'; id: string }>;
        organizations: Array<{ __typename?: 'Organization'; id: string }>;
        subscriptionInfo?: {
          __typename?: 'SiteSubscriptionInfo';
          startsOn?: string | null;
          expiresOn?: string | null;
          subscriptionType: SubscriptionType;
          partner?: string | null;
          salesPerson?: string | null;
          accessBlocked?: boolean | null;
        } | null;
      };
    }>;
  };
};

export type GetSiteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSiteQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    id: string;
    name: string;
    timeZone?: string | null;
    enabledProducts: Array<Products>;
    lightMapLayerIconSize: LightMapLayerIconSize;
    hasCellLine: boolean;
    siteControllers: Array<{
      __typename?: 'SiteController';
      id: string;
      name: string;
      macAddress: string;
      systemVersion?: string | null;
      activeLicenses: Array<string>;
      site?: { __typename?: 'Site'; id: string } | null;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
      remoteAccess?: {
        __typename?: 'SiteControllerRemoteAccess';
        uiProxyUrl: string;
        secretKey: string;
      } | null;
    }>;
    geoLocation: { __typename?: 'GeoLocation'; latitude: number; longitude: number };
    organizations: Array<{ __typename?: 'Organization'; id: string }>;
    subscriptionInfo?: {
      __typename?: 'SiteSubscriptionInfo';
      startsOn?: string | null;
      expiresOn?: string | null;
      subscriptionType: SubscriptionType;
      partner?: string | null;
      salesPerson?: string | null;
      accessBlocked?: boolean | null;
    } | null;
  } | null;
};

export type CreateSiteAsAdminMutationVariables = Exact<{
  input: CreateSiteInput;
}>;

export type CreateSiteAsAdminMutationResult = {
  __typename?: 'Mutation';
  createSite: { __typename?: 'Site'; id: string };
};

export type UpdateSiteAsAdminMutationVariables = Exact<{
  input: UpdateSiteInput;
}>;

export type UpdateSiteAsAdminMutationResult = {
  __typename?: 'Mutation';
  updateSite: { __typename?: 'Site'; id: string };
};

export type DeleteSiteAsAdminMutationVariables = Exact<{
  input: DeleteSiteInput;
}>;

export type DeleteSiteAsAdminMutationResult = {
  __typename?: 'Mutation';
  deleteSite?: string | null;
};

export type GetEditSiteModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetEditSiteModelsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    controlZones: Array<{
      __typename?: 'ControlZone';
      id: string;
      name: string;
      application: Application;
      behavior: {
        __typename?: 'ControlZoneBehaviorEdge';
        parameters?: string | null;
        node: { __typename?: 'Behavior'; id: string };
      };
      thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
      lights?: Array<{ __typename?: 'Light'; id: string }> | null;
    }>;
    scenes: Array<{
      __typename?: 'Scene';
      id: string;
      name: string;
      application: Application;
      controlZones: {
        __typename?: 'SceneControlZoneConnection';
        edges: Array<{
          __typename?: 'SceneControlZoneEdge';
          parameters?: string | null;
          node: { __typename?: 'ControlZone'; id: string };
          behavior: { __typename?: 'Behavior'; id: string };
        }>;
      };
    }>;
  } | null;
};

export type GetSiteUsersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetSiteUsersQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    id: string;
    name: string;
    users: {
      __typename?: 'SiteUserConnection';
      edges: Array<{
        __typename?: 'SiteUserEdge';
        user: { __typename?: 'User'; id: string; name: string; email: string };
      }>;
    };
  } | null;
};

export type GetSiteReportQueryVariables = Exact<{ [key: string]: never }>;

export type GetSiteReportQueryResult = {
  __typename?: 'Query';
  allEnterpriseSubscriptions: Array<{
    __typename?: 'EnterpriseSubscription';
    name: string;
    siteSubType: SubscriptionType;
    startsOn: string;
    expiresOn: string;
  }>;
  allSites: {
    __typename?: 'SiteConnection';
    edges: Array<{
      __typename?: 'SiteEdge';
      site: {
        __typename?: 'Site';
        id: string;
        name: string;
        timeZone?: string | null;
        enabledProducts: Array<Products>;
        hasCellLine: boolean;
        siteControllers: Array<{
          __typename?: 'SiteController';
          id: string;
          name: string;
          activeLicenses: Array<string>;
        }>;
        organizations: Array<{ __typename?: 'Organization'; id: string; name: string }>;
        subscriptionInfo?: {
          __typename?: 'SiteSubscriptionInfo';
          startsOn?: string | null;
          expiresOn?: string | null;
          subscriptionType: SubscriptionType;
          partner?: string | null;
          salesPerson?: string | null;
          accessBlocked?: boolean | null;
        } | null;
        users: {
          __typename?: 'SiteUserConnection';
          edges: Array<{
            __typename?: 'SiteUserEdge';
            node: { __typename?: 'User'; name: string };
          }>;
        };
      };
    }>;
  };
};

export type GetAllSiteControllersAsAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSiteControllersAsAdminQueryResult = {
  __typename?: 'Query';
  allSiteControllers: {
    __typename?: 'SiteControllerConnection';
    edges: Array<{
      __typename?: 'SiteControllerEdge';
      siteController: {
        __typename?: 'SiteController';
        tunnelPort?: number | null;
        id: string;
        name: string;
        macAddress: string;
        systemVersion?: string | null;
        activeLicenses: Array<string>;
        site?: { __typename?: 'Site'; id: string } | null;
        resources: Array<{
          __typename?: 'SiteControllerResource';
          type: SiteControllerResourceType;
          status: SiteControllerResourceStatus;
          lastUpdateReceived?: string | null;
        }>;
        remoteAccess?: {
          __typename?: 'SiteControllerRemoteAccess';
          uiProxyUrl: string;
          secretKey: string;
        } | null;
      };
    }>;
  };
};

export type GetAllSiteControllersAndSitesAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllSiteControllersAndSitesAsAdminQueryResult = {
  __typename?: 'Query';
  allSiteControllers: {
    __typename?: 'SiteControllerConnection';
    edges: Array<{
      __typename?: 'SiteControllerEdge';
      siteController: {
        __typename?: 'SiteController';
        tunnelPort?: number | null;
        id: string;
        name: string;
        macAddress: string;
        systemVersion?: string | null;
        activeLicenses: Array<string>;
        site?: { __typename?: 'Site'; id: string } | null;
        resources: Array<{
          __typename?: 'SiteControllerResource';
          type: SiteControllerResourceType;
          status: SiteControllerResourceStatus;
          lastUpdateReceived?: string | null;
        }>;
        remoteAccess?: {
          __typename?: 'SiteControllerRemoteAccess';
          uiProxyUrl: string;
          secretKey: string;
        } | null;
      };
    }>;
  };
  allSites: {
    __typename?: 'SiteConnection';
    edges: Array<{
      __typename?: 'SiteEdge';
      site: {
        __typename?: 'Site';
        id: string;
        name: string;
        timeZone?: string | null;
        enabledProducts: Array<Products>;
        lightMapLayerIconSize: LightMapLayerIconSize;
        hasCellLine: boolean;
        geoLocation: { __typename?: 'GeoLocation'; latitude: number; longitude: number };
        siteControllers: Array<{ __typename?: 'SiteController'; id: string }>;
        organizations: Array<{ __typename?: 'Organization'; id: string }>;
        subscriptionInfo?: {
          __typename?: 'SiteSubscriptionInfo';
          startsOn?: string | null;
          expiresOn?: string | null;
          subscriptionType: SubscriptionType;
          partner?: string | null;
          salesPerson?: string | null;
          accessBlocked?: boolean | null;
        } | null;
      };
    }>;
  };
};

export type CreateSiteControllerAsAdminMutationVariables = Exact<{
  input: CreateSiteControllerInput;
}>;

export type CreateSiteControllerAsAdminMutationResult = {
  __typename?: 'Mutation';
  createSiteController: { __typename?: 'SiteController'; id: string };
};

export type UpdateSiteControllerAsAdminMutationVariables = Exact<{
  input: UpdateSiteControllerInput;
}>;

export type UpdateSiteControllerAsAdminMutationResult = {
  __typename?: 'Mutation';
  updateSiteController: { __typename?: 'SiteController'; id: string };
};

export type DeleteSiteControllerAsAdminMutationVariables = Exact<{
  input: DeleteSiteControllerInput;
}>;

export type DeleteSiteControllerAsAdminMutationResult = {
  __typename?: 'Mutation';
  deleteSiteController?: string | null;
};

export type InitializeSiteControllerResourcesAsAdminMutationVariables = Exact<{
  input: InitializeSiteControllerResourcesInput;
}>;

export type InitializeSiteControllerResourcesAsAdminMutationResult = {
  __typename?: 'Mutation';
  initializeSiteControllerResources: {
    __typename?: 'SiteController';
    id: string;
    name: string;
    macAddress: string;
    systemVersion?: string | null;
    activeLicenses: Array<string>;
    site?: { __typename?: 'Site'; id: string } | null;
    resources: Array<{
      __typename?: 'SiteControllerResource';
      type: SiteControllerResourceType;
      status: SiteControllerResourceStatus;
      lastUpdateReceived?: string | null;
    }>;
    remoteAccess?: {
      __typename?: 'SiteControllerRemoteAccess';
      uiProxyUrl: string;
      secretKey: string;
    } | null;
  };
};

export type MoveSiteControllerAsAdminMutationVariables = Exact<{
  input: MoveSiteControllerInput;
}>;

export type MoveSiteControllerAsAdminMutationResult = {
  __typename?: 'Mutation';
  moveSiteController: number;
};

export type ReplaceSiteControllerAsAdminMutationVariables = Exact<{
  input: ReplaceSiteControllerInput;
}>;

export type ReplaceSiteControllerAsAdminMutationResult = {
  __typename?: 'Mutation';
  replaceSiteController: number;
};

export type ImportSiteControllerEntitiesAsAdminMutationVariables = Exact<{
  input: ImportEntitiesFromSiteControllerInput;
}>;

export type ImportSiteControllerEntitiesAsAdminMutationResult = {
  __typename?: 'Mutation';
  importEntitiesFromSiteController: number;
};

export type GetAlarmsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetAlarmsQueryResult = {
  __typename?: 'Query';
  alarms: Array<{
    __typename?: 'LegacyAlarm';
    id: string;
    triggerTime: string;
    type: string;
    controllerId: string;
    snapaddr: string;
    cleared: boolean;
    description?: string | null;
    selfClearing?: boolean | null;
    retryable?: boolean | null;
    siteController: { __typename?: 'SiteController'; id: string };
  }>;
  site?: {
    __typename?: 'Site';
    siteControllers: Array<{
      __typename?: 'SiteController';
      id: string;
      name: string;
      macAddress: string;
      systemVersion?: string | null;
      activeLicenses: Array<string>;
      site?: { __typename?: 'Site'; id: string } | null;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
      remoteAccess?: {
        __typename?: 'SiteControllerRemoteAccess';
        uiProxyUrl: string;
        secretKey: string;
      } | null;
    }>;
  } | null;
};

export type GetAllBridge485DetailsModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
  bridge485Id: Scalars['ID']['input'];
}>;

export type GetAllBridge485DetailsModelsQueryResult = {
  __typename?: 'Query';
  bridge485?: {
    __typename?: 'Bridge485';
    id: string;
    name: string;
    samplePeriod: number;
    metrics: Array<{ __typename?: 'Bridge485Metric'; id: string }>;
    network?: {
      __typename?: 'Bridge485Network';
      snapAddress?: string | null;
      siteController?: { __typename?: 'SiteController'; id: string } | null;
    } | null;
    serialSettings: {
      __typename?: 'SerialSettings';
      baudRate: number;
      parity: SerialSettingsParity;
      stopBits: number;
    };
  } | null;
  site?: {
    __typename?: 'Site';
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
  } | null;
};

export type PingBridge485MutationVariables = Exact<{
  pingBridge485Input: PingSnapDeviceInput;
}>;

export type PingBridge485MutationResult = {
  __typename?: 'Mutation';
  pingSnapDevice?: boolean | null;
};

export type DeleteBridge485MutationVariables = Exact<{
  input: DeleteBridge485Input;
}>;

export type DeleteBridge485MutationResult = {
  __typename?: 'Mutation';
  deleteBridge485?: string | null;
};

export type GetAllBridge485ModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetAllBridge485ModelsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    bridge485s: Array<{
      __typename?: 'Bridge485';
      id: string;
      name: string;
      samplePeriod: number;
      metrics: Array<{ __typename?: 'Bridge485Metric'; id: string }>;
      network?: {
        __typename?: 'Bridge485Network';
        snapAddress?: string | null;
        siteController?: { __typename?: 'SiteController'; id: string } | null;
      } | null;
      serialSettings: {
        __typename?: 'SerialSettings';
        baudRate: number;
        parity: SerialSettingsParity;
        stopBits: number;
      };
    }>;
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
    siteControllers: Array<{
      __typename?: 'SiteController';
      id: string;
      name: string;
      macAddress: string;
      systemVersion?: string | null;
      activeLicenses: Array<string>;
      site?: { __typename?: 'Site'; id: string } | null;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
      remoteAccess?: {
        __typename?: 'SiteControllerRemoteAccess';
        uiProxyUrl: string;
        secretKey: string;
      } | null;
    }>;
  } | null;
  configurationTemplates: Array<{
    __typename?: 'ConfigurationTemplate';
    id: string;
    name: string;
    template: Array<{
      __typename?: 'Bridge485MetricTemplate';
      dataAddress: number;
      dataFormat: string;
      functionCode: ModbusFunctionCode;
      name: string;
      unitId: number;
      conversion: { __typename?: 'MetricConversion'; polynomial?: Array<number> | null };
      dataType:
        | { __typename?: 'IndustrialSensorCustomDataType'; name: string; units: string }
        | {
            __typename?: 'IndustrialSensorDefinedDataType';
            type: IndustrialSensorDefinedDataEnum;
          };
    }>;
  }>;
};

export type CreateBridge485MutationVariables = Exact<{
  input: CreateBridge485Input;
}>;

export type CreateBridge485MutationResult = {
  __typename?: 'Mutation';
  createBridge485: {
    __typename?: 'Bridge485';
    id: string;
    name: string;
    samplePeriod: number;
    metrics: Array<{ __typename?: 'Bridge485Metric'; id: string }>;
    network?: {
      __typename?: 'Bridge485Network';
      snapAddress?: string | null;
      siteController?: { __typename?: 'SiteController'; id: string } | null;
    } | null;
    serialSettings: {
      __typename?: 'SerialSettings';
      baudRate: number;
      parity: SerialSettingsParity;
      stopBits: number;
    };
  };
};

export type UpdateBridge485MutationVariables = Exact<{
  input: UpdateBridge485Input;
}>;

export type UpdateBridge485MutationResult = {
  __typename?: 'Mutation';
  updateBridge485: {
    __typename?: 'Bridge485';
    id: string;
    name: string;
    samplePeriod: number;
    metrics: Array<{ __typename?: 'Bridge485Metric'; id: string }>;
    network?: {
      __typename?: 'Bridge485Network';
      snapAddress?: string | null;
      siteController?: { __typename?: 'SiteController'; id: string } | null;
    } | null;
    serialSettings: {
      __typename?: 'SerialSettings';
      baudRate: number;
      parity: SerialSettingsParity;
      stopBits: number;
    };
  };
};

export type SourceModelFragment = {
  __typename?: 'Source';
  id: string;
  name: string;
  type: SourceTypeEnum;
  readingUnits: string;
  siteId: string;
  complexValueDefinitions?: {
    __typename?: 'ComplexValueDefinitions';
    complexValues: Array<{
      __typename?: 'ComplexValueDefinition';
      name: string;
      units: string;
    }>;
  } | null;
};

export type SourceDataModelFragment = {
  __typename?: 'SourceData';
  buckets: Array<{
    __typename?: 'SourceDataBucket';
    timestamp: string;
    value: number;
    complexValue?: { [key: string]: any } | null;
  }>;
};

export type GetSourcesQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetSourcesQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    sources: Array<{
      __typename?: 'Source';
      id: string;
      name: string;
      type: SourceTypeEnum;
      readingUnits: string;
      siteId: string;
      complexValueDefinitions?: {
        __typename?: 'ComplexValueDefinitions';
        complexValues: Array<{
          __typename?: 'ComplexValueDefinition';
          name: string;
          units: string;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetSourceDataQueryVariables = Exact<{
  sourceId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
  start: Scalars['Date']['input'];
  end: Scalars['Date']['input'];
  interval: Interval;
  timezone: Scalars['IANATimeZone']['input'];
}>;

export type GetSourceDataQueryResult = {
  __typename?: 'Query';
  querySource: {
    __typename?: 'SourceData';
    buckets: Array<{
      __typename?: 'SourceDataBucket';
      timestamp: string;
      value: number;
      complexValue?: { [key: string]: any } | null;
    }>;
  };
};

export type GetClimateMapLayerModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetClimateMapLayerModelsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    thermostats: Array<{
      __typename?: 'Thermostat';
      id: string;
      name: string;
      snapaddr?: string | null;
      commissioningStatus: CommissioningStatus;
      make?: string | null;
      model?: string | null;
      siteController?: {
        __typename?: 'SiteController';
        id: string;
        name: string;
        macAddress: string;
        systemVersion?: string | null;
        activeLicenses: Array<string>;
        site?: { __typename?: 'Site'; id: string } | null;
        resources: Array<{
          __typename?: 'SiteControllerResource';
          type: SiteControllerResourceType;
          status: SiteControllerResourceStatus;
          lastUpdateReceived?: string | null;
        }>;
        remoteAccess?: {
          __typename?: 'SiteControllerRemoteAccess';
          uiProxyUrl: string;
          secretKey: string;
        } | null;
      } | null;
      alarms: Array<{
        __typename?: 'ThermostatAlarm';
        id: string;
        reason: ThermostatAlarmReason;
        triggerAt: string;
        siteController: { __typename?: 'SiteController'; id: string };
      }>;
      controlMode: {
        __typename?: 'ThermostatControlMode';
        fanMode: ThermostatFanMode;
        cool: { __typename?: 'Temperature'; F: number };
        heat: { __typename?: 'Temperature'; F: number };
      };
      fallbackControlMode: {
        __typename?: 'ThermostatControlMode';
        fanMode: ThermostatFanMode;
        cool: { __typename?: 'Temperature'; F: number };
        heat: { __typename?: 'Temperature'; F: number };
      };
      floorPlanLocation?: {
        __typename?: 'FloorPlanLocation';
        x: number;
        y: number;
        floorPlan: { __typename?: 'FloorPlan'; id: string };
      } | null;
      state?: {
        __typename?: 'ThermostatState';
        humidity: number;
        lastMessageReceived: string;
        temperature: { __typename?: 'Temperature'; F: number };
      } | null;
    }>;
  } | null;
};

export type GetControlZoneQueryVariables = Exact<{
  controlZoneId: Scalars['ID']['input'];
}>;

export type GetControlZoneQueryResult = {
  __typename?: 'Query';
  controlZone?: {
    __typename?: 'ControlZone';
    id: string;
    name: string;
    application: Application;
    thermostats?: Array<{
      __typename?: 'Thermostat';
      id: string;
      name: string;
      snapaddr?: string | null;
      commissioningStatus: CommissioningStatus;
      make?: string | null;
      model?: string | null;
      controlMode: {
        __typename?: 'ThermostatControlMode';
        fanMode: ThermostatFanMode;
        cool: { __typename?: 'Temperature'; F: number };
        heat: { __typename?: 'Temperature'; F: number };
      };
      fallbackControlMode: {
        __typename?: 'ThermostatControlMode';
        fanMode: ThermostatFanMode;
        cool: { __typename?: 'Temperature'; F: number };
        heat: { __typename?: 'Temperature'; F: number };
      };
      floorPlanLocation?: {
        __typename?: 'FloorPlanLocation';
        x: number;
        y: number;
        floorPlan: { __typename?: 'FloorPlan'; id: string };
      } | null;
      state?: {
        __typename?: 'ThermostatState';
        humidity: number;
        lastMessageReceived: string;
        temperature: { __typename?: 'Temperature'; F: number };
      } | null;
      siteController?: {
        __typename?: 'SiteController';
        id: string;
        name: string;
        macAddress: string;
        systemVersion?: string | null;
        activeLicenses: Array<string>;
        site?: { __typename?: 'Site'; id: string } | null;
        resources: Array<{
          __typename?: 'SiteControllerResource';
          type: SiteControllerResourceType;
          status: SiteControllerResourceStatus;
          lastUpdateReceived?: string | null;
        }>;
        remoteAccess?: {
          __typename?: 'SiteControllerRemoteAccess';
          uiProxyUrl: string;
          secretKey: string;
        } | null;
      } | null;
    }> | null;
    behavior: {
      __typename?: 'ControlZoneBehaviorEdge';
      parameters?: string | null;
      node: { __typename?: 'Behavior'; id: string };
    };
    lights?: Array<{ __typename?: 'Light'; id: string }> | null;
  } | null;
};

export type CreateClimateZoneMutationVariables = Exact<{
  input: CreateControlZoneInput;
}>;

export type CreateClimateZoneMutationResult = {
  __typename?: 'Mutation';
  createControlZone: { __typename?: 'ControlZone'; id: string };
};

export type UpdateClimateZoneMutationVariables = Exact<{
  input: UpdateControlZoneInput;
}>;

export type UpdateClimateZoneMutationResult = {
  __typename?: 'Mutation';
  updateControlZone: { __typename?: 'ControlZone'; id: string };
};

export type DeleteControlZoneMutationVariables = Exact<{
  input: DeleteControlZoneInput;
}>;

export type DeleteControlZoneMutationResult = {
  __typename?: 'Mutation';
  deleteControlZone: string;
};

export type ApplyBehaviorToControlZoneMutationVariables = Exact<{
  input: ApplyBehaviorToControlZoneInput;
}>;

export type ApplyBehaviorToControlZoneMutationResult = {
  __typename?: 'Mutation';
  applyBehaviorToControlZone?: { __typename?: 'ControlZone'; id: string } | null;
};

export type GetSiteZonesQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
  filter: ControlZoneFilterInput;
}>;

export type GetSiteZonesQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    controlZones: Array<{
      __typename?: 'ControlZone';
      id: string;
      name: string;
      application: Application;
      behavior: {
        __typename?: 'ControlZoneBehaviorEdge';
        parameters?: string | null;
        node: { __typename?: 'Behavior'; id: string };
      };
      thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
      lights?: Array<{ __typename?: 'Light'; id: string }> | null;
    }>;
    thermostats: Array<{
      __typename?: 'Thermostat';
      id: string;
      alarms: Array<{
        __typename?: 'ThermostatAlarm';
        id: string;
        reason: ThermostatAlarmReason;
        triggerAt: string;
        siteController: { __typename?: 'SiteController'; id: string };
      }>;
    }>;
  } | null;
};

export type AcceptEulaMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AcceptEulaMutationResult = {
  __typename?: 'Mutation';
  userAcceptedEula?: string | null;
};

export type MyEulaQueryVariables = Exact<{ [key: string]: never }>;

export type MyEulaQueryResult = {
  __typename?: 'Query';
  me: { __typename?: 'User'; eulaAcceptedAt?: string | null };
};

export type CreateDepartmentMutationVariables = Exact<{
  input: CreateDepartmentInput;
}>;

export type CreateDepartmentMutationResult = {
  __typename?: 'Mutation';
  createDepartment: { __typename?: 'Department'; id: string };
};

export type GetDepartmentResourceGroupsAndThingsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetDepartmentResourceGroupsAndThingsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    resourceGroups: Array<
      | {
          __typename: 'Asset';
          make?: string | null;
          model?: string | null;
          imageUrl?: string | null;
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename?: 'Bridge485Metric'; id: string }
            | { __typename?: 'Sense420Sensor'; id: string }
            | { __typename?: 'VirtualIndustrialSensor'; id: string }
          >;
          type:
            | { __typename?: 'CustomAssetType'; name: string }
            | { __typename?: 'DefinedAssetType'; type: DefinedAssetTypeEnum };
          runningState: {
            __typename?: 'AssetRunningState';
            state: RunningState;
            timestamp: string;
          };
          disposition: {
            __typename?: 'AssetDisposition';
            level: DispositionLevel;
            timestamp: string;
          };
          dispositionChanges: Array<{
            __typename: 'AssetTriggerDispositionChange';
            triggerId: string;
            timestamp: string;
            message: string;
            level: DispositionLevel;
          }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'AssetCollection';
          id: string;
          name: string;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'Department';
          energyProfileEquipmentIds: Array<string>;
          id: string;
          name: string;
          controlZones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'LegacyResourceGroup';
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename: 'Bridge485Metric'; id: string }
            | { __typename: 'Sense420Sensor'; id: string }
            | { __typename: 'VirtualIndustrialSensor'; id: string }
          >;
          zones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
    >;
    zones: Array<{ __typename?: 'Zone'; id: string; name: string }>;
  } | null;
};

export type UpdateDepartmentMutationVariables = Exact<{
  input: UpdateDepartmentInput;
}>;

export type UpdateDepartmentMutationResult = {
  __typename?: 'Mutation';
  updateDepartment: { __typename?: 'Department'; id: string };
};

export type DeleteDepartmentMutationVariables = Exact<{
  input: DeleteDepartmentInput;
}>;

export type DeleteDepartmentMutationResult = {
  __typename?: 'Mutation';
  deleteDepartment: string;
};

export type GetEquipmentQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetEquipmentQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    assets: Array<{
      __typename?: 'Asset';
      id: string;
      name: string;
      make?: string | null;
      model?: string | null;
      imageUrl?: string | null;
      type:
        | { __typename: 'CustomAssetType'; name: string }
        | { __typename: 'DefinedAssetType'; type: DefinedAssetTypeEnum };
      industrialSensors: Array<
        | { __typename?: 'Bridge485Metric'; id: string }
        | { __typename?: 'Sense420Sensor'; id: string }
        | { __typename?: 'VirtualIndustrialSensor'; id: string }
      >;
      resourceGroups: Array<
        | { __typename?: 'Asset'; id: string }
        | { __typename?: 'AssetCollection'; id: string }
        | { __typename?: 'Department'; id: string }
        | { __typename?: 'LegacyResourceGroup'; id: string }
      >;
      floorPlanLocation?: {
        __typename?: 'FloorPlanLocation';
        x: number;
        y: number;
        floorPlan: { __typename?: 'FloorPlan'; id: string };
      } | null;
      runningState: {
        __typename?: 'AssetRunningState';
        state: RunningState;
        timestamp: string;
      };
      disposition: {
        __typename?: 'AssetDisposition';
        level: DispositionLevel;
        timestamp: string;
      };
      dispositionChanges: Array<{
        __typename: 'AssetTriggerDispositionChange';
        triggerId: string;
        timestamp: string;
        message: string;
        level: DispositionLevel;
      }>;
    }>;
  } | null;
};

export type GetEquipmentMapLayerDeferredModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetEquipmentMapLayerDeferredModelsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    resourceGroups: Array<
      | {
          __typename: 'Asset';
          make?: string | null;
          model?: string | null;
          imageUrl?: string | null;
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename?: 'Bridge485Metric'; id: string }
            | { __typename?: 'Sense420Sensor'; id: string }
            | { __typename?: 'VirtualIndustrialSensor'; id: string }
          >;
          type:
            | { __typename?: 'CustomAssetType'; name: string }
            | { __typename?: 'DefinedAssetType'; type: DefinedAssetTypeEnum };
          runningState: {
            __typename?: 'AssetRunningState';
            state: RunningState;
            timestamp: string;
          };
          disposition: {
            __typename?: 'AssetDisposition';
            level: DispositionLevel;
            timestamp: string;
          };
          dispositionChanges: Array<{
            __typename: 'AssetTriggerDispositionChange';
            triggerId: string;
            timestamp: string;
            message: string;
            level: DispositionLevel;
          }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'AssetCollection';
          id: string;
          name: string;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'Department';
          energyProfileEquipmentIds: Array<string>;
          id: string;
          name: string;
          controlZones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'LegacyResourceGroup';
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename: 'Bridge485Metric'; id: string }
            | { __typename: 'Sense420Sensor'; id: string }
            | { __typename: 'VirtualIndustrialSensor'; id: string }
          >;
          zones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
    >;
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
    triggers: Array<{
      __typename?: 'Trigger';
      id: string;
      name: string;
      industrialSensor:
        | { __typename?: 'Bridge485Metric'; id: string }
        | { __typename?: 'Sense420Sensor'; id: string }
        | { __typename?: 'VirtualIndustrialSensor'; id: string };
      state?: {
        __typename?: 'TriggerState';
        lastStatusUpdate: string;
        status: TriggerStatus;
      } | null;
      condition?: {
        __typename?: 'TriggerCondition';
        lt?: number | null;
        gt?: number | null;
      } | null;
      transitionConfig: {
        __typename?: 'TriggerTransitionConfig';
        activeTransitionDelayInMinutes: number;
        inactiveTransitionDelayInMinutes: number;
        unknownTransitionDelayInMinutes: number;
      };
      notifications: Array<
        | {
            __typename: 'DispositionTriggerNotification';
            message: string;
            level: DispositionLevel;
          }
        | { __typename: 'RunningStateTriggerNotification'; state: RunningState }
        | { __typename: 'WebhookTriggerNotification'; url: string }
      >;
    }>;
  } | null;
};

export type GetEquipmentProfilePageModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetEquipmentProfilePageModelsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    resourceGroups: Array<
      | {
          __typename: 'Asset';
          make?: string | null;
          model?: string | null;
          imageUrl?: string | null;
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename?: 'Bridge485Metric'; id: string }
            | { __typename?: 'Sense420Sensor'; id: string }
            | { __typename?: 'VirtualIndustrialSensor'; id: string }
          >;
          type:
            | { __typename?: 'CustomAssetType'; name: string }
            | { __typename?: 'DefinedAssetType'; type: DefinedAssetTypeEnum };
          runningState: {
            __typename?: 'AssetRunningState';
            state: RunningState;
            timestamp: string;
          };
          disposition: {
            __typename?: 'AssetDisposition';
            level: DispositionLevel;
            timestamp: string;
          };
          dispositionChanges: Array<{
            __typename: 'AssetTriggerDispositionChange';
            triggerId: string;
            timestamp: string;
            message: string;
            level: DispositionLevel;
          }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'AssetCollection';
          id: string;
          name: string;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'Department';
          energyProfileEquipmentIds: Array<string>;
          id: string;
          name: string;
          controlZones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'LegacyResourceGroup';
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename: 'Bridge485Metric'; id: string }
            | { __typename: 'Sense420Sensor'; id: string }
            | { __typename: 'VirtualIndustrialSensor'; id: string }
          >;
          zones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
    >;
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
    triggers: Array<{
      __typename?: 'Trigger';
      id: string;
      name: string;
      industrialSensor:
        | { __typename?: 'Bridge485Metric'; id: string }
        | { __typename?: 'Sense420Sensor'; id: string }
        | { __typename?: 'VirtualIndustrialSensor'; id: string };
      state?: {
        __typename?: 'TriggerState';
        lastStatusUpdate: string;
        status: TriggerStatus;
      } | null;
      condition?: {
        __typename?: 'TriggerCondition';
        lt?: number | null;
        gt?: number | null;
      } | null;
      transitionConfig: {
        __typename?: 'TriggerTransitionConfig';
        activeTransitionDelayInMinutes: number;
        inactiveTransitionDelayInMinutes: number;
        unknownTransitionDelayInMinutes: number;
      };
      notifications: Array<
        | {
            __typename: 'DispositionTriggerNotification';
            message: string;
            level: DispositionLevel;
          }
        | { __typename: 'RunningStateTriggerNotification'; state: RunningState }
        | { __typename: 'WebhookTriggerNotification'; url: string }
      >;
    }>;
  } | null;
};

export type GetSiteResourceGroupsAndIndustrialSensorsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetSiteResourceGroupsAndIndustrialSensorsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    resourceGroups: Array<
      | {
          __typename: 'Asset';
          make?: string | null;
          model?: string | null;
          imageUrl?: string | null;
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename?: 'Bridge485Metric'; id: string }
            | { __typename?: 'Sense420Sensor'; id: string }
            | { __typename?: 'VirtualIndustrialSensor'; id: string }
          >;
          type:
            | { __typename?: 'CustomAssetType'; name: string }
            | { __typename?: 'DefinedAssetType'; type: DefinedAssetTypeEnum };
          runningState: {
            __typename?: 'AssetRunningState';
            state: RunningState;
            timestamp: string;
          };
          disposition: {
            __typename?: 'AssetDisposition';
            level: DispositionLevel;
            timestamp: string;
          };
          dispositionChanges: Array<{
            __typename: 'AssetTriggerDispositionChange';
            triggerId: string;
            timestamp: string;
            message: string;
            level: DispositionLevel;
          }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'AssetCollection';
          id: string;
          name: string;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'Department';
          energyProfileEquipmentIds: Array<string>;
          id: string;
          name: string;
          controlZones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'LegacyResourceGroup';
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename: 'Bridge485Metric'; id: string }
            | { __typename: 'Sense420Sensor'; id: string }
            | { __typename: 'VirtualIndustrialSensor'; id: string }
          >;
          zones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
    >;
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
  } | null;
};

export type GetEquipmentPageDeferredModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetEquipmentPageDeferredModelsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
    triggers: Array<{
      __typename?: 'Trigger';
      id: string;
      name: string;
      industrialSensor:
        | { __typename?: 'Bridge485Metric'; id: string }
        | { __typename?: 'Sense420Sensor'; id: string }
        | { __typename?: 'VirtualIndustrialSensor'; id: string };
      state?: {
        __typename?: 'TriggerState';
        lastStatusUpdate: string;
        status: TriggerStatus;
      } | null;
      condition?: {
        __typename?: 'TriggerCondition';
        lt?: number | null;
        gt?: number | null;
      } | null;
      transitionConfig: {
        __typename?: 'TriggerTransitionConfig';
        activeTransitionDelayInMinutes: number;
        inactiveTransitionDelayInMinutes: number;
        unknownTransitionDelayInMinutes: number;
      };
      notifications: Array<
        | {
            __typename: 'DispositionTriggerNotification';
            message: string;
            level: DispositionLevel;
          }
        | { __typename: 'RunningStateTriggerNotification'; state: RunningState }
        | { __typename: 'WebhookTriggerNotification'; url: string }
      >;
    }>;
  } | null;
};

export type CreateEquipmentMutationVariables = Exact<{
  input: CreateAssetInput;
}>;

export type CreateEquipmentMutationResult = {
  __typename?: 'Mutation';
  createAsset: { __typename?: 'Asset'; id: string };
};

export type DeleteEquipmentMutationVariables = Exact<{
  input: DeleteAssetInput;
}>;

export type DeleteEquipmentMutationResult = {
  __typename?: 'Mutation';
  deleteAsset: string;
};

export type UpdateEquipmentMutationVariables = Exact<{
  input: UpdateAssetInput;
}>;

export type UpdateEquipmentMutationResult = {
  __typename?: 'Mutation';
  updateAsset: { __typename?: 'Asset'; id: string };
};

export type GetResourceGroupsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetResourceGroupsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    resourceGroups: Array<
      | {
          __typename: 'Asset';
          make?: string | null;
          model?: string | null;
          imageUrl?: string | null;
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename?: 'Bridge485Metric'; id: string }
            | { __typename?: 'Sense420Sensor'; id: string }
            | { __typename?: 'VirtualIndustrialSensor'; id: string }
          >;
          type:
            | { __typename?: 'CustomAssetType'; name: string }
            | { __typename?: 'DefinedAssetType'; type: DefinedAssetTypeEnum };
          runningState: {
            __typename?: 'AssetRunningState';
            state: RunningState;
            timestamp: string;
          };
          disposition: {
            __typename?: 'AssetDisposition';
            level: DispositionLevel;
            timestamp: string;
          };
          dispositionChanges: Array<{
            __typename: 'AssetTriggerDispositionChange';
            triggerId: string;
            timestamp: string;
            message: string;
            level: DispositionLevel;
          }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'AssetCollection';
          id: string;
          name: string;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'Department';
          energyProfileEquipmentIds: Array<string>;
          id: string;
          name: string;
          controlZones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'LegacyResourceGroup';
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename: 'Bridge485Metric'; id: string }
            | { __typename: 'Sense420Sensor'; id: string }
            | { __typename: 'VirtualIndustrialSensor'; id: string }
          >;
          zones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
    >;
  } | null;
};

export type CreateResourceGroupMutationVariables = Exact<{
  input: CreateResourceGroupInput;
}>;

export type CreateResourceGroupMutationResult = {
  __typename?: 'Mutation';
  createResourceGroup: { __typename?: 'LegacyResourceGroup'; id: string };
};

export type UpdateResourceGroupMutationVariables = Exact<{
  input: UpdateResourceGroupInput;
}>;

export type UpdateResourceGroupMutationResult = {
  __typename?: 'Mutation';
  updateResourceGroup: { __typename?: 'LegacyResourceGroup'; id: string };
};

export type DeleteResourceGroupMutationVariables = Exact<{
  input: DeleteResourceGroupInput;
}>;

export type DeleteResourceGroupMutationResult = {
  __typename?: 'Mutation';
  deleteResourceGroup: string;
};

export type GetResourceGroupsAndThingsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetResourceGroupsAndThingsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    resourceGroups: Array<
      | {
          __typename: 'Asset';
          make?: string | null;
          model?: string | null;
          imageUrl?: string | null;
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename?: 'Bridge485Metric'; id: string }
            | { __typename?: 'Sense420Sensor'; id: string }
            | { __typename?: 'VirtualIndustrialSensor'; id: string }
          >;
          type:
            | { __typename?: 'CustomAssetType'; name: string }
            | { __typename?: 'DefinedAssetType'; type: DefinedAssetTypeEnum };
          runningState: {
            __typename?: 'AssetRunningState';
            state: RunningState;
            timestamp: string;
          };
          disposition: {
            __typename?: 'AssetDisposition';
            level: DispositionLevel;
            timestamp: string;
          };
          dispositionChanges: Array<{
            __typename: 'AssetTriggerDispositionChange';
            triggerId: string;
            timestamp: string;
            message: string;
            level: DispositionLevel;
          }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'AssetCollection';
          id: string;
          name: string;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'Department';
          energyProfileEquipmentIds: Array<string>;
          id: string;
          name: string;
          controlZones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
      | {
          __typename: 'LegacyResourceGroup';
          id: string;
          name: string;
          industrialSensors: Array<
            | { __typename: 'Bridge485Metric'; id: string }
            | { __typename: 'Sense420Sensor'; id: string }
            | { __typename: 'VirtualIndustrialSensor'; id: string }
          >;
          zones: Array<{ __typename?: 'ControlZone'; id: string }>;
          resourceGroups: Array<
            | { __typename?: 'Asset'; id: string }
            | { __typename?: 'AssetCollection'; id: string }
            | { __typename?: 'Department'; id: string }
            | { __typename?: 'LegacyResourceGroup'; id: string }
          >;
        }
    >;
    zones: Array<{ __typename?: 'Zone'; id: string; name: string }>;
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
  } | null;
};

export type CreateFloorPlanMutationVariables = Exact<{
  siteId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  image: Scalars['String']['input'];
}>;

export type CreateFloorPlanMutationResult = {
  __typename?: 'Mutation';
  createFloorPlan?: {
    __typename?: 'FloorPlan';
    id: string;
    name: string;
    imageUrl: string;
  } | null;
};

export type GetAllFloorPlansForSiteQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetAllFloorPlansForSiteQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    floorPlans: Array<{
      __typename?: 'FloorPlan';
      id: string;
      name: string;
      imageUrl: string;
    }>;
  } | null;
};

export type DeleteFloorPlanMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteFloorPlanMutationResult = {
  __typename?: 'Mutation';
  deleteFloorPlan?: string | null;
};

export type UpdateFloorPlanImageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  image: Scalars['String']['input'];
}>;

export type UpdateFloorPlanImageMutationResult = {
  __typename?: 'Mutation';
  updateFloorPlanImage?: {
    __typename?: 'FloorPlan';
    id: string;
    name: string;
    imageUrl: string;
  } | null;
};

export type ImportDeviceAssetDataMutationVariables = Exact<{
  input: ImportDeviceAssetDataInput;
}>;

export type ImportDeviceAssetDataMutationResult = {
  __typename?: 'Mutation';
  importDeviceAssetData?: boolean | null;
};

export type GetIndieSensorDetailsModelsQueryVariables = Exact<{
  sensorId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
}>;

export type GetIndieSensorDetailsModelsQueryResult = {
  __typename?: 'Query';
  industrialSensor?:
    | {
        __typename: 'Bridge485Metric';
        dataAddress: number;
        dataFormat: string;
        functionCode: ModbusFunctionCode;
        unitId: number;
        id: string;
        name: string;
        units: string;
        conversion: {
          __typename?: 'MetricConversion';
          polynomial?: Array<number> | null;
        };
        source: { __typename?: 'Bridge485'; id: string };
        dataType:
          | { __typename: 'IndustrialSensorCustomDataType'; name: string; units: string }
          | {
              __typename: 'IndustrialSensorDefinedDataType';
              type: IndustrialSensorDefinedDataEnum;
            };
        state?: {
          __typename: 'Bridge485MetricState';
          lastValue: number;
          lastMessageReceived: string;
        } | null;
      }
    | {
        __typename: 'Sense420Sensor';
        snapaddr: string;
        floorPlanX?: number | null;
        floorPlanY?: number | null;
        floorPlanId?: string | null;
        minValue: number;
        maxValue: number;
        id: string;
        name: string;
        units: string;
        dataType:
          | { __typename: 'IndustrialSensorCustomDataType'; name: string; units: string }
          | {
              __typename: 'IndustrialSensorDefinedDataType';
              type: IndustrialSensorDefinedDataEnum;
            };
        state?: {
          __typename: 'Sense420SensorState';
          lastValue: number;
          siteControllerId?: string | null;
          batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
          upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
          lastMessageReceived: string;
          siteController: { __typename?: 'SiteController'; id: string };
        } | null;
      }
    | {
        __typename: 'VirtualIndustrialSensor';
        id: string;
        name: string;
        units: string;
        dataType:
          | { __typename: 'IndustrialSensorCustomDataType'; name: string; units: string }
          | {
              __typename: 'IndustrialSensorDefinedDataType';
              type: IndustrialSensorDefinedDataEnum;
            };
        state?: {
          __typename: 'VirtualIndustrialSensorState';
          lastValue: number;
          lastMessageReceived: string;
        } | null;
      }
    | null;
  site?: {
    __typename?: 'Site';
    siteControllers: Array<{
      __typename?: 'SiteController';
      id: string;
      name: string;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
    }>;
    bridge485s: Array<{
      __typename?: 'Bridge485';
      id: string;
      name: string;
      samplePeriod: number;
      metrics: Array<{ __typename?: 'Bridge485Metric'; id: string }>;
      network?: {
        __typename?: 'Bridge485Network';
        snapAddress?: string | null;
        siteController?: { __typename?: 'SiteController'; id: string } | null;
      } | null;
      serialSettings: {
        __typename?: 'SerialSettings';
        baudRate: number;
        parity: SerialSettingsParity;
        stopBits: number;
      };
    }>;
  } | null;
};

export type DeleteIndustrialSensorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteIndustrialSensorMutationResult = {
  __typename?: 'Mutation';
  deleteIndustrialSensor?: string | null;
};

export type GetAllIndieSensorsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetAllIndieSensorsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
    utilityServices: Array<{
      __typename?: 'UtilityService';
      id: string;
      name: string;
      mains: Array<
        | { __typename: 'Bridge485Metric'; id: string }
        | { __typename: 'Sense420Sensor'; id: string }
        | { __typename: 'VirtualIndustrialSensor'; id: string }
      >;
      industrialSensors: Array<
        | { __typename: 'Bridge485Metric'; id: string }
        | { __typename: 'Sense420Sensor'; id: string }
        | { __typename: 'VirtualIndustrialSensor'; id: string }
      >;
      lights: Array<{ __typename?: 'Light'; id: string }>;
      scheduledUtilityRates: Array<{
        __typename?: 'ScheduledUtilityRate';
        id: string;
        startDate: string;
        billingFrequency: BillingFrequency;
        utilityRate:
          | {
              __typename?: 'FlatEnergyUtilityRate';
              costOfDemand: string;
              costOfEnergy: string;
            }
          | {
              __typename?: 'OpenEIUtilityRate';
              openEIUtilityRateId: string;
              rateName: string;
              companyName: string;
            };
      }>;
    }>;
  } | null;
};

export type GetAllIndieSensorsAndControllersQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetAllIndieSensorsAndControllersQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
    controllers: Array<{
      __typename?: 'Controller';
      id: string;
      name: string;
      snapaddr: string;
      type: string;
    }>;
    siteControllers: Array<{
      __typename?: 'SiteController';
      id: string;
      name: string;
      macAddress: string;
      systemVersion?: string | null;
      activeLicenses: Array<string>;
      site?: { __typename?: 'Site'; id: string } | null;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
      remoteAccess?: {
        __typename?: 'SiteControllerRemoteAccess';
        uiProxyUrl: string;
        secretKey: string;
      } | null;
    }>;
  } | null;
};

export type CreateSense420SensorMutationVariables = Exact<{
  input: CreateSense420SensorInput;
}>;

export type CreateSense420SensorMutationResult = {
  __typename?: 'Mutation';
  createSense420Sensor?: {
    __typename?: 'Sense420Sensor';
    id: string;
    maxValue: number;
    minValue: number;
    name: string;
    snapaddr: string;
    dataType:
      | { __typename?: 'IndustrialSensorCustomDataType'; name: string; units: string }
      | {
          __typename?: 'IndustrialSensorDefinedDataType';
          type: IndustrialSensorDefinedDataEnum;
        };
  } | null;
};

export type UpdateSense420SensorMutationVariables = Exact<{
  input: UpdateSense420SensorInput;
}>;

export type UpdateSense420SensorMutationResult = {
  __typename?: 'Mutation';
  updateSense420Sensor?: {
    __typename?: 'Sense420Sensor';
    maxValue: number;
    minValue: number;
    name: string;
    snapaddr: string;
    dataType:
      | { __typename?: 'IndustrialSensorCustomDataType'; name: string; units: string }
      | {
          __typename?: 'IndustrialSensorDefinedDataType';
          type: IndustrialSensorDefinedDataEnum;
        };
  } | null;
};

export type DimLightMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  level: Scalars['Int']['input'];
}>;

export type DimLightMutationResult = {
  __typename?: 'Mutation';
  updateLightLevel?: { __typename?: 'LightLevel'; id: string } | null;
};

export type GetLightLevelQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetLightLevelQueryResult = {
  __typename?: 'Query';
  lightLevel?: { __typename?: 'LightLevel'; level?: number | null } | null;
};

export type ReplaceLightMutationVariables = Exact<{
  input: NodeSwapInput;
}>;

export type ReplaceLightMutationResult = {
  __typename?: 'Mutation';
  nodeSwap: Array<{
    __typename?: 'NodeSwapResponse';
    status: NodeSwapResponseStatus;
    message?: string | null;
  }>;
};

export type CheckSignalStrengthMutationVariables = Exact<{
  addresses?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type CheckSignalStrengthMutationResult = {
  __typename?: 'Mutation';
  census?: {
    __typename?: 'CensusResponse';
    controllerAddresses?: Array<string | null> | null;
  } | null;
};

export type PingLightMutationVariables = Exact<{
  input: PingSnapDeviceInput;
}>;

export type PingLightMutationResult = {
  __typename?: 'Mutation';
  pingSnapDevice?: boolean | null;
};

export type GetLightMapLayerModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetLightMapLayerModelsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    lights: Array<{
      __typename?: 'Light';
      id: string;
      name: string;
      level?: number | null;
      floorPlanLocation?: {
        __typename?: 'FloorPlanLocation';
        x: number;
        y: number;
        floorPlan: { __typename?: 'FloorPlan'; id: string };
      } | null;
      controller: {
        __typename?: 'Controller';
        id: string;
        snapaddr: string;
        type: string;
        fixtureAssetData: string;
      };
      siteController?: { __typename?: 'SiteController'; id: string; name: string } | null;
    }>;
  } | null;
};

export type GetLightMapLayerDeferredModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetLightMapLayerDeferredModelsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    lightZones: Array<{
      __typename?: 'LightZone';
      id: string;
      lightId: string;
      zoneId: string;
    }>;
    controlZones: Array<{
      __typename?: 'ControlZone';
      id: string;
      name: string;
      application: Application;
      behavior: {
        __typename?: 'ControlZoneBehaviorEdge';
        parameters?: string | null;
        node: { __typename?: 'Behavior'; id: string };
      };
      thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
      lights?: Array<{ __typename?: 'Light'; id: string }> | null;
    }>;
    behaviors: Array<{
      __typename?: 'Behavior';
      id: string;
      displayGroup: number;
      displayOrder: number;
      application: Application;
      isLicensed: boolean;
    }>;
    scenes: Array<{
      __typename?: 'Scene';
      id: string;
      name: string;
      application: Application;
      controlZones: {
        __typename?: 'SceneControlZoneConnection';
        edges: Array<{
          __typename?: 'SceneControlZoneEdge';
          parameters?: string | null;
          node: { __typename?: 'ControlZone'; id: string };
          behavior: { __typename?: 'Behavior'; id: string };
        }>;
      };
    }>;
    sceneZoneBehaviors: Array<{
      __typename?: 'SceneZoneBehavior';
      id: string;
      sceneId: string;
      behaviorId: string;
      behaviorParameters?: string | null;
      zoneId: string;
    }>;
    controllers: Array<{
      __typename?: 'Controller';
      id: string;
      name: string;
      snapaddr: string;
      type: string;
    }>;
  } | null;
};

export type GetLightZoneDetailsModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
  controlZoneId: Scalars['ID']['input'];
}>;

export type GetLightZoneDetailsModelsQueryResult = {
  __typename?: 'Query';
  controlZone?: {
    __typename?: 'ControlZone';
    id: string;
    name: string;
    application: Application;
    lights?: Array<{
      __typename?: 'Light';
      id: string;
      name: string;
      level?: number | null;
      floorPlanLocation?: {
        __typename?: 'FloorPlanLocation';
        x: number;
        y: number;
        floorPlan: { __typename?: 'FloorPlan'; id: string };
      } | null;
      controller: {
        __typename?: 'Controller';
        id: string;
        snapaddr: string;
        type: string;
        fixtureAssetData: string;
      };
      siteController?: { __typename?: 'SiteController'; id: string; name: string } | null;
    }> | null;
    behavior: {
      __typename?: 'ControlZoneBehaviorEdge';
      parameters?: string | null;
      node: { __typename?: 'Behavior'; id: string };
    };
    thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
  } | null;
  site?: {
    __typename?: 'Site';
    lightZones: Array<{
      __typename?: 'LightZone';
      id: string;
      lightId: string;
      zoneId: string;
    }>;
    scenes: Array<{
      __typename?: 'Scene';
      id: string;
      name: string;
      application: Application;
      controlZones: {
        __typename?: 'SceneControlZoneConnection';
        edges: Array<{
          __typename?: 'SceneControlZoneEdge';
          parameters?: string | null;
          node: { __typename?: 'ControlZone'; id: string };
          behavior: { __typename?: 'Behavior'; id: string };
        }>;
      };
    }>;
    behaviors: Array<{
      __typename?: 'Behavior';
      id: string;
      displayGroup: number;
      displayOrder: number;
      application: Application;
      isLicensed: boolean;
    }>;
  } | null;
};

export type GetSiteControlZonesQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetSiteControlZonesQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    controlZones: Array<{
      __typename?: 'ControlZone';
      id: string;
      name: string;
      application: Application;
      behavior: {
        __typename?: 'ControlZoneBehaviorEdge';
        parameters?: string | null;
        node: { __typename?: 'Behavior'; id: string };
      };
      thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
      lights?: Array<{ __typename?: 'Light'; id: string }> | null;
    }>;
    behaviors: Array<{
      __typename?: 'Behavior';
      id: string;
      displayGroup: number;
      displayOrder: number;
      application: Application;
      isLicensed: boolean;
    }>;
  } | null;
};

export type PlaceThingsOnFloorPlanMutationVariables = Exact<{
  input: PlaceThingsOnFloorPlanInput;
}>;

export type PlaceThingsOnFloorPlanMutationResult = {
  __typename?: 'Mutation';
  placeThingsOnFloorPlan: Array<
    | {
        __typename?: 'Asset';
        floorPlanLocation?: {
          __typename?: 'FloorPlanLocation';
          x: number;
          y: number;
          floorPlan: { __typename?: 'FloorPlan'; id: string };
        } | null;
      }
    | {
        __typename?: 'Light';
        id: string;
        floorPlanLocation?: {
          __typename?: 'FloorPlanLocation';
          x: number;
          y: number;
          floorPlan: { __typename?: 'FloorPlan'; id: string };
        } | null;
      }
    | {
        __typename?: 'Sense420Sensor';
        id: string;
        floorPlanLocation?: {
          __typename?: 'FloorPlanLocation';
          x: number;
          y: number;
          floorPlan: { __typename?: 'FloorPlan'; id: string };
        } | null;
      }
    | {
        __typename?: 'Thermostat';
        id: string;
        floorPlanLocation?: {
          __typename?: 'FloorPlanLocation';
          x: number;
          y: number;
          floorPlan: { __typename?: 'FloorPlan'; id: string };
        } | null;
      }
  >;
};

export type EssentialModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type EssentialModelsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    lightMapLayerIconSize: LightMapLayerIconSize;
    floorPlans: Array<{
      __typename?: 'FloorPlan';
      id: string;
      name: string;
      imageUrl: string;
    }>;
  } | null;
  alarms: Array<{
    __typename?: 'LegacyAlarm';
    id: string;
    triggerTime: string;
    type: string;
    controllerId: string;
    snapaddr: string;
    cleared: boolean;
    description?: string | null;
    selfClearing?: boolean | null;
    retryable?: boolean | null;
    siteController: { __typename?: 'SiteController'; id: string };
  }>;
};

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;

export type CreateOrganizationMutationResult = {
  __typename?: 'Mutation';
  createOrganization: { __typename?: 'Organization'; id: string };
};

export type DeleteOrganizationMutationVariables = Exact<{
  input: DeleteOrganizationInput;
}>;

export type DeleteOrganizationMutationResult = {
  __typename?: 'Mutation';
  deleteOrganization?: string | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutationResult = {
  __typename?: 'Mutation';
  updateOrganization: { __typename?: 'Organization'; id: string };
};

export type GetAllOrganizationsAsAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllOrganizationsAsAdminQueryResult = {
  __typename?: 'Query';
  allOrganizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    defaultSiteRole: PermissionGroupSiteRole;
    capabilities: Array<Capabilities>;
    temporary: boolean;
    sites: {
      __typename?: 'OrganizationSiteConnection';
      edges: Array<{
        __typename?: 'OrganizationSiteEdge';
        node: { __typename?: 'Site'; id: string };
      }>;
    };
    users: {
      __typename?: 'OrganizationUserConnection';
      edges: Array<{
        __typename?: 'OrganizationUserEdge';
        node: { __typename?: 'User'; id: string };
      }>;
    };
  }>;
};

export type GetAllOrganizationsWithSitesAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllOrganizationsWithSitesAsAdminQueryResult = {
  __typename?: 'Query';
  allOrganizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    defaultSiteRole: PermissionGroupSiteRole;
    capabilities: Array<Capabilities>;
    temporary: boolean;
    sites: {
      __typename?: 'OrganizationSiteConnection';
      edges: Array<{
        __typename?: 'OrganizationSiteEdge';
        node: {
          __typename?: 'Site';
          id: string;
          name: string;
          timeZone?: string | null;
          enabledProducts: Array<Products>;
          lightMapLayerIconSize: LightMapLayerIconSize;
          hasCellLine: boolean;
          geoLocation: {
            __typename?: 'GeoLocation';
            latitude: number;
            longitude: number;
          };
          siteControllers: Array<{ __typename?: 'SiteController'; id: string }>;
          organizations: Array<{ __typename?: 'Organization'; id: string }>;
          subscriptionInfo?: {
            __typename?: 'SiteSubscriptionInfo';
            startsOn?: string | null;
            expiresOn?: string | null;
            subscriptionType: SubscriptionType;
            partner?: string | null;
            salesPerson?: string | null;
            accessBlocked?: boolean | null;
          } | null;
        };
      }>;
    };
    users: {
      __typename?: 'OrganizationUserConnection';
      edges: Array<{
        __typename?: 'OrganizationUserEdge';
        node: { __typename?: 'User'; id: string };
      }>;
    };
  }>;
};

export type UpdateEnergyBaselineMutationVariables = Exact<{
  siteId: Scalars['ID']['input'];
  level?: InputMaybe<Scalars['Float']['input']>;
  baselineType?: InputMaybe<BaselineType>;
}>;

export type UpdateEnergyBaselineMutationResult = {
  __typename?: 'Mutation';
  updateEnergyBaseline?: number | null;
};

export type SetBaselineSkippedMutationVariables = Exact<{
  siteId: Scalars['ID']['input'];
  skipBaseline: Scalars['Boolean']['input'];
}>;

export type SetBaselineSkippedMutationResult = {
  __typename?: 'Mutation';
  setBaselineSkipped: boolean;
};

export type GetEnergyBaselineQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetEnergyBaselineQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    energyMonitoring: {
      __typename?: 'SiteEnergyMonitoring';
      baselineSkipped: boolean;
      energyBaseline?: number | null;
      baselineType: BaselineType;
    };
  } | null;
};

export type GetPowerDataQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
  zoneIds?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  >;
  thingIds?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  >;
  dataType: QueryDataType;
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
  interval: Interval;
  timezone: Scalars['String']['input'];
}>;

export type GetPowerDataQueryResult = {
  __typename?: 'Query';
  dataForZones?: Array<{
    __typename?: 'ZoneOrThingData';
    id: string;
    values: Array<{
      __typename?: 'DataForPeriod';
      timestamp: string;
      value: number;
    } | null>;
  } | null> | null;
  dataForThings?: Array<{
    __typename?: 'ZoneOrThingData';
    id: string;
    values: Array<{
      __typename?: 'DataForPeriod';
      timestamp: string;
      value: number;
    } | null>;
  } | null> | null;
};

export type GetAllZonesQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetAllZonesQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    controlZones: Array<{
      __typename?: 'ControlZone';
      id: string;
      name: string;
      application: Application;
      behavior: {
        __typename?: 'ControlZoneBehaviorEdge';
        parameters?: string | null;
        node: { __typename?: 'Behavior'; id: string };
      };
      thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
      lights?: Array<{ __typename?: 'Light'; id: string }> | null;
    }>;
  } | null;
};

export type CreateSceneViewMutationVariables = Exact<{
  input: CreateSceneViewInput;
}>;

export type CreateSceneViewMutationResult = {
  __typename?: 'Mutation';
  createSceneView: { __typename?: 'SceneView'; id: string };
};

export type DeleteSceneViewMutationVariables = Exact<{
  input: DeleteSceneViewInput;
}>;

export type DeleteSceneViewMutationResult = {
  __typename?: 'Mutation';
  deleteSceneView: string;
};

export type UpdateSceneViewMutationVariables = Exact<{
  input: UpdateSceneViewInput;
}>;

export type UpdateSceneViewMutationResult = {
  __typename?: 'Mutation';
  updateSceneView: { __typename?: 'SceneView'; id: string };
};

export type GetSceneViewsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetSceneViewsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    sceneViews: {
      __typename?: 'SiteSceneViewConnection';
      edges: Array<{
        __typename?: 'SiteSceneViewEdge';
        node: {
          __typename?: 'SceneView';
          id: string;
          name: string;
          sceneGroups: {
            __typename?: 'SceneViewSceneGroupConnection';
            edges: Array<{
              __typename?: 'SceneViewSceneGroupEdge';
              displayOrder: number;
              node: {
                __typename?: 'SceneGroup';
                name: string;
                scenes: {
                  __typename?: 'SceneGroupSceneConnection';
                  edges: Array<{
                    __typename?: 'SceneGroupSceneEdge';
                    shortName: string;
                    displayOrder: number;
                    node: { __typename?: 'Scene'; id: string };
                  }>;
                };
              };
            }>;
          };
        };
      }>;
    };
  } | null;
};

export type GetScenesQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetScenesQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    scenes: Array<{
      __typename?: 'Scene';
      id: string;
      name: string;
      application: Application;
      controlZones: {
        __typename?: 'SceneControlZoneConnection';
        edges: Array<{
          __typename?: 'SceneControlZoneEdge';
          parameters?: string | null;
          node: { __typename?: 'ControlZone'; id: string };
          behavior: { __typename?: 'Behavior'; id: string };
        }>;
      };
    }>;
    sceneSequences: Array<{
      __typename?: 'SceneSequence';
      id: string;
      name: string;
      loop: boolean;
      steps: Array<
        | {
            __typename: 'ApplySceneStep';
            stepNumber: number;
            scene: { __typename?: 'Scene'; id: string };
          }
        | { __typename: 'WaitStep'; duration: number; stepNumber: number }
      >;
    }>;
    behaviors: Array<{
      __typename?: 'Behavior';
      id: string;
      displayGroup: number;
      displayOrder: number;
      application: Application;
      isLicensed: boolean;
    }>;
    sceneZoneBehaviors: Array<{
      __typename?: 'SceneZoneBehavior';
      id: string;
      sceneId: string;
      behaviorId: string;
      behaviorParameters?: string | null;
      zoneId: string;
    }>;
    controlZones: Array<{
      __typename?: 'ControlZone';
      id: string;
      name: string;
      application: Application;
      behavior: {
        __typename?: 'ControlZoneBehaviorEdge';
        parameters?: string | null;
        node: { __typename?: 'Behavior'; id: string };
      };
      thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
      lights?: Array<{ __typename?: 'Light'; id: string }> | null;
    }>;
    activeSceneSequences: Array<{
      __typename?: 'ActiveSceneSequence';
      sequenceSceneId: string;
      currentStep: number;
      currentStepCompletionTime: number;
    }>;
  } | null;
};

export type DeleteScene_V2MutationVariables = Exact<{
  deleteSceneInput: DeleteSceneInput;
}>;

export type DeleteScene_V2MutationResult = {
  __typename?: 'Mutation';
  deleteScene_version_2: string;
};

export type ApplyScene_V2MutationVariables = Exact<{
  applySceneInput: ApplySceneInput;
}>;

export type ApplyScene_V2MutationResult = {
  __typename?: 'Mutation';
  applyScene_version_2: { __typename?: 'Scene'; id: string };
};

export type CreateSceneMutationVariables = Exact<{
  createSceneInput: CreateSceneInput;
}>;

export type CreateSceneMutationResult = {
  __typename?: 'Mutation';
  createScene: {
    __typename?: 'Scene';
    id: string;
    name: string;
    application: Application;
    controlZones: {
      __typename?: 'SceneControlZoneConnection';
      edges: Array<{
        __typename?: 'SceneControlZoneEdge';
        parameters?: string | null;
        node: { __typename?: 'ControlZone'; id: string };
        behavior: { __typename?: 'Behavior'; id: string };
      }>;
    };
  };
};

export type UpdateScene_V2MutationVariables = Exact<{
  updateSceneInput: UpdateSceneInput;
}>;

export type UpdateScene_V2MutationResult = {
  __typename?: 'Mutation';
  updateScene_version_2: {
    __typename?: 'Scene';
    id: string;
    name: string;
    application: Application;
    controlZones: {
      __typename?: 'SceneControlZoneConnection';
      edges: Array<{
        __typename?: 'SceneControlZoneEdge';
        parameters?: string | null;
        node: { __typename?: 'ControlZone'; id: string };
        behavior: { __typename?: 'Behavior'; id: string };
      }>;
    };
  };
};

export type DeleteSequenceSceneMutationVariables = Exact<{
  deleteSceneSequenceInput: DeleteSceneSequenceInput;
}>;

export type DeleteSequenceSceneMutationResult = {
  __typename?: 'Mutation';
  deleteSceneSequence: string;
};

export type CreateSequenceSceneMutationVariables = Exact<{
  createSceneSequenceInput: CreateSceneSequenceInput;
}>;

export type CreateSequenceSceneMutationResult = {
  __typename?: 'Mutation';
  createSceneSequence: {
    __typename?: 'SceneSequence';
    id: string;
    name: string;
    loop: boolean;
    steps: Array<
      | {
          __typename: 'ApplySceneStep';
          stepNumber: number;
          scene: { __typename?: 'Scene'; id: string };
        }
      | { __typename: 'WaitStep'; duration: number; stepNumber: number }
    >;
  };
};

export type UpdateSequenceSceneMutationVariables = Exact<{
  updateSceneSequenceInput: UpdateSceneSequenceInput;
}>;

export type UpdateSequenceSceneMutationResult = {
  __typename?: 'Mutation';
  updateSceneSequence: {
    __typename?: 'SceneSequence';
    id: string;
    name: string;
    loop: boolean;
    steps: Array<
      | {
          __typename: 'ApplySceneStep';
          stepNumber: number;
          scene: { __typename?: 'Scene'; id: string };
        }
      | { __typename: 'WaitStep'; duration: number; stepNumber: number }
    >;
  };
};

export type GetAllScheduledEventsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetAllScheduledEventsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    scheduledEvents: Array<{
      __typename?: 'ScheduledEvent';
      id: string;
      name: string;
      start:
        | {
            __typename?: 'AstroEventStartTime';
            startsAt: string;
            event: AstroEvent;
            offset: number;
          }
        | { __typename?: 'FixedStartTime'; startsAt: string };
      recurrence?: { __typename?: 'Recurrence'; rrule: string } | null;
      schedulables?: Array<
        { __typename: 'Scene'; id: string } | { __typename: 'SceneSequence'; id: string }
      > | null;
    }>;
    scenes: Array<{
      __typename?: 'Scene';
      id: string;
      name: string;
      application: Application;
      controlZones: {
        __typename?: 'SceneControlZoneConnection';
        edges: Array<{
          __typename?: 'SceneControlZoneEdge';
          parameters?: string | null;
          node: { __typename?: 'ControlZone'; id: string };
          behavior: { __typename?: 'Behavior'; id: string };
        }>;
      };
    }>;
    sceneSequences: Array<{
      __typename?: 'SceneSequence';
      id: string;
      name: string;
      loop: boolean;
      steps: Array<
        | {
            __typename: 'ApplySceneStep';
            stepNumber: number;
            scene: { __typename?: 'Scene'; id: string };
          }
        | { __typename: 'WaitStep'; duration: number; stepNumber: number }
      >;
    }>;
    sceneZoneBehaviors: Array<{
      __typename?: 'SceneZoneBehavior';
      id: string;
      sceneId: string;
      behaviorId: string;
      behaviorParameters?: string | null;
      zoneId: string;
    }>;
    controlZones: Array<{
      __typename?: 'ControlZone';
      id: string;
      name: string;
      application: Application;
      behavior: {
        __typename?: 'ControlZoneBehaviorEdge';
        parameters?: string | null;
        node: { __typename?: 'Behavior'; id: string };
      };
      thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
      lights?: Array<{ __typename?: 'Light'; id: string }> | null;
    }>;
  } | null;
};

export type CreateScheduledEventMutationVariables = Exact<{
  input: CreateScheduledEventInput;
}>;

export type CreateScheduledEventMutationResult = {
  __typename?: 'Mutation';
  createScheduledEvent: {
    __typename?: 'ScheduledEvent';
    id: string;
    name: string;
    start:
      | {
          __typename?: 'AstroEventStartTime';
          startsAt: string;
          event: AstroEvent;
          offset: number;
        }
      | { __typename?: 'FixedStartTime'; startsAt: string };
    recurrence?: { __typename?: 'Recurrence'; rrule: string } | null;
    schedulables?: Array<
      { __typename: 'Scene'; id: string } | { __typename: 'SceneSequence'; id: string }
    > | null;
  };
};

export type UpdateScheduledEventMutationVariables = Exact<{
  input: UpdateScheduledEventInput;
}>;

export type UpdateScheduledEventMutationResult = {
  __typename?: 'Mutation';
  updateScheduledEvent: {
    __typename?: 'ScheduledEvent';
    id: string;
    name: string;
    start:
      | {
          __typename?: 'AstroEventStartTime';
          startsAt: string;
          event: AstroEvent;
          offset: number;
        }
      | { __typename?: 'FixedStartTime'; startsAt: string };
    recurrence?: { __typename?: 'Recurrence'; rrule: string } | null;
    schedulables?: Array<
      { __typename: 'Scene'; id: string } | { __typename: 'SceneSequence'; id: string }
    > | null;
  };
};

export type DeleteScheduledEventMutationVariables = Exact<{
  input: DeleteScheduledEventInput;
}>;

export type DeleteScheduledEventMutationResult = {
  __typename?: 'Mutation';
  deleteScheduledEvent: string;
};

export type GetSenseMapLayerModelsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetSenseMapLayerModelsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    siteControllers: Array<{
      __typename?: 'SiteController';
      id: string;
      name: string;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
    }>;
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
  } | null;
};

export type GetSiteControllerDetailsQueryVariables = Exact<{
  siteControllerId: Scalars['ID']['input'];
}>;

export type GetSiteControllerDetailsQueryResult = {
  __typename?: 'Query';
  siteController?: {
    __typename?: 'SiteController';
    id: string;
    name: string;
    macAddress: string;
    systemVersion?: string | null;
    activeLicenses: Array<string>;
    site?: {
      __typename?: 'Site';
      id: string;
      name: string;
      timeZone?: string | null;
      enabledProducts: Array<Products>;
      lightMapLayerIconSize: LightMapLayerIconSize;
      hasCellLine: boolean;
      geoLocation: { __typename?: 'GeoLocation'; latitude: number; longitude: number };
      siteControllers: Array<{ __typename?: 'SiteController'; id: string }>;
      organizations: Array<{ __typename?: 'Organization'; id: string }>;
      subscriptionInfo?: {
        __typename?: 'SiteSubscriptionInfo';
        startsOn?: string | null;
        expiresOn?: string | null;
        subscriptionType: SubscriptionType;
        partner?: string | null;
        salesPerson?: string | null;
        accessBlocked?: boolean | null;
      } | null;
    } | null;
    resources: Array<{
      __typename?: 'SiteControllerResource';
      type: SiteControllerResourceType;
      status: SiteControllerResourceStatus;
      lastUpdateReceived?: string | null;
    }>;
    remoteAccess?: {
      __typename?: 'SiteControllerRemoteAccess';
      uiProxyUrl: string;
      secretKey: string;
    } | null;
  } | null;
};

export type DeleteSiteControllerFromDetailsMutationVariables = Exact<{
  input: DeleteSiteControllerInput;
}>;

export type DeleteSiteControllerFromDetailsMutationResult = {
  __typename?: 'Mutation';
  deleteSiteController?: string | null;
};

export type InitializeSiteControllerResourcesFromDetailsMutationVariables = Exact<{
  input: InitializeSiteControllerResourcesInput;
}>;

export type InitializeSiteControllerResourcesFromDetailsMutationResult = {
  __typename?: 'Mutation';
  initializeSiteControllerResources: {
    __typename?: 'SiteController';
    id: string;
    name: string;
    macAddress: string;
    systemVersion?: string | null;
    activeLicenses: Array<string>;
    site?: { __typename?: 'Site'; id: string } | null;
    resources: Array<{
      __typename?: 'SiteControllerResource';
      type: SiteControllerResourceType;
      status: SiteControllerResourceStatus;
      lastUpdateReceived?: string | null;
    }>;
    remoteAccess?: {
      __typename?: 'SiteControllerRemoteAccess';
      uiProxyUrl: string;
      secretKey: string;
    } | null;
  };
};

export type SiteControllersForSiteQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type SiteControllersForSiteQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    siteControllers: Array<{
      __typename?: 'SiteController';
      id: string;
      name: string;
      macAddress: string;
      systemVersion?: string | null;
      activeLicenses: Array<string>;
      site?: { __typename?: 'Site'; id: string } | null;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
      remoteAccess?: {
        __typename?: 'SiteControllerRemoteAccess';
        uiProxyUrl: string;
        secretKey: string;
      } | null;
    }>;
  } | null;
};

export type GetSiteDetailsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetSiteDetailsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    id: string;
    name: string;
    timeZone?: string | null;
    enabledProducts: Array<Products>;
    lightMapLayerIconSize: LightMapLayerIconSize;
    hasCellLine: boolean;
    siteControllers: Array<{
      __typename?: 'SiteController';
      id: string;
      name: string;
      macAddress: string;
      systemVersion?: string | null;
      activeLicenses: Array<string>;
      site?: { __typename?: 'Site'; id: string } | null;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
      remoteAccess?: {
        __typename?: 'SiteControllerRemoteAccess';
        uiProxyUrl: string;
        secretKey: string;
      } | null;
    }>;
    geoLocation: { __typename?: 'GeoLocation'; latitude: number; longitude: number };
    organizations: Array<{ __typename?: 'Organization'; id: string }>;
    subscriptionInfo?: {
      __typename?: 'SiteSubscriptionInfo';
      startsOn?: string | null;
      expiresOn?: string | null;
      subscriptionType: SubscriptionType;
      partner?: string | null;
      salesPerson?: string | null;
      accessBlocked?: boolean | null;
    } | null;
  } | null;
};

export type DeleteSiteFromDetailsMutationVariables = Exact<{
  input: DeleteSiteInput;
}>;

export type DeleteSiteFromDetailsMutationResult = {
  __typename?: 'Mutation';
  deleteSite?: string | null;
};

export type MySitesAndOrganizationsQueryVariables = Exact<{
  filter: UserOrganizationFilterInput;
}>;

export type MySitesAndOrganizationsQueryResult = {
  __typename?: 'Query';
  mySites: Array<{
    __typename?: 'Site';
    id: string;
    name: string;
    timeZone?: string | null;
    enabledProducts: Array<Products>;
    mySiteRole: PermissionGroupSiteRole;
    lightMapLayerIconSize: LightMapLayerIconSize;
    hasCellLine: boolean;
    siteControllers: Array<{
      __typename?: 'SiteController';
      id: string;
      name: string;
      macAddress: string;
      systemVersion?: string | null;
      activeLicenses: Array<string>;
      site?: { __typename?: 'Site'; id: string } | null;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
      remoteAccess?: {
        __typename?: 'SiteControllerRemoteAccess';
        uiProxyUrl: string;
        secretKey: string;
      } | null;
    }>;
    geoLocation: { __typename?: 'GeoLocation'; latitude: number; longitude: number };
    subscriptionInfo?: {
      __typename?: 'SiteSubscriptionInfo';
      startsOn?: string | null;
      expiresOn?: string | null;
      subscriptionType: SubscriptionType;
      partner?: string | null;
      salesPerson?: string | null;
      accessBlocked?: boolean | null;
    } | null;
  }>;
  myOrganizations: {
    __typename?: 'UserOrganizationConnection';
    edges: Array<{
      __typename?: 'UserOrganizationEdge';
      organizationRole: OrganizationRole;
      node: {
        __typename?: 'Organization';
        id: string;
        name: string;
        defaultSiteRole: PermissionGroupSiteRole;
        temporary: boolean;
        capabilities: Array<Capabilities>;
      };
    }>;
  };
};

export type GetThermostatQueryVariables = Exact<{
  thermostatId: Scalars['ID']['input'];
}>;

export type GetThermostatQueryResult = {
  __typename?: 'Query';
  thermostat?: {
    __typename?: 'Thermostat';
    id: string;
    name: string;
    snapaddr?: string | null;
    commissioningStatus: CommissioningStatus;
    make?: string | null;
    model?: string | null;
    controlMode: {
      __typename?: 'ThermostatControlMode';
      fanMode: ThermostatFanMode;
      cool: { __typename?: 'Temperature'; F: number };
      heat: { __typename?: 'Temperature'; F: number };
    };
    fallbackControlMode: {
      __typename?: 'ThermostatControlMode';
      fanMode: ThermostatFanMode;
      cool: { __typename?: 'Temperature'; F: number };
      heat: { __typename?: 'Temperature'; F: number };
    };
    floorPlanLocation?: {
      __typename?: 'FloorPlanLocation';
      x: number;
      y: number;
      floorPlan: { __typename?: 'FloorPlan'; id: string };
    } | null;
    state?: {
      __typename?: 'ThermostatState';
      humidity: number;
      lastMessageReceived: string;
      temperature: { __typename?: 'Temperature'; F: number };
    } | null;
    siteController?: {
      __typename?: 'SiteController';
      id: string;
      name: string;
      macAddress: string;
      systemVersion?: string | null;
      activeLicenses: Array<string>;
      site?: { __typename?: 'Site'; id: string } | null;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
      remoteAccess?: {
        __typename?: 'SiteControllerRemoteAccess';
        uiProxyUrl: string;
        secretKey: string;
      } | null;
    } | null;
  } | null;
};

export type GetThermostatDetailsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
  zoneFilter: ControlZoneFilterInput;
  sceneFilter: SceneFilterInput;
}>;

export type GetThermostatDetailsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    controlZones: Array<{
      __typename?: 'ControlZone';
      id: string;
      name: string;
      application: Application;
      behavior: {
        __typename?: 'ControlZoneBehaviorEdge';
        parameters?: string | null;
        node: { __typename?: 'Behavior'; id: string };
      };
      thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
      lights?: Array<{ __typename?: 'Light'; id: string }> | null;
    }>;
    scenes: Array<{
      __typename?: 'Scene';
      id: string;
      name: string;
      application: Application;
      controlZones: {
        __typename?: 'SceneControlZoneConnection';
        edges: Array<{
          __typename?: 'SceneControlZoneEdge';
          parameters?: string | null;
          node: { __typename?: 'ControlZone'; id: string };
          behavior: { __typename?: 'Behavior'; id: string };
        }>;
      };
    }>;
    sceneZoneBehaviors: Array<{
      __typename?: 'SceneZoneBehavior';
      id: string;
      sceneId: string;
      behaviorId: string;
      behaviorParameters?: string | null;
      zoneId: string;
    }>;
  } | null;
};

export type ControlThermostatMutationVariables = Exact<{
  input: UpdateThermostatControlModeInput;
}>;

export type ControlThermostatMutationResult = {
  __typename?: 'Mutation';
  updateThermostatControlMode: { __typename?: 'Thermostat'; id: string };
};

export type DeleteThermostatMutationVariables = Exact<{
  input: DeleteThermostatInput;
}>;

export type DeleteThermostatMutationResult = {
  __typename?: 'Mutation';
  deleteThermostat: string;
};

export type PingThermostatMutationVariables = Exact<{
  input: PingSnapDeviceInput;
}>;

export type PingThermostatMutationResult = {
  __typename?: 'Mutation';
  pingSnapDevice?: boolean | null;
};

export type CheckThermostatSignalStrengthMutationVariables = Exact<{
  snapaddr?: InputMaybe<Scalars['String']['input']>;
}>;

export type CheckThermostatSignalStrengthMutationResult = {
  __typename?: 'Mutation';
  census?: {
    __typename?: 'CensusResponse';
    controllerAddresses?: Array<string | null> | null;
  } | null;
};

export type GetThermostatsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetThermostatsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    thermostats: Array<{
      __typename?: 'Thermostat';
      id: string;
      name: string;
      snapaddr?: string | null;
      commissioningStatus: CommissioningStatus;
      make?: string | null;
      model?: string | null;
      alarms: Array<{
        __typename?: 'ThermostatAlarm';
        id: string;
        reason: ThermostatAlarmReason;
        triggerAt: string;
        siteController: { __typename?: 'SiteController'; id: string };
      }>;
      controlMode: {
        __typename?: 'ThermostatControlMode';
        fanMode: ThermostatFanMode;
        cool: { __typename?: 'Temperature'; F: number };
        heat: { __typename?: 'Temperature'; F: number };
      };
      fallbackControlMode: {
        __typename?: 'ThermostatControlMode';
        fanMode: ThermostatFanMode;
        cool: { __typename?: 'Temperature'; F: number };
        heat: { __typename?: 'Temperature'; F: number };
      };
      floorPlanLocation?: {
        __typename?: 'FloorPlanLocation';
        x: number;
        y: number;
        floorPlan: { __typename?: 'FloorPlan'; id: string };
      } | null;
      state?: {
        __typename?: 'ThermostatState';
        humidity: number;
        lastMessageReceived: string;
        temperature: { __typename?: 'Temperature'; F: number };
      } | null;
      siteController?: {
        __typename?: 'SiteController';
        id: string;
        name: string;
        macAddress: string;
        systemVersion?: string | null;
        activeLicenses: Array<string>;
        site?: { __typename?: 'Site'; id: string } | null;
        resources: Array<{
          __typename?: 'SiteControllerResource';
          type: SiteControllerResourceType;
          status: SiteControllerResourceStatus;
          lastUpdateReceived?: string | null;
        }>;
        remoteAccess?: {
          __typename?: 'SiteControllerRemoteAccess';
          uiProxyUrl: string;
          secretKey: string;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetThermostatsSiteControllersQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetThermostatsSiteControllersQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    thermostats: Array<{
      __typename?: 'Thermostat';
      id: string;
      name: string;
      snapaddr?: string | null;
      commissioningStatus: CommissioningStatus;
      make?: string | null;
      model?: string | null;
      controlMode: {
        __typename?: 'ThermostatControlMode';
        fanMode: ThermostatFanMode;
        cool: { __typename?: 'Temperature'; F: number };
        heat: { __typename?: 'Temperature'; F: number };
      };
      fallbackControlMode: {
        __typename?: 'ThermostatControlMode';
        fanMode: ThermostatFanMode;
        cool: { __typename?: 'Temperature'; F: number };
        heat: { __typename?: 'Temperature'; F: number };
      };
      floorPlanLocation?: {
        __typename?: 'FloorPlanLocation';
        x: number;
        y: number;
        floorPlan: { __typename?: 'FloorPlan'; id: string };
      } | null;
      state?: {
        __typename?: 'ThermostatState';
        humidity: number;
        lastMessageReceived: string;
        temperature: { __typename?: 'Temperature'; F: number };
      } | null;
      siteController?: {
        __typename?: 'SiteController';
        id: string;
        name: string;
        macAddress: string;
        systemVersion?: string | null;
        activeLicenses: Array<string>;
        site?: { __typename?: 'Site'; id: string } | null;
        resources: Array<{
          __typename?: 'SiteControllerResource';
          type: SiteControllerResourceType;
          status: SiteControllerResourceStatus;
          lastUpdateReceived?: string | null;
        }>;
        remoteAccess?: {
          __typename?: 'SiteControllerRemoteAccess';
          uiProxyUrl: string;
          secretKey: string;
        } | null;
      } | null;
    }>;
    siteControllers: Array<{
      __typename?: 'SiteController';
      id: string;
      name: string;
      macAddress: string;
      systemVersion?: string | null;
      activeLicenses: Array<string>;
      site?: { __typename?: 'Site'; id: string } | null;
      resources: Array<{
        __typename?: 'SiteControllerResource';
        type: SiteControllerResourceType;
        status: SiteControllerResourceStatus;
        lastUpdateReceived?: string | null;
      }>;
      remoteAccess?: {
        __typename?: 'SiteControllerRemoteAccess';
        uiProxyUrl: string;
        secretKey: string;
      } | null;
    }>;
  } | null;
};

export type GetSnapAddressesQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
  filter?: InputMaybe<StringFilterInput>;
}>;

export type GetSnapAddressesQueryResult = {
  __typename?: 'Query';
  site?: { __typename?: 'Site'; snapAddresses: Array<string> } | null;
};

export type CreateThermostatMutationVariables = Exact<{
  input: CreateThermostatInput;
}>;

export type CreateThermostatMutationResult = {
  __typename?: 'Mutation';
  createThermostat: { __typename?: 'Thermostat'; id: string };
};

export type UpdateThermostatMutationVariables = Exact<{
  input: UpdateThermostatInput;
}>;

export type UpdateThermostatMutationResult = {
  __typename?: 'Mutation';
  updateThermostat: { __typename?: 'Thermostat'; id: string };
};

export type CreateTriggerMutationVariables = Exact<{
  input: CreateTriggerInput;
}>;

export type CreateTriggerMutationResult = {
  __typename?: 'Mutation';
  createTrigger: { __typename?: 'Trigger'; id: string };
};

export type DeleteTriggerMutationVariables = Exact<{
  input: DeleteTriggerInput;
}>;

export type DeleteTriggerMutationResult = {
  __typename?: 'Mutation';
  deleteTrigger: string;
};

export type UpdateTriggerMutationVariables = Exact<{
  input: UpdateTriggerInput;
}>;

export type UpdateTriggerMutationResult = {
  __typename?: 'Mutation';
  updateTrigger: { __typename?: 'Trigger'; id: string };
};

export type GetAllTriggersQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetAllTriggersQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    triggers: Array<{
      __typename?: 'Trigger';
      id: string;
      name: string;
      industrialSensor:
        | { __typename?: 'Bridge485Metric'; id: string }
        | { __typename?: 'Sense420Sensor'; id: string }
        | { __typename?: 'VirtualIndustrialSensor'; id: string };
      state?: {
        __typename?: 'TriggerState';
        lastStatusUpdate: string;
        status: TriggerStatus;
      } | null;
      condition?: {
        __typename?: 'TriggerCondition';
        lt?: number | null;
        gt?: number | null;
      } | null;
      transitionConfig: {
        __typename?: 'TriggerTransitionConfig';
        activeTransitionDelayInMinutes: number;
        inactiveTransitionDelayInMinutes: number;
        unknownTransitionDelayInMinutes: number;
      };
      notifications: Array<
        | {
            __typename: 'DispositionTriggerNotification';
            message: string;
            level: DispositionLevel;
          }
        | { __typename: 'RunningStateTriggerNotification'; state: RunningState }
        | { __typename: 'WebhookTriggerNotification'; url: string }
      >;
    }>;
  } | null;
};

export type GetAllMetricSourcesQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
  start: Scalars['Date']['input'];
  end: Scalars['Date']['input'];
}>;

export type GetAllMetricSourcesQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
    metricHistories: Array<
      | {
          __typename?: 'Bridge485Metric';
          id: string;
          history: Array<{
            __typename?: 'MetricDatapoint';
            value: number;
            timestamp: string;
          }>;
        }
      | {
          __typename?: 'Sense420Sensor';
          id: string;
          history: Array<{
            __typename?: 'MetricDatapoint';
            value: number;
            timestamp: string;
          }>;
        }
      | {
          __typename?: 'VirtualIndustrialSensor';
          id: string;
          history: Array<{
            __typename?: 'MetricDatapoint';
            value: number;
            timestamp: string;
          }>;
        }
    >;
  } | null;
};

export type GetAllTriggersAndMetricSourcesQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
  start: Scalars['Date']['input'];
  end: Scalars['Date']['input'];
}>;

export type GetAllTriggersAndMetricSourcesQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    triggers: Array<{
      __typename?: 'Trigger';
      id: string;
      name: string;
      industrialSensor:
        | { __typename?: 'Bridge485Metric'; id: string }
        | { __typename?: 'Sense420Sensor'; id: string }
        | { __typename?: 'VirtualIndustrialSensor'; id: string };
      state?: {
        __typename?: 'TriggerState';
        lastStatusUpdate: string;
        status: TriggerStatus;
      } | null;
      condition?: {
        __typename?: 'TriggerCondition';
        lt?: number | null;
        gt?: number | null;
      } | null;
      transitionConfig: {
        __typename?: 'TriggerTransitionConfig';
        activeTransitionDelayInMinutes: number;
        inactiveTransitionDelayInMinutes: number;
        unknownTransitionDelayInMinutes: number;
      };
      notifications: Array<
        | {
            __typename: 'DispositionTriggerNotification';
            message: string;
            level: DispositionLevel;
          }
        | { __typename: 'RunningStateTriggerNotification'; state: RunningState }
        | { __typename: 'WebhookTriggerNotification'; url: string }
      >;
    }>;
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
    metricHistories: Array<
      | {
          __typename?: 'Bridge485Metric';
          id: string;
          history: Array<{
            __typename?: 'MetricDatapoint';
            value: number;
            timestamp: string;
          }>;
        }
      | {
          __typename?: 'Sense420Sensor';
          id: string;
          history: Array<{
            __typename?: 'MetricDatapoint';
            value: number;
            timestamp: string;
          }>;
        }
      | {
          __typename?: 'VirtualIndustrialSensor';
          id: string;
          history: Array<{
            __typename?: 'MetricDatapoint';
            value: number;
            timestamp: string;
          }>;
        }
    >;
  } | null;
};

export type GetUtilityServicesQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetUtilityServicesQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    id: string;
    utilityServices: Array<{
      __typename?: 'UtilityService';
      id: string;
      name: string;
      mains: Array<
        | { __typename: 'Bridge485Metric'; id: string }
        | { __typename: 'Sense420Sensor'; id: string }
        | { __typename: 'VirtualIndustrialSensor'; id: string }
      >;
      industrialSensors: Array<
        | { __typename: 'Bridge485Metric'; id: string }
        | { __typename: 'Sense420Sensor'; id: string }
        | { __typename: 'VirtualIndustrialSensor'; id: string }
      >;
      lights: Array<{ __typename?: 'Light'; id: string }>;
      scheduledUtilityRates: Array<{
        __typename?: 'ScheduledUtilityRate';
        id: string;
        startDate: string;
        billingFrequency: BillingFrequency;
        utilityRate:
          | {
              __typename?: 'FlatEnergyUtilityRate';
              costOfDemand: string;
              costOfEnergy: string;
            }
          | {
              __typename?: 'OpenEIUtilityRate';
              openEIUtilityRateId: string;
              rateName: string;
              companyName: string;
            };
      }>;
    }>;
  } | null;
};

export type GetBillingPeriodQueryVariables = Exact<{
  utilityServiceId: Scalars['ID']['input'];
  date: Scalars['Date']['input'];
}>;

export type GetBillingPeriodQueryResult = {
  __typename?: 'Query';
  utilityService?: {
    __typename?: 'UtilityService';
    billingPeriod?: {
      __typename: 'BillingPeriod';
      start: string;
      end: string;
      peakDemandPeriods: Array<{
        __typename?: 'PeakDemandReport';
        cost?: string | null;
        dateTimeRange: { __typename?: 'DateTimeRange'; start: string; end: string };
        power: {
          __typename?: 'PowerOutput';
          W: number;
          kW: number;
          MW: number;
          GW: number;
        };
        demandChargePeriod: {
          __typename?: 'DemandChargePeriod';
          periodNumber: number;
          periodName?: string | null;
        };
      }>;
      utilityRate:
        | {
            __typename?: 'FlatEnergyUtilityRate';
            costOfDemand: string;
            costOfEnergy: string;
          }
        | {
            __typename?: 'OpenEIUtilityRate';
            openEIUtilityRateId: string;
            rateName: string;
            companyName: string;
          };
    } | null;
  } | null;
};

export type CreateUtilityServiceMutationVariables = Exact<{
  input: CreateUtilityServiceInput;
}>;

export type CreateUtilityServiceMutationResult = {
  __typename?: 'Mutation';
  createUtilityService: { __typename?: 'UtilityService'; id: string };
};

export type UpdateUtilityServiceMutationVariables = Exact<{
  input: UpdateUtilityServiceInput;
}>;

export type UpdateUtilityServiceMutationResult = {
  __typename?: 'Mutation';
  updateUtilityService: { __typename?: 'UtilityService'; id: string };
};

export type GetUtilityServicesAndThingsQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;

export type GetUtilityServicesAndThingsQueryResult = {
  __typename?: 'Query';
  site?: {
    __typename?: 'Site';
    utilityServices: Array<{
      __typename?: 'UtilityService';
      id: string;
      name: string;
      mains: Array<
        | { __typename: 'Bridge485Metric'; id: string }
        | { __typename: 'Sense420Sensor'; id: string }
        | { __typename: 'VirtualIndustrialSensor'; id: string }
      >;
      industrialSensors: Array<
        | { __typename: 'Bridge485Metric'; id: string }
        | { __typename: 'Sense420Sensor'; id: string }
        | { __typename: 'VirtualIndustrialSensor'; id: string }
      >;
      lights: Array<{ __typename?: 'Light'; id: string }>;
      scheduledUtilityRates: Array<{
        __typename?: 'ScheduledUtilityRate';
        id: string;
        startDate: string;
        billingFrequency: BillingFrequency;
        utilityRate:
          | {
              __typename?: 'FlatEnergyUtilityRate';
              costOfDemand: string;
              costOfEnergy: string;
            }
          | {
              __typename?: 'OpenEIUtilityRate';
              openEIUtilityRateId: string;
              rateName: string;
              companyName: string;
            };
      }>;
    }>;
    industrialSensors: Array<
      | {
          __typename: 'Bridge485Metric';
          dataAddress: number;
          dataFormat: string;
          functionCode: ModbusFunctionCode;
          unitId: number;
          id: string;
          name: string;
          units: string;
          conversion: {
            __typename?: 'MetricConversion';
            polynomial?: Array<number> | null;
          };
          source: { __typename?: 'Bridge485'; id: string };
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Bridge485MetricState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
      | {
          __typename: 'Sense420Sensor';
          snapaddr: string;
          floorPlanX?: number | null;
          floorPlanY?: number | null;
          floorPlanId?: string | null;
          minValue: number;
          maxValue: number;
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'Sense420SensorState';
            lastValue: number;
            siteControllerId?: string | null;
            batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
            upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
            lastMessageReceived: string;
            siteController: { __typename?: 'SiteController'; id: string };
          } | null;
        }
      | {
          __typename: 'VirtualIndustrialSensor';
          id: string;
          name: string;
          units: string;
          dataType:
            | {
                __typename: 'IndustrialSensorCustomDataType';
                name: string;
                units: string;
              }
            | {
                __typename: 'IndustrialSensorDefinedDataType';
                type: IndustrialSensorDefinedDataEnum;
              };
          state?: {
            __typename: 'VirtualIndustrialSensorState';
            lastValue: number;
            lastMessageReceived: string;
          } | null;
        }
    >;
    lights: Array<{
      __typename?: 'Light';
      id: string;
      name: string;
      level?: number | null;
      controllerId: string;
      floorPlanId?: string | null;
      floorPlanX?: number | null;
      floorPlanY?: number | null;
    }>;
  } | null;
};

export type DeleteUtilityServiceMutationVariables = Exact<{
  input: DeleteUtilityServiceInput;
}>;

export type DeleteUtilityServiceMutationResult = {
  __typename?: 'Mutation';
  deleteUtilityService: string;
};

export type CreateScheduledUtilityRateMutationVariables = Exact<{
  input: CreateScheduledUtilityRateInput;
}>;

export type CreateScheduledUtilityRateMutationResult = {
  __typename?: 'Mutation';
  createScheduledUtilityRate: { __typename?: 'ScheduledUtilityRate'; id: string };
};

export type DeleteScheduledUtilityRateMutationVariables = Exact<{
  input: DeleteScheduledUtilityRateInput;
}>;

export type DeleteScheduledUtilityRateMutationResult = {
  __typename?: 'Mutation';
  deleteScheduledUtilityRate: string;
};

export type ApplyBehaviorMutationVariables = Exact<{
  input: ApplyBehaviorToControlZoneInput;
}>;

export type ApplyBehaviorMutationResult = {
  __typename?: 'Mutation';
  applyBehaviorToControlZone?: { __typename?: 'ControlZone'; id: string } | null;
};

export type ApplySceneMutationVariables = Exact<{
  sceneId: Scalars['ID']['input'];
}>;

export type ApplySceneMutationResult = {
  __typename?: 'Mutation';
  applyScene?: { __typename?: 'Scene'; id: string } | null;
};

export type ApplySequenceSceneMutationVariables = Exact<{
  applySceneSequenceInput: ApplySceneSequenceInput;
}>;

export type ApplySequenceSceneMutationResult = {
  __typename?: 'Mutation';
  applySceneSequence: { __typename?: 'SceneSequence'; id: string };
};

export type ActiveSequenceSceneModelFragment = {
  __typename?: 'ActiveSceneSequence';
  sequenceSceneId: string;
  currentStep: number;
  currentStepCompletionTime: number;
};

export type AlarmModelFragment = {
  __typename?: 'LegacyAlarm';
  id: string;
  triggerTime: string;
  type: string;
  controllerId: string;
  snapaddr: string;
  cleared: boolean;
  description?: string | null;
  selfClearing?: boolean | null;
  retryable?: boolean | null;
  siteController: { __typename?: 'SiteController'; id: string };
};

export type BehaviorModelFragment = {
  __typename?: 'Behavior';
  id: string;
  displayGroup: number;
  displayOrder: number;
  application: Application;
  isLicensed: boolean;
};

export type Bridge485TemplateModelFragment = {
  __typename?: 'ConfigurationTemplate';
  id: string;
  name: string;
  template: Array<{
    __typename?: 'Bridge485MetricTemplate';
    dataAddress: number;
    dataFormat: string;
    functionCode: ModbusFunctionCode;
    name: string;
    unitId: number;
    conversion: { __typename?: 'MetricConversion'; polynomial?: Array<number> | null };
    dataType:
      | { __typename?: 'IndustrialSensorCustomDataType'; name: string; units: string }
      | {
          __typename?: 'IndustrialSensorDefinedDataType';
          type: IndustrialSensorDefinedDataEnum;
        };
  }>;
};

export type Bridge485ModelFragment = {
  __typename?: 'Bridge485';
  id: string;
  name: string;
  samplePeriod: number;
  metrics: Array<{ __typename?: 'Bridge485Metric'; id: string }>;
  network?: {
    __typename?: 'Bridge485Network';
    snapAddress?: string | null;
    siteController?: { __typename?: 'SiteController'; id: string } | null;
  } | null;
  serialSettings: {
    __typename?: 'SerialSettings';
    baudRate: number;
    parity: SerialSettingsParity;
    stopBits: number;
  };
};

export type ControllerModelFragment = {
  __typename?: 'Controller';
  id: string;
  name: string;
  snapaddr: string;
  type: string;
};

export type DepartmentModelFragment = {
  __typename?: 'Department';
  id: string;
  name: string;
  energyProfileEquipmentIds: Array<string>;
  controlZones: Array<{
    __typename?: 'ControlZone';
    id: string;
    name: string;
    application: Application;
    behavior: {
      __typename?: 'ControlZoneBehaviorEdge';
      parameters?: string | null;
      node: { __typename?: 'Behavior'; id: string };
    };
    thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
    lights?: Array<{ __typename?: 'Light'; id: string }> | null;
  }>;
  resourceGroups: Array<
    | {
        __typename: 'Asset';
        make?: string | null;
        model?: string | null;
        imageUrl?: string | null;
        id: string;
        name: string;
        industrialSensors: Array<
          | { __typename?: 'Bridge485Metric'; id: string }
          | { __typename?: 'Sense420Sensor'; id: string }
          | { __typename?: 'VirtualIndustrialSensor'; id: string }
        >;
        type:
          | { __typename?: 'CustomAssetType'; name: string }
          | { __typename?: 'DefinedAssetType'; type: DefinedAssetTypeEnum };
        runningState: {
          __typename?: 'AssetRunningState';
          state: RunningState;
          timestamp: string;
        };
        disposition: {
          __typename?: 'AssetDisposition';
          level: DispositionLevel;
          timestamp: string;
        };
        dispositionChanges: Array<{
          __typename: 'AssetTriggerDispositionChange';
          triggerId: string;
          timestamp: string;
          message: string;
          level: DispositionLevel;
        }>;
        resourceGroups: Array<
          | { __typename?: 'Asset'; id: string }
          | { __typename?: 'AssetCollection'; id: string }
          | { __typename?: 'Department'; id: string }
          | { __typename?: 'LegacyResourceGroup'; id: string }
        >;
      }
    | {
        __typename: 'AssetCollection';
        id: string;
        name: string;
        resourceGroups: Array<
          | { __typename?: 'Asset'; id: string }
          | { __typename?: 'AssetCollection'; id: string }
          | { __typename?: 'Department'; id: string }
          | { __typename?: 'LegacyResourceGroup'; id: string }
        >;
      }
    | {
        __typename: 'Department';
        energyProfileEquipmentIds: Array<string>;
        id: string;
        name: string;
        controlZones: Array<{ __typename?: 'ControlZone'; id: string }>;
        resourceGroups: Array<
          | { __typename?: 'Asset'; id: string }
          | { __typename?: 'AssetCollection'; id: string }
          | { __typename?: 'Department'; id: string }
          | { __typename?: 'LegacyResourceGroup'; id: string }
        >;
      }
    | {
        __typename: 'LegacyResourceGroup';
        id: string;
        name: string;
        industrialSensors: Array<
          | { __typename: 'Bridge485Metric'; id: string }
          | { __typename: 'Sense420Sensor'; id: string }
          | { __typename: 'VirtualIndustrialSensor'; id: string }
        >;
        zones: Array<{ __typename?: 'ControlZone'; id: string }>;
        resourceGroups: Array<
          | { __typename?: 'Asset'; id: string }
          | { __typename?: 'AssetCollection'; id: string }
          | { __typename?: 'Department'; id: string }
          | { __typename?: 'LegacyResourceGroup'; id: string }
        >;
      }
  >;
  energyConsumption: {
    __typename?: 'EnergyConsumption';
    start: string;
    end: string;
    timezone: string;
    interval: Interval;
    reports: Array<{
      __typename?: 'EnergyConsumptionReport';
      timestamp: string;
      cost?: string | null;
      energy: { __typename?: 'EnergyOutput'; kWh: number };
    }>;
  };
};

export type EmailGroupModelFragment = {
  __typename?: 'EmailGroup';
  id: string;
  name: string;
  preferences?: {
    __typename?: 'EmailGroupPreferences';
    specs: Array<{
      __typename?: 'EmailGroupAlarmTypeSpec';
      frequency?: AlarmEmailFrequency | null;
      alarmType: {
        __typename?: 'AnyAlarmReason';
        lightAlarmReason?: LightAlarmReason | null;
        thermostatAlarmReason?: ThermostatAlarmReason | null;
      };
      officeHours?: {
        __typename?: 'OfficeHours';
        startTime: string;
        endTime: string;
      } | null;
    }>;
  } | null;
  sites: {
    __typename?: 'EmailGroupSiteConnection';
    edges: Array<{
      __typename?: 'EmailGroupSiteEdge';
      node: { __typename?: 'Site'; id: string };
    }>;
  };
  users: {
    __typename?: 'EmailGroupUserConnection';
    edges: Array<{
      __typename?: 'EmailGroupUserEdge';
      node: { __typename?: 'User'; id: string };
    }>;
  };
};

export type EnergyConsumptionModelFragment = {
  __typename?: 'EnergyConsumption';
  start: string;
  end: string;
  timezone: string;
  interval: Interval;
  reports: Array<{
    __typename?: 'EnergyConsumptionReport';
    timestamp: string;
    cost?: string | null;
    energy: { __typename?: 'EnergyOutput'; kWh: number };
  }>;
};

export type EquipmentModelFragment = {
  __typename?: 'Asset';
  id: string;
  name: string;
  make?: string | null;
  model?: string | null;
  imageUrl?: string | null;
  type:
    | { __typename: 'CustomAssetType'; name: string }
    | { __typename: 'DefinedAssetType'; type: DefinedAssetTypeEnum };
  floorPlanLocation?: {
    __typename?: 'FloorPlanLocation';
    x: number;
    y: number;
    floorPlan: { __typename?: 'FloorPlan'; id: string };
  } | null;
  resourceGroups: Array<
    | { __typename?: 'Asset'; id: string }
    | { __typename?: 'AssetCollection'; id: string }
    | { __typename?: 'Department'; id: string }
    | { __typename?: 'LegacyResourceGroup'; id: string }
  >;
  industrialSensors: Array<
    | { __typename: 'Bridge485Metric'; id: string }
    | { __typename: 'Sense420Sensor'; id: string }
    | { __typename: 'VirtualIndustrialSensor'; id: string }
  >;
  energyConsumption: {
    __typename?: 'EnergyConsumption';
    start: string;
    end: string;
    timezone: string;
    interval: Interval;
    reports: Array<{
      __typename?: 'EnergyConsumptionReport';
      timestamp: string;
      cost?: string | null;
      energy: { __typename?: 'EnergyOutput'; kWh: number };
    }>;
  };
  runningState: {
    __typename?: 'AssetRunningState';
    state: RunningState;
    timestamp: string;
  };
  dispositionChanges: Array<{
    __typename: 'AssetTriggerDispositionChange';
    triggerId: string;
    timestamp: string;
    message: string;
    level: DispositionLevel;
  }>;
  disposition: {
    __typename?: 'AssetDisposition';
    level: DispositionLevel;
    timestamp: string;
  };
};

export type AssetRunningStateModelFragment = {
  __typename?: 'AssetRunningState';
  state: RunningState;
  timestamp: string;
};

export type AssetDispositionChangeModelFragment = {
  __typename: 'AssetTriggerDispositionChange';
  triggerId: string;
  timestamp: string;
  message: string;
  level: DispositionLevel;
};

export type AssetDispositionModelFragment = {
  __typename?: 'AssetDisposition';
  level: DispositionLevel;
  timestamp: string;
};

type IndieSensorModel_Bridge485Metric_Fragment = {
  __typename: 'Bridge485Metric';
  dataAddress: number;
  dataFormat: string;
  functionCode: ModbusFunctionCode;
  unitId: number;
  id: string;
  name: string;
  units: string;
  conversion: { __typename?: 'MetricConversion'; polynomial?: Array<number> | null };
  source: { __typename?: 'Bridge485'; id: string };
  dataType:
    | { __typename: 'IndustrialSensorCustomDataType'; name: string; units: string }
    | {
        __typename: 'IndustrialSensorDefinedDataType';
        type: IndustrialSensorDefinedDataEnum;
      };
  state?: {
    __typename: 'Bridge485MetricState';
    lastValue: number;
    lastMessageReceived: string;
  } | null;
};

type IndieSensorModel_Sense420Sensor_Fragment = {
  __typename: 'Sense420Sensor';
  snapaddr: string;
  floorPlanX?: number | null;
  floorPlanY?: number | null;
  floorPlanId?: string | null;
  minValue: number;
  maxValue: number;
  id: string;
  name: string;
  units: string;
  dataType:
    | { __typename: 'IndustrialSensorCustomDataType'; name: string; units: string }
    | {
        __typename: 'IndustrialSensorDefinedDataType';
        type: IndustrialSensorDefinedDataEnum;
      };
  state?: {
    __typename: 'Sense420SensorState';
    lastValue: number;
    siteControllerId?: string | null;
    batteryLevel: BatteryPoweredIndustrialSensorBatteryLevel;
    upgradeStatus?: BatteryPoweredIndustrialSensorUpgradeStatus | null;
    lastMessageReceived: string;
    siteController: { __typename?: 'SiteController'; id: string };
  } | null;
};

type IndieSensorModel_VirtualIndustrialSensor_Fragment = {
  __typename: 'VirtualIndustrialSensor';
  id: string;
  name: string;
  units: string;
  dataType:
    | { __typename: 'IndustrialSensorCustomDataType'; name: string; units: string }
    | {
        __typename: 'IndustrialSensorDefinedDataType';
        type: IndustrialSensorDefinedDataEnum;
      };
  state?: {
    __typename: 'VirtualIndustrialSensorState';
    lastValue: number;
    lastMessageReceived: string;
  } | null;
};

export type IndieSensorModelFragment =
  | IndieSensorModel_Bridge485Metric_Fragment
  | IndieSensorModel_Sense420Sensor_Fragment
  | IndieSensorModel_VirtualIndustrialSensor_Fragment;

export type LightZoneModelFragment = {
  __typename?: 'LightZone';
  id: string;
  lightId: string;
  zoneId: string;
};

export type LightModelFragment = {
  __typename?: 'Light';
  id: string;
  name: string;
  level?: number | null;
  floorPlanLocation?: {
    __typename?: 'FloorPlanLocation';
    x: number;
    y: number;
    floorPlan: { __typename?: 'FloorPlan'; id: string };
  } | null;
  controller: {
    __typename?: 'Controller';
    id: string;
    snapaddr: string;
    type: string;
    fixtureAssetData: string;
  };
  siteController?: { __typename?: 'SiteController'; id: string; name: string } | null;
};

export type OrganizationUserModelFragment = {
  __typename?: 'OrganizationUserEdge';
  organizationRole: OrganizationRole;
  node: { __typename?: 'User'; id: string; name: string; email: string };
  sites: Array<{
    __typename?: 'OrganizationUserSite';
    site: { __typename?: 'Site'; name: string };
  }>;
};

export type OrganizationModelFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  defaultSiteRole: PermissionGroupSiteRole;
  capabilities: Array<Capabilities>;
  temporary: boolean;
  sites: {
    __typename?: 'OrganizationSiteConnection';
    edges: Array<{
      __typename?: 'OrganizationSiteEdge';
      node: { __typename?: 'Site'; id: string };
    }>;
  };
  users: {
    __typename?: 'OrganizationUserConnection';
    edges: Array<{
      __typename?: 'OrganizationUserEdge';
      node: { __typename?: 'User'; id: string };
    }>;
  };
};

export type OrganizationIdentityModelFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  defaultSiteRole: PermissionGroupSiteRole;
  temporary: boolean;
  capabilities: Array<Capabilities>;
};

export type OrganizationWithSitesModelFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  defaultSiteRole: PermissionGroupSiteRole;
  capabilities: Array<Capabilities>;
  temporary: boolean;
  sites: {
    __typename?: 'OrganizationSiteConnection';
    edges: Array<{
      __typename?: 'OrganizationSiteEdge';
      node: {
        __typename?: 'Site';
        id: string;
        name: string;
        timeZone?: string | null;
        enabledProducts: Array<Products>;
        lightMapLayerIconSize: LightMapLayerIconSize;
        hasCellLine: boolean;
        geoLocation: { __typename?: 'GeoLocation'; latitude: number; longitude: number };
        siteControllers: Array<{ __typename?: 'SiteController'; id: string }>;
        organizations: Array<{ __typename?: 'Organization'; id: string }>;
        subscriptionInfo?: {
          __typename?: 'SiteSubscriptionInfo';
          startsOn?: string | null;
          expiresOn?: string | null;
          subscriptionType: SubscriptionType;
          partner?: string | null;
          salesPerson?: string | null;
          accessBlocked?: boolean | null;
        } | null;
      };
    }>;
  };
  users: {
    __typename?: 'OrganizationUserConnection';
    edges: Array<{
      __typename?: 'OrganizationUserEdge';
      node: { __typename?: 'User'; id: string };
    }>;
  };
};

export type PermissionGroupModelFragment = {
  __typename?: 'PermissionGroup';
  id: string;
  name: string;
  siteRole: PermissionGroupSiteRole;
  sites: Array<{ __typename?: 'Site'; id: string }>;
  users: Array<{ __typename?: 'User'; id: string }>;
};

export type PowerSourceComparisonModelFragment = {
  __typename?: 'ZoneOrThingData';
  id: string;
  values: Array<{
    __typename?: 'DataForPeriod';
    timestamp: string;
    value: number;
  } | null>;
};

type ResourceGroupModel_Asset_Fragment = {
  __typename: 'Asset';
  make?: string | null;
  model?: string | null;
  imageUrl?: string | null;
  id: string;
  name: string;
  industrialSensors: Array<
    | { __typename?: 'Bridge485Metric'; id: string }
    | { __typename?: 'Sense420Sensor'; id: string }
    | { __typename?: 'VirtualIndustrialSensor'; id: string }
  >;
  type:
    | { __typename?: 'CustomAssetType'; name: string }
    | { __typename?: 'DefinedAssetType'; type: DefinedAssetTypeEnum };
  runningState: {
    __typename?: 'AssetRunningState';
    state: RunningState;
    timestamp: string;
  };
  disposition: {
    __typename?: 'AssetDisposition';
    level: DispositionLevel;
    timestamp: string;
  };
  dispositionChanges: Array<{
    __typename: 'AssetTriggerDispositionChange';
    triggerId: string;
    timestamp: string;
    message: string;
    level: DispositionLevel;
  }>;
  resourceGroups: Array<
    | { __typename?: 'Asset'; id: string }
    | { __typename?: 'AssetCollection'; id: string }
    | { __typename?: 'Department'; id: string }
    | { __typename?: 'LegacyResourceGroup'; id: string }
  >;
};

type ResourceGroupModel_AssetCollection_Fragment = {
  __typename: 'AssetCollection';
  id: string;
  name: string;
  resourceGroups: Array<
    | { __typename?: 'Asset'; id: string }
    | { __typename?: 'AssetCollection'; id: string }
    | { __typename?: 'Department'; id: string }
    | { __typename?: 'LegacyResourceGroup'; id: string }
  >;
};

type ResourceGroupModel_Department_Fragment = {
  __typename: 'Department';
  energyProfileEquipmentIds: Array<string>;
  id: string;
  name: string;
  controlZones: Array<{ __typename?: 'ControlZone'; id: string }>;
  resourceGroups: Array<
    | { __typename?: 'Asset'; id: string }
    | { __typename?: 'AssetCollection'; id: string }
    | { __typename?: 'Department'; id: string }
    | { __typename?: 'LegacyResourceGroup'; id: string }
  >;
};

type ResourceGroupModel_LegacyResourceGroup_Fragment = {
  __typename: 'LegacyResourceGroup';
  id: string;
  name: string;
  industrialSensors: Array<
    | { __typename: 'Bridge485Metric'; id: string }
    | { __typename: 'Sense420Sensor'; id: string }
    | { __typename: 'VirtualIndustrialSensor'; id: string }
  >;
  zones: Array<{ __typename?: 'ControlZone'; id: string }>;
  resourceGroups: Array<
    | { __typename?: 'Asset'; id: string }
    | { __typename?: 'AssetCollection'; id: string }
    | { __typename?: 'Department'; id: string }
    | { __typename?: 'LegacyResourceGroup'; id: string }
  >;
};

export type ResourceGroupModelFragment =
  | ResourceGroupModel_Asset_Fragment
  | ResourceGroupModel_AssetCollection_Fragment
  | ResourceGroupModel_Department_Fragment
  | ResourceGroupModel_LegacyResourceGroup_Fragment;

export type SceneViewModelFragment = {
  __typename?: 'SceneView';
  id: string;
  name: string;
  sceneGroups: {
    __typename?: 'SceneViewSceneGroupConnection';
    edges: Array<{
      __typename?: 'SceneViewSceneGroupEdge';
      displayOrder: number;
      node: {
        __typename?: 'SceneGroup';
        name: string;
        scenes: {
          __typename?: 'SceneGroupSceneConnection';
          edges: Array<{
            __typename?: 'SceneGroupSceneEdge';
            shortName: string;
            displayOrder: number;
            node: { __typename?: 'Scene'; id: string };
          }>;
        };
      };
    }>;
  };
};

export type SceneZoneBehaviorModelFragment = {
  __typename?: 'SceneZoneBehavior';
  id: string;
  sceneId: string;
  behaviorId: string;
  behaviorParameters?: string | null;
  zoneId: string;
};

export type SceneModelFragment = {
  __typename?: 'Scene';
  id: string;
  name: string;
  application: Application;
  controlZones: {
    __typename?: 'SceneControlZoneConnection';
    edges: Array<{
      __typename?: 'SceneControlZoneEdge';
      parameters?: string | null;
      node: { __typename?: 'ControlZone'; id: string };
      behavior: { __typename?: 'Behavior'; id: string };
    }>;
  };
};

export type ScheduledEventModelFragment = {
  __typename?: 'ScheduledEvent';
  id: string;
  name: string;
  start:
    | {
        __typename?: 'AstroEventStartTime';
        startsAt: string;
        event: AstroEvent;
        offset: number;
      }
    | { __typename?: 'FixedStartTime'; startsAt: string };
  recurrence?: { __typename?: 'Recurrence'; rrule: string } | null;
  schedulables?: Array<
    { __typename: 'Scene'; id: string } | { __typename: 'SceneSequence'; id: string }
  > | null;
};

export type ScheduledUtilityRateModelFragment = {
  __typename?: 'UtilityService';
  id: string;
  scheduledUtilityRates: Array<{
    __typename?: 'ScheduledUtilityRate';
    id: string;
    startDate: string;
    billingFrequency: BillingFrequency;
    utilityRate:
      | {
          __typename?: 'FlatEnergyUtilityRate';
          costOfDemand: string;
          costOfEnergy: string;
        }
      | {
          __typename?: 'OpenEIUtilityRate';
          openEIUtilityRateId: string;
          rateName: string;
          companyName: string;
        };
  }>;
};

type SceneSequenceStepModel_ApplySceneStep_Fragment = {
  __typename: 'ApplySceneStep';
  stepNumber: number;
  scene: { __typename?: 'Scene'; id: string };
};

type SceneSequenceStepModel_WaitStep_Fragment = {
  __typename: 'WaitStep';
  duration: number;
  stepNumber: number;
};

export type SceneSequenceStepModelFragment =
  | SceneSequenceStepModel_ApplySceneStep_Fragment
  | SceneSequenceStepModel_WaitStep_Fragment;

export type SequenceSceneModelFragment = {
  __typename?: 'SceneSequence';
  id: string;
  name: string;
  loop: boolean;
  steps: Array<
    | {
        __typename: 'ApplySceneStep';
        stepNumber: number;
        scene: { __typename?: 'Scene'; id: string };
      }
    | { __typename: 'WaitStep'; duration: number; stepNumber: number }
  >;
};

export type SiteControllerModelFragment = {
  __typename?: 'SiteController';
  id: string;
  name: string;
  macAddress: string;
  systemVersion?: string | null;
  activeLicenses: Array<string>;
  site?: { __typename?: 'Site'; id: string } | null;
  resources: Array<{
    __typename?: 'SiteControllerResource';
    type: SiteControllerResourceType;
    status: SiteControllerResourceStatus;
    lastUpdateReceived?: string | null;
  }>;
  remoteAccess?: {
    __typename?: 'SiteControllerRemoteAccess';
    uiProxyUrl: string;
    secretKey: string;
  } | null;
};

export type SiteModelFragment = {
  __typename?: 'Site';
  id: string;
  name: string;
  timeZone?: string | null;
  enabledProducts: Array<Products>;
  lightMapLayerIconSize: LightMapLayerIconSize;
  hasCellLine: boolean;
  geoLocation: { __typename?: 'GeoLocation'; latitude: number; longitude: number };
  siteControllers: Array<{ __typename?: 'SiteController'; id: string }>;
  organizations: Array<{ __typename?: 'Organization'; id: string }>;
  subscriptionInfo?: {
    __typename?: 'SiteSubscriptionInfo';
    startsOn?: string | null;
    expiresOn?: string | null;
    subscriptionType: SubscriptionType;
    partner?: string | null;
    salesPerson?: string | null;
    accessBlocked?: boolean | null;
  } | null;
};

export type SiteWithSiteRoleModelFragment = {
  __typename?: 'Site';
  id: string;
  name: string;
  timeZone?: string | null;
  enabledProducts: Array<Products>;
  mySiteRole: PermissionGroupSiteRole;
  lightMapLayerIconSize: LightMapLayerIconSize;
  hasCellLine: boolean;
  geoLocation: { __typename?: 'GeoLocation'; latitude: number; longitude: number };
  subscriptionInfo?: {
    __typename?: 'SiteSubscriptionInfo';
    startsOn?: string | null;
    expiresOn?: string | null;
    subscriptionType: SubscriptionType;
    partner?: string | null;
    salesPerson?: string | null;
    accessBlocked?: boolean | null;
  } | null;
};

export type SiteWithSiteControllersModelFragment = {
  __typename?: 'Site';
  id: string;
  name: string;
  timeZone?: string | null;
  enabledProducts: Array<Products>;
  lightMapLayerIconSize: LightMapLayerIconSize;
  hasCellLine: boolean;
  geoLocation: { __typename?: 'GeoLocation'; latitude: number; longitude: number };
  siteControllers: Array<{
    __typename?: 'SiteController';
    id: string;
    name: string;
    macAddress: string;
    systemVersion?: string | null;
    activeLicenses: Array<string>;
    site?: { __typename?: 'Site'; id: string } | null;
    resources: Array<{
      __typename?: 'SiteControllerResource';
      lastUpdateReceived?: string | null;
      status: SiteControllerResourceStatus;
      type: SiteControllerResourceType;
    }>;
    remoteAccess?: {
      __typename?: 'SiteControllerRemoteAccess';
      uiProxyUrl: string;
      secretKey: string;
    } | null;
  }>;
  organizations: Array<{ __typename?: 'Organization'; id: string }>;
  subscriptionInfo?: {
    __typename?: 'SiteSubscriptionInfo';
    startsOn?: string | null;
    expiresOn?: string | null;
    subscriptionType: SubscriptionType;
    partner?: string | null;
    salesPerson?: string | null;
    accessBlocked?: boolean | null;
  } | null;
};

export type SiteIdentityModelFragment = { __typename?: 'Site'; id: string; name: string };

export type OrgSiteWithUsersModelFragment = {
  __typename?: 'OrganizationSiteEdge';
  node: { __typename?: 'Site'; id: string; name: string };
  users: Array<{
    __typename?: 'OrganizationSiteUser';
    siteRole: PermissionGroupSiteRole;
    user: { __typename?: 'User'; name: string };
  }>;
};

export type ThermostatControlModeModelFragment = {
  __typename?: 'ThermostatControlMode';
  fanMode: ThermostatFanMode;
  cool: { __typename?: 'Temperature'; F: number };
  heat: { __typename?: 'Temperature'; F: number };
};

export type ThermostatStateModelFragment = {
  __typename?: 'ThermostatState';
  humidity: number;
  lastMessageReceived: string;
  temperature: { __typename?: 'Temperature'; F: number };
};

export type ThermostatModelFragment = {
  __typename?: 'Thermostat';
  id: string;
  name: string;
  snapaddr?: string | null;
  commissioningStatus: CommissioningStatus;
  make?: string | null;
  model?: string | null;
  controlMode: {
    __typename?: 'ThermostatControlMode';
    fanMode: ThermostatFanMode;
    cool: { __typename?: 'Temperature'; F: number };
    heat: { __typename?: 'Temperature'; F: number };
  };
  fallbackControlMode: {
    __typename?: 'ThermostatControlMode';
    fanMode: ThermostatFanMode;
    cool: { __typename?: 'Temperature'; F: number };
    heat: { __typename?: 'Temperature'; F: number };
  };
  floorPlanLocation?: {
    __typename?: 'FloorPlanLocation';
    x: number;
    y: number;
    floorPlan: { __typename?: 'FloorPlan'; id: string };
  } | null;
  state?: {
    __typename?: 'ThermostatState';
    humidity: number;
    lastMessageReceived: string;
    temperature: { __typename?: 'Temperature'; F: number };
  } | null;
  siteController?: {
    __typename?: 'SiteController';
    id: string;
    name: string;
    macAddress: string;
    systemVersion?: string | null;
    activeLicenses: Array<string>;
    site?: { __typename?: 'Site'; id: string } | null;
    resources: Array<{
      __typename?: 'SiteControllerResource';
      type: SiteControllerResourceType;
      status: SiteControllerResourceStatus;
      lastUpdateReceived?: string | null;
    }>;
    remoteAccess?: {
      __typename?: 'SiteControllerRemoteAccess';
      uiProxyUrl: string;
      secretKey: string;
    } | null;
  } | null;
};

export type ThermostatAlarmModelFragment = {
  __typename?: 'ThermostatAlarm';
  id: string;
  reason: ThermostatAlarmReason;
  triggerAt: string;
  siteController: { __typename?: 'SiteController'; id: string };
};

type MetricHistoryModel_Bridge485Metric_Fragment = {
  __typename?: 'Bridge485Metric';
  id: string;
  history: Array<{ __typename?: 'MetricDatapoint'; value: number; timestamp: string }>;
};

type MetricHistoryModel_Sense420Sensor_Fragment = {
  __typename?: 'Sense420Sensor';
  id: string;
  history: Array<{ __typename?: 'MetricDatapoint'; value: number; timestamp: string }>;
};

type MetricHistoryModel_VirtualIndustrialSensor_Fragment = {
  __typename?: 'VirtualIndustrialSensor';
  id: string;
  history: Array<{ __typename?: 'MetricDatapoint'; value: number; timestamp: string }>;
};

export type MetricHistoryModelFragment =
  | MetricHistoryModel_Bridge485Metric_Fragment
  | MetricHistoryModel_Sense420Sensor_Fragment
  | MetricHistoryModel_VirtualIndustrialSensor_Fragment;

export type TriggerModelFragment = {
  __typename?: 'Trigger';
  id: string;
  name: string;
  industrialSensor:
    | { __typename?: 'Bridge485Metric'; id: string }
    | { __typename?: 'Sense420Sensor'; id: string }
    | { __typename?: 'VirtualIndustrialSensor'; id: string };
  state?: {
    __typename?: 'TriggerState';
    lastStatusUpdate: string;
    status: TriggerStatus;
  } | null;
  condition?: {
    __typename?: 'TriggerCondition';
    lt?: number | null;
    gt?: number | null;
  } | null;
  transitionConfig: {
    __typename?: 'TriggerTransitionConfig';
    activeTransitionDelayInMinutes: number;
    inactiveTransitionDelayInMinutes: number;
    unknownTransitionDelayInMinutes: number;
  };
  notifications: Array<
    | {
        __typename: 'DispositionTriggerNotification';
        message: string;
        level: DispositionLevel;
      }
    | { __typename: 'RunningStateTriggerNotification'; state: RunningState }
    | { __typename: 'WebhookTriggerNotification'; url: string }
  >;
};

export type UserModelFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  email: string;
  role: UserRole;
};

export type UtilityServiceModelFragment = {
  __typename?: 'UtilityService';
  id: string;
  name: string;
  mains: Array<
    | { __typename: 'Bridge485Metric'; id: string }
    | { __typename: 'Sense420Sensor'; id: string }
    | { __typename: 'VirtualIndustrialSensor'; id: string }
  >;
  industrialSensors: Array<
    | { __typename: 'Bridge485Metric'; id: string }
    | { __typename: 'Sense420Sensor'; id: string }
    | { __typename: 'VirtualIndustrialSensor'; id: string }
  >;
  lights: Array<{ __typename?: 'Light'; id: string }>;
};

export type ZoneModelFragment = {
  __typename?: 'Zone';
  id: string;
  name: string;
  behaviorId: string;
  behaviorParameters?: string | null;
};

export type ControlZoneModelFragment = {
  __typename?: 'ControlZone';
  id: string;
  name: string;
  application: Application;
  behavior: {
    __typename?: 'ControlZoneBehaviorEdge';
    parameters?: string | null;
    node: { __typename?: 'Behavior'; id: string };
  };
  thermostats?: Array<{ __typename?: 'Thermostat'; id: string }> | null;
  lights?: Array<{ __typename?: 'Light'; id: string }> | null;
};

export type GetAllUsersAsAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersAsAdminQueryResult = {
  __typename?: 'Query';
  allUsers: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    role: UserRole;
  }>;
};

export type GetUserAsAdminQueryVariables = Exact<{
  input: UserQueryInput;
}>;

export type GetUserAsAdminQueryResult = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    role: UserRole;
  } | null;
};

export type GetUserSitesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUserSitesQueryResult = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    sites: {
      __typename?: 'UserSiteConnection';
      edges: Array<{
        __typename?: 'UserSiteEdge';
        site: { __typename?: 'Site'; id: string; name: string };
      }>;
    };
  } | null;
};

export type CreateUserAsAdminMutationVariables = Exact<{
  input: UserCreateInput;
}>;

export type CreateUserAsAdminMutationResult = {
  __typename?: 'Mutation';
  createUser: { __typename?: 'User'; id: string };
};

export type UpdateUserAsAdminMutationVariables = Exact<{
  input: UserUpdateInput;
}>;

export type UpdateUserAsAdminMutationResult = {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'User'; id: string };
};

export type DeleteUserAsAdminMutationVariables = Exact<{
  input: UserDeleteInput;
}>;

export type DeleteUserAsAdminMutationResult = {
  __typename?: 'Mutation';
  deleteUser?: string | null;
};

export type TriggerResetPasswordAsAdminMutationVariables = Exact<{
  input: UserTriggerPasswordReset;
}>;

export type TriggerResetPasswordAsAdminMutationResult = {
  __typename?: 'Mutation';
  triggerPasswordReset?: string | null;
};

export const GetColorPaletteDocument = gql`
  query getColorPalette($siteId: ID!) {
    site(id: $siteId) {
      colorPalette {
        colors {
          hex
        }
      }
    }
  }
`;
export const UpdateColorPaletteDocument = gql`
  mutation updateColorPalette($input: UpdateColorPaletteInput!) {
    updateColorPalette(input: $input) {
      hex
    }
  }
`;
export const CreateEmailGroupDocument = gql`
  mutation createEmailGroup($input: CreateEmailGroupInput!) {
    createEmailGroup(input: $input) {
      id
    }
  }
`;
export const DeleteEmailGroupDocument = gql`
  mutation deleteEmailGroup($input: DeleteEmailGroupInput!) {
    deleteEmailGroup(input: $input)
  }
`;
export const UpdateEmailGroupDocument = gql`
  mutation updateEmailGroup($input: UpdateEmailGroupInput!) {
    updateEmailGroup(input: $input) {
      id
    }
  }
`;
export const CreatePermissionGroupDocument = gql`
  mutation createPermissionGroup($input: CreatePermissionGroupInput!) {
    createPermissionGroup(input: $input) {
      id
    }
  }
`;
export const DeletePermissionGroupDocument = gql`
  mutation deletePermissionGroup($input: DeletePermissionGroupInput!) {
    deletePermissionGroup(input: $input)
  }
`;
export const UpdatePermissionGroupDocument = gql`
  mutation updatePermissionGroup($input: UpdatePermissionGroupInput!) {
    updatePermissionGroup(input: $input) {
      id
    }
  }
`;
export const GetOrganizationByIdDocument = gql`
  query getOrganizationById($id: ID!) {
    organization(id: $id) {
      id
      name
      defaultSiteRole
      temporary
      capabilities
    }
  }
`;
export const GetEmailGroupsByOrganizationDocument = gql`
  query getEmailGroupsByOrganization($organizationId: ID!) {
    organization(id: $organizationId) {
      emailGroups {
        edges {
          node {
            id
            name
            preferences {
              specs {
                alarmType {
                  lightAlarmReason
                  thermostatAlarmReason
                }
                frequency
                officeHours {
                  startTime
                  endTime
                }
              }
            }
            sites {
              edges {
                node {
                  id
                }
              }
            }
            users {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GetUsersByOrganizationDocument = gql`
  query getUsersByOrganization($organizationId: ID!) {
    organization(id: $organizationId) {
      users {
        edges {
          node {
            id
            name
            email
          }
          organizationRole
          sites {
            site {
              name
            }
          }
        }
      }
    }
  }
`;
export const RemoveOrganizationUserDocument = gql`
  mutation removeOrganizationUser($input: RemoveOrganizationUserInput!) {
    removeOrganizationUser(input: $input) {
      id
      name
      defaultSiteRole
      sites {
        edges {
          node {
            id
          }
        }
      }
      users {
        edges {
          node {
            id
          }
        }
      }
      capabilities
      temporary
    }
  }
`;
export const InviteOrganizationUserDocument = gql`
  mutation inviteOrganizationUser($input: InviteOrganizationUserInput!) {
    inviteOrganizationUser(input: $input) {
      node {
        id
        name
        email
      }
      organizationRole
      sites {
        site {
          name
        }
      }
    }
  }
`;
export const UpdateOrganizationUserDocument = gql`
  mutation updateOrganizationUser($input: UpdateOrganizationUserInput!) {
    updateOrganizationUser(input: $input) {
      node {
        id
        name
        email
      }
      organizationRole
      sites {
        site {
          name
        }
      }
    }
  }
`;
export const GetPermissionGroupsByOrganizationDocument = gql`
  query getPermissionGroupsByOrganization($organizationId: ID!) {
    organization(id: $organizationId) {
      permissionGroups {
        id
        name
        siteRole
        sites {
          id
        }
        users {
          id
        }
      }
    }
  }
`;
export const GetSitesByOrganizationDocument = gql`
  query getSitesByOrganization($organizationId: ID!) {
    organization(id: $organizationId) {
      sites {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
export const GetSitesWithUsersByOrganizationDocument = gql`
  query getSitesWithUsersByOrganization($organizationId: ID!) {
    organization(id: $organizationId) {
      sites {
        edges {
          node {
            id
            name
          }
          users {
            siteRole
            user {
              name
            }
          }
        }
      }
    }
  }
`;
export const QuickSiteSetupDocument = gql`
  mutation quickSiteSetup($input: QuickSiteSetupInput!) {
    quickSiteSetup(input: $input) {
      id
    }
  }
`;
export const GetAllSitesAsAdminDocument = gql`
  query getAllSitesAsAdmin {
    allSites {
      edges {
        site: node {
          id
          name
          timeZone
          geoLocation {
            latitude
            longitude
          }
          enabledProducts
          siteControllers {
            id
          }
          organizations {
            id
          }
          subscriptionInfo {
            startsOn
            expiresOn
            subscriptionType
            partner
            salesPerson
            accessBlocked
          }
          lightMapLayerIconSize
          hasCellLine
        }
      }
    }
  }
`;
export const GetSiteDocument = gql`
  query getSite($id: ID!) {
    site(id: $id) {
      id
      name
      timeZone
      geoLocation {
        latitude
        longitude
      }
      enabledProducts
      siteControllers {
        id
      }
      organizations {
        id
      }
      subscriptionInfo {
        startsOn
        expiresOn
        subscriptionType
        partner
        salesPerson
        accessBlocked
      }
      lightMapLayerIconSize
      hasCellLine
      siteControllers {
        id
        name
        macAddress
        site {
          id
        }
        systemVersion
        activeLicenses
        resources {
          type
          status
          lastUpdateReceived
        }
        remoteAccess {
          uiProxyUrl
          secretKey
        }
      }
    }
  }
`;
export const CreateSiteAsAdminDocument = gql`
  mutation createSiteAsAdmin($input: CreateSiteInput!) {
    createSite(input: $input) {
      id
    }
  }
`;
export const UpdateSiteAsAdminDocument = gql`
  mutation updateSiteAsAdmin($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      id
    }
  }
`;
export const DeleteSiteAsAdminDocument = gql`
  mutation deleteSiteAsAdmin($input: DeleteSiteInput!) {
    deleteSite(input: $input)
  }
`;
export const GetEditSiteModelsDocument = gql`
  query getEditSiteModels($siteId: ID!) {
    site(id: $siteId) {
      controlZones {
        id
        name
        application
        behavior {
          node {
            id
          }
          parameters
        }
        thermostats {
          id
        }
        lights {
          id
        }
      }
      scenes {
        id
        name
        application
        controlZones {
          edges {
            node {
              id
            }
            behavior {
              id
            }
            parameters
          }
        }
      }
    }
  }
`;
export const GetSiteUsersDocument = gql`
  query getSiteUsers($id: ID!) {
    site(id: $id) {
      id
      name
      users {
        edges {
          user: node {
            id
            name
            email
          }
        }
      }
    }
  }
`;
export const GetSiteReportDocument = gql`
  query getSiteReport {
    allEnterpriseSubscriptions {
      name
      siteSubType
      startsOn
      expiresOn
    }
    allSites {
      edges {
        site: node {
          id
          name
          timeZone
          enabledProducts
          siteControllers {
            id
            name
            activeLicenses
          }
          organizations {
            id
            name
          }
          hasCellLine
          subscriptionInfo {
            startsOn
            expiresOn
            subscriptionType
            partner
            salesPerson
            accessBlocked
          }
          users {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const GetAllSiteControllersAsAdminDocument = gql`
  query getAllSiteControllersAsAdmin {
    allSiteControllers {
      edges {
        siteController: node {
          id
          name
          macAddress
          site {
            id
          }
          systemVersion
          activeLicenses
          resources {
            type
            status
            lastUpdateReceived
          }
          remoteAccess {
            uiProxyUrl
            secretKey
          }
          tunnelPort
        }
      }
    }
  }
`;
export const GetAllSiteControllersAndSitesAsAdminDocument = gql`
  query getAllSiteControllersAndSitesAsAdmin {
    allSiteControllers {
      edges {
        siteController: node {
          id
          name
          macAddress
          site {
            id
          }
          systemVersion
          activeLicenses
          resources {
            type
            status
            lastUpdateReceived
          }
          remoteAccess {
            uiProxyUrl
            secretKey
          }
          tunnelPort
        }
      }
    }
    allSites {
      edges {
        site: node {
          id
          name
          timeZone
          geoLocation {
            latitude
            longitude
          }
          enabledProducts
          siteControllers {
            id
          }
          organizations {
            id
          }
          subscriptionInfo {
            startsOn
            expiresOn
            subscriptionType
            partner
            salesPerson
            accessBlocked
          }
          lightMapLayerIconSize
          hasCellLine
        }
      }
    }
  }
`;
export const CreateSiteControllerAsAdminDocument = gql`
  mutation createSiteControllerAsAdmin($input: CreateSiteControllerInput!) {
    createSiteController(input: $input) {
      id
    }
  }
`;
export const UpdateSiteControllerAsAdminDocument = gql`
  mutation updateSiteControllerAsAdmin($input: UpdateSiteControllerInput!) {
    updateSiteController(input: $input) {
      id
    }
  }
`;
export const DeleteSiteControllerAsAdminDocument = gql`
  mutation deleteSiteControllerAsAdmin($input: DeleteSiteControllerInput!) {
    deleteSiteController(input: $input)
  }
`;
export const InitializeSiteControllerResourcesAsAdminDocument = gql`
  mutation initializeSiteControllerResourcesAsAdmin(
    $input: InitializeSiteControllerResourcesInput!
  ) {
    initializeSiteControllerResources(input: $input) {
      id
      name
      macAddress
      site {
        id
      }
      systemVersion
      activeLicenses
      resources {
        type
        status
        lastUpdateReceived
      }
      remoteAccess {
        uiProxyUrl
        secretKey
      }
    }
  }
`;
export const MoveSiteControllerAsAdminDocument = gql`
  mutation moveSiteControllerAsAdmin($input: MoveSiteControllerInput!) {
    moveSiteController(input: $input)
  }
`;
export const ReplaceSiteControllerAsAdminDocument = gql`
  mutation replaceSiteControllerAsAdmin($input: ReplaceSiteControllerInput!) {
    replaceSiteController(input: $input)
  }
`;
export const ImportSiteControllerEntitiesAsAdminDocument = gql`
  mutation importSiteControllerEntitiesAsAdmin(
    $input: ImportEntitiesFromSiteControllerInput!
  ) {
    importEntitiesFromSiteController(input: $input)
  }
`;
export const GetAlarmsDocument = gql`
  query getAlarms($siteId: ID!) {
    alarms(siteId: $siteId) {
      id
      triggerTime
      type
      controllerId
      snapaddr
      cleared
      description
      selfClearing
      retryable
      siteController {
        id
      }
    }
    site(id: $siteId) {
      siteControllers {
        id
        name
        macAddress
        site {
          id
        }
        systemVersion
        activeLicenses
        resources {
          type
          status
          lastUpdateReceived
        }
        remoteAccess {
          uiProxyUrl
          secretKey
        }
      }
    }
  }
`;
export const GetAllBridge485DetailsModelsDocument = gql`
  query getAllBridge485DetailsModels($siteId: ID!, $bridge485Id: ID!) {
    bridge485(id: $bridge485Id) {
      id
      name
      samplePeriod
      metrics {
        id
      }
      network {
        siteController {
          id
        }
        snapAddress
      }
      serialSettings {
        baudRate
        parity
        stopBits
      }
    }
    site(id: $siteId) {
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
    }
  }
`;
export const PingBridge485Document = gql`
  mutation pingBridge485($pingBridge485Input: PingSnapDeviceInput!) {
    pingSnapDevice(input: $pingBridge485Input)
  }
`;
export const DeleteBridge485Document = gql`
  mutation deleteBridge485($input: DeleteBridge485Input!) {
    deleteBridge485(input: $input)
  }
`;
export const GetAllBridge485ModelsDocument = gql`
  query getAllBridge485Models($siteId: ID!) {
    site(id: $siteId) {
      bridge485s {
        id
        name
        samplePeriod
        metrics {
          id
        }
        network {
          siteController {
            id
          }
          snapAddress
        }
        serialSettings {
          baudRate
          parity
          stopBits
        }
      }
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
      siteControllers {
        id
        name
        macAddress
        site {
          id
        }
        systemVersion
        activeLicenses
        resources {
          type
          status
          lastUpdateReceived
        }
        remoteAccess {
          uiProxyUrl
          secretKey
        }
      }
    }
    configurationTemplates(input: { type: BRIDGE485_TEMPLATE }) {
      id
      name
      template {
        ... on Bridge485MetricTemplate {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          name
          unitId
          dataType {
            ... on IndustrialSensorCustomDataType {
              name
              units
            }
            ... on IndustrialSensorDefinedDataType {
              type
            }
          }
        }
      }
    }
  }
`;
export const CreateBridge485Document = gql`
  mutation createBridge485($input: CreateBridge485Input!) {
    createBridge485(input: $input) {
      id
      name
      samplePeriod
      metrics {
        id
      }
      network {
        siteController {
          id
        }
        snapAddress
      }
      serialSettings {
        baudRate
        parity
        stopBits
      }
    }
  }
`;
export const UpdateBridge485Document = gql`
  mutation updateBridge485($input: UpdateBridge485Input!) {
    updateBridge485(input: $input) {
      id
      name
      samplePeriod
      metrics {
        id
      }
      network {
        siteController {
          id
        }
        snapAddress
      }
      serialSettings {
        baudRate
        parity
        stopBits
      }
    }
  }
`;
export const GetSourcesDocument = gql`
  query getSources($siteId: ID!) {
    site(id: $siteId) {
      sources {
        id
        name
        type
        readingUnits
        siteId
        complexValueDefinitions {
          complexValues {
            name
            units
          }
        }
      }
    }
  }
`;
export const GetSourceDataDocument = gql`
  query getSourceData(
    $sourceId: ID!
    $siteId: ID!
    $start: Date!
    $end: Date!
    $interval: Interval!
    $timezone: IANATimeZone!
  ) {
    querySource(
      sourceId: $sourceId
      siteId: $siteId
      start: $start
      end: $end
      interval: $interval
      timezone: $timezone
    ) {
      buckets {
        timestamp
        value
        complexValue
      }
    }
  }
`;
export const GetClimateMapLayerModelsDocument = gql`
  query getClimateMapLayerModels($siteId: ID!) {
    site(id: $siteId) {
      thermostats {
        id
        name
        controlMode {
          cool {
            F
          }
          heat {
            F
          }
          fanMode
        }
        fallbackControlMode {
          cool {
            F
          }
          heat {
            F
          }
          fanMode
        }
        snapaddr
        floorPlanLocation {
          floorPlan {
            id
          }
          x
          y
        }
        commissioningStatus
        state {
          temperature {
            F
          }
          humidity
          lastMessageReceived
        }
        make
        model
        siteController {
          id
          name
          macAddress
          site {
            id
          }
          systemVersion
          activeLicenses
          resources {
            type
            status
            lastUpdateReceived
          }
          remoteAccess {
            uiProxyUrl
            secretKey
          }
        }
        siteController {
          id
          name
          macAddress
          site {
            id
          }
          systemVersion
          activeLicenses
          resources {
            type
            status
            lastUpdateReceived
          }
          remoteAccess {
            uiProxyUrl
            secretKey
          }
        }
        alarms {
          id
          reason
          siteController {
            id
          }
          triggerAt
        }
      }
    }
  }
`;
export const GetControlZoneDocument = gql`
  query getControlZone($controlZoneId: ID!) {
    controlZone(id: $controlZoneId) {
      id
      name
      application
      behavior {
        node {
          id
        }
        parameters
      }
      thermostats {
        id
      }
      lights {
        id
      }
      thermostats {
        id
        name
        controlMode {
          cool {
            F
          }
          heat {
            F
          }
          fanMode
        }
        fallbackControlMode {
          cool {
            F
          }
          heat {
            F
          }
          fanMode
        }
        snapaddr
        floorPlanLocation {
          floorPlan {
            id
          }
          x
          y
        }
        commissioningStatus
        state {
          temperature {
            F
          }
          humidity
          lastMessageReceived
        }
        make
        model
        siteController {
          id
          name
          macAddress
          site {
            id
          }
          systemVersion
          activeLicenses
          resources {
            type
            status
            lastUpdateReceived
          }
          remoteAccess {
            uiProxyUrl
            secretKey
          }
        }
      }
    }
  }
`;
export const CreateClimateZoneDocument = gql`
  mutation createClimateZone($input: CreateControlZoneInput!) {
    createControlZone(input: $input) {
      id
    }
  }
`;
export const UpdateClimateZoneDocument = gql`
  mutation updateClimateZone($input: UpdateControlZoneInput!) {
    updateControlZone(input: $input) {
      id
    }
  }
`;
export const DeleteControlZoneDocument = gql`
  mutation deleteControlZone($input: DeleteControlZoneInput!) {
    deleteControlZone(input: $input)
  }
`;
export const ApplyBehaviorToControlZoneDocument = gql`
  mutation applyBehaviorToControlZone($input: ApplyBehaviorToControlZoneInput!) {
    applyBehaviorToControlZone(input: $input) {
      id
    }
  }
`;
export const GetSiteZonesDocument = gql`
  query getSiteZones($siteId: ID!, $filter: ControlZoneFilterInput!) {
    site(id: $siteId) {
      controlZones(filter: $filter) {
        id
        name
        application
        behavior {
          node {
            id
          }
          parameters
        }
        thermostats {
          id
        }
        lights {
          id
        }
      }
      thermostats {
        id
        alarms {
          id
          reason
          siteController {
            id
          }
          triggerAt
        }
      }
    }
  }
`;
export const AcceptEulaDocument = gql`
  mutation acceptEula($id: ID!) {
    userAcceptedEula(id: $id)
  }
`;
export const MyEulaDocument = gql`
  query myEula {
    me {
      eulaAcceptedAt
    }
  }
`;
export const CreateDepartmentDocument = gql`
  mutation createDepartment($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      id
    }
  }
`;
export const GetDepartmentResourceGroupsAndThingsDocument = gql`
  query getDepartmentResourceGroupsAndThings($siteId: ID!) {
    site(id: $siteId) {
      resourceGroups {
        __typename
        id
        name
        resourceGroups {
          id
        }
        ... on Asset {
          industrialSensors {
            id
          }
          type {
            ... on CustomAssetType {
              name
            }
            ... on DefinedAssetType {
              type
            }
          }
          make
          model
          imageUrl
          runningState {
            state
            timestamp
          }
          disposition {
            level
            timestamp
          }
          dispositionChanges {
            __typename
            timestamp
            message
            level
            ... on AssetTriggerDispositionChange {
              triggerId
            }
          }
        }
        ... on Department {
          controlZones {
            id
          }
          energyProfileEquipmentIds
        }
        ... on LegacyResourceGroup {
          industrialSensors {
            __typename
            id
          }
          zones {
            id
          }
        }
      }
      zones {
        id
        name
      }
    }
  }
`;
export const UpdateDepartmentDocument = gql`
  mutation updateDepartment($input: UpdateDepartmentInput!) {
    updateDepartment(input: $input) {
      id
    }
  }
`;
export const DeleteDepartmentDocument = gql`
  mutation deleteDepartment($input: DeleteDepartmentInput!) {
    deleteDepartment(input: $input)
  }
`;
export const GetEquipmentDocument = gql`
  query getEquipment($siteId: ID!) {
    site(id: $siteId) {
      assets {
        id
        name
        type {
          __typename
          ... on CustomAssetType {
            name
          }
          ... on DefinedAssetType {
            type
          }
        }
        make
        model
        imageUrl
        industrialSensors {
          id
        }
        resourceGroups {
          id
        }
        floorPlanLocation {
          floorPlan {
            id
          }
          x
          y
        }
        runningState {
          state
          timestamp
        }
        disposition {
          level
          timestamp
        }
        dispositionChanges {
          __typename
          timestamp
          message
          level
          ... on AssetTriggerDispositionChange {
            triggerId
          }
        }
      }
    }
  }
`;
export const GetEquipmentMapLayerDeferredModelsDocument = gql`
  query getEquipmentMapLayerDeferredModels($siteId: ID!) {
    site(id: $siteId) {
      resourceGroups {
        __typename
        id
        name
        resourceGroups {
          id
        }
        ... on Asset {
          industrialSensors {
            id
          }
          type {
            ... on CustomAssetType {
              name
            }
            ... on DefinedAssetType {
              type
            }
          }
          make
          model
          imageUrl
          runningState {
            state
            timestamp
          }
          disposition {
            level
            timestamp
          }
          dispositionChanges {
            __typename
            timestamp
            message
            level
            ... on AssetTriggerDispositionChange {
              triggerId
            }
          }
        }
        ... on Department {
          controlZones {
            id
          }
          energyProfileEquipmentIds
        }
        ... on LegacyResourceGroup {
          industrialSensors {
            __typename
            id
          }
          zones {
            id
          }
        }
      }
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
      triggers {
        id
        name
        industrialSensor {
          id
        }
        state {
          lastStatusUpdate
          status
        }
        condition {
          lt
          gt
        }
        transitionConfig {
          activeTransitionDelayInMinutes
          inactiveTransitionDelayInMinutes
          unknownTransitionDelayInMinutes
        }
        notifications {
          __typename
          ... on WebhookTriggerNotification {
            url
          }
          ... on RunningStateTriggerNotification {
            state
          }
          ... on DispositionTriggerNotification {
            message
            level
          }
        }
      }
    }
  }
`;
export const GetEquipmentProfilePageModelsDocument = gql`
  query getEquipmentProfilePageModels($siteId: ID!) {
    site(id: $siteId) {
      resourceGroups {
        __typename
        id
        name
        resourceGroups {
          id
        }
        ... on Asset {
          industrialSensors {
            id
          }
          type {
            ... on CustomAssetType {
              name
            }
            ... on DefinedAssetType {
              type
            }
          }
          make
          model
          imageUrl
          runningState {
            state
            timestamp
          }
          disposition {
            level
            timestamp
          }
          dispositionChanges {
            __typename
            timestamp
            message
            level
            ... on AssetTriggerDispositionChange {
              triggerId
            }
          }
        }
        ... on Department {
          controlZones {
            id
          }
          energyProfileEquipmentIds
        }
        ... on LegacyResourceGroup {
          industrialSensors {
            __typename
            id
          }
          zones {
            id
          }
        }
      }
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
      triggers {
        id
        name
        industrialSensor {
          id
        }
        state {
          lastStatusUpdate
          status
        }
        condition {
          lt
          gt
        }
        transitionConfig {
          activeTransitionDelayInMinutes
          inactiveTransitionDelayInMinutes
          unknownTransitionDelayInMinutes
        }
        notifications {
          __typename
          ... on WebhookTriggerNotification {
            url
          }
          ... on RunningStateTriggerNotification {
            state
          }
          ... on DispositionTriggerNotification {
            message
            level
          }
        }
      }
    }
  }
`;
export const GetSiteResourceGroupsAndIndustrialSensorsDocument = gql`
  query getSiteResourceGroupsAndIndustrialSensors($siteId: ID!) {
    site(id: $siteId) {
      resourceGroups {
        __typename
        id
        name
        resourceGroups {
          id
        }
        ... on Asset {
          industrialSensors {
            id
          }
          type {
            ... on CustomAssetType {
              name
            }
            ... on DefinedAssetType {
              type
            }
          }
          make
          model
          imageUrl
          runningState {
            state
            timestamp
          }
          disposition {
            level
            timestamp
          }
          dispositionChanges {
            __typename
            timestamp
            message
            level
            ... on AssetTriggerDispositionChange {
              triggerId
            }
          }
        }
        ... on Department {
          controlZones {
            id
          }
          energyProfileEquipmentIds
        }
        ... on LegacyResourceGroup {
          industrialSensors {
            __typename
            id
          }
          zones {
            id
          }
        }
      }
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
    }
  }
`;
export const GetEquipmentPageDeferredModelsDocument = gql`
  query getEquipmentPageDeferredModels($siteId: ID!) {
    site(id: $siteId) {
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
      triggers {
        id
        name
        industrialSensor {
          id
        }
        state {
          lastStatusUpdate
          status
        }
        condition {
          lt
          gt
        }
        transitionConfig {
          activeTransitionDelayInMinutes
          inactiveTransitionDelayInMinutes
          unknownTransitionDelayInMinutes
        }
        notifications {
          __typename
          ... on WebhookTriggerNotification {
            url
          }
          ... on RunningStateTriggerNotification {
            state
          }
          ... on DispositionTriggerNotification {
            message
            level
          }
        }
      }
    }
  }
`;
export const CreateEquipmentDocument = gql`
  mutation createEquipment($input: CreateAssetInput!) {
    createAsset(input: $input) {
      id
    }
  }
`;
export const DeleteEquipmentDocument = gql`
  mutation deleteEquipment($input: DeleteAssetInput!) {
    deleteAsset(input: $input)
  }
`;
export const UpdateEquipmentDocument = gql`
  mutation updateEquipment($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      id
    }
  }
`;
export const GetResourceGroupsDocument = gql`
  query getResourceGroups($siteId: ID!) {
    site(id: $siteId) {
      resourceGroups {
        __typename
        id
        name
        resourceGroups {
          id
        }
        ... on Asset {
          industrialSensors {
            id
          }
          type {
            ... on CustomAssetType {
              name
            }
            ... on DefinedAssetType {
              type
            }
          }
          make
          model
          imageUrl
          runningState {
            state
            timestamp
          }
          disposition {
            level
            timestamp
          }
          dispositionChanges {
            __typename
            timestamp
            message
            level
            ... on AssetTriggerDispositionChange {
              triggerId
            }
          }
        }
        ... on Department {
          controlZones {
            id
          }
          energyProfileEquipmentIds
        }
        ... on LegacyResourceGroup {
          industrialSensors {
            __typename
            id
          }
          zones {
            id
          }
        }
      }
    }
  }
`;
export const CreateResourceGroupDocument = gql`
  mutation createResourceGroup($input: CreateResourceGroupInput!) {
    createResourceGroup(input: $input) {
      id
    }
  }
`;
export const UpdateResourceGroupDocument = gql`
  mutation updateResourceGroup($input: UpdateResourceGroupInput!) {
    updateResourceGroup(input: $input) {
      id
    }
  }
`;
export const DeleteResourceGroupDocument = gql`
  mutation deleteResourceGroup($input: DeleteResourceGroupInput!) {
    deleteResourceGroup(input: $input)
  }
`;
export const GetResourceGroupsAndThingsDocument = gql`
  query getResourceGroupsAndThings($siteId: ID!) {
    site(id: $siteId) {
      resourceGroups {
        __typename
        id
        name
        resourceGroups {
          id
        }
        ... on Asset {
          industrialSensors {
            id
          }
          type {
            ... on CustomAssetType {
              name
            }
            ... on DefinedAssetType {
              type
            }
          }
          make
          model
          imageUrl
          runningState {
            state
            timestamp
          }
          disposition {
            level
            timestamp
          }
          dispositionChanges {
            __typename
            timestamp
            message
            level
            ... on AssetTriggerDispositionChange {
              triggerId
            }
          }
        }
        ... on Department {
          controlZones {
            id
          }
          energyProfileEquipmentIds
        }
        ... on LegacyResourceGroup {
          industrialSensors {
            __typename
            id
          }
          zones {
            id
          }
        }
      }
      zones {
        id
        name
      }
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
    }
  }
`;
export const CreateFloorPlanDocument = gql`
  mutation createFloorPlan($siteId: ID!, $name: String!, $image: String!) {
    createFloorPlan(siteId: $siteId, name: $name, image: $image) {
      id
      name
      imageUrl
    }
  }
`;
export const GetAllFloorPlansForSiteDocument = gql`
  query getAllFloorPlansForSite($siteId: ID!) {
    site(id: $siteId) {
      floorPlans {
        id
        name
        imageUrl
      }
    }
  }
`;
export const DeleteFloorPlanDocument = gql`
  mutation deleteFloorPlan($id: ID!) {
    deleteFloorPlan(id: $id)
  }
`;
export const UpdateFloorPlanImageDocument = gql`
  mutation updateFloorPlanImage($id: ID!, $image: String!) {
    updateFloorPlanImage(id: $id, image: $image) {
      id
      name
      imageUrl
    }
  }
`;
export const ImportDeviceAssetDataDocument = gql`
  mutation importDeviceAssetData($input: ImportDeviceAssetDataInput!) {
    importDeviceAssetData(input: $input)
  }
`;
export const GetIndieSensorDetailsModelsDocument = gql`
  query getIndieSensorDetailsModels($sensorId: ID!, $siteId: ID!) {
    industrialSensor(id: $sensorId) {
      __typename
      id
      name
      dataType {
        __typename
        ... on IndustrialSensorDefinedDataType {
          type
        }
        ... on IndustrialSensorCustomDataType {
          name
          units
        }
      }
      units
      state {
        __typename
        lastMessageReceived
        ... on Sense420SensorState {
          lastValue
          siteControllerId
          siteController {
            id
          }
          batteryLevel
          upgradeStatus
        }
        ... on VirtualIndustrialSensorState {
          lastValue
        }
        ... on Bridge485MetricState {
          lastValue
        }
      }
      ... on Sense420Sensor {
        snapaddr
        floorPlanX
        floorPlanY
        floorPlanId
        minValue
        maxValue
      }
      ... on Bridge485Metric {
        conversion {
          polynomial
        }
        dataAddress
        dataFormat
        functionCode
        unitId
        source {
          id
        }
      }
    }
    site(id: $siteId) {
      siteControllers {
        id
        name
        resources {
          type
          status
          lastUpdateReceived
        }
      }
      bridge485s {
        id
        name
        samplePeriod
        metrics {
          id
        }
        network {
          siteController {
            id
          }
          snapAddress
        }
        serialSettings {
          baudRate
          parity
          stopBits
        }
      }
    }
  }
`;
export const DeleteIndustrialSensorDocument = gql`
  mutation deleteIndustrialSensor($id: ID!) {
    deleteIndustrialSensor(id: $id)
  }
`;
export const GetAllIndieSensorsDocument = gql`
  query getAllIndieSensors($siteId: ID!) {
    site(id: $siteId) {
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
      utilityServices {
        id
        name
        mains {
          __typename
          id
        }
        industrialSensors {
          __typename
          id
        }
        lights {
          id
        }
        scheduledUtilityRates {
          id
          startDate
          billingFrequency
          utilityRate {
            ... on OpenEIUtilityRate {
              openEIUtilityRateId
              rateName
              companyName
            }
            ... on FlatEnergyUtilityRate {
              costOfDemand
              costOfEnergy
            }
          }
        }
      }
    }
  }
`;
export const GetAllIndieSensorsAndControllersDocument = gql`
  query getAllIndieSensorsAndControllers($siteId: ID!) {
    site(id: $siteId) {
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
      controllers {
        id
        name
        snapaddr
        type
      }
      siteControllers {
        id
        name
        macAddress
        site {
          id
        }
        systemVersion
        activeLicenses
        resources {
          type
          status
          lastUpdateReceived
        }
        remoteAccess {
          uiProxyUrl
          secretKey
        }
      }
    }
  }
`;
export const CreateSense420SensorDocument = gql`
  mutation createSense420Sensor($input: CreateSense420SensorInput!) {
    createSense420Sensor(input: $input) {
      id
      dataType {
        ... on IndustrialSensorDefinedDataType {
          type
        }
        ... on IndustrialSensorCustomDataType {
          name
          units
        }
      }
      maxValue
      minValue
      name
      snapaddr
    }
  }
`;
export const UpdateSense420SensorDocument = gql`
  mutation updateSense420Sensor($input: UpdateSense420SensorInput!) {
    updateSense420Sensor(input: $input) {
      dataType {
        ... on IndustrialSensorDefinedDataType {
          type
        }
        ... on IndustrialSensorCustomDataType {
          name
          units
        }
      }
      maxValue
      minValue
      name
      snapaddr
    }
  }
`;
export const DimLightDocument = gql`
  mutation dimLight($id: ID!, $level: Int!) {
    updateLightLevel(id: $id, level: $level) {
      id
    }
  }
`;
export const GetLightLevelDocument = gql`
  query getLightLevel($id: ID!) {
    lightLevel(id: $id) {
      level
    }
  }
`;
export const ReplaceLightDocument = gql`
  mutation replaceLight($input: NodeSwapInput!) {
    nodeSwap(input: $input) {
      status
      message
    }
  }
`;
export const CheckSignalStrengthDocument = gql`
  mutation checkSignalStrength($addresses: [String]) {
    census(controllerAddresses: $addresses) {
      controllerAddresses
    }
  }
`;
export const PingLightDocument = gql`
  mutation pingLight($input: PingSnapDeviceInput!) {
    pingSnapDevice(input: $input)
  }
`;
export const GetLightMapLayerModelsDocument = gql`
  query getLightMapLayerModels($siteId: ID!) {
    site(id: $siteId) {
      lights {
        id
        name
        level
        floorPlanLocation {
          floorPlan {
            id
          }
          x
          y
        }
        controller {
          id
          snapaddr
          type
          fixtureAssetData
        }
        siteController {
          id
          name
        }
        siteController {
          id
          name
        }
      }
    }
  }
`;
export const GetLightMapLayerDeferredModelsDocument = gql`
  query getLightMapLayerDeferredModels($siteId: ID!) {
    site(id: $siteId) {
      lightZones {
        id
        lightId
        zoneId
      }
      controlZones {
        id
        name
        application
        behavior {
          node {
            id
          }
          parameters
        }
        thermostats {
          id
        }
        lights {
          id
        }
      }
      behaviors {
        id
        displayGroup
        displayOrder
        application
        isLicensed
      }
      scenes {
        id
        name
        application
        controlZones {
          edges {
            node {
              id
            }
            behavior {
              id
            }
            parameters
          }
        }
      }
      sceneZoneBehaviors {
        id
        sceneId
        behaviorId
        behaviorParameters
        zoneId
      }
      controllers {
        id
        name
        snapaddr
        type
      }
    }
  }
`;
export const GetLightZoneDetailsModelsDocument = gql`
  query getLightZoneDetailsModels($siteId: ID!, $controlZoneId: ID!) {
    controlZone(id: $controlZoneId) {
      id
      name
      application
      behavior {
        node {
          id
        }
        parameters
      }
      thermostats {
        id
      }
      lights {
        id
      }
      lights {
        id
        name
        level
        floorPlanLocation {
          floorPlan {
            id
          }
          x
          y
        }
        controller {
          id
          snapaddr
          type
          fixtureAssetData
        }
        siteController {
          id
          name
        }
        siteController {
          id
          name
        }
      }
    }
    site(id: $siteId) {
      lightZones {
        id
        lightId
        zoneId
      }
      scenes {
        id
        name
        application
        controlZones {
          edges {
            node {
              id
            }
            behavior {
              id
            }
            parameters
          }
        }
      }
      behaviors {
        id
        displayGroup
        displayOrder
        application
        isLicensed
      }
    }
  }
`;
export const GetSiteControlZonesDocument = gql`
  query getSiteControlZones($siteId: ID!) {
    site(id: $siteId) {
      controlZones {
        id
        name
        application
        behavior {
          node {
            id
          }
          parameters
        }
        thermostats {
          id
        }
        lights {
          id
        }
      }
      behaviors {
        id
        displayGroup
        displayOrder
        application
        isLicensed
      }
    }
  }
`;
export const PlaceThingsOnFloorPlanDocument = gql`
  mutation placeThingsOnFloorPlan($input: PlaceThingsOnFloorPlanInput!) {
    placeThingsOnFloorPlan(input: $input) {
      ... on Light {
        id
      }
      ... on Thermostat {
        id
      }
      ... on IndustrialSensor {
        id
      }
      floorPlanLocation {
        floorPlan {
          id
        }
        x
        y
      }
    }
  }
`;
export const EssentialModelsDocument = gql`
  query essentialModels($siteId: ID!) {
    site(id: $siteId) {
      floorPlans {
        id
        name
        imageUrl
      }
      lightMapLayerIconSize
    }
    alarms(siteId: $siteId) {
      id
      triggerTime
      type
      controllerId
      snapaddr
      cleared
      description
      selfClearing
      retryable
      siteController {
        id
      }
    }
  }
`;
export const CreateOrganizationDocument = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
    }
  }
`;
export const DeleteOrganizationDocument = gql`
  mutation deleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input)
  }
`;
export const UpdateOrganizationDocument = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
    }
  }
`;
export const GetAllOrganizationsAsAdminDocument = gql`
  query getAllOrganizationsAsAdmin {
    allOrganizations {
      id
      name
      defaultSiteRole
      sites {
        edges {
          node {
            id
          }
        }
      }
      users {
        edges {
          node {
            id
          }
        }
      }
      capabilities
      temporary
    }
  }
`;
export const GetAllOrganizationsWithSitesAsAdminDocument = gql`
  query getAllOrganizationsWithSitesAsAdmin {
    allOrganizations {
      id
      name
      defaultSiteRole
      sites {
        edges {
          node {
            id
            name
            timeZone
            geoLocation {
              latitude
              longitude
            }
            enabledProducts
            siteControllers {
              id
            }
            organizations {
              id
            }
            subscriptionInfo {
              startsOn
              expiresOn
              subscriptionType
              partner
              salesPerson
              accessBlocked
            }
            lightMapLayerIconSize
            hasCellLine
          }
        }
      }
      users {
        edges {
          node {
            id
          }
        }
      }
      capabilities
      temporary
    }
  }
`;
export const UpdateEnergyBaselineDocument = gql`
  mutation updateEnergyBaseline(
    $siteId: ID!
    $level: Float
    $baselineType: BaselineType
  ) {
    updateEnergyBaseline(siteId: $siteId, level: $level, baselineType: $baselineType)
  }
`;
export const SetBaselineSkippedDocument = gql`
  mutation setBaselineSkipped($siteId: ID!, $skipBaseline: Boolean!) {
    setBaselineSkipped(siteId: $siteId, skipBaseline: $skipBaseline)
  }
`;
export const GetEnergyBaselineDocument = gql`
  query getEnergyBaseline($siteId: ID!) {
    site(id: $siteId) {
      energyMonitoring {
        baselineSkipped
        energyBaseline
        baselineType
      }
    }
  }
`;
export const GetPowerDataDocument = gql`
  query getPowerData(
    $siteId: ID!
    $zoneIds: [ID]
    $thingIds: [ID]
    $dataType: QueryDataType!
    $from: Date!
    $to: Date!
    $interval: Interval!
    $timezone: String!
  ) {
    dataForZones(
      siteId: $siteId
      zoneIds: $zoneIds
      dataType: $dataType
      from: $from
      to: $to
      interval: $interval
      timezone: $timezone
    ) {
      id
      values {
        timestamp
        value
      }
    }
    dataForThings(
      siteId: $siteId
      thingIds: $thingIds
      dataType: $dataType
      from: $from
      to: $to
      interval: $interval
      timezone: $timezone
    ) {
      id
      values {
        timestamp
        value
      }
    }
  }
`;
export const GetAllZonesDocument = gql`
  query getAllZones($siteId: ID!) {
    site(id: $siteId) {
      controlZones {
        id
        name
        application
        behavior {
          node {
            id
          }
          parameters
        }
        thermostats {
          id
        }
        lights {
          id
        }
      }
    }
  }
`;
export const CreateSceneViewDocument = gql`
  mutation createSceneView($input: CreateSceneViewInput!) {
    createSceneView(input: $input) {
      id
    }
  }
`;
export const DeleteSceneViewDocument = gql`
  mutation deleteSceneView($input: DeleteSceneViewInput!) {
    deleteSceneView(input: $input)
  }
`;
export const UpdateSceneViewDocument = gql`
  mutation updateSceneView($input: UpdateSceneViewInput!) {
    updateSceneView(input: $input) {
      id
    }
  }
`;
export const GetSceneViewsDocument = gql`
  query getSceneViews($siteId: ID!) {
    site(id: $siteId) {
      sceneViews {
        edges {
          node {
            id
            name
            sceneGroups {
              edges {
                node {
                  name
                  scenes {
                    edges {
                      node {
                        id
                      }
                      shortName
                      displayOrder
                    }
                  }
                }
                displayOrder
              }
            }
          }
        }
      }
    }
  }
`;
export const GetScenesDocument = gql`
  query getScenes($siteId: ID!) {
    site(id: $siteId) {
      scenes {
        id
        name
        application
        controlZones {
          edges {
            node {
              id
            }
            behavior {
              id
            }
            parameters
          }
        }
      }
      sceneSequences {
        id
        name
        steps {
          __typename
          stepNumber
          ... on ApplySceneStep {
            scene {
              id
            }
          }
          ... on WaitStep {
            duration
          }
        }
        loop
      }
      behaviors {
        id
        displayGroup
        displayOrder
        application
        isLicensed
      }
      sceneZoneBehaviors {
        id
        sceneId
        behaviorId
        behaviorParameters
        zoneId
      }
      controlZones {
        id
        name
        application
        behavior {
          node {
            id
          }
          parameters
        }
        thermostats {
          id
        }
        lights {
          id
        }
      }
      activeSceneSequences {
        sequenceSceneId
        currentStep
        currentStepCompletionTime
      }
    }
  }
`;
export const DeleteScene_V2Document = gql`
  mutation deleteScene_v2($deleteSceneInput: DeleteSceneInput!) {
    deleteScene_version_2(input: $deleteSceneInput)
  }
`;
export const ApplyScene_V2Document = gql`
  mutation applyScene_v2($applySceneInput: ApplySceneInput!) {
    applyScene_version_2(input: $applySceneInput) {
      id
    }
  }
`;
export const CreateSceneDocument = gql`
  mutation createScene($createSceneInput: CreateSceneInput!) {
    createScene(input: $createSceneInput) {
      id
      name
      application
      controlZones {
        edges {
          node {
            id
          }
          behavior {
            id
          }
          parameters
        }
      }
    }
  }
`;
export const UpdateScene_V2Document = gql`
  mutation updateScene_v2($updateSceneInput: UpdateSceneInput!) {
    updateScene_version_2(input: $updateSceneInput) {
      id
      name
      application
      controlZones {
        edges {
          node {
            id
          }
          behavior {
            id
          }
          parameters
        }
      }
    }
  }
`;
export const DeleteSequenceSceneDocument = gql`
  mutation deleteSequenceScene($deleteSceneSequenceInput: DeleteSceneSequenceInput!) {
    deleteSceneSequence(input: $deleteSceneSequenceInput)
  }
`;
export const CreateSequenceSceneDocument = gql`
  mutation createSequenceScene($createSceneSequenceInput: CreateSceneSequenceInput!) {
    createSceneSequence(input: $createSceneSequenceInput) {
      id
      name
      steps {
        __typename
        stepNumber
        ... on ApplySceneStep {
          scene {
            id
          }
        }
        ... on WaitStep {
          duration
        }
      }
      loop
    }
  }
`;
export const UpdateSequenceSceneDocument = gql`
  mutation updateSequenceScene($updateSceneSequenceInput: UpdateSceneSequenceInput!) {
    updateSceneSequence(input: $updateSceneSequenceInput) {
      id
      name
      steps {
        __typename
        stepNumber
        ... on ApplySceneStep {
          scene {
            id
          }
        }
        ... on WaitStep {
          duration
        }
      }
      loop
    }
  }
`;
export const GetAllScheduledEventsDocument = gql`
  query getAllScheduledEvents($siteId: ID!) {
    site(id: $siteId) {
      scheduledEvents {
        id
        name
        start {
          ... on AstroEventStartTime {
            startsAt
            event
            offset
          }
          ... on FixedStartTime {
            startsAt
          }
        }
        recurrence {
          rrule
        }
        schedulables {
          __typename
          id
        }
      }
      scenes {
        id
        name
        application
        controlZones {
          edges {
            node {
              id
            }
            behavior {
              id
            }
            parameters
          }
        }
      }
      sceneSequences {
        id
        name
        steps {
          __typename
          stepNumber
          ... on ApplySceneStep {
            scene {
              id
            }
          }
          ... on WaitStep {
            duration
          }
        }
        loop
      }
      sceneZoneBehaviors {
        id
        sceneId
        behaviorId
        behaviorParameters
        zoneId
      }
      controlZones {
        id
        name
        application
        behavior {
          node {
            id
          }
          parameters
        }
        thermostats {
          id
        }
        lights {
          id
        }
      }
    }
  }
`;
export const CreateScheduledEventDocument = gql`
  mutation createScheduledEvent($input: CreateScheduledEventInput!) {
    createScheduledEvent(input: $input) {
      id
      name
      start {
        ... on AstroEventStartTime {
          startsAt
          event
          offset
        }
        ... on FixedStartTime {
          startsAt
        }
      }
      recurrence {
        rrule
      }
      schedulables {
        __typename
        id
      }
    }
  }
`;
export const UpdateScheduledEventDocument = gql`
  mutation updateScheduledEvent($input: UpdateScheduledEventInput!) {
    updateScheduledEvent(input: $input) {
      id
      name
      start {
        ... on AstroEventStartTime {
          startsAt
          event
          offset
        }
        ... on FixedStartTime {
          startsAt
        }
      }
      recurrence {
        rrule
      }
      schedulables {
        __typename
        id
      }
    }
  }
`;
export const DeleteScheduledEventDocument = gql`
  mutation deleteScheduledEvent($input: DeleteScheduledEventInput!) {
    deleteScheduledEvent(input: $input)
  }
`;
export const GetSenseMapLayerModelsDocument = gql`
  query getSenseMapLayerModels($siteId: ID!) {
    site(id: $siteId) {
      siteControllers {
        id
        name
        resources {
          type
          status
          lastUpdateReceived
        }
      }
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
    }
  }
`;
export const GetSiteControllerDetailsDocument = gql`
  query getSiteControllerDetails($siteControllerId: ID!) {
    siteController(id: $siteControllerId) {
      id
      name
      macAddress
      site {
        id
      }
      systemVersion
      activeLicenses
      resources {
        type
        status
        lastUpdateReceived
      }
      remoteAccess {
        uiProxyUrl
        secretKey
      }
      site {
        id
        name
        timeZone
        geoLocation {
          latitude
          longitude
        }
        enabledProducts
        siteControllers {
          id
        }
        organizations {
          id
        }
        subscriptionInfo {
          startsOn
          expiresOn
          subscriptionType
          partner
          salesPerson
          accessBlocked
        }
        lightMapLayerIconSize
        hasCellLine
      }
    }
  }
`;
export const DeleteSiteControllerFromDetailsDocument = gql`
  mutation deleteSiteControllerFromDetails($input: DeleteSiteControllerInput!) {
    deleteSiteController(input: $input)
  }
`;
export const InitializeSiteControllerResourcesFromDetailsDocument = gql`
  mutation initializeSiteControllerResourcesFromDetails(
    $input: InitializeSiteControllerResourcesInput!
  ) {
    initializeSiteControllerResources(input: $input) {
      id
      name
      macAddress
      site {
        id
      }
      systemVersion
      activeLicenses
      resources {
        type
        status
        lastUpdateReceived
      }
      remoteAccess {
        uiProxyUrl
        secretKey
      }
    }
  }
`;
export const SiteControllersForSiteDocument = gql`
  query siteControllersForSite($siteId: ID!) {
    site(id: $siteId) {
      siteControllers {
        id
        name
        macAddress
        site {
          id
        }
        systemVersion
        activeLicenses
        resources {
          type
          status
          lastUpdateReceived
        }
        remoteAccess {
          uiProxyUrl
          secretKey
        }
      }
    }
  }
`;
export const GetSiteDetailsDocument = gql`
  query getSiteDetails($siteId: ID!) {
    site(id: $siteId) {
      id
      name
      timeZone
      geoLocation {
        latitude
        longitude
      }
      enabledProducts
      siteControllers {
        id
      }
      organizations {
        id
      }
      subscriptionInfo {
        startsOn
        expiresOn
        subscriptionType
        partner
        salesPerson
        accessBlocked
      }
      lightMapLayerIconSize
      hasCellLine
      siteControllers {
        id
        name
        macAddress
        site {
          id
        }
        systemVersion
        activeLicenses
        resources {
          type
          status
          lastUpdateReceived
        }
        remoteAccess {
          uiProxyUrl
          secretKey
        }
      }
    }
  }
`;
export const DeleteSiteFromDetailsDocument = gql`
  mutation deleteSiteFromDetails($input: DeleteSiteInput!) {
    deleteSite(input: $input)
  }
`;
export const MySitesAndOrganizationsDocument = gql`
  query mySitesAndOrganizations($filter: UserOrganizationFilterInput!) {
    mySites {
      id
      name
      timeZone
      geoLocation {
        latitude
        longitude
      }
      enabledProducts
      mySiteRole
      subscriptionInfo {
        startsOn
        expiresOn
        subscriptionType
        partner
        salesPerson
        accessBlocked
      }
      lightMapLayerIconSize
      hasCellLine
      siteControllers {
        id
        name
        macAddress
        site {
          id
        }
        systemVersion
        activeLicenses
        resources {
          type
          status
          lastUpdateReceived
        }
        remoteAccess {
          uiProxyUrl
          secretKey
        }
      }
    }
    myOrganizations(filter: $filter) {
      edges {
        node {
          id
          name
          defaultSiteRole
          temporary
          capabilities
        }
        organizationRole
      }
    }
  }
`;
export const GetThermostatDocument = gql`
  query getThermostat($thermostatId: ID!) {
    thermostat(id: $thermostatId) {
      id
      name
      controlMode {
        cool {
          F
        }
        heat {
          F
        }
        fanMode
      }
      fallbackControlMode {
        cool {
          F
        }
        heat {
          F
        }
        fanMode
      }
      snapaddr
      floorPlanLocation {
        floorPlan {
          id
        }
        x
        y
      }
      commissioningStatus
      state {
        temperature {
          F
        }
        humidity
        lastMessageReceived
      }
      make
      model
      siteController {
        id
        name
        macAddress
        site {
          id
        }
        systemVersion
        activeLicenses
        resources {
          type
          status
          lastUpdateReceived
        }
        remoteAccess {
          uiProxyUrl
          secretKey
        }
      }
    }
  }
`;
export const GetThermostatDetailsDocument = gql`
  query getThermostatDetails(
    $siteId: ID!
    $zoneFilter: ControlZoneFilterInput!
    $sceneFilter: SceneFilterInput!
  ) {
    site(id: $siteId) {
      controlZones(filter: $zoneFilter) {
        id
        name
        application
        behavior {
          node {
            id
          }
          parameters
        }
        thermostats {
          id
        }
        lights {
          id
        }
      }
      scenes(filter: $sceneFilter) {
        id
        name
        application
        controlZones {
          edges {
            node {
              id
            }
            behavior {
              id
            }
            parameters
          }
        }
      }
      sceneZoneBehaviors {
        id
        sceneId
        behaviorId
        behaviorParameters
        zoneId
      }
    }
  }
`;
export const ControlThermostatDocument = gql`
  mutation controlThermostat($input: UpdateThermostatControlModeInput!) {
    updateThermostatControlMode(input: $input) {
      id
    }
  }
`;
export const DeleteThermostatDocument = gql`
  mutation deleteThermostat($input: DeleteThermostatInput!) {
    deleteThermostat(input: $input)
  }
`;
export const PingThermostatDocument = gql`
  mutation pingThermostat($input: PingSnapDeviceInput!) {
    pingSnapDevice(input: $input)
  }
`;
export const CheckThermostatSignalStrengthDocument = gql`
  mutation checkThermostatSignalStrength($snapaddr: String) {
    census(controllerAddresses: [$snapaddr]) {
      controllerAddresses
    }
  }
`;
export const GetThermostatsDocument = gql`
  query getThermostats($siteId: ID!) {
    site(id: $siteId) {
      thermostats {
        id
        name
        controlMode {
          cool {
            F
          }
          heat {
            F
          }
          fanMode
        }
        fallbackControlMode {
          cool {
            F
          }
          heat {
            F
          }
          fanMode
        }
        snapaddr
        floorPlanLocation {
          floorPlan {
            id
          }
          x
          y
        }
        commissioningStatus
        state {
          temperature {
            F
          }
          humidity
          lastMessageReceived
        }
        make
        model
        siteController {
          id
          name
          macAddress
          site {
            id
          }
          systemVersion
          activeLicenses
          resources {
            type
            status
            lastUpdateReceived
          }
          remoteAccess {
            uiProxyUrl
            secretKey
          }
        }
        alarms {
          id
          reason
          siteController {
            id
          }
          triggerAt
        }
      }
    }
  }
`;
export const GetThermostatsSiteControllersDocument = gql`
  query getThermostatsSiteControllers($siteId: ID!) {
    site(id: $siteId) {
      thermostats {
        id
        name
        controlMode {
          cool {
            F
          }
          heat {
            F
          }
          fanMode
        }
        fallbackControlMode {
          cool {
            F
          }
          heat {
            F
          }
          fanMode
        }
        snapaddr
        floorPlanLocation {
          floorPlan {
            id
          }
          x
          y
        }
        commissioningStatus
        state {
          temperature {
            F
          }
          humidity
          lastMessageReceived
        }
        make
        model
        siteController {
          id
          name
          macAddress
          site {
            id
          }
          systemVersion
          activeLicenses
          resources {
            type
            status
            lastUpdateReceived
          }
          remoteAccess {
            uiProxyUrl
            secretKey
          }
        }
      }
      siteControllers {
        id
        name
        macAddress
        site {
          id
        }
        systemVersion
        activeLicenses
        resources {
          type
          status
          lastUpdateReceived
        }
        remoteAccess {
          uiProxyUrl
          secretKey
        }
      }
    }
  }
`;
export const GetSnapAddressesDocument = gql`
  query getSnapAddresses($siteId: ID!, $filter: StringFilterInput) {
    site(id: $siteId) {
      snapAddresses(filter: $filter)
    }
  }
`;
export const CreateThermostatDocument = gql`
  mutation createThermostat($input: CreateThermostatInput!) {
    createThermostat(input: $input) {
      id
    }
  }
`;
export const UpdateThermostatDocument = gql`
  mutation updateThermostat($input: UpdateThermostatInput!) {
    updateThermostat(input: $input) {
      id
    }
  }
`;
export const CreateTriggerDocument = gql`
  mutation createTrigger($input: CreateTriggerInput!) {
    createTrigger(input: $input) {
      id
    }
  }
`;
export const DeleteTriggerDocument = gql`
  mutation deleteTrigger($input: DeleteTriggerInput!) {
    deleteTrigger(input: $input)
  }
`;
export const UpdateTriggerDocument = gql`
  mutation updateTrigger($input: UpdateTriggerInput!) {
    updateTrigger(input: $input) {
      id
    }
  }
`;
export const GetAllTriggersDocument = gql`
  query getAllTriggers($siteId: ID!) {
    site(id: $siteId) {
      triggers {
        id
        name
        industrialSensor {
          id
        }
        state {
          lastStatusUpdate
          status
        }
        condition {
          lt
          gt
        }
        transitionConfig {
          activeTransitionDelayInMinutes
          inactiveTransitionDelayInMinutes
          unknownTransitionDelayInMinutes
        }
        notifications {
          __typename
          ... on WebhookTriggerNotification {
            url
          }
          ... on RunningStateTriggerNotification {
            state
          }
          ... on DispositionTriggerNotification {
            message
            level
          }
        }
      }
    }
  }
`;
export const GetAllMetricSourcesDocument = gql`
  query getAllMetricSources($siteId: ID!, $start: Date!, $end: Date!) {
    site(id: $siteId) {
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
      metricHistories: industrialSensors {
        id
        history(start: $start, end: $end) {
          value
          timestamp
        }
      }
    }
  }
`;
export const GetAllTriggersAndMetricSourcesDocument = gql`
  query getAllTriggersAndMetricSources($siteId: ID!, $start: Date!, $end: Date!) {
    site(id: $siteId) {
      triggers {
        id
        name
        industrialSensor {
          id
        }
        state {
          lastStatusUpdate
          status
        }
        condition {
          lt
          gt
        }
        transitionConfig {
          activeTransitionDelayInMinutes
          inactiveTransitionDelayInMinutes
          unknownTransitionDelayInMinutes
        }
        notifications {
          __typename
          ... on WebhookTriggerNotification {
            url
          }
          ... on RunningStateTriggerNotification {
            state
          }
          ... on DispositionTriggerNotification {
            message
            level
          }
        }
      }
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
      metricHistories: industrialSensors {
        id
        history(start: $start, end: $end) {
          value
          timestamp
        }
      }
    }
  }
`;
export const GetUtilityServicesDocument = gql`
  query getUtilityServices($siteId: ID!) {
    site(id: $siteId) {
      id
      utilityServices {
        id
        name
        mains {
          __typename
          id
        }
        industrialSensors {
          __typename
          id
        }
        lights {
          id
        }
        scheduledUtilityRates {
          id
          startDate
          billingFrequency
          utilityRate {
            ... on OpenEIUtilityRate {
              openEIUtilityRateId
              rateName
              companyName
            }
            ... on FlatEnergyUtilityRate {
              costOfDemand
              costOfEnergy
            }
          }
        }
      }
    }
  }
`;
export const GetBillingPeriodDocument = gql`
  query getBillingPeriod($utilityServiceId: ID!, $date: Date!) {
    utilityService(id: $utilityServiceId) {
      billingPeriod(date: $date) {
        __typename
        start
        end
        peakDemandPeriods {
          dateTimeRange {
            start
            end
          }
          power {
            W
            kW
            MW
            GW
          }
          cost
          demandChargePeriod {
            periodNumber
            periodName
          }
        }
        utilityRate {
          ... on OpenEIUtilityRate {
            openEIUtilityRateId
            rateName
            companyName
          }
          ... on FlatEnergyUtilityRate {
            costOfDemand
            costOfEnergy
          }
        }
      }
    }
  }
`;
export const CreateUtilityServiceDocument = gql`
  mutation createUtilityService($input: CreateUtilityServiceInput!) {
    createUtilityService(input: $input) {
      id
    }
  }
`;
export const UpdateUtilityServiceDocument = gql`
  mutation updateUtilityService($input: UpdateUtilityServiceInput!) {
    updateUtilityService(input: $input) {
      id
    }
  }
`;
export const GetUtilityServicesAndThingsDocument = gql`
  query getUtilityServicesAndThings($siteId: ID!) {
    site(id: $siteId) {
      utilityServices {
        id
        name
        mains {
          __typename
          id
        }
        industrialSensors {
          __typename
          id
        }
        lights {
          id
        }
        scheduledUtilityRates {
          id
          startDate
          billingFrequency
          utilityRate {
            ... on OpenEIUtilityRate {
              openEIUtilityRateId
              rateName
              companyName
            }
            ... on FlatEnergyUtilityRate {
              costOfDemand
              costOfEnergy
            }
          }
        }
      }
      industrialSensors {
        __typename
        id
        name
        dataType {
          __typename
          ... on IndustrialSensorDefinedDataType {
            type
          }
          ... on IndustrialSensorCustomDataType {
            name
            units
          }
        }
        units
        state {
          __typename
          lastMessageReceived
          ... on Sense420SensorState {
            lastValue
            siteControllerId
            siteController {
              id
            }
            batteryLevel
            upgradeStatus
          }
          ... on VirtualIndustrialSensorState {
            lastValue
          }
          ... on Bridge485MetricState {
            lastValue
          }
        }
        ... on Sense420Sensor {
          snapaddr
          floorPlanX
          floorPlanY
          floorPlanId
          minValue
          maxValue
        }
        ... on Bridge485Metric {
          conversion {
            polynomial
          }
          dataAddress
          dataFormat
          functionCode
          unitId
          source {
            id
          }
        }
      }
      lights {
        id
        name
        level
        controllerId
        floorPlanId
        floorPlanX
        floorPlanY
      }
    }
  }
`;
export const DeleteUtilityServiceDocument = gql`
  mutation deleteUtilityService($input: DeleteUtilityServiceInput!) {
    deleteUtilityService(input: $input)
  }
`;
export const CreateScheduledUtilityRateDocument = gql`
  mutation createScheduledUtilityRate($input: CreateScheduledUtilityRateInput!) {
    createScheduledUtilityRate(input: $input) {
      id
    }
  }
`;
export const DeleteScheduledUtilityRateDocument = gql`
  mutation deleteScheduledUtilityRate($input: DeleteScheduledUtilityRateInput!) {
    deleteScheduledUtilityRate(input: $input)
  }
`;
export const ApplyBehaviorDocument = gql`
  mutation applyBehavior($input: ApplyBehaviorToControlZoneInput!) {
    applyBehaviorToControlZone(input: $input) {
      id
    }
  }
`;
export const ApplySceneDocument = gql`
  mutation applyScene($sceneId: ID!) {
    applyScene(id: $sceneId) {
      id
    }
  }
`;
export const ApplySequenceSceneDocument = gql`
  mutation applySequenceScene($applySceneSequenceInput: ApplySceneSequenceInput!) {
    applySceneSequence(input: $applySceneSequenceInput) {
      id
    }
  }
`;
export const GetAllUsersAsAdminDocument = gql`
  query getAllUsersAsAdmin {
    allUsers {
      id
      name
      email
      role
    }
  }
`;
export const GetUserAsAdminDocument = gql`
  query getUserAsAdmin($input: UserQueryInput!) {
    user(input: $input) {
      id
      name
      email
      role
    }
  }
`;
export const GetUserSitesDocument = gql`
  query getUserSites($id: ID!) {
    user(input: { id: $id }) {
      id
      name
      email
      sites {
        edges {
          site: node {
            id
            name
          }
        }
      }
    }
  }
`;
export const CreateUserAsAdminDocument = gql`
  mutation createUserAsAdmin($input: UserCreateInput!) {
    createUser(input: $input) {
      id
    }
  }
`;
export const UpdateUserAsAdminDocument = gql`
  mutation updateUserAsAdmin($input: UserUpdateInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;
export const DeleteUserAsAdminDocument = gql`
  mutation deleteUserAsAdmin($input: UserDeleteInput!) {
    deleteUser(input: $input)
  }
`;
export const TriggerResetPasswordAsAdminDocument = gql`
  mutation triggerResetPasswordAsAdmin($input: UserTriggerPasswordReset!) {
    triggerPasswordReset(input: $input)
  }
`;

@Injectable({ providedIn: 'root' })
export class GraphQLAPIService {
  constructor(protected client: GraphQLClient) {}

  getColorPalette(
    variables?: GetColorPaletteQueryVariables,
  ): Observable<GetColorPaletteQueryResult> {
    return this.client
      .query<GetColorPaletteQueryResult, GetColorPaletteQueryVariables>(
        GetColorPaletteDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateColorPalette(
    variables?: UpdateColorPaletteMutationVariables,
  ): Observable<UpdateColorPaletteMutationResult> {
    return this.client
      .query<UpdateColorPaletteMutationResult, UpdateColorPaletteMutationVariables>(
        UpdateColorPaletteDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createEmailGroup(
    variables?: CreateEmailGroupMutationVariables,
  ): Observable<CreateEmailGroupMutationResult> {
    return this.client
      .query<CreateEmailGroupMutationResult, CreateEmailGroupMutationVariables>(
        CreateEmailGroupDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteEmailGroup(
    variables?: DeleteEmailGroupMutationVariables,
  ): Observable<DeleteEmailGroupMutationResult> {
    return this.client
      .query<DeleteEmailGroupMutationResult, DeleteEmailGroupMutationVariables>(
        DeleteEmailGroupDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateEmailGroup(
    variables?: UpdateEmailGroupMutationVariables,
  ): Observable<UpdateEmailGroupMutationResult> {
    return this.client
      .query<UpdateEmailGroupMutationResult, UpdateEmailGroupMutationVariables>(
        UpdateEmailGroupDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createPermissionGroup(
    variables?: CreatePermissionGroupMutationVariables,
  ): Observable<CreatePermissionGroupMutationResult> {
    return this.client
      .query<CreatePermissionGroupMutationResult, CreatePermissionGroupMutationVariables>(
        CreatePermissionGroupDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deletePermissionGroup(
    variables?: DeletePermissionGroupMutationVariables,
  ): Observable<DeletePermissionGroupMutationResult> {
    return this.client
      .query<DeletePermissionGroupMutationResult, DeletePermissionGroupMutationVariables>(
        DeletePermissionGroupDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updatePermissionGroup(
    variables?: UpdatePermissionGroupMutationVariables,
  ): Observable<UpdatePermissionGroupMutationResult> {
    return this.client
      .query<UpdatePermissionGroupMutationResult, UpdatePermissionGroupMutationVariables>(
        UpdatePermissionGroupDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getOrganizationById(
    variables?: GetOrganizationByIdQueryVariables,
  ): Observable<GetOrganizationByIdQueryResult> {
    return this.client
      .query<GetOrganizationByIdQueryResult, GetOrganizationByIdQueryVariables>(
        GetOrganizationByIdDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getEmailGroupsByOrganization(
    variables?: GetEmailGroupsByOrganizationQueryVariables,
  ): Observable<GetEmailGroupsByOrganizationQueryResult> {
    return this.client
      .query<
        GetEmailGroupsByOrganizationQueryResult,
        GetEmailGroupsByOrganizationQueryVariables
      >(GetEmailGroupsByOrganizationDocument, variables)
      .pipe(map(response => response.data));
  }

  getUsersByOrganization(
    variables?: GetUsersByOrganizationQueryVariables,
  ): Observable<GetUsersByOrganizationQueryResult> {
    return this.client
      .query<GetUsersByOrganizationQueryResult, GetUsersByOrganizationQueryVariables>(
        GetUsersByOrganizationDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  removeOrganizationUser(
    variables?: RemoveOrganizationUserMutationVariables,
  ): Observable<RemoveOrganizationUserMutationResult> {
    return this.client
      .query<
        RemoveOrganizationUserMutationResult,
        RemoveOrganizationUserMutationVariables
      >(RemoveOrganizationUserDocument, variables)
      .pipe(map(response => response.data));
  }

  inviteOrganizationUser(
    variables?: InviteOrganizationUserMutationVariables,
  ): Observable<InviteOrganizationUserMutationResult> {
    return this.client
      .query<
        InviteOrganizationUserMutationResult,
        InviteOrganizationUserMutationVariables
      >(InviteOrganizationUserDocument, variables)
      .pipe(map(response => response.data));
  }

  updateOrganizationUser(
    variables?: UpdateOrganizationUserMutationVariables,
  ): Observable<UpdateOrganizationUserMutationResult> {
    return this.client
      .query<
        UpdateOrganizationUserMutationResult,
        UpdateOrganizationUserMutationVariables
      >(UpdateOrganizationUserDocument, variables)
      .pipe(map(response => response.data));
  }

  getPermissionGroupsByOrganization(
    variables?: GetPermissionGroupsByOrganizationQueryVariables,
  ): Observable<GetPermissionGroupsByOrganizationQueryResult> {
    return this.client
      .query<
        GetPermissionGroupsByOrganizationQueryResult,
        GetPermissionGroupsByOrganizationQueryVariables
      >(GetPermissionGroupsByOrganizationDocument, variables)
      .pipe(map(response => response.data));
  }

  getSitesByOrganization(
    variables?: GetSitesByOrganizationQueryVariables,
  ): Observable<GetSitesByOrganizationQueryResult> {
    return this.client
      .query<GetSitesByOrganizationQueryResult, GetSitesByOrganizationQueryVariables>(
        GetSitesByOrganizationDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getSitesWithUsersByOrganization(
    variables?: GetSitesWithUsersByOrganizationQueryVariables,
  ): Observable<GetSitesWithUsersByOrganizationQueryResult> {
    return this.client
      .query<
        GetSitesWithUsersByOrganizationQueryResult,
        GetSitesWithUsersByOrganizationQueryVariables
      >(GetSitesWithUsersByOrganizationDocument, variables)
      .pipe(map(response => response.data));
  }

  quickSiteSetup(
    variables?: QuickSiteSetupMutationVariables,
  ): Observable<QuickSiteSetupMutationResult> {
    return this.client
      .query<QuickSiteSetupMutationResult, QuickSiteSetupMutationVariables>(
        QuickSiteSetupDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllSitesAsAdmin(
    variables?: GetAllSitesAsAdminQueryVariables,
  ): Observable<GetAllSitesAsAdminQueryResult> {
    return this.client
      .query<GetAllSitesAsAdminQueryResult, GetAllSitesAsAdminQueryVariables>(
        GetAllSitesAsAdminDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getSite(variables?: GetSiteQueryVariables): Observable<GetSiteQueryResult> {
    return this.client
      .query<GetSiteQueryResult, GetSiteQueryVariables>(GetSiteDocument, variables)
      .pipe(map(response => response.data));
  }

  createSiteAsAdmin(
    variables?: CreateSiteAsAdminMutationVariables,
  ): Observable<CreateSiteAsAdminMutationResult> {
    return this.client
      .query<CreateSiteAsAdminMutationResult, CreateSiteAsAdminMutationVariables>(
        CreateSiteAsAdminDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateSiteAsAdmin(
    variables?: UpdateSiteAsAdminMutationVariables,
  ): Observable<UpdateSiteAsAdminMutationResult> {
    return this.client
      .query<UpdateSiteAsAdminMutationResult, UpdateSiteAsAdminMutationVariables>(
        UpdateSiteAsAdminDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteSiteAsAdmin(
    variables?: DeleteSiteAsAdminMutationVariables,
  ): Observable<DeleteSiteAsAdminMutationResult> {
    return this.client
      .query<DeleteSiteAsAdminMutationResult, DeleteSiteAsAdminMutationVariables>(
        DeleteSiteAsAdminDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getEditSiteModels(
    variables?: GetEditSiteModelsQueryVariables,
  ): Observable<GetEditSiteModelsQueryResult> {
    return this.client
      .query<GetEditSiteModelsQueryResult, GetEditSiteModelsQueryVariables>(
        GetEditSiteModelsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getSiteUsers(
    variables?: GetSiteUsersQueryVariables,
  ): Observable<GetSiteUsersQueryResult> {
    return this.client
      .query<GetSiteUsersQueryResult, GetSiteUsersQueryVariables>(
        GetSiteUsersDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getSiteReport(
    variables?: GetSiteReportQueryVariables,
  ): Observable<GetSiteReportQueryResult> {
    return this.client
      .query<GetSiteReportQueryResult, GetSiteReportQueryVariables>(
        GetSiteReportDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllSiteControllersAsAdmin(
    variables?: GetAllSiteControllersAsAdminQueryVariables,
  ): Observable<GetAllSiteControllersAsAdminQueryResult> {
    return this.client
      .query<
        GetAllSiteControllersAsAdminQueryResult,
        GetAllSiteControllersAsAdminQueryVariables
      >(GetAllSiteControllersAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  getAllSiteControllersAndSitesAsAdmin(
    variables?: GetAllSiteControllersAndSitesAsAdminQueryVariables,
  ): Observable<GetAllSiteControllersAndSitesAsAdminQueryResult> {
    return this.client
      .query<
        GetAllSiteControllersAndSitesAsAdminQueryResult,
        GetAllSiteControllersAndSitesAsAdminQueryVariables
      >(GetAllSiteControllersAndSitesAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  createSiteControllerAsAdmin(
    variables?: CreateSiteControllerAsAdminMutationVariables,
  ): Observable<CreateSiteControllerAsAdminMutationResult> {
    return this.client
      .query<
        CreateSiteControllerAsAdminMutationResult,
        CreateSiteControllerAsAdminMutationVariables
      >(CreateSiteControllerAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  updateSiteControllerAsAdmin(
    variables?: UpdateSiteControllerAsAdminMutationVariables,
  ): Observable<UpdateSiteControllerAsAdminMutationResult> {
    return this.client
      .query<
        UpdateSiteControllerAsAdminMutationResult,
        UpdateSiteControllerAsAdminMutationVariables
      >(UpdateSiteControllerAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  deleteSiteControllerAsAdmin(
    variables?: DeleteSiteControllerAsAdminMutationVariables,
  ): Observable<DeleteSiteControllerAsAdminMutationResult> {
    return this.client
      .query<
        DeleteSiteControllerAsAdminMutationResult,
        DeleteSiteControllerAsAdminMutationVariables
      >(DeleteSiteControllerAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  initializeSiteControllerResourcesAsAdmin(
    variables?: InitializeSiteControllerResourcesAsAdminMutationVariables,
  ): Observable<InitializeSiteControllerResourcesAsAdminMutationResult> {
    return this.client
      .query<
        InitializeSiteControllerResourcesAsAdminMutationResult,
        InitializeSiteControllerResourcesAsAdminMutationVariables
      >(InitializeSiteControllerResourcesAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  moveSiteControllerAsAdmin(
    variables?: MoveSiteControllerAsAdminMutationVariables,
  ): Observable<MoveSiteControllerAsAdminMutationResult> {
    return this.client
      .query<
        MoveSiteControllerAsAdminMutationResult,
        MoveSiteControllerAsAdminMutationVariables
      >(MoveSiteControllerAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  replaceSiteControllerAsAdmin(
    variables?: ReplaceSiteControllerAsAdminMutationVariables,
  ): Observable<ReplaceSiteControllerAsAdminMutationResult> {
    return this.client
      .query<
        ReplaceSiteControllerAsAdminMutationResult,
        ReplaceSiteControllerAsAdminMutationVariables
      >(ReplaceSiteControllerAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  importSiteControllerEntitiesAsAdmin(
    variables?: ImportSiteControllerEntitiesAsAdminMutationVariables,
  ): Observable<ImportSiteControllerEntitiesAsAdminMutationResult> {
    return this.client
      .query<
        ImportSiteControllerEntitiesAsAdminMutationResult,
        ImportSiteControllerEntitiesAsAdminMutationVariables
      >(ImportSiteControllerEntitiesAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  getAlarms(variables?: GetAlarmsQueryVariables): Observable<GetAlarmsQueryResult> {
    return this.client
      .query<GetAlarmsQueryResult, GetAlarmsQueryVariables>(GetAlarmsDocument, variables)
      .pipe(map(response => response.data));
  }

  getAllBridge485DetailsModels(
    variables?: GetAllBridge485DetailsModelsQueryVariables,
  ): Observable<GetAllBridge485DetailsModelsQueryResult> {
    return this.client
      .query<
        GetAllBridge485DetailsModelsQueryResult,
        GetAllBridge485DetailsModelsQueryVariables
      >(GetAllBridge485DetailsModelsDocument, variables)
      .pipe(map(response => response.data));
  }

  pingBridge485(
    variables?: PingBridge485MutationVariables,
  ): Observable<PingBridge485MutationResult> {
    return this.client
      .query<PingBridge485MutationResult, PingBridge485MutationVariables>(
        PingBridge485Document,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteBridge485(
    variables?: DeleteBridge485MutationVariables,
  ): Observable<DeleteBridge485MutationResult> {
    return this.client
      .query<DeleteBridge485MutationResult, DeleteBridge485MutationVariables>(
        DeleteBridge485Document,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllBridge485Models(
    variables?: GetAllBridge485ModelsQueryVariables,
  ): Observable<GetAllBridge485ModelsQueryResult> {
    return this.client
      .query<GetAllBridge485ModelsQueryResult, GetAllBridge485ModelsQueryVariables>(
        GetAllBridge485ModelsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createBridge485(
    variables?: CreateBridge485MutationVariables,
  ): Observable<CreateBridge485MutationResult> {
    return this.client
      .query<CreateBridge485MutationResult, CreateBridge485MutationVariables>(
        CreateBridge485Document,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateBridge485(
    variables?: UpdateBridge485MutationVariables,
  ): Observable<UpdateBridge485MutationResult> {
    return this.client
      .query<UpdateBridge485MutationResult, UpdateBridge485MutationVariables>(
        UpdateBridge485Document,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getSources(variables?: GetSourcesQueryVariables): Observable<GetSourcesQueryResult> {
    return this.client
      .query<GetSourcesQueryResult, GetSourcesQueryVariables>(
        GetSourcesDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getSourceData(
    variables?: GetSourceDataQueryVariables,
  ): Observable<GetSourceDataQueryResult> {
    return this.client
      .query<GetSourceDataQueryResult, GetSourceDataQueryVariables>(
        GetSourceDataDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getClimateMapLayerModels(
    variables?: GetClimateMapLayerModelsQueryVariables,
  ): Observable<GetClimateMapLayerModelsQueryResult> {
    return this.client
      .query<GetClimateMapLayerModelsQueryResult, GetClimateMapLayerModelsQueryVariables>(
        GetClimateMapLayerModelsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getControlZone(
    variables?: GetControlZoneQueryVariables,
  ): Observable<GetControlZoneQueryResult> {
    return this.client
      .query<GetControlZoneQueryResult, GetControlZoneQueryVariables>(
        GetControlZoneDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createClimateZone(
    variables?: CreateClimateZoneMutationVariables,
  ): Observable<CreateClimateZoneMutationResult> {
    return this.client
      .query<CreateClimateZoneMutationResult, CreateClimateZoneMutationVariables>(
        CreateClimateZoneDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateClimateZone(
    variables?: UpdateClimateZoneMutationVariables,
  ): Observable<UpdateClimateZoneMutationResult> {
    return this.client
      .query<UpdateClimateZoneMutationResult, UpdateClimateZoneMutationVariables>(
        UpdateClimateZoneDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteControlZone(
    variables?: DeleteControlZoneMutationVariables,
  ): Observable<DeleteControlZoneMutationResult> {
    return this.client
      .query<DeleteControlZoneMutationResult, DeleteControlZoneMutationVariables>(
        DeleteControlZoneDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  applyBehaviorToControlZone(
    variables?: ApplyBehaviorToControlZoneMutationVariables,
  ): Observable<ApplyBehaviorToControlZoneMutationResult> {
    return this.client
      .query<
        ApplyBehaviorToControlZoneMutationResult,
        ApplyBehaviorToControlZoneMutationVariables
      >(ApplyBehaviorToControlZoneDocument, variables)
      .pipe(map(response => response.data));
  }

  getSiteZones(
    variables?: GetSiteZonesQueryVariables,
  ): Observable<GetSiteZonesQueryResult> {
    return this.client
      .query<GetSiteZonesQueryResult, GetSiteZonesQueryVariables>(
        GetSiteZonesDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  acceptEula(
    variables?: AcceptEulaMutationVariables,
  ): Observable<AcceptEulaMutationResult> {
    return this.client
      .query<AcceptEulaMutationResult, AcceptEulaMutationVariables>(
        AcceptEulaDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  myEula(variables?: MyEulaQueryVariables): Observable<MyEulaQueryResult> {
    return this.client
      .query<MyEulaQueryResult, MyEulaQueryVariables>(MyEulaDocument, variables)
      .pipe(map(response => response.data));
  }

  createDepartment(
    variables?: CreateDepartmentMutationVariables,
  ): Observable<CreateDepartmentMutationResult> {
    return this.client
      .query<CreateDepartmentMutationResult, CreateDepartmentMutationVariables>(
        CreateDepartmentDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getDepartmentResourceGroupsAndThings(
    variables?: GetDepartmentResourceGroupsAndThingsQueryVariables,
  ): Observable<GetDepartmentResourceGroupsAndThingsQueryResult> {
    return this.client
      .query<
        GetDepartmentResourceGroupsAndThingsQueryResult,
        GetDepartmentResourceGroupsAndThingsQueryVariables
      >(GetDepartmentResourceGroupsAndThingsDocument, variables)
      .pipe(map(response => response.data));
  }

  updateDepartment(
    variables?: UpdateDepartmentMutationVariables,
  ): Observable<UpdateDepartmentMutationResult> {
    return this.client
      .query<UpdateDepartmentMutationResult, UpdateDepartmentMutationVariables>(
        UpdateDepartmentDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteDepartment(
    variables?: DeleteDepartmentMutationVariables,
  ): Observable<DeleteDepartmentMutationResult> {
    return this.client
      .query<DeleteDepartmentMutationResult, DeleteDepartmentMutationVariables>(
        DeleteDepartmentDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getEquipment(
    variables?: GetEquipmentQueryVariables,
  ): Observable<GetEquipmentQueryResult> {
    return this.client
      .query<GetEquipmentQueryResult, GetEquipmentQueryVariables>(
        GetEquipmentDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getEquipmentMapLayerDeferredModels(
    variables?: GetEquipmentMapLayerDeferredModelsQueryVariables,
  ): Observable<GetEquipmentMapLayerDeferredModelsQueryResult> {
    return this.client
      .query<
        GetEquipmentMapLayerDeferredModelsQueryResult,
        GetEquipmentMapLayerDeferredModelsQueryVariables
      >(GetEquipmentMapLayerDeferredModelsDocument, variables)
      .pipe(map(response => response.data));
  }

  getEquipmentProfilePageModels(
    variables?: GetEquipmentProfilePageModelsQueryVariables,
  ): Observable<GetEquipmentProfilePageModelsQueryResult> {
    return this.client
      .query<
        GetEquipmentProfilePageModelsQueryResult,
        GetEquipmentProfilePageModelsQueryVariables
      >(GetEquipmentProfilePageModelsDocument, variables)
      .pipe(map(response => response.data));
  }

  getSiteResourceGroupsAndIndustrialSensors(
    variables?: GetSiteResourceGroupsAndIndustrialSensorsQueryVariables,
  ): Observable<GetSiteResourceGroupsAndIndustrialSensorsQueryResult> {
    return this.client
      .query<
        GetSiteResourceGroupsAndIndustrialSensorsQueryResult,
        GetSiteResourceGroupsAndIndustrialSensorsQueryVariables
      >(GetSiteResourceGroupsAndIndustrialSensorsDocument, variables)
      .pipe(map(response => response.data));
  }

  getEquipmentPageDeferredModels(
    variables?: GetEquipmentPageDeferredModelsQueryVariables,
  ): Observable<GetEquipmentPageDeferredModelsQueryResult> {
    return this.client
      .query<
        GetEquipmentPageDeferredModelsQueryResult,
        GetEquipmentPageDeferredModelsQueryVariables
      >(GetEquipmentPageDeferredModelsDocument, variables)
      .pipe(map(response => response.data));
  }

  createEquipment(
    variables?: CreateEquipmentMutationVariables,
  ): Observable<CreateEquipmentMutationResult> {
    return this.client
      .query<CreateEquipmentMutationResult, CreateEquipmentMutationVariables>(
        CreateEquipmentDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteEquipment(
    variables?: DeleteEquipmentMutationVariables,
  ): Observable<DeleteEquipmentMutationResult> {
    return this.client
      .query<DeleteEquipmentMutationResult, DeleteEquipmentMutationVariables>(
        DeleteEquipmentDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateEquipment(
    variables?: UpdateEquipmentMutationVariables,
  ): Observable<UpdateEquipmentMutationResult> {
    return this.client
      .query<UpdateEquipmentMutationResult, UpdateEquipmentMutationVariables>(
        UpdateEquipmentDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getResourceGroups(
    variables?: GetResourceGroupsQueryVariables,
  ): Observable<GetResourceGroupsQueryResult> {
    return this.client
      .query<GetResourceGroupsQueryResult, GetResourceGroupsQueryVariables>(
        GetResourceGroupsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createResourceGroup(
    variables?: CreateResourceGroupMutationVariables,
  ): Observable<CreateResourceGroupMutationResult> {
    return this.client
      .query<CreateResourceGroupMutationResult, CreateResourceGroupMutationVariables>(
        CreateResourceGroupDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateResourceGroup(
    variables?: UpdateResourceGroupMutationVariables,
  ): Observable<UpdateResourceGroupMutationResult> {
    return this.client
      .query<UpdateResourceGroupMutationResult, UpdateResourceGroupMutationVariables>(
        UpdateResourceGroupDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteResourceGroup(
    variables?: DeleteResourceGroupMutationVariables,
  ): Observable<DeleteResourceGroupMutationResult> {
    return this.client
      .query<DeleteResourceGroupMutationResult, DeleteResourceGroupMutationVariables>(
        DeleteResourceGroupDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getResourceGroupsAndThings(
    variables?: GetResourceGroupsAndThingsQueryVariables,
  ): Observable<GetResourceGroupsAndThingsQueryResult> {
    return this.client
      .query<
        GetResourceGroupsAndThingsQueryResult,
        GetResourceGroupsAndThingsQueryVariables
      >(GetResourceGroupsAndThingsDocument, variables)
      .pipe(map(response => response.data));
  }

  createFloorPlan(
    variables?: CreateFloorPlanMutationVariables,
  ): Observable<CreateFloorPlanMutationResult> {
    return this.client
      .query<CreateFloorPlanMutationResult, CreateFloorPlanMutationVariables>(
        CreateFloorPlanDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllFloorPlansForSite(
    variables?: GetAllFloorPlansForSiteQueryVariables,
  ): Observable<GetAllFloorPlansForSiteQueryResult> {
    return this.client
      .query<GetAllFloorPlansForSiteQueryResult, GetAllFloorPlansForSiteQueryVariables>(
        GetAllFloorPlansForSiteDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteFloorPlan(
    variables?: DeleteFloorPlanMutationVariables,
  ): Observable<DeleteFloorPlanMutationResult> {
    return this.client
      .query<DeleteFloorPlanMutationResult, DeleteFloorPlanMutationVariables>(
        DeleteFloorPlanDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateFloorPlanImage(
    variables?: UpdateFloorPlanImageMutationVariables,
  ): Observable<UpdateFloorPlanImageMutationResult> {
    return this.client
      .query<UpdateFloorPlanImageMutationResult, UpdateFloorPlanImageMutationVariables>(
        UpdateFloorPlanImageDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  importDeviceAssetData(
    variables?: ImportDeviceAssetDataMutationVariables,
  ): Observable<ImportDeviceAssetDataMutationResult> {
    return this.client
      .query<ImportDeviceAssetDataMutationResult, ImportDeviceAssetDataMutationVariables>(
        ImportDeviceAssetDataDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getIndieSensorDetailsModels(
    variables?: GetIndieSensorDetailsModelsQueryVariables,
  ): Observable<GetIndieSensorDetailsModelsQueryResult> {
    return this.client
      .query<
        GetIndieSensorDetailsModelsQueryResult,
        GetIndieSensorDetailsModelsQueryVariables
      >(GetIndieSensorDetailsModelsDocument, variables)
      .pipe(map(response => response.data));
  }

  deleteIndustrialSensor(
    variables?: DeleteIndustrialSensorMutationVariables,
  ): Observable<DeleteIndustrialSensorMutationResult> {
    return this.client
      .query<
        DeleteIndustrialSensorMutationResult,
        DeleteIndustrialSensorMutationVariables
      >(DeleteIndustrialSensorDocument, variables)
      .pipe(map(response => response.data));
  }

  getAllIndieSensors(
    variables?: GetAllIndieSensorsQueryVariables,
  ): Observable<GetAllIndieSensorsQueryResult> {
    return this.client
      .query<GetAllIndieSensorsQueryResult, GetAllIndieSensorsQueryVariables>(
        GetAllIndieSensorsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllIndieSensorsAndControllers(
    variables?: GetAllIndieSensorsAndControllersQueryVariables,
  ): Observable<GetAllIndieSensorsAndControllersQueryResult> {
    return this.client
      .query<
        GetAllIndieSensorsAndControllersQueryResult,
        GetAllIndieSensorsAndControllersQueryVariables
      >(GetAllIndieSensorsAndControllersDocument, variables)
      .pipe(map(response => response.data));
  }

  createSense420Sensor(
    variables?: CreateSense420SensorMutationVariables,
  ): Observable<CreateSense420SensorMutationResult> {
    return this.client
      .query<CreateSense420SensorMutationResult, CreateSense420SensorMutationVariables>(
        CreateSense420SensorDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateSense420Sensor(
    variables?: UpdateSense420SensorMutationVariables,
  ): Observable<UpdateSense420SensorMutationResult> {
    return this.client
      .query<UpdateSense420SensorMutationResult, UpdateSense420SensorMutationVariables>(
        UpdateSense420SensorDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  dimLight(variables?: DimLightMutationVariables): Observable<DimLightMutationResult> {
    return this.client
      .query<DimLightMutationResult, DimLightMutationVariables>(
        DimLightDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getLightLevel(
    variables?: GetLightLevelQueryVariables,
  ): Observable<GetLightLevelQueryResult> {
    return this.client
      .query<GetLightLevelQueryResult, GetLightLevelQueryVariables>(
        GetLightLevelDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  replaceLight(
    variables?: ReplaceLightMutationVariables,
  ): Observable<ReplaceLightMutationResult> {
    return this.client
      .query<ReplaceLightMutationResult, ReplaceLightMutationVariables>(
        ReplaceLightDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  checkSignalStrength(
    variables?: CheckSignalStrengthMutationVariables,
  ): Observable<CheckSignalStrengthMutationResult> {
    return this.client
      .query<CheckSignalStrengthMutationResult, CheckSignalStrengthMutationVariables>(
        CheckSignalStrengthDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  pingLight(variables?: PingLightMutationVariables): Observable<PingLightMutationResult> {
    return this.client
      .query<PingLightMutationResult, PingLightMutationVariables>(
        PingLightDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getLightMapLayerModels(
    variables?: GetLightMapLayerModelsQueryVariables,
  ): Observable<GetLightMapLayerModelsQueryResult> {
    return this.client
      .query<GetLightMapLayerModelsQueryResult, GetLightMapLayerModelsQueryVariables>(
        GetLightMapLayerModelsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getLightMapLayerDeferredModels(
    variables?: GetLightMapLayerDeferredModelsQueryVariables,
  ): Observable<GetLightMapLayerDeferredModelsQueryResult> {
    return this.client
      .query<
        GetLightMapLayerDeferredModelsQueryResult,
        GetLightMapLayerDeferredModelsQueryVariables
      >(GetLightMapLayerDeferredModelsDocument, variables)
      .pipe(map(response => response.data));
  }

  getLightZoneDetailsModels(
    variables?: GetLightZoneDetailsModelsQueryVariables,
  ): Observable<GetLightZoneDetailsModelsQueryResult> {
    return this.client
      .query<
        GetLightZoneDetailsModelsQueryResult,
        GetLightZoneDetailsModelsQueryVariables
      >(GetLightZoneDetailsModelsDocument, variables)
      .pipe(map(response => response.data));
  }

  getSiteControlZones(
    variables?: GetSiteControlZonesQueryVariables,
  ): Observable<GetSiteControlZonesQueryResult> {
    return this.client
      .query<GetSiteControlZonesQueryResult, GetSiteControlZonesQueryVariables>(
        GetSiteControlZonesDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  placeThingsOnFloorPlan(
    variables?: PlaceThingsOnFloorPlanMutationVariables,
  ): Observable<PlaceThingsOnFloorPlanMutationResult> {
    return this.client
      .query<
        PlaceThingsOnFloorPlanMutationResult,
        PlaceThingsOnFloorPlanMutationVariables
      >(PlaceThingsOnFloorPlanDocument, variables)
      .pipe(map(response => response.data));
  }

  essentialModels(
    variables?: EssentialModelsQueryVariables,
  ): Observable<EssentialModelsQueryResult> {
    return this.client
      .query<EssentialModelsQueryResult, EssentialModelsQueryVariables>(
        EssentialModelsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createOrganization(
    variables?: CreateOrganizationMutationVariables,
  ): Observable<CreateOrganizationMutationResult> {
    return this.client
      .query<CreateOrganizationMutationResult, CreateOrganizationMutationVariables>(
        CreateOrganizationDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteOrganization(
    variables?: DeleteOrganizationMutationVariables,
  ): Observable<DeleteOrganizationMutationResult> {
    return this.client
      .query<DeleteOrganizationMutationResult, DeleteOrganizationMutationVariables>(
        DeleteOrganizationDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateOrganization(
    variables?: UpdateOrganizationMutationVariables,
  ): Observable<UpdateOrganizationMutationResult> {
    return this.client
      .query<UpdateOrganizationMutationResult, UpdateOrganizationMutationVariables>(
        UpdateOrganizationDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllOrganizationsAsAdmin(
    variables?: GetAllOrganizationsAsAdminQueryVariables,
  ): Observable<GetAllOrganizationsAsAdminQueryResult> {
    return this.client
      .query<
        GetAllOrganizationsAsAdminQueryResult,
        GetAllOrganizationsAsAdminQueryVariables
      >(GetAllOrganizationsAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  getAllOrganizationsWithSitesAsAdmin(
    variables?: GetAllOrganizationsWithSitesAsAdminQueryVariables,
  ): Observable<GetAllOrganizationsWithSitesAsAdminQueryResult> {
    return this.client
      .query<
        GetAllOrganizationsWithSitesAsAdminQueryResult,
        GetAllOrganizationsWithSitesAsAdminQueryVariables
      >(GetAllOrganizationsWithSitesAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }

  updateEnergyBaseline(
    variables?: UpdateEnergyBaselineMutationVariables,
  ): Observable<UpdateEnergyBaselineMutationResult> {
    return this.client
      .query<UpdateEnergyBaselineMutationResult, UpdateEnergyBaselineMutationVariables>(
        UpdateEnergyBaselineDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  setBaselineSkipped(
    variables?: SetBaselineSkippedMutationVariables,
  ): Observable<SetBaselineSkippedMutationResult> {
    return this.client
      .query<SetBaselineSkippedMutationResult, SetBaselineSkippedMutationVariables>(
        SetBaselineSkippedDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getEnergyBaseline(
    variables?: GetEnergyBaselineQueryVariables,
  ): Observable<GetEnergyBaselineQueryResult> {
    return this.client
      .query<GetEnergyBaselineQueryResult, GetEnergyBaselineQueryVariables>(
        GetEnergyBaselineDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getPowerData(
    variables?: GetPowerDataQueryVariables,
  ): Observable<GetPowerDataQueryResult> {
    return this.client
      .query<GetPowerDataQueryResult, GetPowerDataQueryVariables>(
        GetPowerDataDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllZones(variables?: GetAllZonesQueryVariables): Observable<GetAllZonesQueryResult> {
    return this.client
      .query<GetAllZonesQueryResult, GetAllZonesQueryVariables>(
        GetAllZonesDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createSceneView(
    variables?: CreateSceneViewMutationVariables,
  ): Observable<CreateSceneViewMutationResult> {
    return this.client
      .query<CreateSceneViewMutationResult, CreateSceneViewMutationVariables>(
        CreateSceneViewDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteSceneView(
    variables?: DeleteSceneViewMutationVariables,
  ): Observable<DeleteSceneViewMutationResult> {
    return this.client
      .query<DeleteSceneViewMutationResult, DeleteSceneViewMutationVariables>(
        DeleteSceneViewDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateSceneView(
    variables?: UpdateSceneViewMutationVariables,
  ): Observable<UpdateSceneViewMutationResult> {
    return this.client
      .query<UpdateSceneViewMutationResult, UpdateSceneViewMutationVariables>(
        UpdateSceneViewDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getSceneViews(
    variables?: GetSceneViewsQueryVariables,
  ): Observable<GetSceneViewsQueryResult> {
    return this.client
      .query<GetSceneViewsQueryResult, GetSceneViewsQueryVariables>(
        GetSceneViewsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getScenes(variables?: GetScenesQueryVariables): Observable<GetScenesQueryResult> {
    return this.client
      .query<GetScenesQueryResult, GetScenesQueryVariables>(GetScenesDocument, variables)
      .pipe(map(response => response.data));
  }

  deleteScene_v2(
    variables?: DeleteScene_V2MutationVariables,
  ): Observable<DeleteScene_V2MutationResult> {
    return this.client
      .query<DeleteScene_V2MutationResult, DeleteScene_V2MutationVariables>(
        DeleteScene_V2Document,
        variables,
      )
      .pipe(map(response => response.data));
  }

  applyScene_v2(
    variables?: ApplyScene_V2MutationVariables,
  ): Observable<ApplyScene_V2MutationResult> {
    return this.client
      .query<ApplyScene_V2MutationResult, ApplyScene_V2MutationVariables>(
        ApplyScene_V2Document,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createScene(
    variables?: CreateSceneMutationVariables,
  ): Observable<CreateSceneMutationResult> {
    return this.client
      .query<CreateSceneMutationResult, CreateSceneMutationVariables>(
        CreateSceneDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateScene_v2(
    variables?: UpdateScene_V2MutationVariables,
  ): Observable<UpdateScene_V2MutationResult> {
    return this.client
      .query<UpdateScene_V2MutationResult, UpdateScene_V2MutationVariables>(
        UpdateScene_V2Document,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteSequenceScene(
    variables?: DeleteSequenceSceneMutationVariables,
  ): Observable<DeleteSequenceSceneMutationResult> {
    return this.client
      .query<DeleteSequenceSceneMutationResult, DeleteSequenceSceneMutationVariables>(
        DeleteSequenceSceneDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createSequenceScene(
    variables?: CreateSequenceSceneMutationVariables,
  ): Observable<CreateSequenceSceneMutationResult> {
    return this.client
      .query<CreateSequenceSceneMutationResult, CreateSequenceSceneMutationVariables>(
        CreateSequenceSceneDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateSequenceScene(
    variables?: UpdateSequenceSceneMutationVariables,
  ): Observable<UpdateSequenceSceneMutationResult> {
    return this.client
      .query<UpdateSequenceSceneMutationResult, UpdateSequenceSceneMutationVariables>(
        UpdateSequenceSceneDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllScheduledEvents(
    variables?: GetAllScheduledEventsQueryVariables,
  ): Observable<GetAllScheduledEventsQueryResult> {
    return this.client
      .query<GetAllScheduledEventsQueryResult, GetAllScheduledEventsQueryVariables>(
        GetAllScheduledEventsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createScheduledEvent(
    variables?: CreateScheduledEventMutationVariables,
  ): Observable<CreateScheduledEventMutationResult> {
    return this.client
      .query<CreateScheduledEventMutationResult, CreateScheduledEventMutationVariables>(
        CreateScheduledEventDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateScheduledEvent(
    variables?: UpdateScheduledEventMutationVariables,
  ): Observable<UpdateScheduledEventMutationResult> {
    return this.client
      .query<UpdateScheduledEventMutationResult, UpdateScheduledEventMutationVariables>(
        UpdateScheduledEventDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteScheduledEvent(
    variables?: DeleteScheduledEventMutationVariables,
  ): Observable<DeleteScheduledEventMutationResult> {
    return this.client
      .query<DeleteScheduledEventMutationResult, DeleteScheduledEventMutationVariables>(
        DeleteScheduledEventDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getSenseMapLayerModels(
    variables?: GetSenseMapLayerModelsQueryVariables,
  ): Observable<GetSenseMapLayerModelsQueryResult> {
    return this.client
      .query<GetSenseMapLayerModelsQueryResult, GetSenseMapLayerModelsQueryVariables>(
        GetSenseMapLayerModelsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getSiteControllerDetails(
    variables?: GetSiteControllerDetailsQueryVariables,
  ): Observable<GetSiteControllerDetailsQueryResult> {
    return this.client
      .query<GetSiteControllerDetailsQueryResult, GetSiteControllerDetailsQueryVariables>(
        GetSiteControllerDetailsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteSiteControllerFromDetails(
    variables?: DeleteSiteControllerFromDetailsMutationVariables,
  ): Observable<DeleteSiteControllerFromDetailsMutationResult> {
    return this.client
      .query<
        DeleteSiteControllerFromDetailsMutationResult,
        DeleteSiteControllerFromDetailsMutationVariables
      >(DeleteSiteControllerFromDetailsDocument, variables)
      .pipe(map(response => response.data));
  }

  initializeSiteControllerResourcesFromDetails(
    variables?: InitializeSiteControllerResourcesFromDetailsMutationVariables,
  ): Observable<InitializeSiteControllerResourcesFromDetailsMutationResult> {
    return this.client
      .query<
        InitializeSiteControllerResourcesFromDetailsMutationResult,
        InitializeSiteControllerResourcesFromDetailsMutationVariables
      >(InitializeSiteControllerResourcesFromDetailsDocument, variables)
      .pipe(map(response => response.data));
  }

  siteControllersForSite(
    variables?: SiteControllersForSiteQueryVariables,
  ): Observable<SiteControllersForSiteQueryResult> {
    return this.client
      .query<SiteControllersForSiteQueryResult, SiteControllersForSiteQueryVariables>(
        SiteControllersForSiteDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getSiteDetails(
    variables?: GetSiteDetailsQueryVariables,
  ): Observable<GetSiteDetailsQueryResult> {
    return this.client
      .query<GetSiteDetailsQueryResult, GetSiteDetailsQueryVariables>(
        GetSiteDetailsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteSiteFromDetails(
    variables?: DeleteSiteFromDetailsMutationVariables,
  ): Observable<DeleteSiteFromDetailsMutationResult> {
    return this.client
      .query<DeleteSiteFromDetailsMutationResult, DeleteSiteFromDetailsMutationVariables>(
        DeleteSiteFromDetailsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  mySitesAndOrganizations(
    variables?: MySitesAndOrganizationsQueryVariables,
  ): Observable<MySitesAndOrganizationsQueryResult> {
    return this.client
      .query<MySitesAndOrganizationsQueryResult, MySitesAndOrganizationsQueryVariables>(
        MySitesAndOrganizationsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getThermostat(
    variables?: GetThermostatQueryVariables,
  ): Observable<GetThermostatQueryResult> {
    return this.client
      .query<GetThermostatQueryResult, GetThermostatQueryVariables>(
        GetThermostatDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getThermostatDetails(
    variables?: GetThermostatDetailsQueryVariables,
  ): Observable<GetThermostatDetailsQueryResult> {
    return this.client
      .query<GetThermostatDetailsQueryResult, GetThermostatDetailsQueryVariables>(
        GetThermostatDetailsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  controlThermostat(
    variables?: ControlThermostatMutationVariables,
  ): Observable<ControlThermostatMutationResult> {
    return this.client
      .query<ControlThermostatMutationResult, ControlThermostatMutationVariables>(
        ControlThermostatDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteThermostat(
    variables?: DeleteThermostatMutationVariables,
  ): Observable<DeleteThermostatMutationResult> {
    return this.client
      .query<DeleteThermostatMutationResult, DeleteThermostatMutationVariables>(
        DeleteThermostatDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  pingThermostat(
    variables?: PingThermostatMutationVariables,
  ): Observable<PingThermostatMutationResult> {
    return this.client
      .query<PingThermostatMutationResult, PingThermostatMutationVariables>(
        PingThermostatDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  checkThermostatSignalStrength(
    variables?: CheckThermostatSignalStrengthMutationVariables,
  ): Observable<CheckThermostatSignalStrengthMutationResult> {
    return this.client
      .query<
        CheckThermostatSignalStrengthMutationResult,
        CheckThermostatSignalStrengthMutationVariables
      >(CheckThermostatSignalStrengthDocument, variables)
      .pipe(map(response => response.data));
  }

  getThermostats(
    variables?: GetThermostatsQueryVariables,
  ): Observable<GetThermostatsQueryResult> {
    return this.client
      .query<GetThermostatsQueryResult, GetThermostatsQueryVariables>(
        GetThermostatsDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getThermostatsSiteControllers(
    variables?: GetThermostatsSiteControllersQueryVariables,
  ): Observable<GetThermostatsSiteControllersQueryResult> {
    return this.client
      .query<
        GetThermostatsSiteControllersQueryResult,
        GetThermostatsSiteControllersQueryVariables
      >(GetThermostatsSiteControllersDocument, variables)
      .pipe(map(response => response.data));
  }

  getSnapAddresses(
    variables?: GetSnapAddressesQueryVariables,
  ): Observable<GetSnapAddressesQueryResult> {
    return this.client
      .query<GetSnapAddressesQueryResult, GetSnapAddressesQueryVariables>(
        GetSnapAddressesDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createThermostat(
    variables?: CreateThermostatMutationVariables,
  ): Observable<CreateThermostatMutationResult> {
    return this.client
      .query<CreateThermostatMutationResult, CreateThermostatMutationVariables>(
        CreateThermostatDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateThermostat(
    variables?: UpdateThermostatMutationVariables,
  ): Observable<UpdateThermostatMutationResult> {
    return this.client
      .query<UpdateThermostatMutationResult, UpdateThermostatMutationVariables>(
        UpdateThermostatDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createTrigger(
    variables?: CreateTriggerMutationVariables,
  ): Observable<CreateTriggerMutationResult> {
    return this.client
      .query<CreateTriggerMutationResult, CreateTriggerMutationVariables>(
        CreateTriggerDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteTrigger(
    variables?: DeleteTriggerMutationVariables,
  ): Observable<DeleteTriggerMutationResult> {
    return this.client
      .query<DeleteTriggerMutationResult, DeleteTriggerMutationVariables>(
        DeleteTriggerDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateTrigger(
    variables?: UpdateTriggerMutationVariables,
  ): Observable<UpdateTriggerMutationResult> {
    return this.client
      .query<UpdateTriggerMutationResult, UpdateTriggerMutationVariables>(
        UpdateTriggerDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllTriggers(
    variables?: GetAllTriggersQueryVariables,
  ): Observable<GetAllTriggersQueryResult> {
    return this.client
      .query<GetAllTriggersQueryResult, GetAllTriggersQueryVariables>(
        GetAllTriggersDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllMetricSources(
    variables?: GetAllMetricSourcesQueryVariables,
  ): Observable<GetAllMetricSourcesQueryResult> {
    return this.client
      .query<GetAllMetricSourcesQueryResult, GetAllMetricSourcesQueryVariables>(
        GetAllMetricSourcesDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllTriggersAndMetricSources(
    variables?: GetAllTriggersAndMetricSourcesQueryVariables,
  ): Observable<GetAllTriggersAndMetricSourcesQueryResult> {
    return this.client
      .query<
        GetAllTriggersAndMetricSourcesQueryResult,
        GetAllTriggersAndMetricSourcesQueryVariables
      >(GetAllTriggersAndMetricSourcesDocument, variables)
      .pipe(map(response => response.data));
  }

  getUtilityServices(
    variables?: GetUtilityServicesQueryVariables,
  ): Observable<GetUtilityServicesQueryResult> {
    return this.client
      .query<GetUtilityServicesQueryResult, GetUtilityServicesQueryVariables>(
        GetUtilityServicesDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getBillingPeriod(
    variables?: GetBillingPeriodQueryVariables,
  ): Observable<GetBillingPeriodQueryResult> {
    return this.client
      .query<GetBillingPeriodQueryResult, GetBillingPeriodQueryVariables>(
        GetBillingPeriodDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createUtilityService(
    variables?: CreateUtilityServiceMutationVariables,
  ): Observable<CreateUtilityServiceMutationResult> {
    return this.client
      .query<CreateUtilityServiceMutationResult, CreateUtilityServiceMutationVariables>(
        CreateUtilityServiceDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateUtilityService(
    variables?: UpdateUtilityServiceMutationVariables,
  ): Observable<UpdateUtilityServiceMutationResult> {
    return this.client
      .query<UpdateUtilityServiceMutationResult, UpdateUtilityServiceMutationVariables>(
        UpdateUtilityServiceDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getUtilityServicesAndThings(
    variables?: GetUtilityServicesAndThingsQueryVariables,
  ): Observable<GetUtilityServicesAndThingsQueryResult> {
    return this.client
      .query<
        GetUtilityServicesAndThingsQueryResult,
        GetUtilityServicesAndThingsQueryVariables
      >(GetUtilityServicesAndThingsDocument, variables)
      .pipe(map(response => response.data));
  }

  deleteUtilityService(
    variables?: DeleteUtilityServiceMutationVariables,
  ): Observable<DeleteUtilityServiceMutationResult> {
    return this.client
      .query<DeleteUtilityServiceMutationResult, DeleteUtilityServiceMutationVariables>(
        DeleteUtilityServiceDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createScheduledUtilityRate(
    variables?: CreateScheduledUtilityRateMutationVariables,
  ): Observable<CreateScheduledUtilityRateMutationResult> {
    return this.client
      .query<
        CreateScheduledUtilityRateMutationResult,
        CreateScheduledUtilityRateMutationVariables
      >(CreateScheduledUtilityRateDocument, variables)
      .pipe(map(response => response.data));
  }

  deleteScheduledUtilityRate(
    variables?: DeleteScheduledUtilityRateMutationVariables,
  ): Observable<DeleteScheduledUtilityRateMutationResult> {
    return this.client
      .query<
        DeleteScheduledUtilityRateMutationResult,
        DeleteScheduledUtilityRateMutationVariables
      >(DeleteScheduledUtilityRateDocument, variables)
      .pipe(map(response => response.data));
  }

  applyBehavior(
    variables?: ApplyBehaviorMutationVariables,
  ): Observable<ApplyBehaviorMutationResult> {
    return this.client
      .query<ApplyBehaviorMutationResult, ApplyBehaviorMutationVariables>(
        ApplyBehaviorDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  applyScene(
    variables?: ApplySceneMutationVariables,
  ): Observable<ApplySceneMutationResult> {
    return this.client
      .query<ApplySceneMutationResult, ApplySceneMutationVariables>(
        ApplySceneDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  applySequenceScene(
    variables?: ApplySequenceSceneMutationVariables,
  ): Observable<ApplySequenceSceneMutationResult> {
    return this.client
      .query<ApplySequenceSceneMutationResult, ApplySequenceSceneMutationVariables>(
        ApplySequenceSceneDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getAllUsersAsAdmin(
    variables?: GetAllUsersAsAdminQueryVariables,
  ): Observable<GetAllUsersAsAdminQueryResult> {
    return this.client
      .query<GetAllUsersAsAdminQueryResult, GetAllUsersAsAdminQueryVariables>(
        GetAllUsersAsAdminDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getUserAsAdmin(
    variables?: GetUserAsAdminQueryVariables,
  ): Observable<GetUserAsAdminQueryResult> {
    return this.client
      .query<GetUserAsAdminQueryResult, GetUserAsAdminQueryVariables>(
        GetUserAsAdminDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  getUserSites(
    variables?: GetUserSitesQueryVariables,
  ): Observable<GetUserSitesQueryResult> {
    return this.client
      .query<GetUserSitesQueryResult, GetUserSitesQueryVariables>(
        GetUserSitesDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  createUserAsAdmin(
    variables?: CreateUserAsAdminMutationVariables,
  ): Observable<CreateUserAsAdminMutationResult> {
    return this.client
      .query<CreateUserAsAdminMutationResult, CreateUserAsAdminMutationVariables>(
        CreateUserAsAdminDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  updateUserAsAdmin(
    variables?: UpdateUserAsAdminMutationVariables,
  ): Observable<UpdateUserAsAdminMutationResult> {
    return this.client
      .query<UpdateUserAsAdminMutationResult, UpdateUserAsAdminMutationVariables>(
        UpdateUserAsAdminDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  deleteUserAsAdmin(
    variables?: DeleteUserAsAdminMutationVariables,
  ): Observable<DeleteUserAsAdminMutationResult> {
    return this.client
      .query<DeleteUserAsAdminMutationResult, DeleteUserAsAdminMutationVariables>(
        DeleteUserAsAdminDocument,
        variables,
      )
      .pipe(map(response => response.data));
  }

  triggerResetPasswordAsAdmin(
    variables?: TriggerResetPasswordAsAdminMutationVariables,
  ): Observable<TriggerResetPasswordAsAdminMutationResult> {
    return this.client
      .query<
        TriggerResetPasswordAsAdminMutationResult,
        TriggerResetPasswordAsAdminMutationVariables
      >(TriggerResetPasswordAsAdminDocument, variables)
      .pipe(map(response => response.data));
  }
}
