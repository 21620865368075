import { Component } from '@angular/core';

@Component({
  selector: 'sui-nav-drawer-footer',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        padding: 16px;
      }

      :host ::ng-deep a {
        text-decoration: none;
        color: var(--color-500);
        font-size: 12px;
        padding: 0 6px;
      }

      :host ::ng-deep a:first-child {
        padding-left: 0;
      }

      :host ::ng-deep a:last-child {
        padding-right: 0;
      }
    `,
  ],
})
export class NavDrawerFooterComponent {}
