import {
  Component,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { LightSuiModel as LightModel } from '@spog-ui/shared/models/lights';
import {
  ControllerTypeModel,
  DEFAULT_FIXTURE_TYPE,
  DeviceStatusTable,
  FixtureTypeModel,
  LightCreateProps,
  LightUpdateProps,
} from '../../models';
import { markAsTouched, UniqueNameBaseDirective } from '@spog-ui/shared/components';
import { formatSnapAddress } from '@spog-ui/shared/directives/unique-snapaddrs-validator';
import { ZONE_ALL_ID, ZoneModel } from '@spog-ui/shared/models/zones';

@Component({
  selector: 'lit-light-form',
  template: `
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <mat-form-field id="suiLightNameInput">
        <input
          formControlName="name"
          matInput
          type="text"
          placeholder="Name"
          [litUniqueLightName]="lights | litExcept : activeLightId"
          />
        <mat-error [suiFormError]="nameControl.errors"></mat-error>
      </mat-form-field>
    
      <mat-form-field id="suiSnapAddrInput">
        <input
          formControlName="snapaddr"
          matInput
          type="text"
          placeholder="SNAP Address"
          [litCanAddLight]="canHaveLightConfigured$ | async"
          suiSnapAddress
          />
        <mat-error [suiFormError]="snapaddrControl.errors"></mat-error>
      </mat-form-field>
    
      <mat-form-field id="suiControllerTypeInput">
        <mat-select formControlName="controllerType" placeholder="Controller Type">
          @for (controllerType of controllerTypes; track selectId($index, controllerType)) {
            <mat-option
              [value]="controllerType.id"
              >
              {{ controllerType.name }}
            </mat-option>
          }
        </mat-select>
        <mat-error [suiFormError]="controllerTypeControl.errors"></mat-error>
      </mat-form-field>
    
      <mat-form-field id="suiZonesInput">
        <mat-select formControlName="zones" placeholder="Zones" multiple litZoneLimit>
          @for (zone of zones; track selectId($index, zone)) {
            <mat-option
              [value]="zone.id"
              [disabled]="zone.id === zoneAllId"
              >
              {{ zone.name }}
            </mat-option>
          }
        </mat-select>
        <mat-error [suiFormError]="zonesControl.errors"></mat-error>
      </mat-form-field>
    
      <mat-expansion-panel>
        <mat-expansion-panel-header>Advanced Options</mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <label>High End Trim</label>
          <p>
            Maximum power output for this light, as a percentage of its total capacity.
            The effective light level is the product of the high-end trim and the
            user-requested level (e.g. if the user sets a light level of 100, but the
            high-end trim is set to 80, the actual, effective light level will be 80.
          </p>
          <sui-dimmer-control formControlName="highEndTrim"></sui-dimmer-control>
    
          <mat-divider></mat-divider>
    
          <label>Initial Level</label>
          <p>The light level set when a light is first powered on</p>
          <sui-dimmer-control formControlName="powerOnLevel"></sui-dimmer-control>
    
          <mat-divider></mat-divider>
    
          <mat-form-field>
            <mat-select formControlName="fixtureType" placeholder="Fixture Type">
              @for (fixtureType of fixtureTypes; track selectId($index, fixtureType)) {
                <mat-option
                  [value]="fixtureType.id"
                  >
                  {{ fixtureType.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </ng-template>
      </mat-expansion-panel>
    
      <div class="litLightFormActions">
        <button
          mat-raised-button
          color="accent"
          type="submit"
          id="suiLightFormSaveButton"
          >
          {{ isEditMode ? 'Save Light' : 'Create Light' }}
        </button>
    
        <button
          mat-button
          type="button"
          (click)="cancel.emit()"
          id="suiLightFormCancelButton"
          >
          Cancel
        </button>
      </div>
    </form>
    `,
  styles: [
    `
      form {
        max-width: 450px;
        margin: 60px auto 20px;
        display: flex;
        flex-direction: column;
      }

      mat-form-field {
        margin-bottom: 20px;
      }

      .litLightFormActions {
        display: flex;
        flex-direction: row-reverse;
        margin: 18px 0 0;
      }

      mat-expansion-panel {
        margin-top: 8px;
      }

      mat-divider {
        margin-bottom: 18px;
      }
    `,
  ],
})
export class LightFormComponent implements OnInit {
  deviceStatusTable$ = new BehaviorSubject<DeviceStatusTable>({});
  disabled$ = new BehaviorSubject<boolean>(false);
  isEditMode = false;
  activeLightId: string | null = null;
  zoneAllId = ZONE_ALL_ID;
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    snapaddr: new UntypedFormControl('', Validators.required),
    controllerType: new UntypedFormControl(undefined, Validators.required),
    highEndTrim: new UntypedFormControl(100),
    powerOnLevel: new UntypedFormControl(100),
    fixtureType: new UntypedFormControl(DEFAULT_FIXTURE_TYPE),
    zones: new UntypedFormControl([ZONE_ALL_ID]),
  });
  canHaveLightConfigured$: Observable<boolean>;

  @Output() save = new EventEmitter<LightCreateProps>();
  @Output() update = new EventEmitter<LightUpdateProps>();
  @Output() cancel = new EventEmitter();
  @Input() lights: LightModel[] = [];
  @Input() controllerTypes: ControllerTypeModel[] = [];
  @Input() fixtureTypes: FixtureTypeModel[] = [];
  @Input() zones: ZoneModel[] = [];
  @Input() set deviceStatusTable(value: DeviceStatusTable) {
    this.deviceStatusTable$.next(value);
  }
  @Input() set pending(isPending: boolean) {
    isPending ? this.form.disable() : this.form.enable();

    this.disabled$.next(isPending);
    this.disableUneditableFieldsIfInEditMode();
  }
  @Input() set light({ id, zones, ...changes }: LightUpdateProps) {
    this.isEditMode = true;
    this.activeLightId = id;
    this.form.patchValue({
      ...changes,
      zones: zones.map(zone => zone.id),
    });
    this.disableUneditableFieldsIfInEditMode();
  }

  selectId = (_: number, value: { id: string }) => value.id;

  get nameControl(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  get snapaddrControl(): UntypedFormControl {
    return this.form.get('snapaddr') as UntypedFormControl;
  }

  get controllerTypeControl(): UntypedFormControl {
    return this.form.get('controllerType') as UntypedFormControl;
  }

  get highEndTrimControl(): UntypedFormControl {
    return this.form.get('highEndTrim') as UntypedFormControl;
  }

  get powerOnLevelControl(): UntypedFormControl {
    return this.form.get('powerOnLevel') as UntypedFormControl;
  }

  get fixtureTypeControl(): UntypedFormControl {
    return this.form.get('fixtureType') as UntypedFormControl;
  }

  get zonesControl(): UntypedFormControl {
    return this.form.get('zones') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.canHaveLightConfigured$ = this.isEditMode
      ? of(false)
      : combineLatest([
          this.snapaddrControl.valueChanges.pipe(
            map((snapaddr: string) => formatSnapAddress(snapaddr)),
          ),
          this.deviceStatusTable$,
          this.disabled$,
        ]).pipe(
          filter(([snapaddr, deviceStatusTable, disabled]) => !disabled),
          map(([snapaddr, deviceStatusTable]) => deviceStatusTable[snapaddr]),
          tap(status => {
            if (!status) {
              this.controllerTypeControl.enable();
            } else {
              this.controllerTypeControl.patchValue(status.controllerType);
              this.controllerTypeControl.disable();
            }
          }),
          map(status => {
            return status ? status.canHaveLightConfigured : true;
          }),
          distinctUntilChanged(),
        );
  }

  disableUneditableFieldsIfInEditMode(): void {
    if (!this.isEditMode) return;

    this.snapaddrControl.disable();
    this.controllerTypeControl.disable();
  }

  onSubmit(): void {
    markAsTouched(this.form);

    if (!this.form.valid) return;

    const value = this.form.value;
    const zones = (value.zones as string[])
      .map(zoneId => this.zones.find(zone => zone.id === zoneId))
      .filter((zone: ZoneModel | undefined): zone is ZoneModel => Boolean(zone));

    if (this.activeLightId) {
      this.update.emit({
        ...value,
        id: this.activeLightId,
        controllerType: this.controllerTypeControl.value,
        snapaddr: this.snapaddrControl.value,
        zones,
      });
    } else {
      this.save.emit({
        ...value,
        controllerType: this.controllerTypeControl.value,
        zones,
      });
    }
  }
}

@Directive({
  selector: '[litCanAddLight]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CanAddLightDirective,
    },
  ],
})
export class CanAddLightDirective implements Validator {
  valid = true;
  onValidatorChange = (): void => void 0;

  @Input('litCanAddLight') set canAddLight(canAddLight: boolean) {
    this.valid = canAddLight;
    this.onValidatorChange();
  }

  validate(): ValidationErrors | null {
    return this.valid
      ? null
      : {
          litCanAddLight: 'Invalid',
        };
  }

  registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }
}

@Directive({
  selector: '[litUniqueLightName]',
  providers: [
    { provide: NG_VALIDATORS, multi: true, useExisting: UniqueLightNameDirective },
  ],
})
export class UniqueLightNameDirective extends UniqueNameBaseDirective {
  lightNames$: BehaviorSubject<string[]>;

  @Input('litUniqueLightName') set lights(lights: LightModel[]) {
    this.lightNames$.next(lights.map(light => light.name));
  }

  constructor() {
    const lightNames$ = new BehaviorSubject<string[]>([]);

    super(lightNames$);

    this.lightNames$ = lightNames$;
  }
}

@Directive({
  selector: '[litZoneLimit]',
  providers: [{ provide: NG_VALIDATORS, multi: true, useExisting: ZoneLimitDirective }],
})
export class ZoneLimitDirective implements Validator {
  static ZONE_LIMIT = 18;

  validate(control: AbstractControl): ValidationErrors | null {
    const value: any[] = control.value;

    return value.length <= ZoneLimitDirective.ZONE_LIMIT
      ? null
      : {
          litZoneLimit: 'Invalid',
        };
  }
}

@Pipe({ name: 'litExcept' })
export class ExceptPipe implements PipeTransform {
  transform<T extends { id: string }>(collection: T[], idToFilter: string | null): T[] {
    if (!idToFilter) return collection;

    return collection.filter(item => item.id !== idToFilter);
  }
}
