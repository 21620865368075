import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { animationFrameScheduler, BehaviorSubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  observeOn,
} from 'rxjs/operators';

@Component({
  selector: 'sui-charm-filter',
  template: `
    <sui-charm-filter-internal
      *suiCharm
      [term]="(term$ | async) || ''"
      [type]="(type$ | async) || ''"
      [options]="options"
      (filter)="filter.emit($event)"
    >
    </sui-charm-filter-internal>
  `,
})
export class CharmFilterComponent {
  termChanges$ = new BehaviorSubject<string>('');
  typeChanges$ = new BehaviorSubject<string>('');
  term$ = this.termChanges$.pipe(observeOn(animationFrameScheduler));
  type$ = this.typeChanges$.pipe(observeOn(animationFrameScheduler));

  @Input() options: string[];
  @Input() set term(term: string | null) {
    this.termChanges$.next(term ?? '');
  }
  @Input() set type(type: string) {
    this.typeChanges$.next(type);
  }
  @Output() filter = new EventEmitter<string>();
}

@Injectable({ providedIn: 'root' })
export class CharmFilterInternalComponentSettings {
  debounce = true;
}

@Component({
  selector: 'sui-charm-filter-internal',
  template: `
    <div class="suiCharmFilterWrapper" [class.suiCharmFilterExpanded]="expanded">
      <button
        (click)="onClick()"
        mat-icon-button
        color="primary"
        [attr.aria-label]="'Search ' + type"
        color="default"
      >
        <mat-icon *ngIf="!expanded">search</mat-icon>
        <mat-icon *ngIf="expanded">close</mat-icon>
      </button>
      <input
        type="text"
        [value]="value"
        [placeholder]="'Search ' + type"
        (input)="onInput()"
        (blur)="onBlur()"
        #input
        matInput
        [formControl]="searchControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 52px;
        height: 40px;
        position: relative;
      }

      .suiCharmFilterWrapper {
        padding-left: 8px;
        border-radius: 64px;
        display: flex;
        align-items: center;
        background-color: transparent;
        transition: background-color 300ms;
        position: absolute;
        top: 0px;
        right: 0px;
      }

      .suiCharmFilterWrapper.suiCharmFilterExpanded {
        background-color: var(--color-background-card);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
      }

      button {
        position: relative;
        left: -6px;
      }

      input {
        width: 0px;
        background-color: transparent;
        border: none;
        outline: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.0025em;
        color: var(--color-foreground-text);
        transition: width 300ms;
      }

      .suiCharmFilterExpanded input {
        width: 180px;
      }
    `,
  ],
})
export class CharmFilterInternalComponent {
  valueChanges$ = new BehaviorSubject({ value: '', emitChangeEvent: false });
  expanded = false;
  filteredOptions: string[];
  searchControl = new UntypedFormControl();

  @Input() set term(value: string) {
    this.valueChanges$.next({ value, emitChangeEvent: false });

    if (value.trim()) this.expanded = true;
  }
  @Input() type = '';
  @Input() options: string[];
  @ViewChild('input') inputElementRef: ElementRef<HTMLInputElement>;
  @Output() filter = this.valueChanges$.pipe(
    filter(event => event.emitChangeEvent),
    map(event => event.value),
    this.settings.debounce ? debounceTime(300) : v$ => v$,
    distinctUntilChanged(),
  );

  constructor(
    readonly changeDetectorRef: ChangeDetectorRef,
    readonly settings: CharmFilterInternalComponentSettings,
  ) {}

  get value() {
    return this.valueChanges$.getValue().value;
  }

  onInput() {
    const value = this.inputElementRef.nativeElement.value;
    this.valueChanges$.next({
      value: value,
      emitChangeEvent: true,
    });
    this.filteredOptions = this.options.filter(
      option => option.toLowerCase().indexOf(value.toLowerCase()) === 0,
    );
  }

  onClick() {
    if (this.expanded) {
      this.expanded = false;
      this.valueChanges$.next({ value: '', emitChangeEvent: true });
      this.inputElementRef.nativeElement.value = '';
      this.inputElementRef.nativeElement.blur();
    } else {
      this.expanded = true;
      this.inputElementRef.nativeElement.focus();
    }

    this.changeDetectorRef.detectChanges();
  }

  onBlur() {
    const trimmedValue = this.value.trim();

    if (trimmedValue) return;

    this.expanded = false;
    this.changeDetectorRef.detectChanges();
  }
}
