import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { AccordionComponent } from './accordion.component';
import { ExpansionComponent } from './expansion.component';
import { ExpansionDescriptionComponent } from './expansion-description.component';
import { ExpansionTitleComponent } from './expansion-title.component';

@NgModule({
  imports: [MatExpansionModule, MatInputModule],
  declarations: [
    AccordionComponent,
    ExpansionComponent,
    ExpansionDescriptionComponent,
    ExpansionTitleComponent,
  ],
  exports: [
    AccordionComponent,
    ExpansionComponent,
    ExpansionDescriptionComponent,
    ExpansionTitleComponent,
  ],
})
export class SuiExpansionModule {}
