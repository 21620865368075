import { Directive, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';
import { startWith, switchMap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[suiInputMatches]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputMatchesDirective,
    },
  ],
})
export class InputMatchesDirective implements OnDestroy, Validator {
  compareControls$ = new Subject<UntypedFormControl>();
  onValidatorChangeSubscription: Subscription;
  onValidatorChangeFn: () => void;
  compareTo: unknown;

  @Input()
  set suiInputMatches(control: UntypedFormControl) {
    this.compareControls$.next(control);
  }

  constructor() {
    this.onValidatorChangeSubscription = this.compareControls$
      .pipe(switchMap(control => control.valueChanges.pipe(startWith(control.value))))
      .subscribe(value => {
        this.compareTo = value;

        if (this.onValidatorChangeFn) {
          this.onValidatorChangeFn();
        }
      });
  }

  validate(control: AbstractControl) {
    const value = control.value;

    return this.compareTo === value
      ? null
      : {
          suiInputMatches: { value, comparedTo: this.compareTo },
        };
  }

  registerOnValidatorChange(onValidatorChangeFn: () => void) {
    this.onValidatorChangeFn = onValidatorChangeFn;
  }

  ngOnDestroy() {
    if (this.onValidatorChangeSubscription) {
      this.onValidatorChangeSubscription.unsubscribe();
    }
  }
}
