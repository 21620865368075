import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'sui-error-card',
  template: `
    <sui-empty-state color="warn">
      <ng-template suiEmptyStateTitle>
        <div *ngIf="!customTitle">Loading Error</div>
        <div *ngIf="customTitle">
          {{ customTitle }}
        </div>
      </ng-template>
      <ng-template suiEmptyStateMessage>
        <div *ngIf="!customErrorMessage">
          A error has occurred loading your {{ item }}.
        </div>
        <div *ngIf="customErrorMessage">{{ customErrorMessage }}</div>
        <div *ngIf="showSupportLink" class="supportLink">
          Please contact Synapse at:
          <a href="https://support.synapsewireless.com/" target="_blank"
            >support.synapsewireless.com</a
          >.
        </div></ng-template
      >

      <ng-template suiEmptyStateActions>
        <div *ngIf="actionName">
          <button
            id="action"
            class="action"
            type="button"
            mat-raised-button
            (click)="onAction()"
          >
            {{ actionName }}
          </button>
        </div>
      </ng-template>
    </sui-empty-state>
  `,
  styles: [
    `
      :host {
        display: block;
        width: calc(100% - 40px);
        max-width: 560px;
        margin: 0 auto;
      }

      span {
        white-space: nowrap;
      }

      .supportLink {
        padding-top: 16px;
      }

      a:link,
      a:visited {
        color: var(--color-contrast-500);
        font-weight: 500px;
      }

      .action {
        background-color: var(--color-warn-900);
      }
    `,
  ],
})
export class ErrorCardComponent {
  @Input() customTitle: string;
  @Input() item = 'item';
  @Input() customErrorMessage: string;
  @Input() showSupportLink = true;
  @Input() actionName: string;
  @Output() action = new EventEmitter();
  @HostBinding('attr.role') role = 'alert';

  onAction() {
    this.action.emit();
  }
}
