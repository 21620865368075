import { Component } from '@angular/core';

@Component({
  selector: 'sui-card-list',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host ::ng-deep sui-card {
        box-shadow: none;
        border-radius: 0;
        border-top: 1px solid var(--color-hint-text);
      }
    `,
  ],
})
export class CardListComponent {}
