import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import * as ColorPickerActions from './color-picker.actions';
import { ColorPickerStore } from './color-picker.store';

@Component({
  selector: 'clr-hex-input',
  template: `
    <input
      type="text"
      [value]="hexInputValue$ | async"
      [disabled]="isDisabled$ | async"
      (input)="onInput($event)"
      (change)="onChange($event)"
      (blur)="onBlur()"
    />
    <span class="error" *ngIf="(isValid$ | async) === false">
      Hex strings must be 11 characters long and start with a hash (#).
    </span>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
      }

      input {
        font-family: 'Roboto Mono', monospace;
        font-size: 12px;
        color: var(--color-foreground-text);
        background-color: transparent;
        border: 1px solid var(--color-foreground-text);
        border-radius: 4px;
        padding: 8px 16px;
        /**
         * The width of 11 characters (hash + 5 color channels)
         * plus 32px from the left and right padding and an
         * additional 2px from the border
         */
        width: calc(11ch + 32px + 2px);
      }

      .error {
        color: #f44336;
        font-size: 12px;
        margin-top: 4px;
      }
    `,
  ],
})
export class HexInputComponent {
  hexInputValue$: Observable<string> = this.store.hexInputValue$;
  isDisabled$: Observable<boolean> = this.store.isDisabled$;
  isValid$: Observable<boolean> = this.store.hexInputIsValid$;

  constructor(readonly store: ColorPickerStore) {}

  onInput($event: Event) {
    $event.stopImmediatePropagation();

    const input = $event.target as HTMLInputElement;

    this.store.dispatch(ColorPickerActions.userInputsHexValue(input.value));
  }

  /**
   * The root <clr-color-picker /> component exposes a `change` event
   * that is fired whenever the user changes the color.
   *
   * We need to capture and prevent this event from bubbling up to the
   * parent <clr-color-picker /> component.
   */
  onChange($event: Event) {
    $event.stopImmediatePropagation();
  }

  onBlur() {
    this.store.dispatch(ColorPickerActions.userBlursHexInput());
  }
}
