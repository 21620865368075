import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiAlertModule } from '../alert';
import { SuiAccordionModule } from '../accordion';
import { ErrorMessagesComponent } from './components/error-messages.component';
import { FormControlComponent } from './components/form-control.component';
import { FormGroupAccordionComponent } from './components/form-group-accordion.component';
import { FormHeaderComponent } from './components/form-header.component';
import { FormWrapperComponent } from './components/form-wrapper.component';
import { LabelComponent } from './components/label.component';
import { SplitFormControlComponent } from './components/split-form-control.component';
import { ToggleControlComponent } from './components/toggle-control.component';
import { ToggleOptionComponent } from './components/toggle-option.component';

export const COMPONENTS = [
  ErrorMessagesComponent,
  FormControlComponent,
  FormGroupAccordionComponent,
  FormHeaderComponent,
  FormWrapperComponent,
  LabelComponent,
  SplitFormControlComponent,
  ToggleControlComponent,
  ToggleOptionComponent,
];

@NgModule({
  imports: [CommonModule, SuiAlertModule, SuiAccordionModule],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class FormModule {}
