import { startWith } from 'rxjs/operators';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import tinycolor from 'tinycolor2';

@Component({
  selector: 'sui-color-luminance-control',
  template: `
    <sui-color-slider
      [min]="0"
      [max]="100"
      [value]="value"
      [gradient]="luminance"
      (update)="updateLuminance($event)"
    ></sui-color-slider>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ColorLuminanceControlComponent,
    },
  ],
})
export class ColorLuminanceControlComponent implements ControlValueAccessor, OnInit {
  onChange: (value: any) => any;
  onTouched: () => any;
  value: number;
  disabled = false;
  hue: number;
  saturation: number;
  luminance = [
    [0, 'black'],
    [0.5, 'red'],
    [1, 'white'],
  ];

  @Input() hueControl: UntypedFormControl;
  @Input() saturationControl: UntypedFormControl;

  updateLuminance(hue: number): void {
    this.value = hue;
    this.updateValue(this.value);
  }

  updateLuminanceRange(): void {
    const hueAndSaturationColor = tinycolor({ h: this.hue, s: this.saturation, l: 50 });
    this.luminance = [
      [0, 'black'],
      [0.5, hueAndSaturationColor.toRgbString()],
      [1, 'white'],
    ];
  }

  updateValue(value: number): void {
    this.onChange(this.value);
    this.value = value;
  }

  ngOnInit(): void {
    this.hueControl.valueChanges.pipe(startWith(this.hueControl.value)).subscribe(hue => {
      this.hue = hue;
      this.updateLuminanceRange();
    });

    this.saturationControl.valueChanges
      .pipe(startWith(this.saturationControl.value))
      .subscribe(saturation => {
        this.saturation = saturation;
        this.updateLuminanceRange();
      });
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}

export const COLOR_LUMINANCE_CONTROL_DIRECTIVES = [ColorLuminanceControlComponent];
