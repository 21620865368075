import {
  PermissionGroupGQLModel,
  PermissionGroupInternalModel,
  PermissionGroupFormModel,
  PermissionGroupWSModel,
} from './permission-group.model';
import { PermissionGroupSiteRole } from '@spog-ui/graphql/types';

export function createMockPermissionGroupModel(
  updates?: Partial<PermissionGroupInternalModel>,
): PermissionGroupInternalModel {
  return {
    id: '12345',
    name: 'test permission group',
    siteIds: [],
    userIds: [],
    siteRole: PermissionGroupSiteRole.MEMBER,
    ...updates,
  };
}

export function createMockGQLPermissionGroupModel(
  updates?: Partial<PermissionGroupGQLModel>,
): PermissionGroupGQLModel {
  return {
    id: '12345',
    name: 'test permission group',
    sites: [],
    users: [],
    siteRole: PermissionGroupSiteRole.MEMBER,
    ...updates,
  };
}

export function createMockPermissionGroupFormModel(
  updates: Partial<PermissionGroupFormModel> = {},
): PermissionGroupFormModel {
  return {
    name: 'Mock PermissionGroup',
    siteIds: [],
    userIds: [],
    siteRole: PermissionGroupSiteRole.MEMBER,
    ...updates,
  };
}

export function createMockPermissionGroupWSModel(
  updates?: Partial<PermissionGroupWSModel>,
): PermissionGroupWSModel {
  return {
    id: '12345',
    organizationId: '54321',
    name: 'test permission group',
    siteRole: 'MEMBER',
    siteIds: [],
    userIds: [],
    createdAt: '2022-06-21T15:13:00-05:00',
    updatedAt: '2022-06-21T15:13:00-05:00',
    ...updates,
  };
}
