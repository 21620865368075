import { Component } from '@angular/core';

@Component({
  selector: 'sui-badge',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        height: 36px;
        background-color: var(--color-warn-500);
        color: var(--color-background-card);
        border-radius: 2px;
        border: 0 none;
        padding: 5px 7px 5px 7px;
        font-size: 9px;
        font-weight: 500;
        margin: 4px;
      }
    `,
  ],
})
export class BadgeComponent {}

export const BADGE_DIRECTIVES = [BadgeComponent];
