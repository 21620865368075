import { Component } from '@angular/core';

@Component({
  selector: 'sui-expansion-description',
  template: `
    <mat-panel-description>
      <ng-content></ng-content>
    </mat-panel-description>
  `,
})
export class ExpansionDescriptionComponent {}
