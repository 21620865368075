import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessDeniedComponent } from './access-denied.component';
import { SuiEmptyStateModule } from '../empty-state';

@NgModule({
  imports: [CommonModule, SuiEmptyStateModule],
  declarations: [AccessDeniedComponent],
  exports: [AccessDeniedComponent],
})
export class SuiAccessDeniedModule {}
