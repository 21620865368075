import { Component, HostBinding, Input } from '@angular/core';
import { ToggleControlComponent, ToggleOption } from './toggle-control.component';

@Component({
  selector: 'sui-toggle-option',
  template: `
    <label [class.toggled]="toggled" [class.multi]="multi">
      <input
        [disabled]="disabled"
        type="radio"
        name="options"
        autocomplete="off"
        *ngIf="!multi"
        (click)="onClick()"
      />
      <input
        [disabled]="disabled"
        type="checkbox"
        name="options"
        autocomplete="off"
        *ngIf="multi"
        (click)="onClick()"
      />
      <ng-content></ng-content>
    </label>
  `,
  styles: [
    `
      label {
        display: block;
        padding: 3px 10px;
        background-color: var(--color-700);
        color: var(--color-contrast-700);
        text-align: center;
        min-width: 100px;
        border: 2px solid var(--color-700);
        margin: 0;
        cursor: pointer !important;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }

      @media (max-width: 420px) {
        label {
          padding: 3px 6px;
        }
      }

      label.multi {
        min-width: auto;
      }

      label:not(.toggled) {
        background-color: var(--color-background-card);
        color: var(--color-foreground-text);
        border-color: var(--color-foreground-divider);
        box-shadow: none;
        opacity: 0.8;
        transition: opacity 250ms;
      }

      :host(:not(.controlDisabled)) label:not(.toggled):hover {
        opacity: 1;
      }

      input {
        display: none;
      }

      :host(:first-child) label {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      :host(:last-child) label {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      :host(.controlDisabled) label {
        opacity: 0.5;
        cursor: pointer !important;
      }
    `,
  ],
})
export class ToggleOptionComponent implements ToggleOption {
  toggled = false;
  _disabled = false;

  @Input()
  value: any;

  @HostBinding('class.controlDisabled')
  @Input()
  set disabled(val: boolean) {
    this._disabled = val;
  }
  get disabled() {
    return this.parent.disabled || this._disabled;
  }

  constructor(private parent: ToggleControlComponent) {
    this.parent.register(this);
  }

  onClick() {
    this.parent.toggleOption(this);
  }

  get multi() {
    return this.parent.multi;
  }
}
