import { AfterViewInit, Component, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ToggleOption {
  toggled: boolean;
  value: any;
}

@Component({
  selector: 'sui-toggle-control',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ToggleControlComponent,
      multi: true,
    },
  ],
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }

      :host(.disabled) {
        opacity: 0.6;
      }
    `,
  ],
})
export class ToggleControlComponent implements ControlValueAccessor, AfterViewInit {
  private onChangeCallback: any;
  private onTouchedCallback: any;
  private viewInitialized = false;
  private hasInitializedValue = false;
  private preViewInitializedValue: any;

  @HostBinding('class.disabled')
  @Input()
  disabled = false;
  @Input()
  multi = false;
  options: ToggleOption[] = [];

  register(option: ToggleOption) {
    this.options.push(option);
  }

  toggleOption(clickedOption: ToggleOption) {
    if (this.multi) {
      this.optionClickedMulti(clickedOption);
    } else {
      this.optionClickedSingle(clickedOption);
    }
    this.onTouchedCallback();
  }

  private optionClickedSingle(clickedOption: ToggleOption) {
    this.options.forEach(option => {
      option.toggled = false;

      if (option === clickedOption) {
        option.toggled = true;
      }
    });

    if (this.onChangeCallback) {
      this.onChangeCallback(clickedOption.value);
    }
  }

  private optionClickedMulti(clickedOption: ToggleOption) {
    clickedOption.toggled = !clickedOption.toggled;

    const selectedOptionValues = this.options
      .filter(option => option.toggled)
      .map(option => option.value);

    if (this.onChangeCallback) {
      this.onChangeCallback(selectedOptionValues);
    }
  }

  ngAfterViewInit() {
    this.viewInitialized = true;

    if (this.hasInitializedValue) {
      this.writeValue(this.preViewInitializedValue);
    }
  }

  registerOnChange(callback: any) {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: any) {
    this.onTouchedCallback = callback;
  }

  writeValue(value: any) {
    if (!this.viewInitialized) {
      this.hasInitializedValue = true;
      this.preViewInitializedValue = value;
      return;
    }

    if (this.multi) {
      const values: any[] = value;
      this.options.forEach(option => {
        let found = false;
        for (const value of values) {
          found = value === option.value;

          if (found) break;
        }

        option.toggled = found;
      });
    } else {
      const target = this.options.find(option => option.value === value);

      this.options.forEach(option => {
        option.toggled = false;

        if (option === target) {
          option.toggled = true;
        }
      });
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
