import { Component } from '@angular/core';

@Component({
  selector: 'sui-nav-drawer-divider',
  template: ` <div class="suiNavDrawerDividerLine"></div> `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        padding: 8px 0 8px 72px;
      }

      .suiNavDrawerDividerLine {
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-foreground-divider);
      }
    `,
  ],
})
export class NavDrawerDividerComponent {}
