import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { BodyService } from '../body';
import * as d3 from 'd3';

declare let jasmine: any;

export type LineChartDataPoint<X = Date, Y = number> = {
  x: X;
  y: Y;
};

@Component({
  selector: 'sui-line-chart',
  template: `
    <svg id="{{ lineChartId }}" [attr.width]="width" [attr.height]="height"></svg>
  `,
})
export class LineChartComponent implements AfterViewInit, OnChanges {
  @Input() width: number;
  @Input() height = 320;
  @Input() margin: { top: number; right: number; bottom: number; left: number };
  @Input() data: LineChartDataPoint[] = [];
  @Input() units = '';
  @Input() xAxisLabel = '';
  @Input() yAxisLabel = '';
  public chartId = getNextChartId();
  initialized = false;

  get lineChartId(): string {
    return `suiLineChart${this.chartId}`;
  }

  constructor(
    private bodyService: BodyService,
    public viewContainerRef: ViewContainerRef,
  ) {}

  ngAfterViewInit(): void {
    this.initialized = true;
    this.drawGraph();
  }

  ngOnChanges(): void {
    if (this.initialized) {
      this.drawGraph();
    }
  }

  drawGraph(): void {
    const svg = d3.select(`#${this.lineChartId}`);
    svg.select('g').remove();
    svg.select('rect').remove();
    const width = this.width - this.margin.left - this.margin.right;
    const height = this.height - this.margin.top - this.margin.bottom;
    const g = svg.append('g').attr('class', 'suiGraph');
    const x = d3.scaleTime().rangeRound([0, width]);
    const y = d3.scaleLinear().rangeRound([height, 0]);
    const bisectDate = d3.bisector((d: LineChartDataPoint<any, any>) => d.x).left;

    const line = d3
      .line<LineChartDataPoint<Date, number>>()
      .x(d => x(d.x) ?? 0)
      .y(d => y(d.y) ?? 0);

    x.domain(d3.extent(this.data, m => m.x) as any);
    y.domain([
      d3.min(this.data, (m: LineChartDataPoint<any, any>) => m.y) / 1.2,
      d3.max(this.data, (m: LineChartDataPoint<any, any>) => m.y) * 1.2,
    ]);

    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%I:%M%p') as any) as any);

    g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(y) as any)
      .append('text')
      .attr('fill', '#000')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text(this.yAxisLabel);

    g.append('path')
      .datum(this.data)
      .attr('fill', 'none')
      .attr('stroke', this.bodyService.getCssVariable('--color-500', '#5AA454'))
      .attr('stroke-width', 1.5)
      .attr('d', line);

    const focus = g.append('g').attr('class', 'focus').style('display', 'none');

    focus
      .append('line')
      .attr('class', 'x-hover-line hover-line')
      .attr('fill', 'none')
      .attr('y1', 0)
      .attr('y2', height);

    focus
      .append('line')
      .attr('class', 'y-hover-line hover-line')
      .attr('x1', width)
      .attr('x2', width);

    focus.append('circle').attr('r', 4).attr('fill', 'none');

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    svg
      .append('rect')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
      .attr('class', 'overlay')
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', () => {
        focus.style('display', null);
      })
      .on('mouseout', () => {
        focus.style('display', 'none');
        focus.select('rect').remove();
        focus.select('text').remove();
      })
      .on('mousemove', function () {
        focus.select('rect').remove();
        focus.select('text').remove();
        mousemove(this);
      });

    const tooltipRectColor = this.bodyService.getCssVariable('--color-500', '#5AA454');
    const tooltipTextColor = this.bodyService.getCssVariable(
      '--color-contrast-500',
      '#5AA454',
    );

    function mousemove(element: any) {
      const [elementX] = d3.mouse(element);
      const x0: Date = x.invert(elementX);
      const i = bisectDate(self.data, x0, 1);
      const d0 = self.data[i - 1];
      const d1 = self.data[i];

      if (!d0 || !d1) {
        return;
      }

      const d = x0.getTime() - d0.y > d1.y - x0.getTime() ? d1 : d0;

      const xCoord = x(d.x);
      const yCoord = y(d.y);

      if (!xCoord || !yCoord) return;

      focus
        .append('text')
        .attr('font-size', 10)
        .attr('dy', -30)
        .attr('dx', 0)
        .attr('fill', tooltipTextColor)
        .attr('text-anchor', 'middle')
        .text(
          `${d.y}${self.units ? ' ' + self.units : ''}` +
            ' at ' +
            `${d.x.toLocaleTimeString()}`,
        );

      // Figure out text width so we can center and size 'rect' around it
      const textWidth = (focus.select('text').node() as Element).getBoundingClientRect()
        .width;

      focus
        .append('rect')
        .attr('id', 'tooltip')
        .attr('width', textWidth + 32)
        .attr('height', 32)
        .attr('x', -(textWidth / 2) - 16)
        .attr('y', -50)
        .attr('fill', tooltipRectColor);

      focus.select('text').raise();

      // Get the boxes for elements so we can compare them
      const tooltipBox = (
        focus.select('#tooltip').node() as Element
      ).getBoundingClientRect();
      const chartBox = (svg.select('.overlay').node() as Element).getBoundingClientRect();

      // Handle tooltip going past right side of chart
      if (tooltipBox.x + tooltipBox.width > chartBox.x + chartBox.width) {
        const additionalOffset =
          tooltipBox.x + tooltipBox.width - (chartBox.x + chartBox.width);
        focus.select('rect').attr('x', -(textWidth / 2) - 16 - additionalOffset);
        focus.select('text').attr('x', -additionalOffset);
      }

      // Handle tooltip going past left side of chart
      if (tooltipBox.x < chartBox.x) {
        const additionalOffset = chartBox.x - tooltipBox.x;
        focus.select('rect').attr('x', -(textWidth / 2) - 16 + additionalOffset);
        focus.select('text').attr('x', additionalOffset);
      }

      focus.attr('transform', 'translate(' + xCoord + ',' + yCoord + ')');
      focus.select('.x-hover-line').attr('y2', height - yCoord);
      focus.select('.y-hover-line').attr('x2', width + width);
    }
  }
}

let uniqueChartId = 0;

function getNextChartId() {
  if (typeof jasmine === 'undefined') {
    return ++uniqueChartId;
  }

  return 0;
}

@Component({
  selector: 'sui-tooltip-panel',
  template: '<div matTooltip="{{ tip }}" #tooltip></div>',
})
export class TooltipPanelComponent implements AfterViewInit {
  @ViewChild('tooltip', { read: MatTooltip, static: true })
  tooltip: MatTooltip;
  tip = '';

  ngAfterViewInit() {
    this.tooltip.show();
  }
}
