import { Component, Input } from '@angular/core';
import { LightViewModel } from '@spog-ui/shared/models/lights';

@Component({
  selector: 'lit-light-asset-data-card',
  template: `
    @if (hasAssetData()) {
      <mat-card class="lightAssetDataCard">
        <mat-card-content>
          <mat-card-title>Asset Info</mat-card-title>
          @for (datum of light.deviceAssetData; track datum) {
            <div class="info">
              <div class="detail">{{ datum.value }}</div>
              <div class="label">{{ datum.key }}</div>
            </div>
          }
        </mat-card-content>
      </mat-card>
    }
    `,
  styles: [
    `
      .lightAssetDataCard {
        border-radius: 2px;
        margin-bottom: 10px;
        margin-right: auto;
        margin-left: auto;
        width: 328px;
        top: 10px;
      }

      mat-card-title {
        font-size: 16px;
        line-height: 19px;
        padding: 0px;
      }

      .info {
        padding-bottom: 6px;
      }

      .detail {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        padding-bottom: 4px;
      }

      .label {
        font-size: 10px;
        font-weight: 500;
        line-height: 11px;
        padding-bottom: 4px;
        text-transform: uppercase;
      }
    `,
  ],
})
export class LightAssetDataCardComponent {
  @Input() light: LightViewModel;

  constructor() {}

  hasAssetData() {
    return this.light.deviceAssetData.length > 0;
  }
}
