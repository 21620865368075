import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sui-card-select',
  template: `
    <div class="suiCardSelectLabel">
      <ng-content select="sui-card-select-label"></ng-content>
    </div>
    <div class="suiCardSelectOptions" role="radiogroup">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .suiCardSelectLabel {
        margin-bottom: 18px;
      }

      .suiCardSelectOptions {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        column-gap: 8px;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CardSelectComponent,
    },
  ],
})
export class CardSelectComponent implements ControlValueAccessor {
  onChangeFn: any;
  onTouchedFn: any;

  @Input()
  value: any;

  @Input()
  disabled: boolean;

  @Output()
  update = new EventEmitter<any>();

  selectOption(value: any) {
    this.value = value;

    if (this.onChangeFn) {
      this.onChangeFn(value);
    }

    if (this.onTouchedFn) {
      this.onTouchedFn();
    }

    this.update.emit(value);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}

@Component({
  selector: 'sui-card-select-label',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        font-size: 17px;
        color: var(--color-foreground-secondary-text);
      }
    `,
  ],
})
export class CardSelectLabelComponent {}

@Component({
  selector: 'sui-card-select-option',
  template: `
    <mat-divider></mat-divider>
    <div class="suiCardSelectOptionIcon">
      <ng-content select="mat-icon"></ng-content>
    </div>
    <div class="suiCardSelectOptionContent">
      <ng-content select="sui-card-select-option-label"></ng-content>
      <ng-content select="sui-card-select-option-hint"></ng-content>
    </div>
    <div class="suiCardSelectOptionIndicator">
      <mat-icon [inline]="true">check</mat-icon>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        position: relative;
        min-height: 66px;
        cursor: pointer;
        background-color: var(--color-background-unselected-chip);
        overflow: hidden;
      }

      mat-divider {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
      }

      :host:first-of-type mat-divider {
        display: none;
      }

      .suiCardSelectOptionIcon {
        display: flex;
        position: relative;
        width: 66px;
        flex-shrink: 0;
        flex-grow: 0;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.12);
        color: var(--color-foreground-secondary-text);
      }

      :host .suiCardSelectOptionIcon ::ng-deep mat-icon {
        font-size: 40px;
        width: 40px;
        height: 40px;
      }

      .suiCardSelectOptionContent {
        flex-grow: 2;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .suiCardSelectOptionIndicator {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 22px;
        height: 22px;
        border-radius: 22px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-background-unselected-chip);
        color: var(--color-background-unselected-chip);
      }

      .suiCardSelectOptionIndicator mat-icon {
        position: relative;
        top: -1px;
        width: 16px;
        height: 16px;
        line-height: 1.15; /* Overrides bootstrap_theme.css. */
      }

      :host(.suiCardSelectOptionDisabled) {
        cursor: inherit;
        opacity: 0.9;
      }

      :host(.suiCardSelectOptionDisabled) .suiCardSelectOptionIcon ::ng-deep mat-icon,
      :host(.suiCardSelectOptionDisabled) .suiCardSelectOptionContent {
        opacity: 0.6;
      }

      :host:not(.suiCardSelectOptionSelected):not(.suiCardSelectOptionDisabled):hover {
        filter: brightness(1.1);
      }

      :host:not(.suiCardSelectOptionSelected):not(.suiCardSelectOptionDisabled):hover
        .suiCardSelectOptionIndicator {
        filter: brightness(0.8);
      }

      :host(.suiCardSelectOptionSelected) {
        background-color: var(--color-100);
      }

      :host(.suiCardSelectOptionSelected) .suiCardSelectOptionIcon {
        background-color: var(--color-200);
        color: var(--color-contrast-200);
      }

      :host(.suiCardSelectOptionSelected) .suiCardSelectOptionIndicator {
        background-color: var(--color-500);
        color: var(--color-100);
      }

      :host,
      .suiCardSelectOptionIcon,
      .suiCardSelectOptionIndicator {
        transition: color 200ms, background-color 200ms, filter 200ms;
      }
    `,
  ],
})
export class CardSelectOptionComponent {
  @Input() value: any;
  @Input() disabled: boolean;
  @HostBinding('attr.role') role = 'radio';

  @HostBinding('class.suiCardSelectOptionSelected')
  get isSelected() {
    return this.value === this.container.value;
  }

  @HostBinding('class.suiCardSelectOptionDisabled')
  get isDisabled() {
    return this.container.disabled || this.disabled;
  }

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    $event.preventDefault();

    if (!this.isDisabled) {
      this.container.selectOption(this.value);
    }
  }

  constructor(
    @Inject(forwardRef(() => CardSelectComponent)) public container: CardSelectComponent,
  ) {}
}

@Component({
  selector: 'sui-card-select-option-label',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        font-size: 15px;
        font-weight: 500;
        color: var(--color-foreground-text);
        transition: color 200ms;
        margin-bottom: 8px;
      }

      :host-context(.suiCardSelectOptionSelected) {
        color: var(--color-contrast-100);
      }
    `,
  ],
})
export class CardSelectOptionLabelComponent {}

@Component({
  selector: 'sui-card-select-option-hint',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        font-size: 13px;
        color: var(--color-foreground-secondary-text);
        font-style: italic;
        transition: color 200ms;
      }

      :host-context(.suiCardSelectOptionSelected) {
        color: var(--color-contrast-100);
        opacity: 0.82;
      }
    `,
  ],
})
export class CardSelectOptionHintComponent {}

export const CARD_SELECT_DIRECTIVES = [
  CardSelectComponent,
  CardSelectLabelComponent,
  CardSelectOptionComponent,
  CardSelectOptionHintComponent,
  CardSelectOptionLabelComponent,
];
