import { ModbusFunctionCode } from '@spog-ui/graphql/types';
import { Bridge485TemplateGQLModel, Bridge485TemplateInternalModel } from '..';

export function createMockBridge485TemplateInternalModel(
  updates?: Partial<Bridge485TemplateInternalModel>,
): Bridge485TemplateInternalModel {
  return {
    id: 'mock-template-id',
    name: 'the archetype of solace',
    metrics: [],
    ...updates,
  };
}

export function createMockBridge485TemplateGQLModel(
  updates?: Partial<Bridge485TemplateGQLModel>,
): Bridge485TemplateGQLModel {
  return {
    id: 'mock-template-id',
    name: 'the archetype of temerity',
    template: [
      {
        __typename: 'Bridge485MetricTemplate',
        name: 'Anything Sensor',
        dataType: { name: 'Anything', units: 'A' },
        unitId: 10,
        dataAddress: 1,
        functionCode: ModbusFunctionCode.READ_HOLDING_REGISTERS,
        conversion: { __typename: 'MetricConversion', polynomial: [1, 0] },
        dataFormat: '<h',
      },
    ],
    ...updates,
  };
}
