import {
  CurrentUser,
  UserFormModel,
  UserGQLModel,
  UserInternalModel,
} from './user.model';
import { UserRole } from '@spog-ui/graphql/types';

export function createMockCurrentUser(updates?: Partial<CurrentUser>): CurrentUser {
  return {
    id: '12345',
    name: 'test user',
    email: 'test@email.com',
    picture: 'https://test.com/test.jpg',
    ...updates,
  };
}

export function createMockUser(updates?: Partial<UserInternalModel>): UserInternalModel {
  return {
    id: '12345',
    name: 'test user',
    email: 'test@user.com',
    role: UserRole.MEMBER,
    ...updates,
  };
}

export function createMockGQLUser(updates?: Partial<UserGQLModel>): UserGQLModel {
  return {
    id: '12345',
    name: 'test user',
    email: 'test@user.com',
    role: UserRole.MEMBER,
    ...updates,
  };
}

export function createMockUserForm(updates?: Partial<UserFormModel>): UserFormModel {
  return {
    id: 'user-id',
    name: 'test user',
    email: 'test@user.com',
    role: UserRole.MEMBER,
    ...updates,
  };
}
