import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'sui-checkbox',
    template: `
    <mat-checkbox
      [checked]="checked"
      [disabled]="disabled"
      [color]="type"
      (change)="onChange($event)"
    >
      <ng-content></ng-content>
    </mat-checkbox>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CheckboxComponent,
        },
    ],
    standalone: true,
    imports: [MatCheckbox],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input()
  type: 'primary' | 'accent' | 'warn' = 'primary';

  checked: boolean;
  disabled: boolean;
  onChangeFn: (value: any) => void;
  onTouchedFn: () => void;

  onChange($event: MatCheckboxChange) {
    this.change($event.checked);
  }

  change(value: boolean) {
    this.checked = value;
    if (this.onChangeFn) {
      this.onChangeFn(value);
    }
  }

  touched() {
    if (this.onTouchedFn) {
      this.onTouchedFn();
    }
  }

  writeValue(value: any): void {
    this.checked = value;
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
