import { Attribute, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'sui-header',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        color: var(--color-900);
        display: block;
      }

      :host([size='1']) {
        font-size: 24px;
        font-weight: 400;
        margin: 12px 0 8px;
      }

      :host([size='2']) {
        font-size: 20px;
        font-weight: 500;
        margin: 12px 0 8px;
      }

      :host([size='3']) {
        font-size: 16px;
        font-weight: 400;
        margin: 12px 0 8px;
      }
    `,
  ],
})
export class HeaderComponent {
  constructor(@Attribute('size') public size: string) {}

  @HostBinding('attr.size')
  get realSize() {
    if (this.size) {
      return this.size;
    }

    return '1';
  }
}

export const HEADER_DIRECTIVES = [HeaderComponent];
