import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LayoutModule } from '@angular/cdk/layout';
import { DeprecatedPanelComponent, InnerPanelComponent } from './panel.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, LayoutModule],
  declarations: [InnerPanelComponent, DeprecatedPanelComponent],
  exports: [DeprecatedPanelComponent],
})
export class SuiDeprecatedPanelModule {}

export { DeprecatedPanelComponent };
