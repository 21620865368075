import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SuiDimmerModule } from '../dimmer';
import { LightControlsComponent } from './light-controls.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatTooltipModule, SuiDimmerModule],
  declarations: [LightControlsComponent],
  exports: [LightControlsComponent],
})
export class SuiLightControlsModule {}
