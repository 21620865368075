import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SpeedControlComponentModule } from '../speed-control';
import { BFColorSparkleComponent } from './bf-color-sparkle.component';
import { ColorPickerModule } from '@sui/color-picker';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    SpeedControlComponentModule,
    ColorPickerModule,
  ],
  declarations: [BFColorSparkleComponent],
  exports: [BFColorSparkleComponent],
})
export class BFColorSparkleComponentModule {}
