import {
  toUtilityServiceInternalModelFromGQL,
  UtilityServiceFormModel,
  UtilityServiceGQLModel,
  UtilityServiceInternalModel,
  UtilityServiceViewModel,
  UtilityServiceWSModel,
} from './utility-service.model';

export function createMockUtilityServiceGQLModel(
  updates: Partial<UtilityServiceGQLModel> = {},
): UtilityServiceGQLModel {
  return {
    id: 'mock-utility-service-id',
    name: 'Mock Utility Service',
    industrialSensors: [
      {
        __typename: 'Sense420Sensor',
        id: 'mock-indie-sensor-id',
      },
    ],
    lights: [
      {
        id: 'mock-light-id',
      },
    ],
    mains: [
      {
        __typename: 'Sense420Sensor',
        id: 'mock-indie-sensor-id-for-main',
      },
    ],
    ...updates,
  };
}

export function createMockUtilityServiceWSModel(
  updates: Partial<UtilityServiceWSModel> = {},
): UtilityServiceWSModel {
  return {
    id: 'mock-utility-service-id',
    name: 'Mock Utility Service',
    siteId: 'mock-site-id',
    industrialSensorIds: ['mock-indie-sensor-id'],
    lightIds: ['mock-light-id'],
    mainIds: ['mock-indie-sensor-id-for-main'],
    ...updates,
  };
}

export function createMockUtilityServiceInternalModel(
  updates: Partial<UtilityServiceInternalModel> = {},
): UtilityServiceInternalModel {
  const gqlModel = createMockUtilityServiceGQLModel();
  const internalModel = toUtilityServiceInternalModelFromGQL(gqlModel);

  return {
    ...internalModel,
    ...updates,
  };
}

export function createMockUtilityServiceViewModel(
  updates: Partial<UtilityServiceViewModel> = {},
): UtilityServiceViewModel {
  return {
    id: 'mock-utility-service',
    name: 'Mock Utility Service',
    indieSensors: [],
    lights: [],
    mains: [],
    scheduledUtilityRates: [],
    ...updates,
  };
}

export function createMockUtilityServiceFormModel(
  updates: Partial<UtilityServiceFormModel> = {},
): UtilityServiceFormModel {
  return {
    id: null,
    name: 'Mock Utility Service',
    indieSensorIds: [],
    lightIds: [],
    mainIds: [],
    ...updates,
  };
}
