import { NgModule, Type, ModuleWithProviders } from '@angular/core';
import {
  AddLightPageComponent,
  AddLightPageComponentModule,
  AddLightPageState,
} from './components/add-light-page';

@NgModule({
  exports: [AddLightPageComponentModule],
})
export class AddLightFeatureModule {
  static connect(
    adapter: Type<AddLightPageState>,
  ): ModuleWithProviders<AddLightFeatureModule> {
    return {
      ngModule: AddLightFeatureModule,
      providers: [
        {
          provide: AddLightPageState,
          useClass: adapter,
        },
      ],
    };
  }
}

export { AddLightPageState, AddLightPageComponent };
