import { ControllerInternalModel } from './controller.model';

export function createMockControllerInternalModel(
  updates: Partial<ControllerInternalModel> = {},
): ControllerInternalModel {
  return {
    id: 'mock-controller-id',
    name: 'mock controller name',
    snapaddr: 'abc123',
    type: 'DIM10-250-11',
    ...updates,
  };
}
