import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DateRangeFormComponent } from './date-range-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SuiButtonModule } from '../button';
import { SuiFormsModule } from '../forms';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    SuiButtonModule,
    SuiFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  declarations: [DateRangeFormComponent],
  exports: [DateRangeFormComponent],
})
export class SuiDateRangeFormModule {}
