import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { CharmsOutletComponent } from './charms-outlet.component';
import { CharmDirective } from './charms.component';

@NgModule({
  imports: [CommonModule, PortalModule],
  declarations: [CharmsOutletComponent, CharmDirective],
  exports: [CharmsOutletComponent, CharmDirective],
})
export class SuiCharmsModule {}
