import { Component } from '@angular/core';

@Component({
  selector: 'sui-empty-card',
  template: `
    <sui-card>
      <ng-content></ng-content>
    </sui-card>
  `,
  styles: [
    `
      sui-card {
        opacity: 0.5;
        font-size: 16px;
        font-style: italic;
        padding: 24px;
        color: var(--color-foreground-text);
      }
    `,
  ],
})
export class EmptyCardComponent {}

export const EMPTY_CARD_DIRECTIVES = [EmptyCardComponent];
