import * as ThermostatsApiActions from './thermostats-api.actions';
import * as ThermostatsPageActions from './thermostats-page.actions';
import * as AddThermostatPageActions from './add-thermostat-page.actions';
import * as EditThermostatPageActions from './edit-thermostat-page.actions';
import * as EditThermostatApiActions from './edit-thermostat-api.actions';
import * as AddThermostatApiActions from './add-thermostat-api.actions';

export {
  ThermostatsApiActions,
  ThermostatsPageActions,
  AddThermostatPageActions,
  EditThermostatPageActions,
  EditThermostatApiActions,
  AddThermostatApiActions,
};
