import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { SuiButtonModule } from '../button';
import { BUTTON_TOGGLE_DIRECTIVES } from './button-toggle.component';

@NgModule({
  imports: [PortalModule, CommonModule, SuiButtonModule],
  declarations: [BUTTON_TOGGLE_DIRECTIVES],
  exports: [BUTTON_TOGGLE_DIRECTIVES],
})
export class SuiButtonToggleModule {}
