import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiButtonModule } from '../button';
import { SuiSpinnerModule } from '../spinner';
import { PendingButtonComponent } from './pending-button.component';

@NgModule({
  imports: [CommonModule, SuiButtonModule, SuiSpinnerModule],
  declarations: [PendingButtonComponent],
  exports: [PendingButtonComponent],
})
export class SuiPendingButtonModule {}
