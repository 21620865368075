import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

export interface FadeBehaviorParameters {
  level: number;
  fadeTimeSeconds: number;
}

@Component({
  selector: `sui-bf-fade`,
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        All lights in the zone fade to a dimming level between 0 and 100% over a period of
        time.
      </p>
      <div class="bfForm">
        <div class="bfChunk">
          <p class="bfLabel">Dim the lights to:</p>
          <sui-dimmer-control formControlName="level"></sui-dimmer-control>

          <p class="bfLabel">Over a period of:</p>
          <mat-form-field>
            <input
              type="number"
              matInput
              formControlName="fadeTimeMinutes"
              step="1"
              min="0"
              max="500"
            />
            <span matSuffix>minutes</span>
            <mat-error> Must be a whole number between 0 and 500</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              formControlName="fadeTimeSeconds"
              type="number"
              step="1"
              min="0"
              max="32767"
            />
            <span matSuffix>seconds</span>

            <mat-error> Must be a whole number of seconds between 0 and 59. </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  `,
})
export class BFFadeComponent implements BehaviorForm {
  integerOnly = /^\d+$/;

  form = new UntypedFormGroup({
    level: new UntypedFormControl(0),
    fadeTimeMinutes: new UntypedFormControl(0, [
      Validators.min(0),
      Validators.max(500),
      Validators.pattern(this.integerOnly),
      Validators.required,
    ]),
    fadeTimeSeconds: new UntypedFormControl(2, [
      Validators.min(0),
      Validators.max(59),
      Validators.pattern(this.integerOnly),
      Validators.required,
    ]),
  });

  getBehaviorParameters(): FadeBehaviorParameters | null {
    const level = this.form.get('level')?.value;
    const fadeTimeMinutes = this.form.get('fadeTimeMinutes')?.value;
    const fadeTimeSeconds = this.form.get('fadeTimeSeconds')?.value;

    return {
      level,
      fadeTimeSeconds: fadeTimeMinutes * 60 + fadeTimeSeconds,
    };
  }

  writeBehaviorParameters(parameters: FadeBehaviorParameters): void {
    this.form.setValue(
      {
        level: parameters.level,
        fadeTimeMinutes: Math.floor(parameters.fadeTimeSeconds / 60),
        fadeTimeSeconds: parameters.fadeTimeSeconds % 60,
      },
      { onlySelf: true, emitEvent: false },
    );
  }
}
