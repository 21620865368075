import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PAGINATION_DIRECTIVES } from './pagination.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PAGINATION_DIRECTIVES],
  exports: [PAGINATION_DIRECTIVES],
})
export class SuiPaginationModule {}
