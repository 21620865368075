import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiSpinnerModule } from '@spog-ui/shared/components';
import { LightFormComponentModule } from '../light-form';
import { AddLightPageComponent } from './add-light-page.component';

@NgModule({
  imports: [CommonModule, SuiSpinnerModule, LightFormComponentModule],
  declarations: [AddLightPageComponent],
  exports: [AddLightPageComponent],
})
export class AddLightPageComponentModule {}
