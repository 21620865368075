import { AlarmGQLModel, AlarmViewModel } from './alarm.model';

import { AlarmTypes, SuiAlarmModel } from '@shared/alarms';

export function createMockAlarmView(
  updates: Partial<AlarmViewModel> = {},
): AlarmViewModel {
  return {
    id: 'mock-alarm-id',
    type: AlarmTypes.DeviceCommunicationFailure,
    snapaddr: 'abc123',
    triggerTime: '2018-11-28T18:25:32Z',
    controllerId: 'controller id',
    siteControllerId: 'site controller id',
    siteControllerName: 'Site Controller Name',
    cleared: false,

    ...updates,
  };
}

export function createMockAlarmGQLModel(
  updates: Partial<AlarmGQLModel> = {},
): AlarmGQLModel {
  return {
    id: 'mock-alarm-id',
    triggerTime: '2018-11-28T18:25:32Z',
    type: AlarmTypes.DeviceCommunicationFailure,
    siteController: { id: '12345' },
    controllerId: 'controller id',
    snapaddr: 'abc123',
    cleared: true,
    description: 'desription',
    selfClearing: true,
    retryable: true,
    ...updates,
  };
}
