import { Component } from '@angular/core';
import * as BigSelectActions from './big-select.actions';
import { BigSelectState } from './big-select.state';

@Component({
  selector: 'sui-big-select-filter',
  template: `
    <mat-form-field
      appearance="outline"
      [class.bigSelectExpandedFilter]="state.filterIsExpanded$ | async"
    >
      <mat-icon matPrefix>search</mat-icon>
      <mat-label><!-- Intentionally omitted --></mat-label>
      <input
        matInput
        type="text"
        placeholder="Filter"
        [value]="state.filter$ | async"
        (input)="onFilter($event.target.value)"
        (focus)="onFocusFilter()"
        (blur)="onBlurFilter()"
      />
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
      }

      mat-form-field {
        width: 16px;
        transition: width 300ms;
        position: relative;
        top: 6px;
      }

      mat-form-field.bigSelectExpandedFilter {
        width: 100%;
      }

      mat-form-field mat-icon {
        font-size: 150%;
        position: relative;
        top: 6px;
        margin-right: 6px;
      }
    `,
  ],
})
export class BigSelectFilterComponent {
  constructor(readonly state: BigSelectState) {}

  onFilter(filter: string) {
    this.state.dispatch(BigSelectActions.filterAction(filter));
  }

  onFocusFilter() {
    this.state.dispatch(BigSelectActions.focusFilterAction());
  }

  onBlurFilter() {
    this.state.dispatch(BigSelectActions.blurFilterAction());
  }
}
