export const DEFAULT_FIXTURE_TYPE = 'id_builtin_fixture_type';

export interface FixtureTypeModel {
  id: string;
  name: string;
}

export function createMockFixtureType(
  changes: Partial<FixtureTypeModel> = {},
): FixtureTypeModel {
  return {
    id: 'id_builtin_fixture_type',
    name: 'Default Fixture Type',
    ...changes,
  };
}
