import { NgModule } from '@angular/core';
import { ALERT_DIRECTIVES } from './alert.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [ALERT_DIRECTIVES],
  exports: [ALERT_DIRECTIVES],
})
export class SuiAlertModule {}
