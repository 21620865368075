import { Component } from '@angular/core';

@Component({
  selector: 'sui-card-footer',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        border-top: 1px solid var(--color-foreground-divider);
        padding: 0 2px;
      }
    `,
  ],
})
export class CardFooterComponent {}
