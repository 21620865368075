import { DateTime } from 'luxon';
import { DateDomainModel, PowerDomainModel } from './power-source-comparison.model';

export function createMockPowerDomainModel(): PowerDomainModel {
  return [0, 100];
}

export function createMockDateDomainModel(): DateDomainModel {
  return [DateTime.local(2018, 0, 1, 0), DateTime.local(2018, 0, 1, 12)];
}
