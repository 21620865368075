export const enum Pixel {
  r = 0,
  g = 1,
  b = 2,
  a = 3,
}

export type Color = [number, number, number];

export function parseRGBAString(color: string): Color {
  const colorValues = color
    .substring(color.indexOf('(') + 1, color.length - 1)
    .split(', ');
  const r = parseInt(colorValues[0]);
  const g = parseInt(colorValues[1]);
  const b = parseInt(colorValues[2]);

  return [r, g, b];
}

export function parseHexString(color: string): Color {
  const hexCode = color.trim().replace('#', '');
  const rString = hexCode.slice(0, 2);
  const gString = hexCode.slice(2, 4);
  const bString = hexCode.slice(4, 6);

  const r = parseInt(rString, 16);
  const g = parseInt(gString, 16);
  const b = parseInt(bString, 16);

  return [r, g, b];
}

export function serializeColor([r, g, b]: Color): string {
  const rString = r.toString(16).padStart(2, '0');
  const gString = g.toString(16).padStart(2, '0');
  const bString = b.toString(16).padStart(2, '0');

  return `#${rString}${gString}${bString}`;
}

export function colorToGrayscale([r, g, b]: Color): Color {
  const grayscale = r * 0.3 + g * 0.59 + b * 0.11;

  return [grayscale, grayscale, grayscale];
}

export function darkenColor([r, g, b]: Color, percent: number): Color {
  return [Math.round(r * percent), Math.round(g * percent), Math.round(b * percent)];
}
