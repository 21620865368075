import { GraphQLFormattedError } from 'graphql';
import { ApiErrorResponse, GraphQLErrorResponse } from './error-response.model';

export function mockApiErrorResponse(errorMessage: string): ApiErrorResponse {
  return mockGraphQLErrorResponse(errorMessage);
}

export function mockGraphQLErrorResponse(errorMessage: string): GraphQLErrorResponse {
  return { errors: new Array<GraphQLFormattedError>({ message: errorMessage }) };
}
