import * as EquipmentPageActions from './equipment-page.actions';
import * as EquipmentApiActions from './equipment-api.actions';
import * as AddEquipmentPageActions from './add-equipment-page.actions';
import * as AddEquipmentApiActions from './add-equipment-api.actions';
import * as EditEquipmentPageActions from './edit-equipment-page.actions';
import * as EditEquipmentApiActions from './edit-equipment-api.actions';
import * as EquipmentProfilePageActions from './equipment-profile-page.actions';
import * as EquipmentProfileApiActions from './equipment-profile-api.actions';

export {
  EquipmentPageActions,
  EquipmentApiActions,
  AddEquipmentPageActions,
  AddEquipmentApiActions,
  EditEquipmentPageActions,
  EditEquipmentApiActions,
  EquipmentProfilePageActions,
  EquipmentProfileApiActions,
};
