import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export const errorMessages = {
  required: 'This field is required to continue.',
  maxlength: ({ requiredLength }: { requiredLength: number }) =>
    `This field can be no longer than ${requiredLength} characters`,
  suiIsBetweenInclusive: ({ min, max }: { min: number; max: number }) =>
    `Value must be between ${min} and ${max}.`,
  suiOneOf: 'One of!?!?!',
  suiSnapAddress:
    'A valid SNAP Address must be a hex value of at least 6 digits with optional period and colon delimiters (ex:"a18ca9", "04:3E:82").',
  suiDeviceName: 'Device name cannot include the "|" character.',
  suiUniqueName: 'A device with this name already exists.',
  suiUniqueSnapAddress: 'A device with this snap address already exists.',
  suiValidateNameChars: 'Device name cannot include the "|" characer',
  suiSameValueAs: 'Must match new password',
  suiMinLength: ({ min }: { min: number }) =>
    `Must be at least ${min} characters in length`,
  suiValidZoneName: 'Zone name cannot include the "|" or ":" character.',
  suiUniqueSceneName: (name: string) => `A scene with this name already exists.`,
  suiNoDuplicateZoneInScene: (zones: any[]) =>
    `Zone${zones.length > 1 ? 's' : ''} ${zones
      .map(z => z.name)
      .join(', ')} cannot be assigned to more than one behavior.`,
  suiMaxZoneLimitInScene: ({ max, actual }: { max: number; actual: number }) =>
    `There cannot be more than ${max} zones assigned to behaviors in a scene.`,
  suiZoneBehaviorControlInvalid: 'A behavior has errors that must be fixed',
  suiZoneBehaviorControlListInvalid: 'One or more behaviors have errors',
  suiStartDate:
    'Start date must be prior to end date.  End date is required if a start date is selected.',
  suiNoSlotsAvailable: 'All sensor slots on this controller are already in use.',
  suiNoSlotsOnController: 'This controller type does not have any sensor slots.',
  suiNoEmergencyZoneSet: 'An emergency zone must be configured to use this sensor type',
  litCanAddLight:
    'This device already has a light configured for it or does not support a light',
  litZoneLimit: 'A light may only be in 18 zones',
};

@Injectable()
export class CustomValidators {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const result = (errorMessages as any)[validatorName];

    return typeof result === 'string' ? result : result(validatorValue);
  }

  static requiredIf(predicate: (c: AbstractControl) => boolean): ValidatorFn {
    return function (control: AbstractControl) {
      const result = predicate(control);

      if (result) {
        return Validators.required(control);
      }

      return null;
    };
  }

  static oneOf<T>(...args: T[]): ValidatorFn {
    return function (control: AbstractControl) {
      const isOneOf = args.indexOf(control.value) > -1;

      return isOneOf ? null : { suiOneOf: false };
    };
  }

  static hasAtLeastOne(): ValidatorFn {
    return function (control: AbstractControl) {
      const length = control.value ? control.value.length : 0;

      return length > 0 ? null : { suiHasAtLeastOne: false };
    };
  }

  static applyIf(
    predicate: (control: AbstractControl) => boolean,
    validators: ValidatorFn[],
  ): ValidatorFn {
    const composedValidator = Validators.compose(validators);

    return function (control: AbstractControl) {
      const shouldApply = predicate(control);

      if (shouldApply && composedValidator) {
        return composedValidator(control);
      }

      return null;
    };
  }

  static isBetweenInclusive(min: number, max: number): ValidatorFn {
    return function (control: AbstractControl) {
      const val: number = control.value;

      return val >= min && val <= max ? null : { suiIsBetweenInclusive: { min, max } };
    };
  }
}
