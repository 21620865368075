import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'sui-toolbar',
  template: `
    <button
      data-test-id="left-nav-menu-button"
      suiButton
      class="suiToolbarHamburgerButton"
      (click)="opened.emit()"
    >
      <i class="material-icons" title="Show navigation">menu</i>
      <div class="alarm" *ngIf="hasAlarms"></div>
    </button>
    <div class="title">
      <span class="hostname"> {{ hostname }} </span>
      <span class="separator" *ngIf="hostname">&nbsp;/&nbsp;</span>
      <span class="pageTitle"> {{ title }} </span>
    </div>
    <ng-content></ng-content>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 64px;
        background-color: var(--color-500);
        color: var(--color-contrast-500);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1002;
      }

      .alarm {
        background-color: var(--color-warn-500);
        width: 12px;
        height: 12px;
        border-radius: 12px;
        border: 1px solid #000000;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        position: absolute;
        left: 35px;
        bottom: 17px;
      }

      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        flex-wrap: wrap;
      }

      .title .hostname {
        margin-right: 4px;
        font-weight: 300;
      }

      [suiButton].suiToolbarHamburgerButton {
        font-size: 20px;
        color: var(--color-contrast-500);
      }

      i {
        vertical-align: middle;
      }

      @media screen and (max-width: 400px) {
        .separator {
          visibility: hidden;
          display: none;
        }

        .hostname {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    `,
  ],
})
export class ToolbarComponent {
  @Input() hostname = '';
  @Input() title = '';
  @Input() hasAlarms = false;
  @Output() opened = new EventEmitter();
}
