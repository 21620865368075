import { NgModule } from '@angular/core';
import { NAV_DIRECTIVES } from './nav.component';

@NgModule({
  declarations: [NAV_DIRECTIVES],
  exports: [NAV_DIRECTIVES],
})
export class SuiNavModule {}

export * from './nav.component';
