import { NgModule } from '@angular/core';
import { CardBodyComponent } from './card-body.component';
import { CardFooterComponent } from './card-footer.component';
import { CardHeaderComponent } from './card-header.component';
import { CardListComponent } from './card-list.component';
import { CardComponent } from './card.component';
import { EMPTY_CARD_DIRECTIVES } from './empty-card.component';

const PUBLIC_COMPONENTS = [
  CardBodyComponent,
  CardFooterComponent,
  CardHeaderComponent,
  CardListComponent,
  CardComponent,
  EMPTY_CARD_DIRECTIVES,
];

@NgModule({
  declarations: [PUBLIC_COMPONENTS],
  exports: [PUBLIC_COMPONENTS],
})
export class SuiCardModule {}
