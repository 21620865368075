import { LightViewModel } from './light.model';
import { createMockLightingZones, ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { range } from 'lodash';

function reduceZoneIds(zones: ZoneInternalModel[]): LightViewModel['zones'] {
  const lightZones = zones.reduce(
    (ids: LightViewModel['zones'], zone: ZoneInternalModel) => {
      ids[zone.id] = true;
      return ids;
    },
    { id_builtin_zone_all: true },
  );

  return lightZones;
}

export function createMockLightViewModel(
  changes?: Partial<LightViewModel>,
): LightViewModel {
  return {
    id: 'mock-light',
    name: 'Mock Light',
    floorPlanId: 'mock-floorplan-id',
    floorPlanX: 0,
    floorPlanY: 0,
    level: 0,
    zones: {},
    snapaddr: 'DEADBEEF',
    alarms: [],
    controllerId: 'controller-id',
    controllerType: 'controller-type',
    deviceAssetData: [],
    siteControllerName: 'site-controller-name',
    siteControllerId: 'site-controller-id',
    ...(changes ?? {}),
  };
}

export function createMockPlacedLight(
  origin = { x: 100, y: 100 },
  zones: ZoneInternalModel[] = createMockLightingZones(1),
): LightViewModel {
  const { x, y } = origin;
  return {
    id: `id_${x}_${y}`,
    name: `name_${x}_${y}`,
    floorPlanId: 'test-floorplan-id',
    floorPlanX: x,
    floorPlanY: y,
    zones: reduceZoneIds(zones),
    level: 45,
    alarms: [],
    controllerId: 'controller-id',
    snapaddr: '05B0F9',
    controllerType: 'controller-type',
    deviceAssetData: [],
    siteControllerName: 'site-controller-name',
    siteControllerId: 'site-controller-id',
  };
}

function createMockUnplacedLight(params?: Partial<LightViewModel>): LightViewModel {
  return {
    id: 'light_id',
    name: 'Light name',
    floorPlanId: null as unknown as string,
    floorPlanX: null as unknown as number,
    floorPlanY: null as unknown as number,
    zones: {},
    level: 45,
    alarms: [],
    controllerId: 'controller-id',
    snapaddr: '05B0F9',
    controllerType: 'controller-type',
    deviceAssetData: [],
    siteControllerName: 'site-controller-name',
    siteControllerId: 'site-controller-id',
    ...(params ? params : {}),
  };
}

function generateOrigins(xRange: number[], yRange: number[]): { x: number; y: number }[] {
  const lightOrigins: { x: number; y: number }[] = [];
  yRange.map(y => {
    xRange.map(x => {
      lightOrigins.push({ x, y });
    });
  });
  return lightOrigins;
}

export interface MockGridConfig {
  cols: number;
  rows: number;
  gap: number;
}

export function createMockPlacedLightZone(
  zone: MockGridConfig = { cols: 10, rows: 10, gap: 15 },
  origin = { x: 100, y: 100 },
  zones: ZoneInternalModel[] = createMockLightingZones(1),
): LightViewModel[] {
  const lightsX = range(origin.x, origin.x + zone.cols * zone.gap, zone.gap);
  const lightsY = range(origin.y, origin.y + zone.rows * zone.gap, zone.gap);
  const lightOrigins = generateOrigins(lightsX, lightsY);

  const lightModels = lightOrigins.map(origin => createMockPlacedLight(origin, zones));

  return lightModels;
}

function generateLightZones(zones: ZoneInternalModel[]): ZoneInternalModel[][] {
  const filteredZones = zones.filter(zone => zone.id !== 'id_builtin_zone_all');
  const lightZones: ZoneInternalModel[][] = [];

  let last = filteredZones.slice(-1)[0];
  filteredZones.forEach(current => {
    lightZones.push([current, last]);
    last = current;
  });

  return lightZones;
}

export function createMockPlacedLightMap(
  map: MockGridConfig = { cols: 17, rows: 6, gap: 25 },
  zone: MockGridConfig = { cols: 10, rows: 10, gap: 15 },
  origin = { x: 100, y: 100 },
): LightViewModel[] {
  const xTrans = zone.cols * zone.gap + map.gap;
  const yTrans = zone.rows * zone.gap + map.gap;
  const zonesX = range(origin.x, origin.x + map.cols * xTrans, xTrans);
  const zonesY = range(origin.y, origin.y + map.rows * yTrans, yTrans);
  const zoneOrigins = generateOrigins(zonesX, zonesY);

  const zones = createMockLightingZones(map.cols * map.rows);
  const lightZones = generateLightZones(zones);

  return [
    ...zoneOrigins.flatMap((origin, i) =>
      createMockPlacedLightZone(zone, origin, lightZones[i]),
    ),
    createMockUnplacedLight({
      id: 'mock-01',
      name: 'Row 32 - A',
      level: 45,
    }),
    createMockUnplacedLight({
      id: 'mock-02',
      name: 'Row 32 - B',
      level: 87,
    }),
    createMockUnplacedLight({
      id: 'mock-03',
      name: 'Row 32 - C',
      level: 22,
    }),
    createMockUnplacedLight({
      id: 'mock-04',
      name: 'Row 32 - D',
      level: 100,
    }),
    createMockUnplacedLight({
      id: 'mock-05',
      name: 'Row 32 - E',
      level: 15,
    }),
    createMockUnplacedLight({
      id: 'mock-06',
      name: 'Row 33 - A',
      level: 35,
    }),
    createMockUnplacedLight({
      id: 'mock-07',
      name: 'Row 33 - B',
      level: 55,
    }),
    createMockUnplacedLight({
      id: 'mock-08',
      name: 'Row 33 - C',
      level: 75,
    }),
    createMockUnplacedLight({
      id: 'mock-09',
      name: 'Row 33 - D',
      level: 25,
    }),
    createMockUnplacedLight({
      id: 'mock-10',
      name: 'Row 33 - E',
      level: 0,
    }),
  ];
}
