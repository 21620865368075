import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { exhaustMap, map, pairwise, startWith, takeUntil, tap } from 'rxjs/operators';

type CompactStatusTrayState = 'moving' | 'open' | 'closed';
type CollapsibleAnimationState = {
  value: CompactStatusTrayState;
  params?: { closedOffset: number; openOffset: number };
};

@Component({
  selector: 'sui-compact-status-tray-header',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: flex;
        padding: 8px 24px;
        margin-top: 8px;
        margin-bottom: 8px;
        height: 64px;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    `,
  ],
})
export class CompactStatusTrayHeaderComponent {}

@Component({
  selector: 'sui-compact-status-tray-content',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        padding: 16px 24px 24px;
      }
    `,
  ],
})
export class CompactStatusTrayContentComponent {}

@Component({
  selector: 'sui-compact-status-tray',
  template: `
    <div class="compactStatusTrayNub" aria-role="button" (click)="onClickNub()" #nubRef>
      <div class="compactStatusTrayInteractivityIndicator"></div>
    </div>
    <!-- Using <ng-content /> this way enforces the use of the wrapper components -->
    <ng-content selector="sui-compact-status-tray-header"></ng-content>
    <ng-content selector="sui-compact-status-tray-content"></ng-content>
  `,
  styles: [
    `
      :host {
        display: block;
        position: fixed;
        top: 100%;
        background-color: var(--color-background-app-bar);
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.12);
        width: 100%;
      }

      .compactStatusTrayNub {
        display: flex;
        width: 100%;
        height: 24px;
        padding-top: 6px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
      }

      .compactStatusTrayInteractivityIndicator {
        display: block;
        width: 120px;
        height: 6px;
        border-radius: 6px;
        background-color: var(--color-background-raised-button);
      }
    `,
  ],
  animations: [
    trigger('collapsible', [
      transition('* => closed', [
        animate('200ms', style({ transform: 'translateY({{ closedOffset }}px)' })),
      ]),
      transition('* => open', [
        animate('200ms', style({ transform: 'translateY({{ openOffset }}px)' })),
      ]),
    ]),
  ],
})
export class CompactStatusTrayComponent implements AfterViewInit, OnDestroy {
  @ViewChild('nubRef') nubElementRef: ElementRef<HTMLDivElement>;
  @HostBinding('@collapsible') collapsibleAnimationState: CollapsibleAnimationState;
  touchSubscription: Subscription;

  get state() {
    return this.collapsibleAnimationState.value;
  }

  set state(state: CompactStatusTrayState) {
    this.collapsibleAnimationState = {
      value: state,
      params: {
        closedOffset: this.getClosedOffset(),
        openOffset: this.getOpenOffset(),
      },
    };
  }

  constructor(
    readonly hostElementRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) readonly document: Document,
  ) {
    this.state = 'closed';
  }

  setHostElementTransform(yOffset: number) {
    this.hostElementRef.nativeElement.style.transform = `translateY(${yOffset}px)`;
  }

  getOpenOffset(): number {
    const { height } = this.hostElementRef.nativeElement.getBoundingClientRect();

    return -height;
  }

  getClosedOffset(): number {
    return -112;
  }

  ngAfterViewInit(): void {
    const nubElement = this.nubElementRef.nativeElement;

    const touchstart$ = fromEvent<TouchEvent>(nubElement, 'touchstart');
    const touchmove$ = fromEvent<TouchEvent>(this.document, 'touchmove');
    const touchend$ = merge(
      fromEvent<TouchEvent>(this.document, 'touchend'),
      fromEvent<TouchEvent>(this.document, 'touchcancel'),
    );

    this.setHostElementTransform(this.getClosedOffset());

    this.touchSubscription = touchstart$
      .pipe(
        tap(event => event.preventDefault()),
        map(event => event.touches[0]),
        exhaustMap(startingTouch => {
          const closedOffset = this.getClosedOffset();
          const openOffset = this.getOpenOffset();
          const startingOffset = this.state === 'closed' ? closedOffset : openOffset;

          /**
           * Picking a direction opposite of the current state and setting
           * the state to be 'moving' before the first 'touchmove' event
           * handles the 'tap' use case where the user 'touchdown's the nub
           * and immediately 'touchend's the document.
           */
          let direction: 'up' | 'down' = this.state === 'closed' ? 'up' : 'down';
          this.state = 'moving';

          return touchmove$.pipe(
            map(event => event.touches[0]),
            startWith(startingTouch),
            pairwise(),
            tap(([previousTouch, currentTouch]) => {
              const diffSinceLastMoveEvent = currentTouch.clientY - previousTouch.clientY;
              const diffSinceStart = currentTouch.clientY - startingTouch.clientY;
              const offset = Math.min(
                closedOffset,
                Math.max(openOffset, startingOffset + diffSinceStart),
              );

              direction = diffSinceLastMoveEvent < 0 ? 'up' : 'down';

              this.setHostElementTransform(offset);
            }),
            takeUntil(
              touchend$.pipe(
                tap(() => (this.state = direction === 'up' ? 'open' : 'closed')),
              ),
            ),
          );
        }),
      )
      .subscribe();
  }

  /**
   * As the user interacts with the nub we manually set a transform
   * on the container element. When the user releases their pointer,
   * our animation fires to transition the container to its final
   * location. However, once the animation finishes the browser
   * will snap the container back to the last transform since
   * we're setting that manually on the container.
   *
   * This callback function updates the container's transform
   * when the animation finishes so that the container does
   * not bounce back to the last spot the user dragged it to
   */
  @HostListener('@collapsible.done', ['$event'])
  setTransformAfterAnimationFinishes(event: any) {
    if (event.toState === 'closed') {
      this.setHostElementTransform(this.getClosedOffset());
    } else if (event.toState === 'open') {
      this.setHostElementTransform(this.getOpenOffset());
    }
  }

  /**
   * The touch events already handle a basic tap, however the
   * touch events don't support mouse input. It's unlikely this
   * component will be used on a device with a mouse (since this
   * is intended for phones) but this click fallback provides
   * at least some functionality for those devices
   */
  onClickNub(): void {
    this.state = this.state === 'open' ? 'closed' : 'open';
  }

  ngOnDestroy(): void {
    this.touchSubscription?.unsubscribe();
  }
}
