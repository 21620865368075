export interface ControllerTypeModel {
  id: string;
  name: string;
}

export function createMockControllerType(
  changes: Partial<ControllerTypeModel> = {},
): ControllerTypeModel {
  return {
    id: 'DIM10-250-11',
    name: 'DIM10-250-11',
    ...changes,
  };
}
