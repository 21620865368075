import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoginFormComponent } from './login-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SuiAlertModule } from '../alert';
import { SuiCheckboxModule } from '../checkbox';
import { SuiFormsModule } from '../forms';
import { SuiValidatorsModule } from '../validators';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    SuiAlertModule,
    SuiCheckboxModule,
    SuiFormsModule,
    SuiValidatorsModule,
  ],
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent],
})
export class SuiLoginFormModule {}
