import { Injectable, Provider } from '@angular/core';
// import { loadImage } from 'canvas';
import { ImageLoader } from '../../services';

async function loadImage(src: string): Promise<HTMLImageElement> {
  return new HTMLImageElement();
}

@Injectable()
export class ImageLoaderNode implements ImageLoader {
  async loadImage(src: string): Promise<HTMLImageElement> {
    return (await loadImage(src)) as any;
  }
}

export const IMAGE_LOADER_NODE_PROVIDERS: Provider[] = [
  { provide: ImageLoader, useClass: ImageLoaderNode },
];
