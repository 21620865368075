import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BigSelectOption } from './big-select.models';
import * as BigSelectActions from './big-select.actions';
import { BigSelectState } from './big-select.state';

@Component({
  selector: 'sui-big-select-list',
  template: ``,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BigSelectListComponent,
    },
  ],
})
export class BigSelectListComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  initialized = false;
  subscriptions: Subscription = new Subscription();
  @Input() title = '';
  @Input() options: BigSelectOption[] = [];
  @Input() value: string[] = [];
  @Input() disabled = false;
  @Output() changed = new EventEmitter<string[]>();

  onChangeFn: (value: string[]) => void = () => void 0;
  onTouchedFn: () => void = () => void 0;

  constructor(readonly state: BigSelectState) {}

  triggerUpdate() {
    this.state.dispatch(
      BigSelectActions.updateListAction({
        title: this.title,
        options: this.options,
        value: new Set(this.value),
        disabled: this.disabled,
      }),
    );
  }

  ngOnInit(): void {
    this.initialized = true;

    console.assert(this.title !== '', 'Big Select Lists require a title');

    this.state.dispatch(
      BigSelectActions.addListAction({
        title: this.title,
        options: this.options,
        value: new Set(this.value),
        disabled: this.disabled,
      }),
    );

    this.subscriptions.add(
      this.state.valueChanges(this.title).subscribe(value => {
        this.value = Array.from(value);
        this.changed.emit(value);
        this.onChangeFn(value);
        this.onTouchedFn();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) return;

    console.assert(!changes['title'], 'Big Select Lists cannot have dynamic titles');

    this.triggerUpdate();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.state.dispatch(BigSelectActions.removeListAction(this.title));
  }

  writeValue(value: string[]): void {
    this.value = value;

    this.triggerUpdate();
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    this.triggerUpdate();
  }
}
