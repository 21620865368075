import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LightSuiModel as LightModel } from '@spog-ui/shared/models/lights';
import {
  ControllerTypeModel,
  DeviceStatusTable,
  FixtureTypeModel,
  LightCreateProps,
} from '../../models';
import { AddLightActions } from '../../actions';
import { ZoneModel } from '@spog-ui/shared/models/zones';

export abstract class AddLightPageState {
  abstract lights$: Observable<LightModel[]>;
  abstract controllerTypes$: Observable<ControllerTypeModel[]>;
  abstract fixtureTypes$: Observable<FixtureTypeModel[]>;
  abstract deviceStatusTable$: Observable<DeviceStatusTable>;
  abstract zones$: Observable<ZoneModel[]>;
  abstract pending$: Observable<boolean>;
  abstract loading$: Observable<boolean>;

  abstract dispatch(action: AddLightActions.Union): void;
}

@Component({
  selector: 'lit-add-light-page',
  template: `
    @if (state.loading$ | async) {
      <div class="litAddLightPageLoader">
        <sui-spinner></sui-spinner>
      </div>
    } @else {
      <lit-light-form
        [lights]="state.lights$ | async"
        [controllerTypes]="state.controllerTypes$ | async"
        [fixtureTypes]="state.fixtureTypes$ | async"
        [deviceStatusTable]="state.deviceStatusTable$ | async"
        [zones]="state.zones$ | async"
        [pending]="state.pending$ | async"
        (save)="onSave($event)"
        (cancel)="onCancel()"
        >
      </lit-light-form>
    }
    
    `,
  styles: [
    `
      .litAddLightPageLoader {
        display: block;
        padding: 80px 0;
        margin: 0 auto;
      }

      sui-spinner {
        display: block;
        width: 50px;
        margin: 0 auto;
      }
    `,
  ],
})
export class AddLightPageComponent implements OnInit {
  constructor(public state: AddLightPageState) {}

  ngOnInit() {
    this.state.dispatch(AddLightActions.enterAction());
  }

  onSave(light: LightCreateProps) {
    this.state.dispatch(AddLightActions.saveAction(light));
  }

  onCancel() {
    this.state.dispatch(AddLightActions.cancelAction());
  }
}
