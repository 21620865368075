import { Color, Pixel } from './color';

export async function imageToCanvas(src: string) {
  const img = new Image();
  const load = new Promise(resolve => (img.onload = resolve));
  img.src = src;
  await load;

  const imageCanvas = document.createElement('canvas');
  imageCanvas.width = imageCanvas.height = img.width;

  if (!imageCanvas.getContext('2d'))
    throw new Error('Can not get 2d context of current element');

  const imageCtx = imageCanvas.getContext('2d');

  if (!imageCtx) throw new Error('imageCtx is null of undefined');
  imageCtx.drawImage(img, 0, 0);

  return imageCanvas;
}

export function fillImage(imageCanvas: HTMLCanvasElement, [r, g, b]: Color) {
  if (!imageCanvas.getContext('2d'))
    throw new Error('Can not get 2d context of current element');
  const imageCtx = imageCanvas.getContext('2d');

  if (!imageCtx) throw new Error('imageCtx is null of undefined');
  const imageData = imageCtx.getImageData(0, 0, imageCanvas.width, imageCanvas.height);

  const bytes = imageData.data;

  for (let i = 0, n = bytes.length; i < n; i += 4) {
    bytes[i + Pixel.r] = r;
    bytes[i + Pixel.g] = g;
    bytes[i + Pixel.b] = b;
  }

  imageCtx.putImageData(imageData, 0, 0);
}
