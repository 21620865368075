import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Color, ColorChannels, DEFAULT_COLOR } from '@sui/color-picker';

export interface ColorFadeBehaviorParameters {
  bytesRed: number;
  bytesGreen: number;
  bytesBlue: number;
  bytesAmber: number;
  bytesWhite: number;

  fadeTimeSeconds: number;
}

@Component({
  selector: 'sui-bf-color-fade',
  template: `
    <form [formGroup]="form">
      <p class="bfDescription">
        All lights in the zone fade from their current color to a specific color over a
        period of time.
      </p>
      <p class="bfLabel">Fade to:</p>
      <clr-color-picker formControlName="color"></clr-color-picker>
      <p class="bfLabel">Over a period of:</p>
      <mat-form-field>
        <input
          type="number"
          matInput
          formControlName="fadeTimeMinutes"
          step="1"
          min="0"
          max="500"
        />
        <span matSuffix>minutes</span>
        <mat-error> Must be a whole number between 0 and 500</mat-error>
      </mat-form-field>
      <p class="bfLabel">and</p>
      <mat-form-field>
        <input
          matInput
          formControlName="fadeTimeSeconds"
          type="number"
          step="1"
          min="0"
          max="32767"
        />
        <span matSuffix>seconds</span>
        <mat-error> Must be a whole number of seconds between 0 and 32,767. </mat-error>
      </mat-form-field>
    </form>
  `,
  styles: [
    `
      form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
      }
      .bfDescription {
        margin: 0;
        padding: 0;
      }
    `,
  ],
})
export class BFColorFadeComponent {
  integerOnly = /^\d+$/;

  form = new UntypedFormGroup({
    color: new UntypedFormControl(DEFAULT_COLOR),
    fadeTimeMinutes: new UntypedFormControl(0, [
      Validators.min(0),
      Validators.max(500),
      Validators.pattern(this.integerOnly),
      Validators.required,
    ]),
    fadeTimeSeconds: new UntypedFormControl(2, [
      Validators.min(0),
      Validators.max(59),
      Validators.pattern(this.integerOnly),
      Validators.required,
    ]),
  });

  getBehaviorParameters(): ColorFadeBehaviorParameters | null {
    const color = this.form.get('color')?.value;
    const fadeTimeMinutes = this.form.get('fadeTimeMinutes')?.value;
    const fadeTimeSeconds = this.form.get('fadeTimeSeconds')?.value;

    if (!color) return null;

    return {
      bytesRed: color[ColorChannels.RED],
      bytesGreen: color[ColorChannels.GREEN],
      bytesBlue: color[ColorChannels.BLUE],
      bytesAmber: color[ColorChannels.AMBER],
      bytesWhite: color[ColorChannels.WHITE],

      fadeTimeSeconds: fadeTimeMinutes * 60 + fadeTimeSeconds,
    };
  }

  writeBehaviorParameters(parameters: ColorFadeBehaviorParameters): void {
    const color = [
      parameters.bytesRed,
      parameters.bytesGreen,
      parameters.bytesBlue,
      parameters.bytesAmber,
      parameters.bytesWhite,
    ];

    this.form.setValue(
      {
        color,
        fadeTimeMinutes: Math.floor(parameters.fadeTimeSeconds / 60),
        fadeTimeSeconds: parameters.fadeTimeSeconds % 60,
      },
      { onlySelf: true, emitEvent: false },
    );
  }
}
