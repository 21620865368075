import { NgModule } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { SLIDER_DIRECTIVES } from './slider.component';

@NgModule({
  imports: [MatSliderModule],
  declarations: [SLIDER_DIRECTIVES],
  exports: [SLIDER_DIRECTIVES],
})
export class SuiSliderModule {}
