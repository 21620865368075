import { DateTime } from 'luxon';
import {
  MetricHistoryInternalModel,
  MetricHistoryViewModel,
} from './metric-history.model';

export function createMockMetricHistoryInternalModel(
  updates?: Partial<MetricHistoryInternalModel>,
): MetricHistoryInternalModel {
  return {
    id: 'mock-indie-sensor-id',
    history: [
      { timestamp: '2020-11-11T05:00:00Z', value: 1312 },
      { timestamp: '2020-11-11T06:00:00Z', value: 1179 },
    ],
    ...updates,
  };
}

export function createMockMetricHistoryViewModel(
  updates?: Partial<MetricHistoryViewModel>,
  zone = 'America/New York',
): MetricHistoryViewModel {
  return {
    units: 'Pa',
    min: 1100,
    max: 1500,
    history: createMockMetricHistoryInternalModel().history.map(
      ({ value, timestamp }) => ({
        value,
        timestamp: DateTime.fromISO(timestamp, { zone }),
      }),
    ),
    ...updates,
  };
}

export function createMockMetricHistoryGQLModel() {
  return {
    id: 'mock-indie-sensor-id',
    history: [
      { timestamp: '2020-11-11T05:00:00Z', value: 1312 },
      { timestamp: '2020-11-11T06:00:00Z', value: 1179 },
    ],
  };
}
