import { Interval } from '@spog-ui/graphql/types';
import {
  EnergyConsumptionGQLModel,
  EnergyConsumptionInternalModel,
} from './energy-consumption.model';
import { DateTime } from 'luxon';

export function createMockEnergyConsumptions(): EnergyConsumptionInternalModel[] {
  return [
    {
      start: new Date('2020-10-13T00:00:00Z').toISOString(),
      end: new Date('2020-10-20T00:00:00Z').toISOString(),
      timezone: 'UTC',
      interval: Interval.HOUR,
      reports: [
        {
          timestamp: DateTime.local().toJSDate().toISOString(),
          energy: 1234,
          cost: '$567',
        },
      ],
    },
    {
      start: new Date('2020-10-13T00:00:00Z').toISOString(),
      end: new Date('2020-10-20T00:00:00Z').toISOString(),
      timezone: 'UTC',
      interval: Interval.HOUR,
      reports: [
        {
          timestamp: DateTime.local().toJSDate().toISOString(),
          energy: 1234,
          cost: '$567',
        },
      ],
    },
  ];
}

export function createMockEnergyConsumptionGQLModel(): EnergyConsumptionGQLModel {
  return {
    __typename: 'EnergyConsumption',
    start: '2021-01-01T00:00:00.000Z',
    end: '2021-01-07T12:00:00.000Z',
    timezone: 'UTC',
    interval: Interval.HOUR,
    reports: [
      {
        __typename: 'EnergyConsumptionReport',
        timestamp: '2021-01-01T00:00:00.000Z',
        cost: '678910',
        energy: { __typename: 'EnergyOutput', kWh: 12345 },
      },
    ],
  };
}
