import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CompactStatusTrayComponent,
  CompactStatusTrayContentComponent,
  CompactStatusTrayHeaderComponent,
} from './compact-status-tray.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CompactStatusTrayComponent,
    CompactStatusTrayHeaderComponent,
    CompactStatusTrayContentComponent,
  ],
  exports: [
    CompactStatusTrayComponent,
    CompactStatusTrayHeaderComponent,
    CompactStatusTrayContentComponent,
  ],
})
export class CompactStatusTrayComponentModule {}
