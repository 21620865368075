import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PanelHeaderComponent } from './panel-header.component';
import { PanelOverlayComponent } from './panel-overlay.component';
import { PanelComponent } from './panel.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [PanelHeaderComponent, PanelOverlayComponent, PanelComponent],
  exports: [PanelComponent],
})
export class SuiPanelModule {}
