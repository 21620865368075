import {
  DispositionLevel,
  RunningState,
  TriggerStatus as TriggerStatusGQLEnum,
} from '@spog-ui/graphql/types';
import {
  toTriggerViewModelFromInternal,
  TriggerFormModel,
  TriggerGQLModel,
  TriggerInternalModel,
  TriggerStateChangeWSModel,
  TriggerViewModel,
} from './trigger.model';

export function createMockTriggerGQLModel(
  updates?: Partial<TriggerGQLModel>,
): TriggerGQLModel {
  return {
    id: 'mock-trigger-id',
    name: 'Power Below 200 kW',
    notifications: [
      { __typename: 'WebhookTriggerNotification', url: 'spogforthe.win/nowhere' },
    ],
    industrialSensor: { id: 'mock-sense-4-20-id' },
    state: {
      lastStatusUpdate: '11-11-2020T05:30:00Z',
      status: TriggerStatusGQLEnum.UNKNOWN,
    },
    transitionConfig: {
      activeTransitionDelayInMinutes: 15,
      inactiveTransitionDelayInMinutes: 15,
      unknownTransitionDelayInMinutes: 15,
    },
    condition: {
      gt: 45,
    },
    ...updates,
  };
}

export function createMockTriggerStateChangeWSModel(
  updates?: Partial<TriggerStateChangeWSModel>,
): TriggerStateChangeWSModel {
  return {
    triggerId: 'mock-trigger-id',
    lastStatusUpdate: '11-11-2020T05:45:00Z',
    value: 'ACTIVE',
    ...updates,
  };
}

export function createMockTriggerInternalModel(
  updates?: Partial<TriggerInternalModel>,
): TriggerInternalModel {
  return {
    id: 'mock-trigger-id',
    name: 'Power Over 15 Wumbo',
    state: { lastStatusUpdate: '2020-11-11T05:00:00Z', status: 'ACTIVE' },
    history: [
      { timestamp: '2020-11-11T04:00:00Z', value: 'ACTIVE' },
      { timestamp: '2020-11-11T04:15:00Z', value: 'ACTIVE' },
      { timestamp: '2020-11-11T04:30:00Z', value: 'INACTIVE' },
      { timestamp: '2020-11-11T04:45:00Z', value: 'UNKNOWN' },
      { timestamp: '2020-11-11T05:00:00Z', value: 'ACTIVE' },
    ],
    sensorId: 'mock-sense-4-20-id',
    condition: { gt: 15 },
    transitionConfig: {
      activeTransitionDelayInMinutes: 15,
      inactiveTransitionDelayInMinutes: 15,
      unknownTransitionDelayInMinutes: 45,
    },
    notifications: {
      webhooks: [{ url: 'spogforthe.win/nowhere' }],
    },
    ...updates,
  };
}

export function createMockTriggerFormModel(
  updates?: Partial<TriggerFormModel>,
): TriggerFormModel {
  return {
    name: 'Temperature Over 15 K',
    sensorId: 'mock-deprecated-climate-sensor-id',
    condition: { gt: 15 },
    advanced: {
      activeTransitionDelayInMinutes: 15,
      inactiveTransitionDelayInMinutes: 15,
      unknownTransitionDelayInMinutes: 45,
    },
    actions: {
      webhook: 'spogforthe.win/nowhere',
      runningState: RunningState.RUNNING,
      disposition: DispositionLevel.NORMAL,
    },
    ...updates,
  };
}

export function createMockTriggerFormModelWithNoRunningState(
  updates?: Partial<TriggerFormModel>,
): TriggerFormModel {
  return {
    name: 'Temperature Over 15 K',
    sensorId: 'mock-deprecated-climate-sensor-id',
    condition: { gt: 15 },
    advanced: {
      activeTransitionDelayInMinutes: 15,
      inactiveTransitionDelayInMinutes: 15,
      unknownTransitionDelayInMinutes: 45,
    },
    actions: { webhook: 'spogforthe.win/nowhere', disposition: DispositionLevel.NORMAL },
    ...updates,
  };
}

export function createMockTriggerViewModel(
  updates?: Partial<TriggerViewModel>,
): TriggerViewModel {
  return {
    ...toTriggerViewModelFromInternal(
      createMockTriggerInternalModel(),
      'America/Chicago',
    ),
    ...updates,
  };
}
