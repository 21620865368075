import { NgModule } from '@angular/core';
import {
  CompactStatusTrayComponentModule,
  WideStatusTrayComponentModule,
} from './components';

@NgModule({
  exports: [CompactStatusTrayComponentModule, WideStatusTrayComponentModule],
})
export class SuiStatusTrayModule {}
