import {
  AddOrganizationUserFormModel,
  OrganizationUserFormModel,
  OrganizationUserGQLModel,
  OrganizationUserInternalModel,
  OrganizationUserWSModel,
} from './organization-user.model';
import { OrganizationRole } from '@spog-ui/graphql/types';

export function createMockOrganizationUser(
  updates?: Partial<OrganizationUserInternalModel>,
): OrganizationUserInternalModel {
  return {
    id: '12345',
    name: 'test user',
    email: 'test@user.com',
    organizationRole: OrganizationRole.MEMBER,
    ...updates,
  };
}

export function createMockGQLOrganizationUser(
  updates?: Partial<OrganizationUserGQLModel>,
): OrganizationUserGQLModel {
  return {
    node: {
      id: '12345',
      name: 'test user',
      email: 'test@user.com',
    },
    sites: [],
    organizationRole: OrganizationRole.MEMBER,
    ...updates,
  };
}

export function createMockOrganizationUserForm(
  updates?: Partial<OrganizationUserFormModel>,
): OrganizationUserFormModel {
  return {
    id: '12345',
    name: 'test user',
    email: 'test@user.com',
    organizationRole: OrganizationRole.MEMBER,
    ...updates,
  };
}

export function createMockAddOrganizationUserForm(
  updates?: Partial<AddOrganizationUserFormModel>,
): AddOrganizationUserFormModel {
  return {
    name: 'test user',
    email: 'test@user.com',
    organizationRole: OrganizationRole.MEMBER,
    ...updates,
  };
}

export function createMockOrganizationUserWSModel(
  updates?: Partial<OrganizationUserWSModel>,
): OrganizationUserWSModel {
  return {
    userId: '12345',
    name: 'test user',
    email: 'test@user.com',
    organizationRole: OrganizationRole.MEMBER,
    organizationId: 'org-1',
    ...updates,
  };
}
