import {
  BehaviorGQLModel,
  BehaviorInternalModel,
  BehaviorType,
  BehaviorApplication,
} from './behavior.model';

export function createMockBehaviors(): BehaviorInternalModel[] {
  return [
    {
      id: BehaviorType.OccupancyOnly,
      displayGroup: 2,
      displayOrder: 1,
      application: BehaviorApplication.LIGHTING,
      isLicensed: true,
    },
    {
      id: BehaviorType.On,
      displayGroup: 1,
      displayOrder: 1,
      application: BehaviorApplication.LIGHTING,
      isLicensed: true,
    },
    {
      id: BehaviorType.Off,
      displayGroup: 1,
      displayOrder: 2,
      application: BehaviorApplication.LIGHTING,
      isLicensed: true,
    },
    {
      id: BehaviorType.None,
      displayGroup: 0,
      displayOrder: 1,
      application: BehaviorApplication.CLIMATE,
      isLicensed: true,
    },
    {
      id: BehaviorType.Dimmer,
      displayGroup: 1,
      displayOrder: 3,
      application: BehaviorApplication.LIGHTING,
      isLicensed: true,
    },
    {
      id: BehaviorType.BasicColor,
      displayGroup: 2,
      displayOrder: 4,
      application: BehaviorApplication.LIGHTING,
      isLicensed: false,
    },
  ];
}

export function createMockGQLBehaviors(): BehaviorGQLModel[] {
  return [
    {
      id: BehaviorType.OccupancyOnly,
      displayGroup: 2,
      displayOrder: 1,
      application: BehaviorApplication.LIGHTING,
      isLicensed: true,
    },
    {
      id: BehaviorType.On,
      displayGroup: 1,
      displayOrder: 1,
      application: BehaviorApplication.LIGHTING,
      isLicensed: true,
    },
    {
      id: BehaviorType.Off,
      displayGroup: 1,
      displayOrder: 2,
      application: BehaviorApplication.LIGHTING,
      isLicensed: true,
    },
    {
      id: BehaviorType.None,
      displayGroup: 0,
      displayOrder: 1,
      application: BehaviorApplication.CLIMATE,
      isLicensed: true,
    },
    {
      id: BehaviorType.Dimmer,
      displayGroup: 1,
      displayOrder: 3,
      application: BehaviorApplication.LIGHTING,
      isLicensed: true,
    },
  ];
}
