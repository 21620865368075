import { BehaviorParameters, BehaviorType } from '@spog-ui/shared/models/behaviors';
import { ZoneModel } from '@spog-ui/shared/models/zones';

export interface ZoneBehaviorModel {
  id: string;
  name: string;
  behaviorId: BehaviorType;
  behaviorParameters: BehaviorParameters;
}

export interface LightCreateProps {
  name: string;
  snapaddr: string;
  controllerType: string;
  highEndTrim: number;
  powerOnLevel: number;
  fixtureType: string;
  zones: ZoneModel[];
}

export interface LightUpdateProps extends LightCreateProps {
  id: string;
}

// comparison function used by onprem state adapter (light.state-adapters)
export function isLightEqual(
  previous: LightUpdateProps | null,
  current: LightUpdateProps | null,
): boolean {
  if (previous === null || current === null) return false;

  if (
    previous.name !== current.name ||
    previous.snapaddr !== current.snapaddr ||
    previous.controllerType !== current.controllerType ||
    previous.highEndTrim !== current.highEndTrim ||
    previous.powerOnLevel !== current.powerOnLevel ||
    previous.fixtureType !== current.fixtureType
  )
    return false;

  const previousZonesFound = previous.zones.every(
    zone => current.zones.find(currentZone => currentZone.id === zone.id) !== undefined,
  );

  if (!previousZonesFound || previous.zones.length !== current.zones.length) return false;

  return true;
}
