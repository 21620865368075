import { Component } from '@angular/core';

@Component({
  selector: 'sui-nav',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 8px;
        border-bottom: solid 1px var(--color-foreground-divider);
      }

      :host ::ng-deep a {
        color: var(--color-foreground-text);
        text-decoration: none;
        padding: 5px 0px;
        opacity: 1;
        transition: color 200ms;
        cursor: pointer;
      }

      :host ::ng-deep a:hover {
        color: var(--color-500);
      }

      :host ::ng-deep a sui-icon {
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 4px;
      }
    `,
  ],
})
export class NavComponent {}

export const NAV_DIRECTIVES = [NavComponent];
