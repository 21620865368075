import {
  EquipmentDisposition,
  EquipmentDispositionChange,
  EquipmentRunningState,
  EquipmentFormModel,
  EquipmentProfileViewModel,
  EquipmentWSModel,
  EquipmentMapViewModel,
  EquipmentTriggerDispositionChange,
} from './equipment.model';
import {
  AssetDispositionChangeModelFragment,
  DefinedAssetTypeEnum,
  DispositionLevel,
  EquipmentModelFragment,
  RunningState,
} from '@spog-ui/graphql/types';
import { createMockIndieSensorGQLModel } from '@spog-ui/shared/models/indie-sensors';
import { createMockResourceGroupGQLModel } from '@spog-ui/shared/models/resource-groups';
import {
  createMockEnergyConsumptionGQLModel,
  createMockEnergyConsumptions,
} from '@spog-ui/shared/models/energy-consumptions';
import { EquipmentInternalModel } from '@spog-ui/shared/models/resource-groups';
import { graphql } from 'msw';
import { DateTime } from 'luxon';

export function createMockEquipmentFormModel(
  updates: Partial<EquipmentFormModel> = {},
): EquipmentFormModel {
  return {
    name: 'Mock Asset',
    existingTypeValue: DefinedAssetTypeEnum.BAGHOUSE,
    newTypeValue: null,
    make: null,
    model: null,
    imageUrl: null,
    resourceGroupIds: [],
    industrialSensorIds: [],
    ...updates,
  };
}

export function createMockEquipment(): EquipmentInternalModel[] {
  return [
    {
      id: 'mock-equipment-id-1',
      name: 'Mock Equipment 1',
      make: null,
      model: null,
      imageUrl: null,
      assetType: DefinedAssetTypeEnum.BAGHOUSE,
      resourceGroupIds: ['mock-rg-id-1', 'mock-rg-id-2', 'mock-rg-id-3'],
      industrialSensorIds: ['mock-indie-sensor-1'],
      energyConsumption: createMockEnergyConsumptions()[0],
      runningState: createMockAssetRunningState({ state: RunningState.RUNNING }),
      dispositionChanges: [],
      disposition: createMockAssetDisposition(),
    },
    {
      id: 'mock-equipment-id-2',
      name: 'Mock Equipment 2',
      make: null,
      model: null,
      imageUrl: 'https://www.image-test.com/image.png',
      assetType: DefinedAssetTypeEnum.CHILLER,
      resourceGroupIds: ['mock-rg-id-1', 'mock-rg-id-2', 'mock-rg-id-3'],
      industrialSensorIds: ['mock-indie-sensor-1', 'mock-indie-sensor-2'],
      energyConsumption: createMockEnergyConsumptions()[1],
      runningState: createMockAssetRunningState(),
      dispositionChanges: [],
      disposition: createMockAssetDisposition(),
    },
  ];
}

export function createMockEquipmentByState(state: RunningState): EquipmentInternalModel {
  return {
    id: 'mock-equipment-id-1',
    name: 'Mock Equipment 1',
    make: null,
    model: null,
    imageUrl: null,
    assetType: DefinedAssetTypeEnum.BAGHOUSE,
    resourceGroupIds: ['mock-rg-id-1', 'mock-rg-id-2', 'mock-rg-id-3'],
    industrialSensorIds: ['mock-indie-sensor-1'],
    energyConsumption: createMockEnergyConsumptions()[0],
    runningState: createMockAssetRunningState({ state: state }),
    dispositionChanges: [],
    disposition: createMockAssetDisposition(),
  };
}
export function createMockEquipmentWithChildren(): EquipmentInternalModel[] {
  return [
    {
      id: 'mock-equipment-id-1',
      name: 'Parent Equipment',
      make: null,
      model: null,
      imageUrl: null,
      assetType: DefinedAssetTypeEnum.BAGHOUSE,
      resourceGroupIds: ['mock-equipment-id-2', 'mock-equipment-id-4'],
      industrialSensorIds: ['mock-indie-sensor-1'],
      energyConsumption: createMockEnergyConsumptions()[0],
      runningState: createMockAssetRunningState(),
      dispositionChanges: [],
      disposition: createMockAssetDisposition(),
    },
    {
      id: 'mock-equipment-id-2',
      name: 'Child Equipment with 1 Child',
      make: null,
      model: null,
      imageUrl: 'https://www.image-test.com/image.png',
      assetType: DefinedAssetTypeEnum.CHILLER,
      resourceGroupIds: ['mock-equipment-id-3'],
      industrialSensorIds: ['mock-indie-sensor-1', 'mock-indie-sensor-2'],
      energyConsumption: createMockEnergyConsumptions()[1],
      runningState: createMockAssetRunningState(),
      dispositionChanges: [],
      disposition: createMockAssetDisposition(),
    },
    {
      id: 'mock-equipment-id-3',
      name: "Destiny's Child",
      make: null,
      model: null,
      imageUrl: 'https://www.image-test.com/image.png',
      assetType: DefinedAssetTypeEnum.CHILLER,
      resourceGroupIds: [],
      industrialSensorIds: ['mock-indie-sensor-1', 'mock-indie-sensor-2'],
      energyConsumption: createMockEnergyConsumptions()[1],
      runningState: createMockAssetRunningState(),
      dispositionChanges: [],
      disposition: createMockAssetDisposition(),
    },
    {
      id: 'mock-equipment-id-4',
      name: 'Child Equipment with No Children',
      make: null,
      model: null,
      imageUrl: 'https://www.image-test.com/image.png',
      assetType: DefinedAssetTypeEnum.CHILLER,
      resourceGroupIds: [],
      industrialSensorIds: ['mock-indie-sensor-1', 'mock-indie-sensor-2'],
      energyConsumption: createMockEnergyConsumptions()[1],
      runningState: createMockAssetRunningState(),
      dispositionChanges: [],
      disposition: createMockAssetDisposition(),
    },
  ];
}

export function createMockGqlEquipment(): EquipmentModelFragment[] {
  return [
    {
      __typename: 'Asset',
      id: 'mock-equipment-id-1',
      name: 'Equipment 1',
      make: '',
      model: '',
      imageUrl: undefined,
      type: { __typename: 'DefinedAssetType', type: DefinedAssetTypeEnum.BAGHOUSE },
      resourceGroups: [],
      industrialSensors: [],
      energyConsumption: createMockEnergyConsumptionGQLModel(),
      runningState: createMockAssetRunningState(),
      dispositionChanges: [],
      disposition: createMockAssetDisposition(),
    },
    {
      __typename: 'Asset',
      id: 'mock-equipment-id-2',
      name: 'Equipment 2',
      make: '',
      model: '',
      imageUrl: undefined,
      type: { __typename: 'DefinedAssetType', type: DefinedAssetTypeEnum.COMPRESSOR },
      resourceGroups: [],
      industrialSensors: [],
      energyConsumption: createMockEnergyConsumptionGQLModel(),
      runningState: createMockAssetRunningState({ state: RunningState.RUNNING }),
      dispositionChanges: [],
      disposition: createMockAssetDisposition({
        level: DispositionLevel.INFO,
      }),
    },
  ];
}

export function createMockEquipmentWSModel(
  updates?: Partial<EquipmentWSModel>,
): EquipmentWSModel {
  return {
    id: 'mock-asset-id',
    name: 'Test equipment',
    make: null,
    model: null,
    imageUrl: null,
    siteId: 'mock-site-id',
    type: DefinedAssetTypeEnum.BAGHOUSE,
    resourceGroupIds: [createMockResourceGroupGQLModel()].map(rg => rg.id),
    industrialSensorIds: [createMockIndieSensorGQLModel()].map(is => is.id),
    ...updates,
  };
}

export function createMockCustomAssetTypes(): string[] {
  return ['Mock Custom Type 1', 'Mock Custom Type 2', 'Another Custom Type'];
}

export function createMockEquipmentProfileViewModels(): EquipmentProfileViewModel[] {
  return [
    {
      id: '66f4b7c4-502b-484b-a236-2b2819a55613',
      name: 'Quality Assurance Test Vessel #1',
      assetType: 'CHAMBER',
      make: 'Safari',
      model: 'Bonobo',
      resourceGroupIds: [],
      industrialSensorIds: [],
      imageUrl:
        'https://s3.amazonaws.com/spog-equipment-images-development/67ec5158-221d-4044-b3b7-3d560f5f7ce3',
      runningState: createMockAssetRunningState(),
      dispositionChanges: [],
      disposition: createMockAssetDisposition(),
    },
    {
      id: 'af9bb3fe-4f82-48d2-8848-af52b556573c',
      name: 'BAGHOUSE 1',
      assetType: 'BAGHOUSE',
      make: null,
      model: null,
      resourceGroupIds: [],
      industrialSensorIds: [],
      imageUrl: null,
      runningState: createMockAssetRunningState({ state: RunningState.RUNNING }),
      dispositionChanges: [],
      disposition: createMockAssetDisposition({ level: DispositionLevel.INFO }),
    },
  ];
}

export function createMockAssetRunningState(
  updates?: Partial<EquipmentRunningState>,
): EquipmentRunningState {
  return {
    state: RunningState.STOPPED,
    timestamp: '2020-11-09T17:12:43.761Z',
    ...updates,
  };
}

export function createMockAssetDisposition(updates?: Partial<EquipmentDisposition>) {
  return {
    level: DispositionLevel.NORMAL,
    timestamp: '2020-11-09T17:12:43.761Z',
    ...updates,
  };
}

export function createMockAssetDispositionChange(
  updates?: Partial<EquipmentDispositionChange>,
) {
  return {
    level: DispositionLevel.NORMAL,
    timestamp: '2020-11-09T17:12:43.761Z',
    message:
      "Letting Knuckles pilot the shuttle over here was more dangerous than you'll ever be. - Sonic Adventure 2",
    ...updates,
  };
}

export function createMockAssetTriggerDispositionChange(
  updates?: Partial<EquipmentTriggerDispositionChange>,
) {
  return {
    ...createMockAssetDispositionChange(),
    triggerId: 'mock-trigger-id',
    ...updates,
  };
}

export function createMockEquipmentMapViewModel(
  updates?: Partial<EquipmentMapViewModel>,
): EquipmentMapViewModel {
  return {
    floorPlanX: 10,
    floorPlanY: 11,
    id: 'mock-equipment-map-view-model',
    name: 'Mock Equipment Map View Model',
    runningState: RunningState.RUNNING,
    disposition: DispositionLevel.NORMAL,
    ...updates,
  };
}

export function createMockAssetDispositionChanges(): AssetDispositionChangeModelFragment[] {
  return [
    {
      __typename: 'AssetTriggerDispositionChange',
      triggerId: 'mock-trigger-id-1',
      timestamp: DateTime.local().minus({ days: 3 }).toISO(),
      message: 'Mock asset trigger disposition level unknown',
      level: DispositionLevel.UNKNOWN,
    },
    {
      __typename: 'AssetTriggerDispositionChange',
      triggerId: 'mock-trigger-id-2',
      timestamp: DateTime.local().minus({ months: 4 }).toISO(),
      message: 'Mock asset trigger disposition level critical',
      level: DispositionLevel.CRITICAL,
    },
    {
      __typename: 'AssetTriggerDispositionChange',
      triggerId: 'mock-trigger-id-3',
      timestamp: DateTime.local().minus({ days: 21 }).toISO(),
      message: 'Mock asset trigger disposition level warning',
      level: DispositionLevel.WARNING,
    },
    {
      __typename: 'AssetTriggerDispositionChange',
      triggerId: 'mock-trigger-id-5',
      timestamp: DateTime.local().minus({ minutes: 10 }).toISO(),
      message: 'Mock asset trigger disposition level info',
      level: DispositionLevel.INFO,
    },
    {
      __typename: 'AssetTriggerDispositionChange',
      triggerId: 'mock-trigger-id-5',
      timestamp: DateTime.local().minus({ days: 36 }).toISO(),
      message: 'Mock asset trigger disposition level normal',
      level: DispositionLevel.NORMAL,
    },
  ];
}

export function createMockPositionedEquipment(): EquipmentMapViewModel[] {
  return [
    {
      floorPlanX: 13,
      floorPlanY: 177,
      id: 'random-id-1',
      name: 'Positioned Nonchild Piece of Equipment',
      disposition: DispositionLevel.UNKNOWN,
      runningState: RunningState.RUNNING,
    },
    {
      floorPlanX: 13,
      floorPlanY: 177,
      id: 'random-id-2',
      name: 'Filtered Positioned Nonchild Piece of Equipment',
      disposition: DispositionLevel.CRITICAL,
      runningState: RunningState.STOPPED,
    },
    {
      floorPlanX: 13,
      floorPlanY: 177,
      id: 'random-id-3',
      name: 'Filtered Positioned Nonchild Piece of Equipment',
      disposition: DispositionLevel.WARNING,
      runningState: RunningState.STOPPED,
    },
    {
      floorPlanX: 13,
      floorPlanY: 177,
      id: 'random-id-4',
      name: 'Filtered Positioned Nonchild Piece of Equipment',
      disposition: DispositionLevel.NORMAL,
      runningState: RunningState.STOPPED,
    },
    {
      floorPlanX: 13,
      floorPlanY: 177,
      id: 'random-id-5',
      name: 'Filtered Positioned Nonchild Piece of Equipment',
      disposition: DispositionLevel.INFO,
      runningState: RunningState.STOPPED,
    },
    {
      floorPlanX: 13,
      floorPlanY: 177,
      id: 'random-id-5',
      name: 'This is yet another Positioned Nonchild Piece of Equipment',
      disposition: DispositionLevel.INFO,
      runningState: RunningState.STOPPED,
    },
  ];
}
