import { Component, ViewEncapsulation } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { ColorChannels, Color } from './color-picker.models';
import * as ColorPickerActions from './color-picker.actions';
import { ColorPickerStore } from './color-picker.store';
import { Observable } from 'rxjs';

type MapToObservable<T> = { [P in keyof T]: Observable<T[P]> };

@Component({
  selector: 'clr-color-sliders',
  template: `
    <mat-slider
      *ngFor="let channel$ of channels; let i = index"
      min="0"
      [attr.data-color-channel]="i"
      [max]="maxValue$ | async"
      discrete
      [disabled]="isDisabled$ | async"
      [class.disabled]="isDisabled$ | async"
      [disableRipple]="'true'"
    >
      <input
        matSliderThumb
        [value]="channel$ | async"
        (valueChange)="onInput(i, $event)"
      />
    </mat-slider>
  `,
  styles: [
    `
      clr-color-sliders {
        display: grid;
        grid-template-rows: repeat(5, 32px);
      }

      clr-color-sliders mat-slider {
        height: auto;
        padding: 0;
      }

      /**
       * Place the touch target such that it's centered on the
       * slider vertically, instead of being above the slider.
       */
      clr-color-sliders mat-slider.mat-mdc-slider-horizontal .mat-mdc-slider-wrapper {
        top: 11px;
      }

      clr-color-sliders mat-slider.disabled {
        opacity: 0.5;
      }

      /**
       * The !important is needed to override the default styling 
       * coming from Material's theming library, but it also serves
       * to override the default behavior of the slider where the
       * thumb and track turn grey when the value is 0. We want to
       * avoid the grey behavior because if the sliders are grey 
       * we are not able to distinguish between the sliders.
       */
      clr-color-sliders mat-slider .mat-mdc-slider-thumb {
        background-color: var(--color-slider) !important;
      }

      clr-color-sliders .mat-mdc-slider .mdc-slider__thumb-knob {
        background-color: var(--color-slider) !important;
        border-color: var(--color-slider) !important;
      }

      clr-color-sliders .mat-mdc-slider .mdc-slider__track--active_fill {
        border-color: var(--color-slider) !important;
      }

      clr-color-sliders mat-slider .mat-mdc-slider-thumb-label-text {
        color: #000 !important;
      }

      /**
       * Colors are intentionally hard coded here as
       * this component is not going to respect the theme
       * of the host application
       */
      clr-color-sliders mat-slider:nth-of-type(1) {
        --color-slider: #f38f8e;
      }

      clr-color-sliders mat-slider:nth-of-type(2) {
        --color-slider: #baf38f;
      }

      clr-color-sliders mat-slider:nth-of-type(3) {
        --color-slider: #8fcff3;
      }

      clr-color-sliders mat-slider:nth-of-type(4) {
        --color-slider: #f3b38e;
      }

      clr-color-sliders mat-slider:nth-of-type(5) {
        --color-slider: #d9d9d9;
      }

      clr-color-sliders .mat-mdc-slider .mdc-slider__track--inactive {
        background-color: rgba(150, 150, 150);
        opacity: 1;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ColorSlidersComponent {
  channels: MapToObservable<Color> = [
    this.store.redSliderValue$,
    this.store.greenSliderValue$,
    this.store.blueSliderValue$,
    this.store.amberSliderValue$,
    this.store.whiteSliderValue$,
  ];
  maxValue$ = this.store.sliderMaxValue$;
  isDisabled$ = this.store.isDisabled$;

  constructor(readonly store: ColorPickerStore) {}

  onInput(channel: ColorChannels, value: number) {
    this.store.dispatch(ColorPickerActions.updateColorChannelFromSliders(channel, value));
  }
}
