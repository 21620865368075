import { BehaviorType } from '@spog-ui/shared/models/behaviors';
import { createMockThermostats } from '@spog-ui/shared/models/thermostats';
import {
  ClimateZoneControlModeInternalModel,
  ClimateZoneFanMode,
  ClimateZoneViewModel,
  ControlZoneFormModel,
} from './climate-zone.model';
import {
  ControlZoneGraphQLModel,
  ZoneApplication,
  ZoneInternalModel,
} from './zone.model';

export function createMockClimateZoneFormForEdit(): ControlZoneFormModel {
  return {
    id: 'mock-id',
    name: 'new climate zone',
    thermostats: [createMockThermostats()[0].id],
  };
}

export function createMockClimateZoneFormForCreate(): ControlZoneFormModel {
  return {
    name: 'new climate zone',
    thermostats: [createMockThermostats()[0].id],
  };
}

export function createMockClimateZoneView(): ClimateZoneViewModel {
  return {
    id: '1',
    name: 'Climate Zone 1',
    thermostatIds: ['thermostat-id-1', 'thermostat-id-2'],
    controlMode: createMockControlMode(),
  };
}

export function createMockControlMode(): ClimateZoneControlModeInternalModel {
  return {
    fanMode: ClimateZoneFanMode.AUTO,
    coolF: 85,
    heatF: 65,
  };
}

export function createMockClimateZones(): ZoneInternalModel[] {
  return [
    {
      id: '1',
      name: 'Climate Zone 1',
      behaviorId: BehaviorType.On,
      behaviorParameters: JSON.stringify({
        type: 'Thermostat',
        cool_setpoint: 85,
        heat_setpoint: 55,
        fan: 'Auto',
      }),
      application: ZoneApplication.CLIMATE,
      deviceIds: [],
    },

    {
      id: '2',
      name: 'Climate Zone 2',
      behaviorId: BehaviorType.On,
      behaviorParameters: JSON.stringify({
        type: 'Thermostat',
        cool_setpoint: 85,
        heat_setpoint: 55,
        fan: 'Auto',
      }),
      application: ZoneApplication.CLIMATE,
      deviceIds: [],
    },
    {
      id: '3',
      name: 'Climate Zone 3',
      behaviorId: BehaviorType.On,
      behaviorParameters: JSON.stringify({
        type: 'Thermostat',
        cool_setpoint: 85,
        heat_setpoint: 55,
        fan: 'Auto',
      }),
      application: ZoneApplication.CLIMATE,
      deviceIds: [],
    },
  ];
}

export function createMockGqlControlZone(): ControlZoneGraphQLModel {
  return {
    id: '1',
    name: 'Climate Zone 1',
    behavior: {
      node: {
        id: '1',
      },
      parameters: null,
    },
    application: ZoneApplication.CLIMATE,
    thermostats: null,
  };
}
