import { Injectable, Provider } from '@angular/core';
// import { createCanvas } from 'canvas'
import { CanvasFactory } from '../../services';

function createCanvas(width: number, height: number): any {
  return {};
}

@Injectable()
export class CanvasFactoryNode implements CanvasFactory {
  createCanvas(width: number, height: number) {
    return createCanvas(width, height) as any;
  }
}

export const CANVAS_FACTORY_NODE_PROVIDERS: Provider[] = [
  { provide: CanvasFactory, useClass: CanvasFactoryNode },
];
