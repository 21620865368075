import { BehaviorParameters, BehaviorType } from '@spog-ui/shared/models/behaviors';
import { ZoneInternalModel, ZoneApplication, ZoneBehaviorFormModel } from './zone.model';

export const createMockLightingZones = (
  numOfZones = 1,
  application: ZoneApplication = ZoneApplication.LIGHTING,
): ZoneInternalModel[] => {
  const zones: ZoneInternalModel[] = [
    {
      id: 'id_builtin_zone_all',
      name: 'Zone All',
      behaviorId: BehaviorType.On,
      behaviorParameters: null,
      application: application,
      deviceIds: [],
    },
  ];

  const behaviors: {
    behaviorId: BehaviorType;
    behaviorParameters: BehaviorParameters;
  }[] = [
    {
      behaviorId: BehaviorType.On,
      behaviorParameters: null,
    },
    {
      behaviorId: BehaviorType.Off,
      behaviorParameters: null,
    },
    {
      behaviorId: BehaviorType.Dimmer,
      behaviorParameters: JSON.stringify({ level: 40 }),
    },
  ];

  let randomIndex = 0;

  for (let i = 0; i < numOfZones; i++) {
    if (randomIndex > behaviors.length - 1) {
      randomIndex = 0;
    }
    zones.push({
      id: `zone id ${i}`,
      name: `zone name ${i}`,
      ...behaviors[randomIndex],
      application: ZoneApplication.LIGHTING,
      deviceIds: [],
    });
    randomIndex++;
  }
  return zones;
};

export const createMockLightingZoneModel = (
  zoneId = '1',
  behaviorParameters = '80',
  zoneName = 'zone name',
  behaviorId = BehaviorType.Dimmer,
): ZoneInternalModel => {
  return {
    id: zoneId,
    behaviorId,
    behaviorParameters,
    name: zoneName,
    application: ZoneApplication.LIGHTING,
    deviceIds: [],
  };
};

export function createMockZoneInternalModelZoneAll(): ZoneInternalModel {
  return {
    id: 'id_builtin_zone_all',
    name: 'All1',
    behaviorId: BehaviorType.On,
    behaviorParameters: null,
    application: ZoneApplication.LIGHTING,
    deviceIds: [],
  };
}

export function createMockZoneBehaviorFormModel(
  updates?: Partial<ZoneBehaviorFormModel>,
): ZoneBehaviorFormModel {
  return {
    behaviorId: BehaviorType.Off,
    behaviorParameters: null,
    zones: [],
    ...updates,
  };
}
