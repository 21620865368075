import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  SuiEmptyStateModule,
  SuiSpinnerModule,
} from '@spog-ui/shared/components';
import { LightFormComponentModule } from '../light-form';
import { EditLightPageComponent } from './edit-light-page.component';

@NgModule({
  imports: [
    CommonModule,
    SuiSpinnerModule,
    ButtonComponent,
    SuiEmptyStateModule,
    LightFormComponentModule,
  ],
  declarations: [EditLightPageComponent],
  exports: [EditLightPageComponent],
})
export class EditLightPageComponentModule {}
