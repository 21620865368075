import {
  OrganizationGQLModel,
  OrganizationInternalModel,
  OrganizationFormModel,
  OrganizationWSModel,
  QuickSiteSetupFormModel,
} from './organization.model';
import { PermissionGroupSiteRole, Capabilities } from '@spog-ui/graphql/types';

export function createMockOrganizationModel(
  updates?: Partial<OrganizationInternalModel>,
): OrganizationInternalModel {
  return {
    id: '12345',
    name: 'test organization',
    siteIds: ['mock-site-id-1', 'mock-site-id-2'],
    userIds: ['mock-user-id-1', 'mock-user-id-2'],
    capabilities: [Capabilities.QUICK_SITE_SETUP],
    defaultSiteRole: PermissionGroupSiteRole.MEMBER,
    temporary: false,
    ...updates,
  };
}

export function createMockGQLOrganizationModel(
  updates?: Partial<OrganizationGQLModel>,
): OrganizationGQLModel {
  return {
    id: '12345',
    name: 'test organization',
    sites: {
      edges: [
        {
          node: {
            __typename: 'Site',
            id: 'mock-site-id-1',
          },
        },
      ],
    },
    users: {
      edges: [
        {
          node: {
            __typename: 'User',
            id: 'mock-user-id-1',
          },
        },
      ],
    },
    defaultSiteRole: PermissionGroupSiteRole.MEMBER,
    capabilities: [Capabilities.QUICK_SITE_SETUP],
    temporary: false,
    ...updates,
  };
}

export function createMockOrganizationFormModel(
  updates: Partial<OrganizationFormModel> = {},
): OrganizationFormModel {
  return {
    name: 'Mock Organization',
    defaultSiteRole: PermissionGroupSiteRole.MEMBER,
    capabilities: [],
    siteIds: [],
    addBasicPermissionGroups: false,
    ...updates,
  };
}

export function createMockOrganizationWSModel(
  updates?: Partial<OrganizationWSModel>,
): OrganizationWSModel {
  return {
    id: '12345',
    name: 'test organization',
    defaultSiteRole: 'MEMBER',
    capabilities: [],
    siteIds: [],
    users: [],
    permissionGroupIds: [],
    createdAt: '2022-06-17T12:37:00T-05:00',
    updatedAt: '2022-06-17T12:37:00T-05:00',
    temporary: false,
    ...updates,
  };
}

export function createMockQuickSiteSetupFormModel(
  updates: Partial<QuickSiteSetupFormModel> = {},
): QuickSiteSetupFormModel {
  return {
    name: 'Mock Organization',
    lat: 1.2,
    lon: 3.4,
    siteControllerMac: 'aabbcc',
    ...updates,
  };
}
