import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';

const PUBLIC_COMPONENTS = [SearchComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [PUBLIC_COMPONENTS],
  exports: [PUBLIC_COMPONENTS],
})
export class SuiSearchModule {}
