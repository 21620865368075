import { Bridge485TemplateViewModel } from './bridge-485-template.view-model';

export function createMockBridge485TemplateViewModels(): Bridge485TemplateViewModel[] {
  return [
    {
      id: 'abc-def',
      name: 'StrexCorp SCSI-1013',
      sensors: [],
    },
    {
      id: 'abc-deg',
      name: 'InterTech Mains Meter SNX100-7',
      sensors: [
        { name: 'Phase A Voltage', units: 'V', type: 'other' },
        { name: 'Phase B Voltage', units: 'V', type: 'other' },
        { name: 'Phase C Voltage', units: 'V', type: 'other' },
        { name: 'Active Power', units: 'W', type: 'power' },
      ],
    },
    {
      id: 'abc-deh',
      name: 'Absolution Matrix #7',
      sensors: [
        { name: 'Absolver of Guilt', units: 'kPa', type: 'other' },
        { name: 'Absolver of Silence', units: 'kPa', type: 'other' },
        {
          name: 'Absolver of Responsibility',
          units: 'kPa',
          type: 'other',
        },
      ],
    },
    {
      id: 'abc-dei',
      name: 'Template with Just One Sensor',
      sensors: [
        {
          name: 'Example Sensor',
          units: 'Anything',
          type: 'other',
        },
      ],
    },
  ];
}
