import { LightZoneGQLModel, LightZoneInternalModel } from './light-zone.model';

export function createMockLightZone(
  changes: Partial<LightZoneInternalModel> = {},
): LightZoneInternalModel {
  return {
    id: '12345',
    lightId: 'test light',
    zoneId: 'test zone',
    ...changes,
  };
}

export function createMockLightZones(): LightZoneInternalModel[] {
  return [
    {
      id: '12345',
      lightId: 'test light',
      zoneId: 'test zone',
    },
    {
      id: '12346',
      lightId: 'test light 1',
      zoneId: 'test zone 1',
    },
    {
      id: '12347',
      lightId: 'test light 2',
      zoneId: 'test zone 2',
    },
  ];
}

export function createMockGqlLightZones(): LightZoneGQLModel[] {
  return [
    {
      id: '12345',
      lightId: 'test light',
      zoneId: 'test zone',
    },
    {
      id: '12346',
      lightId: 'test light 1',
      zoneId: 'test zone 1',
    },
    {
      id: '12347',
      lightId: 'test light 2',
      zoneId: 'test zone 2',
    },
  ];
}
