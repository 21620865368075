import { DateTime } from 'luxon';
import { PowerModel } from './power-source-comparison.model';

export function createMockPowerData(): PowerModel[] {
  const powerData: any[] = [];

  for (let i = 0; i <= 23; i++) {
    powerData.push({
      date: DateTime.utc(2018, 1, 1, i),
      power: i * 3,
    });
  }

  return powerData;
}
