import { Component, Directive, EventEmitter, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'map-stepper-content',
})
export class StepperContentDirective {}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'map-stepper-actions',
})
export class StepperActionsDirective {}

@Component({
  selector: 'map-stepper',
  template: `
    <div class="stepper" @slide>
      <button mat-icon-button (click)="onClose()" class="close">
        <mat-icon>close</mat-icon>
      </button>

      <div class="stepperContent">
        <span class="step">{{ step }}</span>
        <span class="title">{{ title }}</span>
        <div>
          <ng-content select="map-stepper-content"></ng-content>
        </div>
        <div class="stepperActions">
          <ng-content select="map-stepper-actions"></ng-content>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .stepper {
        color: var(--color-foreground-text);
        background-color: var(--color-background-card);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        width: 100vw;
        position: fixed;
        top: var(--content-offset);
        left: 0;
      }

      .stepperContent {
        padding: 20px 8px 8px 70px;
      }

      .step {
        position: absolute;
        top: 12px;
        left: 20px;
        display: block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        vertical-align: middle;
        text-align: center;
        border-radius: 32px;
        background-color: var(--color-500);
        color: var(--color-contrast-500);
      }

      .stepperActions {
        position: relative;
        padding-top: 12px;
        left: -16px;
      }

      .stepperActions ::ng-deep [suiButton] {
        padding: 0 !important;
      }

      .close {
        position: absolute;
        top: 12px;
        right: 16px;
      }
    `,
  ],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ transform: 'translate3d(0,-500px,0)' }),
        animate(
          '225ms cubic-bezier(0.0, 0.0, 0.2, 1)',
          style({ transform: 'translate3d(0,0,0)' }),
        ),
      ]),
      transition(':leave', [
        style({ transform: 'translate3d(0,0,0)' }),
        animate(
          '225ms cubic-bezier(0.4, 0.0, 1, 1)',
          style({ transform: 'translate3d(0,-500px,0)' }),
        ),
      ]),
    ]),
  ],
})
export class StepperComponent {
  @Input() title: string;
  @Input() step: string;
  @Output() closed = new EventEmitter();

  onClose() {
    this.closed.emit();
  }
}
