import { PowerSourceModel, SourceType } from './power-source-comparison.model';

export const createMockPowerSource = (
  source?: Partial<PowerSourceModel>,
): PowerSourceModel => {
  return {
    id: `mock-power-source-id`,
    name: `Mock Power Source`,
    sourceType: SourceType.ControlZone,
    ...source,
  };
};
