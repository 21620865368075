import { async, merge, Scheduler, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Component, Input, Optional, Output } from '@angular/core';

export class SearchComponentSettings {
  time = 300;
  scheduler: Scheduler = async;
}

@Component({
  selector: 'sui-search',
  styles: [
    `
      :host {
        display: inline-block;
        background-color: var(--color-background-card);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        position: relative;
        padding: 0;
        border-radius: 2px;
      }

      .suiSearchClear {
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 200ms;
      }

      .suiSearchClear:hover {
        opacity: 1;
      }

      input {
        background-color: var(--color-background-card);
        color: inherit;
        padding: 10px 10px 10px 46px;
        height: 40px;
        line-height: 20px;
        border: 0;
        border-radius: 2px;
        width: 250px;
      }

      input:focus {
        outline: none;
      }

      button[suiButton] {
        position: absolute;
        display: flex;
        height: 100%;
        min-width: auto;
        left: 4px;
        padding: 0;
        margin: 0;
      }

      button[suiButton].suiButtonInactive {
        pointer-events: none;
      }

      :host button[suiButton] ::ng-deep span {
        padding: 0 8px;
      }

      button i {
        position: relative;
        top: 2px;
        left: 2px;
      }

      @media screen and (max-width: 500px) {
        :host {
          width: 100%;
        }
      }
    `,
  ],
  template: `
    <button
      suiButton
      (click)="realTerm !== '' && clear$.next('')"
      [class.suiButtonInactive]="realTerm === ''"
    >
      <i class="material-icons" *ngIf="realTerm === ''">search</i>
      <i class="material-icons" *ngIf="realTerm !== ''">clear</i>
    </button>
    <input
      matInput
      type="text"
      [attr.placeholder]="'Search ' + type"
      [value]="term"
      (input)="input$.next($event.target.value)"
    />
  `,
})
export class SearchComponent {
  @Input()
  term: string | null;
  @Input()
  type = '';
  settings: SearchComponentSettings;
  input$ = new Subject<string>();
  clear$ = new Subject<string>();

  constructor(@Optional() settings: SearchComponentSettings) {
    this.settings = settings || new SearchComponentSettings();
  }

  @Output()
  get filter() {
    const { time, scheduler } = this.settings;

    return merge(this.input$.pipe(debounceTime(time, scheduler)), this.clear$);
  }

  get realTerm() {
    return (this.term ?? '').trim();
  }
}

export const SEARCH_DIRECTIVES = [SearchComponent];
