import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
} from '@angular/core';

@Component({
  selector: 'sui-settings',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host(.suiSettingsHidden) {
        display: none;
      }
    `,
  ],
})
export class SettingsComponent {
  @Input() label = '';

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Component({
  selector: 'sui-settings-group',
  template: `
    <div class="suiSettingsHeader">
      <h3>{{ title }}</h3>
    </div>
    <div class="suiSettingsSidebar">
      <button
        type="button"
        *ngFor="let setting of settings; let i = index"
        [class.suiSettingOpen]="i === openIndex"
        (click)="onOpenSetting(i)"
      >
        {{ setting.label }}
      </button>
    </div>
    <div class="suiSettingsContent">
      <ng-content></ng-content>
    </div>
    <div class="suiSettingsActions">
      <button mat-button type="button" color="primary" (click)="onClose()">Done</button>
    </div>
  `,
  styles: [
    `
      :host {
        max-width: 600px;
        max-height: 570px;
        display: grid;
        grid-template-columns: 170px 1fr;
        grid-template-rows: 60px 1fr 60px;
        grid-template-areas:
          'header header'
          'sidebar content'
          'sidebar actions';
      }

      :host(.suiSettingsHasOneSetting) {
        max-width: 430px;
        grid-template-columns: 1fr;
        grid-template-rows: 60px 1fr 60px;
        grid-template-areas:
          'header'
          'content'
          'actions';
      }

      .suiSettingsHeader {
        grid-area: header;
        padding: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--color-foreground-divider);
      }

      .suiSettingsHeader h3 {
        font-size: 16px;
        padding: 0;
        margin: 0;
        font-weight: normal;
      }

      .suiSettingsSidebar {
        grid-area: sidebar;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-right: 1px solid var(--color-foreground-divider);
      }

      :host(.suiSettingsHasOneSetting) .suiSettingsSidebar {
        display: none;
      }

      .suiSettingsSidebar button {
        color: var(--color-foreground-text);
        background: none;
        outline: none;
        border: none;
        width: 100%;
        height: 36px;
        line-height: 36px;
        padding: 0 20px;
        text-align: left;
        cursor: pointer;
        font-size: 14px;
      }

      .suiSettingsSidebar button.suiSettingOpen {
        background-color: var(--color-background-selected-button);
        font-weight: bold;
      }

      .suiSettingsContent {
        grid-area: content;
        padding: 16px 24px 0 16px;
      }

      .suiSettingsActions {
        grid-area: actions;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsGroupComponent implements AfterContentChecked {
  openIndex = 0;
  @Input() title = 'Settings';
  @Output() closed = new EventEmitter();
  @ContentChildren(SettingsComponent) settings: QueryList<SettingsComponent>;

  @HostBinding('class.suiSettingsHasOneSetting')
  get hasOneSetting(): boolean {
    return this.settings && this.settings.length === 1;
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.settings.forEach((setting, index) => {
      if (index === this.openIndex) {
        setting.elementRef.nativeElement.classList.remove('suiSettingsHidden');
      } else {
        setting.elementRef.nativeElement.classList.add('suiSettingsHidden');
      }
    });

    this.cd.markForCheck();
  }

  onOpenSetting(index: number) {
    this.openIndex = index;

    this.cd.markForCheck();
  }

  onClose() {
    this.closed.emit();
  }
}
