import { HttpErrorResponse } from '@angular/common/http';
import { ApiErrorModel } from './error-response.model';

export const createMockApiError = (message: string): ApiErrorModel => ({
  message,
  location: new Array({ line: 42, column: 42 }),
  path: ['fake/path'],
  extensions: {
    code: '#code',
    exception: {
      stacktrace: [
        'Error: Something went wrong',
        '    at Object.something (/app/src/xxx.ts:22:11)',
        '    at runMicrotasks (<anonymous>)',
      ],
    },
  },
});

export const createMockApiErrorResponse = (
  apiErrorModelList: ApiErrorModel[] = [createMockApiError('Fake Error')],
): HttpErrorResponse & { error: { errors: ApiErrorModel[] } } =>
  new HttpErrorResponse({ error: { errors: apiErrorModelList } });
