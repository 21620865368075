import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PercentSliderComponent } from './percent-slider.component';
import { SuiSliderModule } from '../slider';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatInputModule, SuiSliderModule],
  declarations: [PercentSliderComponent],
  exports: [PercentSliderComponent],
})
export class SuiPercentSliderComponentModule {}
