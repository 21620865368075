import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BodyService } from '../body';

export type NavDrawerMode = 'auto-expand' | 'collapsed';

@Component({
  selector: 'sui-nav-drawer',
  template: `
    <div class="suiNavDrawerBackdrop" *ngIf="open" (click)="onClose($event)"></div>
    <div class="suiNavDrawerContent">
      <button suiButton class="suiNavDrawerClose" type="button" (click)="onClose($event)">
        <i class="material-icons">close</i>
      </button>
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 300px;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: 16px;
        transition: transform ease-in 150ms;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1005;
        transform: translate3d(-300px, 0, 0);
      }

      .suiNavDrawerBackdrop {
        transform: translate3d(300px, 0, 0);
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 1010;
        cursor: pointer;
        display: block;
      }

      .suiNavDrawerContent {
        display: flex;
        width: 300px;
        box-sizing: border-box;
        border-right: 1px solid var(--color-foreground-divider);
        background-color: var(--color-background-card);
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1020;
        overflow: auto;
      }

      :host(.suiNavDrawerOpen) {
        transform: translate3d(0, 0, 0);
      }

      .suiNavDrawerClose {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
      }

      [suiButton].suiNavDrawerClose ::ng-deep span {
        min-width: auto;
        padding: 6px 0 0;
      }

      @media screen and (min-width: 1300px) {
        :host(.suiNavDrawerAutoExpand) {
          transform: translate3d(0, 0, 0);
        }

        :host(.suiNavDrawerAutoExpand) .suiNavDrawerBackdrop,
        :host(.suiNavDrawerAutoExpand) .suiNavDrawerClose {
          display: none;
        }
      }
    `,
  ],
})
export class NavDrawerComponent {
  @Input() mode: NavDrawerMode = 'auto-expand';
  @HostBinding('class.suiNavDrawerAutoExpand') get isModeAutoExpand() {
    return this.mode === 'auto-expand';
  }

  @HostBinding('class.suiNavDrawerOpen') private isOpen = false;
  @Input() set open(isOpen: boolean) {
    this.isOpen = isOpen;

    if (isOpen) {
      this.body.addClass('suiNavDrawerOpen');
    } else {
      this.body.removeClass('suiNavDrawerOpen');
    }
  }
  get open() {
    return this.isOpen;
  }

  @Output() closed = new EventEmitter();

  constructor(private body: BodyService) {}

  onClose($event: Event) {
    $event.preventDefault();
    this.closed.emit();
  }
}
