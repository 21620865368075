import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import { NgIf, AsyncPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'spog-remote-access-down-banner',
  template: `
    @if (!(remoteAccessUpForAllSiteControllers$ | async)) {
    <div class="remoteAccessDownBanner">
      One or more Site Controllers is not reachable. Some configuration changes will not
      function at this time.
    </div>
    }
  `,
  styles: [
    `
      .remoteAccessDownBanner {
        background-color: var(--color-warn-500);
        text-align: center;
        width: 100%;
        min-height: 40px;
        line-height: 24px;
        padding: 8px;
        margin-bottom: 16px;
      }
    `,
  ],
  standalone: true,
  imports: [NgIf, AsyncPipe, DatePipe],
})
export class RemoteAccessDownBannerComponent {
  remoteAccessUpForAllSiteControllers$ = this.store.select(
    CoreState.selectIfRemoteAccessUpForAllSiteControllersInSelectedSite,
  );

  constructor(private store: Store) {}
}
