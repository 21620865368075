import { startWith } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import tinycolor from 'tinycolor2';

@Component({
  selector: 'sui-color-preview',
  template: ` <div [style.background-color]="colorString"></div> `,
  styles: [
    `
      div {
        width: calc(100% - 20px);
        margin: 10px;
        height: 90px;
        border-radius: 2px;
        border: 1px solid #d8d8d8;
      }
    `,
  ],
})
export class ColorPreviewComponent implements OnInit {
  @Input()
  hueControl: UntypedFormControl;
  @Input()
  saturationControl: UntypedFormControl;
  @Input()
  luminanceControl: UntypedFormControl;

  hue: number;
  saturation: number;
  luminance: number;
  colorString: string;

  updateColorPreview() {
    this.colorString = tinycolor({
      h: this.hue,
      s: this.saturation,
      l: this.luminance,
    }).toRgbString();
  }

  ngOnInit() {
    this.colorString = tinycolor({
      h: this.hue,
      s: this.saturation,
      l: this.luminance,
    }).toRgbString();

    this.hueControl.valueChanges.pipe(startWith(this.hueControl.value)).subscribe(hue => {
      this.hue = hue;
      this.updateColorPreview();
    });

    this.saturationControl.valueChanges
      .pipe(startWith(this.saturationControl.value))
      .subscribe(saturation => {
        this.saturation = saturation;
        this.updateColorPreview();
      });

    this.luminanceControl.valueChanges
      .pipe(startWith(this.luminanceControl.value))
      .subscribe(luminance => {
        this.luminance = luminance;
        this.updateColorPreview();
      });
  }
}

export const COLOR_PREVIEW_DIRECTIVES = [ColorPreviewComponent];
