import { LightSuiModel as LightModel } from '@spog-ui/shared/models/lights';
import { BehaviorType } from '@spog-ui/shared/models/behaviors';
import { ZoneBehaviorModel } from '../index';

export function createMockLight(updates: Partial<LightModel> = {}): LightModel {
  return {
    id: 'mock-light-id',
    name: 'Mock Light',
    level: 80,
    ...updates,
  };
}

export function createMockZoneBehavior(
  updates: Partial<ZoneBehaviorModel> = {},
): ZoneBehaviorModel {
  return {
    id: 'mock-zone-id',
    name: 'Mock Zone',
    behaviorId: BehaviorType.DLH,
    behaviorParameters: null,
    ...updates,
  };
}
