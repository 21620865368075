import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  Component,
  ContentChild,
  Directive,
  EmbeddedViewRef,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { chunk, range } from 'lodash';

@Directive({
  selector: '[suiPaginationItem]',
})
export class PaginationItemDirective<T extends { id: string }> {
  constructor(public templateRef: TemplateRef<PaginationItemContext<T>>) {}
}

@Component({
  selector: 'sui-pagination-controls',
  template: `
    <div class="container">
      <div class="suiPaginationControls">
        <div class="suiPaginationControlsArrow">
          <button *ngIf="this.currentPageIndex > 0" (click)="goTo(currentPageIndex - 1)">
            <i class="material-icons">keyboard_arrow_left</i> <span>Previous Page</span>
          </button>
        </div>

        <div class="suiPaginationControlsPageIndices">
          <button
            (click)="goTo(0)"
            [class.active]="currentPageIndex === 0"
            *ngIf="showFirstPageButton"
          >
            1
          </button>
          <span *ngIf="showLeadingEllipsis">...</span>
          <button
            *ngFor="let pageIndex of pageIndexGroup"
            (click)="goTo(pageIndex)"
            [class.active]="pageIndex === currentPageIndex"
          >
            {{ pageIndex + 1 }}
          </button>
          <span *ngIf="showTrailingEllipsis">...</span>
          <button
            *ngIf="showLastPageButton"
            (click)="goTo(pages - 1)"
            [class.active]="currentPageIndex === pages"
          >
            {{ pages }}
          </button>
        </div>

        <div class="suiPaginationControlsArrow">
          <button
            *ngIf="this.currentPageIndex < this.pages - 1"
            (click)="goTo(currentPageIndex + 1)"
          >
            <span>Next Page</span> <i class="material-icons">keyboard_arrow_right</i>
          </button>
        </div>
      </div>
      <div class="suiPaginationControlsContent">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .container {
        width: 100%;
        background-color: var(--color-background-card);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        padding: 8px;
        margin-bottom: 16px;
      }

      .suiPaginationControls {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .suiPaginationControlsPageIndices,
      .suiPaginationControlsArrow {
        display: flex;
        align-items: baseline;
      }

      button {
        outline: none;
        background: transparent;
        border: none;
        min-width: 28px;
        text-align: center;
        padding: 4px;
        margin: 0 8px;
        border-radius: 2px;
      }

      button.active {
        background-color: var(--color-50);
        color: var(--color-contrast-50);
      }

      .suiPaginationControlsArrow button {
        display: flex;
        align-items: center;
      }

      .suiPaginationControlsArrow span {
        padding: 0 6px;
      }

      @media screen and (max-width: 600px) {
        .suiPaginationControlsArrow span {
          display: none;
        }
      }
    `,
  ],
})
export class PaginationControlsComponent {
  private _currentPageIndex = 0;
  private _pages = 0;

  pageIndexGroup: number[] = [];
  showFirstPageButton = false;
  showLeadingEllipsis = false;
  showLastPageButton = false;
  showTrailingEllipsis = false;
  change$ = new Subject<number>();

  @Input()
  set pages(pages: number) {
    this._pages = pages;
    this.recomputePageIndexGroup();
  }

  get pages() {
    return this._pages;
  }

  @Input()
  set currentPageIndex(currentPageIndex: number) {
    this._currentPageIndex = currentPageIndex;
    this.recomputePageIndexGroup();
  }

  get currentPageIndex() {
    return this._currentPageIndex;
  }

  @Output()
  get changed() {
    return this.change$.pipe(debounceTime(150));
  }

  goTo(page: number) {
    const clampedPage = clamp(page, 0, this.pages - 1);
    this.change$.next(clampedPage);
    this.currentPageIndex = clampedPage;
  }

  recomputePageIndexGroup() {
    this.pageIndexGroup = createNumberGroup(this.currentPageIndex, this.pages);
    this.showFirstPageButton = shouldShowFirstPageButton(this.pageIndexGroup);
    this.showLeadingEllipsis = shouldShowLeadingEllipsis(this.pageIndexGroup);
    this.showLastPageButton = shouldShowLastPageButton(this.pageIndexGroup, this.pages);
    this.showTrailingEllipsis = shouldShowTrailingEllipsis(
      this.pageIndexGroup,
      this.pages,
    );
  }
}

@Component({
  selector: 'sui-pagination',
  template: `
    <ng-template [ngIf]="itemContainer">
      <sui-pagination-controls
        class="first"
        (changed)="goTo($event)"
        [pages]="pages.length"
        [currentPageIndex]="currentPageIndex"
      >
        <ng-content></ng-content>
      </sui-pagination-controls>

      <div
        class="suiPaginationPage"
        *ngFor="let page of pages; trackBy: trackByIndex; let i = index"
      >
        <ng-template [ngIf]="i === currentPageIndex">
          <div class="suiPaginationItem" *ngFor="let item of page; trackBy: trackById">
            <ng-template [suiPaginationItemSlot]="item"></ng-template>
          </div>
        </ng-template>
      </div>

      <sui-pagination-controls
        (changed)="goTo($event)"
        [pages]="pages.length"
        [currentPageIndex]="currentPageIndex"
      >
      </sui-pagination-controls>
    </ng-template>
  `,
  styles: [
    `
      sui-pagination-controls.first {
        margin-bottom: 16px;
      }
    `,
  ],
})
export class PaginationComponent<T extends { id: string }> implements OnInit {
  initialized = false;
  perPage = 20;
  currentPageIndex = 0;
  list: T[] = [];
  pages: T[][] = [];

  @ContentChild(forwardRef(() => PaginationItemDirective), { static: true })
  itemContainer: PaginationItemDirective<T>;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('list') set listSetter(list: T[]) {
    this.list = list;
    this.recomputePages();
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('perPage') set perPageSetter(perPage: number) {
    this.perPage = perPage;
    this.recomputePages();
  }

  ngOnInit(): void {
    this.initialized = true;
    this.recomputePages();
  }

  recomputePages(): void {
    if (this.initialized) {
      this.pages = createPageGroups(this.list, this.perPage);
      this.goTo(this.currentPageIndex);
    }
  }

  goTo(pageIndex: number) {
    this.currentPageIndex = clamp(pageIndex, 0, this.pages.length - 1);
  }

  trackById(_: unknown, item: T | null) {
    return item ? item.id : null;
  }

  trackByIndex(index: number) {
    return index;
  }

  get showing() {
    return this.pages[this.currentPageIndex].length;
  }
}

@Directive({
  selector: '[suiPaginationItemSlot]',
})
export class PaginationItemSlotDirective<T extends { id: string }> {
  private embeddedView: EmbeddedViewRef<PaginationItemContext<T>> | null = null;
  private lastItem: T | null = null;

  constructor(
    private view: ViewContainerRef,
    private pagination: PaginationComponent<T>,
  ) {}

  @Input()
  set suiPaginationItemSlot(item: T) {
    if (this.embeddedView) {
      this.embeddedView.context.$implicit = item;
    } else {
      this.createView(item);
    }
  }

  createView(item: T) {
    this.lastItem = item;
    this.embeddedView = this.view.createEmbeddedView(
      this.pagination.itemContainer.templateRef,
      { $implicit: item },
    );
  }
}

function createPageGroups<T>(list: T[], perPage: number): T[][] {
  return chunk(list, perPage);
}

function clamp(index: number, min: number, max: number) {
  return Math.max(Math.min(index, max), min);
}

function createNumberGroup(index: number, pages: number) {
  if (pages > 6) {
    return [index - 1, index, index + 1].filter(
      pageIndex => pageIndex >= 0 && pageIndex < pages,
    );
  }

  return range(0, pages);
}

function shouldShowFirstPageButton(pageIndexGroup: number[]) {
  return pageIndexGroup[0] > 0;
}

function shouldShowLeadingEllipsis(pageIndexGroup: number[]) {
  return pageIndexGroup[0] > 1;
}

function shouldShowLastPageButton(pageIndexGroup: number[], pages: number) {
  return pageIndexGroup[pageIndexGroup.length - 1] !== pages - 1;
}

function shouldShowTrailingEllipsis(pageIndexGroup: number[], pages: number) {
  return pageIndexGroup[pageIndexGroup.length - 1] < pages - 2;
}

export class PaginationItemContext<T extends { id: string }> {
  $implicit: T;
}

export const PAGINATION_DIRECTIVES = [
  PaginationItemSlotDirective,
  PaginationItemDirective,
  PaginationComponent,
  PaginationControlsComponent,
];
