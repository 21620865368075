import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiButtonModule } from '../button';
import { ToolbarComponent } from './toolbar.component';

const PUBLIC_COMPONENTS = [ToolbarComponent];

@NgModule({
  imports: [CommonModule, SuiButtonModule],
  declarations: [PUBLIC_COMPONENTS],
  exports: [PUBLIC_COMPONENTS],
})
export class SuiToolbarModule {}
