import { Component } from '@angular/core';

@Component({
  selector: 'sui-form-wrapper',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        width: 100%;
        max-width: 800px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }
    `,
  ],
})
export class FormWrapperComponent {}
