import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { ExtendedFabComponent } from './extended-fab.component';

@NgModule({
  imports: [CommonModule, LayoutModule],
  declarations: [ExtendedFabComponent],
  exports: [ExtendedFabComponent],
})
export class SuiExtendedFabModule {}
