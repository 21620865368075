import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Color, ColorChannels, LightColors } from './color-picker.models';
import { ColorPickerStore } from './color-picker.store';
import { CanvasFactory } from './canvas-factory';
import { CanvasFactoryBrowser } from './canvas-factory/canvas-factory-browser';
import * as THREE from 'three';

const PREVIEW_DIMENSIONS = [74, 80] as const; // px

/**
 * The octohedron is the 3D shape we will be casting light onto
 * for the preview. It is centered on the origin point of the scene.
 */
const OCTOHEDRON_POSITION = [0, 0, 0] as const;
/**
 * The octohedron is rotated slightly for style reasons.
 * This rotation is a tuple of Euler angles represented in radians.
 * We are rotating the nose of the octohedron down, and left just a touch.
 */
const OCTOHEDRON_ROTATION = [0.2, -0.03, 0] as const;

/**
 * The camera is slightly up from the center of the octohedron,
 * and is 10 units nearer to the canvas than the octohedron to
 * get it properly in-frame.
 */
const CAMERA_POSITION = [
  OCTOHEDRON_POSITION[0] + 0,
  OCTOHEDRON_POSITION[1] + 0.2,
  OCTOHEDRON_POSITION[2] + 10,
] as const;

/**
 * The lights are positioned all in the same location, up
 * and slightly to the left of the octohedron, and far enough
 * away that the rays from these point lights hit every
 * side of the octohedron.
 */
const LIGHT_POSITION = [
  OCTOHEDRON_POSITION[0] - 2,
  OCTOHEDRON_POSITION[1] + 0.9,
  OCTOHEDRON_POSITION[2] + 13,
] as const;

@Component({
  selector: 'clr-color-preview',
  template: ``,
  styles: [``],
})
export class ColorPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
  color$: Observable<Color> = this.store.color$;
  subscriptions: Subscription = new Subscription();

  canvas?: HTMLCanvasElement;
  render?: (color: Color) => void;
  _lights: THREE.PointLight[] = []; // exposing this for tests

  constructor(
    readonly store: ColorPickerStore,
    readonly canvasFactory: CanvasFactory,
    readonly elementRef: ElementRef<HTMLElement>,
  ) {}

  ngOnInit() {
    this.canvas = this.canvasFactory.createCanvas(...PREVIEW_DIMENSIONS);
  }

  ngAfterViewInit() {
    if (!this.canvas) return;

    if (this.canvasFactory instanceof CanvasFactoryBrowser) {
      this.elementRef.nativeElement.appendChild(this.canvas);
    }

    const [scene, camera, renderer] = this.createScene();

    const octohedronSize = 4;
    const octohedronGeo = new THREE.OctahedronGeometry(octohedronSize, 0);
    const octohedronMat = new THREE.MeshPhongMaterial({
      color: '#fff',
    });
    const octohedron = new THREE.Mesh(octohedronGeo, octohedronMat);
    octohedron.position.set(...OCTOHEDRON_POSITION);
    octohedron.rotation.set(...OCTOHEDRON_ROTATION);
    scene.add(octohedron);

    const lightColors = [
      LightColors.Red,
      LightColors.Green,
      LightColors.Blue,
      LightColors.Amber,
      LightColors.White,
    ];
    const [redLight, greenLight, blueLight, amberLight, whiteLight] = lightColors.map(
      lightColor => {
        const light = new THREE.PointLight(lightColor);
        light.position.set(...LIGHT_POSITION);
        scene.add(light);
        return light;
      },
    );
    this._lights = [redLight, greenLight, blueLight, amberLight, whiteLight];

    renderer.setSize(...PREVIEW_DIMENSIONS);

    /**
     * Set the light intensities based on the color of the color picker.
     * The minimum intensity for each light is 0.2, and the maximum is 0.8.
     * We picked these values because 0.2 gives a decent ambient light if
     * everything is fully off, and because maxing out lights to 1.0 intensity
     * washes out the shape and makes the color hard to see.
     *
     * Multiplying by 0.6 lets us scale linearly between 0.2 and 0.8.
     *
     * Lastly, the color picker's color is stored as a tuple of bytes,
     * so dividing by 255 gives us a number between 0 and 1 -- which is
     * the range the light intensity API uses.
     */
    this.render = color => {
      const minimum = 0.2;
      const scalingFactor = 0.6;

      redLight.intensity = minimum + (color[ColorChannels.RED] / 255) * scalingFactor;
      greenLight.intensity = minimum + (color[ColorChannels.GREEN] / 255) * scalingFactor;
      blueLight.intensity = minimum + (color[ColorChannels.BLUE] / 255) * scalingFactor;
      amberLight.intensity = minimum + (color[ColorChannels.AMBER] / 255) * scalingFactor;
      whiteLight.intensity = minimum + (color[ColorChannels.WHITE] / 255) * scalingFactor;

      renderer.render(scene, camera);
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.subscriptions.add(this.color$.subscribe(color => this.render!(color)));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  createScene(
  ): [THREE.Scene, THREE.PerspectiveCamera, THREE.WebGLRenderer] {
    const scene = new THREE.Scene();

    const fov = 45; // field of view (degrees).
    const aspect = 1; // aspect ratio. the canvas default
    const near = 0.1; // distance from the camera to the near clipping plane
    const far = 100; // distance from the camera to the far clipping plane
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.set(...CAMERA_POSITION);

    const renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true,
    });

    return [scene, camera, renderer];
  }
}
