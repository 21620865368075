import { Component, Input } from '@angular/core';
import { LightViewModel } from '@spog-ui/shared/models/lights';

@Component({
  selector: 'lit-light-info-card',
  template: `
    <mat-card class="lightInfoCard">
      <mat-card-content>
        <mat-card-title>Light Info</mat-card-title>
        <div class="info">
          <div class="detail">{{ light.snapaddr }}</div>
          <div class="label">snap address</div>
        </div>
        <div class="info">
          <div class="detail">{{ light.controllerType }}</div>
          <div class="label">controller type</div>
        </div>
        <div class="info">
          <div class="detail">{{ light.siteControllerName }}</div>
          <div class="label">site controller</div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      .lightInfoCard {
        border-radius: 2px;
        margin-bottom: 10px;
        margin-right: auto;
        margin-left: auto;
        width: 328px;
        top: 10px;
      }

      mat-card-title {
        font-size: 16px;
        line-height: 19px;
        padding: 0px;
      }

      .info {
        padding-bottom: 6px;
      }

      .detail {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        padding-bottom: 4px;
      }

      .label {
        font-size: 10px;
        font-weight: 500;
        line-height: 11px;
        padding-bottom: 4px;
        text-transform: uppercase;
      }
    `,
  ],
})
export class LightInfoCardComponent {
  @Input() light: LightViewModel;

  constructor() {}
}
