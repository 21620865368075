import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WideStatusTrayComponent } from './wide-status-tray.component';

@NgModule({
  imports: [CommonModule],
  declarations: [WideStatusTrayComponent],
  exports: [WideStatusTrayComponent],
})
export class WideStatusTrayComponentModule {}
