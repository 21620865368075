import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { BFColorRandomComponent } from './bf-color-random.component';
import { ColorPickerModule } from '@sui/color-picker';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatCheckboxModule, ColorPickerModule],
  declarations: [BFColorRandomComponent],
  exports: [BFColorRandomComponent],
})
export class BFColorRandomComponentModule {}
