import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import { MenuActions } from '@spog-ui/modules/menu-state';
import { Products } from '@spog-ui/graphql/types';
import { CurrentUserService } from '@spog-ui/current-user/feature';
import * as OrganizationsState from '@spog-ui/shared/state/organizations';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';
import { Router } from '@angular/router';
import { environment } from '@spog-ui/shared/environments';
import { Knobs } from '@omni/knobs';

@Component({
  selector: 'spog-main-nav-drawer',
  template: `
    <spog-drawer [open]="isOpen$ | async" (close)="onClose()">
      <spog-drawer-logo></spog-drawer-logo>

      <spog-drawer-account
        [user]="user$ | async"
        [site]="site$ | async"
        [organizations]="organizations$ | async"
        (logout)="onLogout()"
        (changeSite)="onChangeSite()"
        (navigateToManageOrg)="onNavigateToManageOrg($event)"
        (openOrganizationsMenu)="onOpenOrganizationMenu($event)"
      >
      </spog-drawer-account>

      <ng-container *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer', 'ssc:sceneUser']">
        <spog-drawer-link
          [to]="(urlPrefix$ | async) + '/go-to-map'"
          icon="map"
          *spogIfSupportsProducts="
            [Products.SENSE, Products.ILLUMINATE, Products.CLIMATE];
            any: true
          "
        >
          Map
        </spog-drawer-link>
      </ng-container>
      <ng-container
        *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer', 'ssc:schedule-and-scene']"
      >
        <spog-drawer-link
          *spogIfSupportsProducts="
            [Products.SENSE, Products.ILLUMINATE, Products.CLIMATE];
            any: true
          "
          [to]="(urlPrefix$ | async) + '/schedule'"
          icon="schedule"
        >
          Schedule
        </spog-drawer-link>
      </ng-container>

      <ng-container *spogIfSupportsProducts="[Products.REMOTE_ACCESS]">
        <spog-drawer-link
          [to]="(urlPrefix$ | async) + '/site-controllers'"
          icon="router"
          *suiIfUserCan="['ssc:siteAdmin']"
          class="siteControllers"
        >
          Site Controllers @if ( totalNumberOfOfflineSiteControllers$ | async; as
          totalNumberOfOfflineSiteControllers ) {
          <spog-alarming-badge>
            {{ totalNumberOfOfflineSiteControllers }}
          </spog-alarming-badge>
          }
        </spog-drawer-link>
      </ng-container>

      <ng-container *spogIfSupportsProducts="[Products.CLIMATE]">
        <spog-drawer-label *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']">
          Climate
        </spog-drawer-label>
        <spog-drawer-link
          [to]="(urlPrefix$ | async) + '/climate/zones'"
          icon="blur_circular"
          class="climateDevices"
          *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
        >
          Devices & Zones @if ( totalNumberOfThermostatAlarms$ | async; as
          totalNumberOfThermostatAlarms ) {
          <spog-alarming-badge>
            {{ totalNumberOfThermostatAlarms }}
          </spog-alarming-badge>
          }
        </spog-drawer-link>
        <spog-drawer-link
          *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
          [to]="(urlPrefix$ | async) + '/climate/scenes'"
          icon="smart_button"
          >Routines
        </spog-drawer-link>
      </ng-container>

      <ng-container *spogIfSupportsProducts="[Products.ILLUMINATE]">
        <spog-drawer-label
          *suiIfUserCan="[
            'ssc:siteAdmin',
            'ssc:siteViewer',
            'ssc:sceneUser',
            'ssc:schedule-and-scene'
          ]"
        >
          Lighting
        </spog-drawer-label>
        <spog-drawer-link
          [to]="(urlPrefix$ | async) + '/lighting/scenes'"
          icon="satellite"
          *suiIfUserCan="[
            'ssc:siteAdmin',
            'ssc:siteViewer',
            'ssc:sceneUser',
            'ssc:schedule-and-scene'
          ]"
        >
          Scenes
        </spog-drawer-link>
        <spog-drawer-link
          data-test-id="light-zones-nav-link"
          [to]="(urlPrefix$ | async) + '/lighting/zones'"
          icon="blur_circular"
          *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
        >
          Zones
        </spog-drawer-link>
        <ng-container>
          <spog-drawer-link
            [to]="(urlPrefix$ | async) + '/lighting/alarms'"
            icon="alarm"
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
          >
            @if (totalNumberOfAlarms$ | async; as totalNumberOfAlarms) {
            <spog-alarming-badge>
              {{ totalNumberOfAlarms }}
            </spog-alarming-badge>
            } Alarms
          </spog-drawer-link>
        </ng-container>
      </ng-container>

      @if (hasSceneViews) {
      <!-- <ng-container *spogIfSupportsProducts="[Products.SCENE_VIEWS]"> -->
      <spog-drawer-label
        *suiIfUserCan="[
          'ssc:siteAdmin',
          'ssc:siteViewer',
          'ssc:sceneUser',
          'ssc:schedule-and-scene'
        ]"
      >
        Sports and Events
      </spog-drawer-label>
      <spog-drawer-link
        [to]="(urlPrefix$ | async) + '/scene-views'"
        icon="calendar_view_month"
        *suiIfUserCan="[
          'ssc:siteAdmin',
          'ssc:siteViewer',
          'ssc:sceneUser',
          'ssc:schedule-and-scene'
        ]"
      >
        Scene Views
      </spog-drawer-link>
      <!-- </ng-container> -->
      }

      <ng-container *spogIfSupportsProducts="[Products.SENSE]">
        <spog-drawer-label *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']">
          Sense
        </spog-drawer-label>
        <spog-drawer-link
          [to]="(urlPrefix$ | async) + '/sense/industrial-sensors'"
          icon="settings_input_antenna"
          *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
        >
          Industrial Sensors
        </spog-drawer-link>
        <spog-drawer-link
          [to]="(urlPrefix$ | async) + '/sense/triggers'"
          svgIcon="spog_trigger"
          *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
          >Triggers
        </spog-drawer-link>
        <spog-drawer-link
          [to]="(urlPrefix$ | async) + '/power/equipment'"
          icon="developer_board"
          *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
        >
          Equipment
        </spog-drawer-link>
        <spog-drawer-link
          [to]="(urlPrefix$ | async) + '/power/departments'"
          icon="account_tree"
          *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
        >
          Departments
        </spog-drawer-link>
      </ng-container>

      <ng-container
        *spogIfSupportsProducts="
          [Products.SENSE_POWER, Products.ILLUMINATE_POWER];
          any: true
        "
      >
        <spog-drawer-label *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']">
          Power
        </spog-drawer-label>

        <ng-container *spogIfSupportsProducts="[Products.ILLUMINATE_POWER]">
          <spog-drawer-link
            [to]="(urlPrefix$ | async) + '/power/comparison'"
            icon="show_chart"
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
          >
            Power Comparison
          </spog-drawer-link>
        </ng-container>

        <ng-container *spogIfSupportsProducts="[Products.SENSE_POWER]">
          <spog-drawer-link
            [to]="(urlPrefix$ | async) + '/charting'"
            icon="multiline_chart"
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
          >
            Charting
          </spog-drawer-link>
          <spog-drawer-link
            [to]="(urlPrefix$ | async) + '/power/insights/overview'"
            icon="bolt"
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
          >
            Overview
          </spog-drawer-link>
          <spog-drawer-link
            [to]="(urlPrefix$ | async) + '/power/insights/explore'"
            icon="explore"
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
          >
            Explore
          </spog-drawer-link>
          <spog-drawer-link
            [to]="(urlPrefix$ | async) + '/power/insights/peak'"
            svgIcon="peak"
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
          >
            Peak Demand
          </spog-drawer-link>
          <spog-drawer-link
            [to]="(urlPrefix$ | async) + '/power/utility-services'"
            svgIcon="gauge"
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']"
          >
            Utility Services
          </spog-drawer-link>
        </ng-container>
      </ng-container>

      <ng-container
        *spogIfSupportsProducts="
          [Products.SENSE_POWER, Products.ILLUMINATE_POWER, Products.CLIMATE];
          any: true
        "
      >
        <spog-drawer-label *suiIfUserCan="['ssc:siteAdmin', 'ssc:siteViewer']">
          Site Configuration
        </spog-drawer-label>

        <spog-drawer-link
          [to]="(urlPrefix$ | async) + '/enabled-features'"
          icon="library_add_check"
        >
          Enabled Features
        </spog-drawer-link>

        <ng-container *suiIfUserCan="['ssc:siteAdmin']">
          <spog-drawer-link [to]="(urlPrefix$ | async) + '/import'" icon="upload">
            Import
          </spog-drawer-link>
        </ng-container>
      </ng-container>
    </spog-drawer>
  `,
  styles: [
    `
      spog-alarming-badge {
        display: block;
        position: absolute;
        margin-top: -11px;
      }
    `,
  ],
})
export class MainNavDrawerComponent {
  user$ = this.currentUser.user$;

  Products = Products;

  urlPrefix$ = this.store.select(CoreState.selectSiteURLPrefix);
  isOpen$ = this.store.select(CoreState.selectMenuIsOpen);
  site$ = this.store.select(CoreState.selectSelectedSite);
  organizations$ = this.store.select(OrganizationsState.selectAllOrganizations);

  hasSceneViews = !!(environment.knobs & Knobs.SceneViews);

  constructor(
    private store: Store,
    readonly currentUser: CurrentUserService,
    readonly router: Router,
  ) {}
  totalNumberOfAlarms$ = this.store.select(CoreState.selectTotalUnclearedAlarms);
  totalNumberOfThermostatAlarms$ = this.store.select(
    CoreState.selectTotalThermostatAlarms,
  );
  totalNumberOfOfflineSiteControllers$ = this.store.select(
    CoreState.selectTotalOfflineSiteControllers,
  );

  onClose() {
    this.store.dispatch(MenuActions.closeAction());
  }

  onLogout() {
    this.store.dispatch(MenuActions.logoutAction());
  }

  onChangeSite() {
    this.store.dispatch(MenuActions.changeSiteAction());
  }

  onOpenOrganizationMenu(organizations: OrganizationInternalModel[]) {
    this.store.dispatch(MenuActions.openOrganizationMenu(organizations));
  }

  onNavigateToManageOrg(organizationId: string) {
    this.store.dispatch(
      MenuActions.navigateToOrganization(organizationId, this.router.url),
    );
  }
}
