import { Component, Input } from '@angular/core';

@Component({
  selector: 'sui-expansion',
  template: `
    <mat-expansion-panel [hideToggle]="!showToggle" [disabled]="disabled">
      <mat-expansion-panel-header [expandedHeight]="expandable">
        <ng-content select="[suiExpansionHeader]"></ng-content>
      </mat-expansion-panel-header>

      <ng-content select="[suiExpansionContent]"></ng-content>
    </mat-expansion-panel>
  `,
  styles: [],
})
export class ExpansionComponent {
  @Input() showToggle = true;
  @Input() disabled = false;
  private _expandable: string | null;

  @Input()
  set expandable(isExpandable: boolean) {
    this._expandable = isExpandable ? null : '48px';
  }

  get expandable() {
    return this._expandable as any;
  }
}
