import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'sui-inner-panel',
  template: `
    <div class="suiPanelHeader">
      <h3>{{ title }}</h3>
      <button mat-icon-button (click)="closed.emit()">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </div>
    <div class="suiPanelContentArea">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 802;
        background-color: var(--color-background-background);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        padding-bottom: 30px;
      }

      :host:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-background-status-bar);
        opacity: 0.2;
        z-index: -1;
      }

      @media screen and (max-width: 900px) {
        :host {
          width: 100vw;
          height: calc(42vh + 30px);
          bottom: -30px;
          left: 0;
        }
      }

      @media screen and (min-width: 900px) {
        :host {
          width: 360px;
          /**
         * TODO: @mikeryan
         *
         * Height and top offsets should check the theme to see if
         * they need additional padding for the presence of a
         * "branded bar"
         */
          height: calc(100vh + 30px);
          top: 0;
          right: 0;
        }
      }

      .suiPanelHeader {
        width: 100%;
        height: 64px;
        padding: 0 6px 0 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 0;
        flex-shrink: 0;
      }

      @media screen and (max-width: 900px) {
        :host {
          background-color: var(--color-background-card);
        }
      }

      @media screen and (min-width: 900px) {
        .suiPanelHeader {
          background-color: rgba(0, 0, 0, 0.3);
          color: var(--color-foreground-text);
        }
      }

      .suiPanelContentArea {
        width: 100%;
        height: 100%;
        overflow: auto;
        position: relative;
      }

      h3 {
        margin: 0;
        font-size: 18px;
        flex-grow: 1;
        font-weight: normal;
      }
    `,
  ],
})
export class InnerPanelComponent {
  @Input() title: string;
  @Output() closed = new EventEmitter();
}

@Component({
  selector: 'sui-deprecated-panel',
  template: `
    <sui-inner-panel
      *ngIf="open"
      [title]="title"
      [@slide]="mode$ | async"
      (closed)="closed.emit()"
    >
      <ng-content></ng-content>
    </sui-inner-panel>
  `,
  animations: [
    trigger('slide', [
      transition('void => mobile', [
        style({ transform: 'translate3d(0,42vh,0)' }),
        animate(
          '225ms cubic-bezier(0.0, 0.0, 0.2, 1)',
          style({ transform: 'translate3d(0,0,0)' }),
        ),
      ]),
      transition('mobile => void', [
        style({ transform: 'translate3d(0,0,0)' }),
        animate(
          '225ms cubic-bezier(0.4, 0.0, 1, 1)',
          style({ transform: 'translate3d(0,42vh,0)' }),
        ),
      ]),
      transition('void => desktop', [
        style({ transform: 'translate3d(300px,0,0)' }),
        animate(
          '180ms cubic-bezier(0.0, 0.0, 0.2, 1)',
          style({ transform: 'translate3d(0,0,0)' }),
        ),
      ]),
      transition('desktop => void', [
        style({ transform: 'translate3d(0,0,0)' }),
        animate(
          '180ms cubic-bezier(0.4, 0.0, 1, 1)',
          style({ transform: 'translate3d(300px,0,0)' }),
        ),
      ]),
    ]),
  ],
})
export class DeprecatedPanelComponent {
  mode$: Observable<'mobile' | 'desktop'>;

  @Input() title = '';
  @Input() open = false;
  @Output() closed = new EventEmitter();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.mode$ = this.breakpointObserver
      .observe('(max-width: 900px)')
      .pipe(map(state => (state.matches ? 'mobile' : 'desktop')));
  }
}
