import { Component, Input } from '@angular/core';

@Component({
  selector: 'sui-nav-drawer-link',
  template: `
    <div class="suiNavDrawerLinkIcon">
      <sui-icon [icon]="icon"></sui-icon>
    </div>
    <div class="suiNavDrawerLinkLabel">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        font-size: 14px;
        height: 48px;
        line-height: 48px;
        text-decoration: none;
        color: var(--color-foreground-text);
        transition: color 150ms, background-color 150ms;
        background-color: rgba(0, 0, 0, 0);
        position: relative;
      }

      :host ::ng-deep a {
        display: block;
        font-size: 15px;
        text-decoration: none;
        height: 48px;
        line-height: 48px;
        color: var(--color-foreground-text);
        transition: color 150ms;
        padding-left: 72px;
        cursor: pointer;
      }

      :host ::ng-deep a:active {
        background-color: rgba(0, 0, 0, 0.05);
      }

      .suiNavDrawerLinkIcon {
        padding: 0 16px;
        display: inline-block;
        box-sizing: border-box;
        width: 72px;
        font-size: 18px;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
      }

      :host [suiButton].suiNavDrawerLinkLabel {
        display: inline;
        position: relative;
      }

      :host(.active),
      :host(:hover),
      :host(:hover) ::ng-deep a,
      :host(.active) ::ng-deep a {
        color: var(--color-500);
      }

      :host(.active) {
      }
    `,
  ],
})
export class NavDrawerLinkComponent {
  @Input()
  icon = '';
}
