import { Component, Input } from '@angular/core';
import { BigSelectState } from './big-select.state';

@Component({
  selector: 'sui-big-select',
  template: `
    <div class="bigSelectHeader">
      <h3>{{ title }}</h3>
      <sui-big-select-filter></sui-big-select-filter>
      <sui-big-select-global-checkbox></sui-big-select-global-checkbox>
    </div>
    <sui-big-select-tabs></sui-big-select-tabs>
    <sui-big-select-options></sui-big-select-options>
  `,
  styles: [
    `
      .bigSelectHeader {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 120px 64px;
        background-color: var(--color-background-app-bar);
        border-bottom: 1px solid var(--color-foreground-divider);
      }

      h3 {
        padding-left: 16px;
        font-weight: normal;
      }
    `,
  ],
  providers: [BigSelectState],
})
export class BigSelectComponent {
  @Input() title = '';
}
