import { NgModule } from '@angular/core';
import { SuiColorSliderModule } from '../color-slider';
import { ColorHueControlComponent } from './color-hue-control.component';
import { ColorLuminanceControlComponent } from './color-luminance-control.component';
import { ColorSaturationControlComponent } from './color-saturation-control.component';

const PUBLIC_COMPONENTS = [
  ColorHueControlComponent,
  ColorLuminanceControlComponent,
  ColorSaturationControlComponent,
];

@NgModule({
  imports: [SuiColorSliderModule],
  declarations: [PUBLIC_COMPONENTS],
  exports: [PUBLIC_COMPONENTS],
})
export class SuiHslControlsModule {}
