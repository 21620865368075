import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReplaceLightFormComponent } from './replace-light-form.component';
import { SuiDialogModule, ButtonComponent } from '@spog-ui/shared/components';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    SuiDialogModule,
    ButtonComponent,
  ],
  declarations: [ReplaceLightFormComponent],
  exports: [ReplaceLightFormComponent],
})
export class ReplaceLightFormComponentModule {}
