export interface DeviceStatusModel {
  snapaddr: string;
  controllerType: string;
  canHaveLightConfigured: boolean;
}

export interface DeviceStatusTable {
  [snapaddr: string]: DeviceStatusModel;
}

export function createDeviceStatusTable(
  statuses: DeviceStatusModel[],
): DeviceStatusTable {
  return statuses.reduce((table: DeviceStatusTable, status) => {
    table[status.snapaddr] = status;

    return table;
  }, {});
}

export function createMockDeviceStatus(
  changes: Partial<DeviceStatusModel> = {},
): DeviceStatusModel {
  return {
    snapaddr: 'aaaaaa',
    controllerType: 'DIM10-250-11',
    canHaveLightConfigured: true,
    ...changes,
  };
}
