import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIMMER_CONTROL_DIRECTIVES } from './dimmer-control.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SuiDimmerModule } from '../dimmer';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    SuiDimmerModule,
  ],
  declarations: [DIMMER_CONTROL_DIRECTIVES],
  exports: [DIMMER_CONTROL_DIRECTIVES],
})
export class SuiDimmerControlModule {}

export * from './dimmer-control.component';
