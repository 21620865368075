import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import {
  EditLightPageComponent,
  EditLightPageComponentModule,
  EditLightPageState,
} from './components/edit-light-page';

@NgModule({
  exports: [EditLightPageComponentModule],
})
export class EditLightFeatureModule {
  static connect(
    adapter: Type<EditLightPageState>,
  ): ModuleWithProviders<EditLightFeatureModule> {
    return {
      ngModule: EditLightFeatureModule,
      providers: [
        {
          provide: EditLightPageState,
          useClass: adapter,
        },
      ],
    };
  }
}

export { EditLightPageState, EditLightPageComponent };
