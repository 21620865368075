import { Component } from '@angular/core';
import { SuiPanelState } from '../panel';

@Component({
  selector: 'sui-page',
  template: `
    <main [class.suiPanelPageWrapperIsPushed]="state.isLayoutPushed$ | async">
      <ng-content></ng-content>
    </main>
  `,
  styles: [
    `
      main {
        width: 100%;
        display: block;
        padding-right: 0px;
        transition: padding-right 200ms;
        padding: 24px 24px 96px;
      }

      main.suiPanelPageWrapperIsPushed {
        padding-right: 384px;
      }
    `,
  ],
})
export class PageComponent {
  constructor(readonly state: SuiPanelState) {}
}
