import { NgModule } from '@angular/core';
import { InputMatchesDirective } from './input-matches.directive';
import { SnapAddressValidatorDirective } from './snap-address.directive';

const DIRECTIVES = [InputMatchesDirective, SnapAddressValidatorDirective];

@NgModule({
  declarations: [DIRECTIVES],
  exports: [DIRECTIVES],
})
export class SuiValidatorsModule {}

export * from './date-time-validators';
export * from './input-matches.directive';
export * from './snap-address.directive';
export * from './unique-name.directive';
