import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FocusService } from '../platform';

@Component({
  selector: 'sui-login-form',
  template: `
    <form [formGroup]="loginForm" (submit)="onSubmit($event)" name="loginForm">
      <mat-form-field>
        <input
          matInput
          placeholder="Username"
          type="text"
          id="inputUsername"
          formControlName="username"
          required
          #usernameInput
        />
        <mat-error
          *ngIf="
            loginForm.hasError('required', ['username']) &&
            loginForm.get('username')?.touched
          "
          >Username is required</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Password"
          id="inputPassword"
          [attr.type]="passwordInputType$ | async"
          formControlName="password"
          required
          autocomplete="off"
        />
        <mat-error
          *ngIf="
            loginForm.hasError('required', ['password']) &&
            loginForm.get('password')?.touched
          "
          >Password is required</mat-error
        >
      </mat-form-field>

      <sui-checkbox [formControl]="showPasswordControl">Show Password</sui-checkbox>

      <sui-alert [message]="errorMessage" *ngIf="errorMessage"></sui-alert>

      <div class="suiLoginFormActions">
        <button
          type="submit"
          mat-stroked-button
          color="accent"
          [disabled]="loginForm.disabled"
        >
          Login
        </button>
      </div>
    </form>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }

      mat-form-field {
        display: block;
        width: 100%;
        margin: 6px 0;
      }

      .suiLoginFormShowPassword {
        display: block;
        margin: 0 8px 12px 4px;
      }

      .suiLoginFormShowPassword span {
        padding: 2px 0 0 8px;
      }

      sui-checkbox {
        display: block;
        margin: 16px 0;
      }

      .suiLoginFormActions {
        display: flex;
        flex-direction: row-reverse;
      }

      :host button[suiButton] {
        padding: 0px;
      }
    `,
  ],
})
export class LoginFormComponent implements AfterViewInit {
  showPassword = false;
  username = new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]);
  password = new UntypedFormControl('', [Validators.required]);
  showPasswordControl = new UntypedFormControl(false);

  loginForm: UntypedFormGroup = this.formBuilder.group({
    username: this.username,
    password: this.password,
  });

  passwordInputType$ = this.showPasswordControl.valueChanges.pipe(
    startWith(this.showPasswordControl.value),
    map(showPassword => (showPassword ? 'text' : 'password')),
  );

  @Output() login = new EventEmitter();
  @Output() cancel = new EventEmitter();

  errorMessage: string | null = null;

  @Input() set error(error: string | null) {
    if (error) {
      this.loginForm.reset();
      this.focusOnUsername();
    }

    this.errorMessage = error;
  }

  @Input() set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.loginForm.disable();
    } else {
      this.loginForm.enable();
    }
  }

  @ViewChild('usernameInput', { static: true })
  usernameInput: ElementRef;

  constructor(private formBuilder: UntypedFormBuilder, private focus: FocusService) {}

  ngAfterViewInit(): void {
    this.focusOnUsername();
  }

  focusOnUsername(): void {
    if (this.usernameInput) {
      this.focus.on(this.usernameInput);
    }
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onSubmit($event: Event): void {
    $event.preventDefault();

    if (this.loginForm.valid) {
      this.login.emit(this.loginForm.value);
    }
  }
}
