import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LineChartComponent, TooltipPanelComponent } from './line-chart.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [LineChartComponent, TooltipPanelComponent],
  exports: [LineChartComponent],
})
export class SuiLineChartModule {}

export * from './line-chart.component';
