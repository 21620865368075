import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'sui-date-range-form',
  template: `
    <form [formGroup]="dateRangeForm" (submit)="onSubmit($event)" name="dateRangeForm">
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="startDate"
          placeholder="Choose a start date"
          required
          #startDateInput
          formControlName="startDate"
        />
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate startView="month"></mat-datepicker>
      </mat-form-field>
      <mat-error
        *ngIf="
          dateRangeForm.hasError('required', ['startDate']) &&
          dateRangeForm.get('startDate')?.touched
        "
        >Start Date is Required</mat-error
      >

      <mat-form-field>
        <input
          matInput
          [matDatepicker]="endDate"
          placeholder="Choose an end date"
          required
          #endDateInput
          formControlName="endDate"
        />
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate startView="month"></mat-datepicker>
      </mat-form-field>
      <mat-error
        *ngIf="
          dateRangeForm.hasError('required', ['endDate']) &&
          dateRangeForm.get('endDate')?.touched
        "
        >End Date is Required</mat-error
      >

      <div class="suidateRangeFormActions">
        <button suiButton raised color="accent" [disabled]="dateRangeForm.disabled">
          Select Date Range
        </button>
      </div>
    </form>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }

      input {
        color: var(--color-foreground-text);
      }

      mat-form-field {
        display: block;
        width: 100%;
        margin: 6px 0;
      }

      .suidateRangeFormActions {
        display: flex;
        flex-direction: row-reverse;
      }
    `,
  ],
})
export class DateRangeFormComponent {
  constructor(private formBuilder: UntypedFormBuilder) {}

  @Output() dateRange = new EventEmitter<any>();

  dateRangeForm: UntypedFormGroup = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
  });

  onSubmit($event: Event): void {
    $event.preventDefault();

    if (this.dateRangeForm.valid) {
      this.dateRange.emit(this.dateRangeForm.value);
    }
  }
}
