import { Component } from '@angular/core';
import { expandHeightAnimation, expandHeightAnimationStates } from '@sui/animations';

@Component({
  selector: 'sui-notice-card',
  template: `
    <sui-card class="suiNoticeCard" [@expand]="expandState">
      <ng-content class="suiNoticeCardContent"></ng-content>
    </sui-card>
  `,
  styles: [
    `
      .suiNoticeCard {
        display: flex;
        padding: 12px;
        margin: 20px 0 20px 0;
        justify-content: center;
      }

      sui-card {
        color: var(--color-contrast-800);
        background-color: var(--color-800);
      }

      :host[color='warn'] sui-card {
        color: var(--color-warn-contrast-800);
        background-color: var(--color-warn-800);
      }

      :host[color='accent'] sui-card {
        color: var(--color-accent-contrast-800);
        background-color: var(--color-accent-800);
      }
    `,
  ],
  animations: [expandHeightAnimation('expand')],
})
export class NoticeCardComponent {
  expandState: expandHeightAnimationStates = 'expanded';
}

export const NOTICE_CARD_DIRECTIVES = [NoticeCardComponent];
