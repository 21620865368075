import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'sui-alert',
  template: `
    <div
      [@expanding]="message ? 'expanded' : 'collapsed'"
      class="sui-alert alert alert-danger"
      [ngClass]="{
        'alert-danger': type === 'error',
        'alert-success': type === 'success',
        'alert-info': type === 'info'
      }"
    >
      {{ message }}
      <button [class.has-message]="message" (click)="dismissAlert()" *ngIf="collapsible">
        x
      </button>
    </div>
  `,
  styles: [
    `
      .sui-alert {
        border: 0;
        margin-top: 8px;
        margin-bottom: 8px;
      }
      button {
        display: none;
      }
      .has-message {
        display: block;
        float: right;
        background: transparent;
        border: 0 none;
        font-size: 18px;
        line-height: 1.1;
      }
    `,
  ],
  animations: [
    trigger('expanding', [
      state(
        'collapsed',
        style({
          height: '0px',
          paddingTop: '0px',
          paddingBottom: '0px',
        }),
      ),
      state(
        'expanded',
        style({
          height: '*',
          paddingTop: '15px',
          paddingBottom: '15px',
        }),
      ),
      transition('collapsed <=> expanded', animate(250)),
    ]),
  ],
})
export class AlertComponent {
  @Input()
  message: string;
  @Input()
  type: 'success' | 'error' | 'info' = 'error';
  @Input()
  collapsible = false;
  @Output()
  dismiss = new EventEmitter();

  dismissAlert() {
    this.dismiss.emit();
  }
}

export const ALERT_DIRECTIVES = [AlertComponent];
