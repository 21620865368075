import { PowerSourceComparisonInternalModel } from './power-source-comparison.model';
import { createMockPowerSource } from './power-source.mock';
import { createMockPowerData } from './power.mock';
import { createMockEnergyData } from './energy.mock';

export const createMockPowerSourceComparison = (
  comparison?: Partial<PowerSourceComparisonInternalModel>,
): PowerSourceComparisonInternalModel => {
  const totalPower = 43;

  return {
    powerSource: createMockPowerSource(),
    powerData: createMockPowerData(),
    energyData: createMockEnergyData(),
    totalPower: totalPower,
    ...comparison,
  };
};

export const createMockPowerSourceComparisons = (
  numOfComparisons = 1,
): PowerSourceComparisonInternalModel[] => {
  const comparisons: PowerSourceComparisonInternalModel[] = [];

  for (let i = 0; i < numOfComparisons; i++) {
    const comparison = createMockPowerSourceComparison({
      powerSource: createMockPowerSource({ id: `zone id ${i}`, name: `zone name ${i}` }),
      powerData: createMockPowerData(),
      totalPower: i * 10,
    });
    comparisons.push(comparison);
  }
  return comparisons;
};
