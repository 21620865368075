import { NgModule } from '@angular/core';
import {
  ANIMATOR_SERVICE_PROVIDER,
  AnimatorService,
  RAF_PROVIDER,
} from './animator.service';

@NgModule({
  providers: [ANIMATOR_SERVICE_PROVIDER, RAF_PROVIDER],
})
export class SuiAnimatorModule {}

export { AnimatorService };
