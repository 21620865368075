import { FloorPlanModel } from './floor-plan.model';

export function createMockFloorPlan(): FloorPlanModel {
  return {
    id: '12345',
    siteId: '123abc',
    name: 'test floor plan',
    imageUrl: 'test',
  };
}
