import { DateTime } from 'luxon';
import { EnergyModel } from './power-source-comparison.model';

export function createMockEnergyData(): EnergyModel[] {
  const energyData: any[] = [];

  for (let i = 0; i <= 23; i++) {
    energyData.push({
      date: DateTime.utc(2018, 1, 1, i),
      energy: i * 7,
    });
  }

  return energyData;
}

export function createMockEnergyLines() {
  const energyLines: EnergyModel[][] = [];

  for (let i = 0; i <= 4; i++) {
    energyLines.push(createMockEnergyData());
  }

  return energyLines;
}
