import {
  SiteFormModel,
  SiteGQLModel,
  SiteInternalModel,
  SiteWSModel,
  SiteWithSiteRoleGQLModel,
  OrganizationSiteInternalModel,
} from './site.model';
import {
  LightMapLayerIconSize,
  Products,
  PermissionGroupSiteRole,
  GetSitesWithUsersByOrganizationQueryResult,
  BaselineType,
  SubscriptionType,
} from '@spog-ui/graphql/types';

export function createMockSiteModel(
  updates: Partial<SiteInternalModel> = {},
): SiteInternalModel {
  return {
    id: '12345',
    name: 'Site Name',
    gateways: [],
    timeZone: 'America/Chicago',
    geoLocation: {
      latitude: 34.7304,
      longitude: -86.5861,
    },
    enabledProducts: [Products.ILLUMINATE],
    organizations: [],
    lightMapLayerIconSize: LightMapLayerIconSize.AUTO,
    hasCellLine: true,
    ...updates,
  };
}

export function createMockOrganizationSiteModel(
  updates: Partial<OrganizationSiteInternalModel> = {},
): OrganizationSiteInternalModel {
  return {
    id: '12345',
    name: 'test site',
    users: [],
    ...updates,
  };
}

export function createMockSiteModels(numberOfSites: number): SiteInternalModel[] {
  return Array.from(Array(numberOfSites), (x, i) =>
    createMockSiteModel({ id: `test-id-${i}`, name: `test site ${i}` }),
  );
}

export function createMockOrganizationSiteModels(
  numberOfSites: number,
): OrganizationSiteInternalModel[] {
  return Array.from(Array(numberOfSites), (x, i) =>
    createMockOrganizationSiteModel({ id: `test-id-${i}`, name: `test site ${i}` }),
  );
}

export function createMockSiteFormModel(
  updates: Partial<SiteFormModel> = {},
): SiteFormModel {
  return {
    name: 'Mock Site Form',
    latitude: 34.7304,
    longitude: -86.5861,
    gateways: [],
    organizations: ['organization-id'],
    enabledProducts: {
      remoteAccess: false,
      illuminate: true,
      illuminatePower: false,
      sense: false,
      sensePower: false,
      climate: false,
      color: false,
      colorFade: false,
      allDynamicEffects: false,
      monoDynamicEffects: false,
      chaseEffects: false,
      tiltDetection: false,
      thresholdDetection: false,
    },
    lightMapLayerIconSize: LightMapLayerIconSize.AUTO,
    hasCellLine: 'cell',
    subscriptionType: SubscriptionType.LM_BASE,
    subscriptionStartDate: '',
    subscriptionEndDate: '',
    partner: '',
    salesPerson: '',
    blockAccess: false,
    ...updates,
  };
}

export function createMockGQLSiteWithSiteRoleModel(
  updates?: Partial<SiteWithSiteRoleGQLModel>,
): SiteWithSiteRoleGQLModel {
  return {
    id: 'site-id',
    name: 'Site Name',
    timeZone: 'America/Chicago',
    enabledProducts: [Products.ILLUMINATE],
    geoLocation: {
      latitude: 34.7304,
      longitude: -86.5861,
    },
    mySiteRole: PermissionGroupSiteRole.VIEWER,
    lightMapLayerIconSize: LightMapLayerIconSize.AUTO,
    hasCellLine: false,
    ...updates,
  };
}

export function createMockGQLSiteModel(updates?: Partial<SiteGQLModel>): SiteGQLModel {
  return {
    id: 'site-id',
    name: 'Site Name',
    timeZone: 'America/Chicago',
    enabledProducts: [Products.ILLUMINATE],
    geoLocation: {
      latitude: 34.7304,
      longitude: -86.5861,
    },
    siteControllers: [],
    organizations: [],
    lightMapLayerIconSize: LightMapLayerIconSize.AUTO,
    hasCellLine: true,
    ...updates,
  };
}

export function createMockSiteWSModel(updates?: Partial<SiteWSModel>): SiteWSModel {
  return {
    id: 'site-id',
    name: 'Site Name',
    timezone: 'America/Chicago',
    latitude: 34.7304,
    longitude: -86.5861,
    enabledProducts: [Products.ILLUMINATE],
    siteControllerIds: [],
    lightMapLayerIconSize: LightMapLayerIconSize.AUTO,
    hasCellLine: false,
    baselineType: BaselineType.FLAT,
    ...updates,
  };
}

export const mockGetSitesWithUsersByOrganizationResponse: GetSitesWithUsersByOrganizationQueryResult =
  {
    __typename: 'Query',
    organization: {
      __typename: 'Organization',
      sites: {
        __typename: 'OrganizationSiteConnection',
        edges: [
          {
            __typename: 'OrganizationSiteEdge',
            node: {
              __typename: 'Site',
              id: '12345',
              name: 'Mock Site',
            },
            users: [
              {
                __typename: 'OrganizationSiteUser',
                siteRole: PermissionGroupSiteRole.MEMBER,
                user: {
                  __typename: 'User',
                  name: 'Jane Doe',
                },
              },
            ],
          },
        ],
      },
    },
  };
