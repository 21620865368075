import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sui-access-denied',
  template: `
    <sui-empty-state>
      <ng-template suiEmptyStateTitle>Access Denied</ng-template>
      <ng-template suiEmptyStateMessage>
        You have attempted to access a page without the necessary permissions.
      </ng-template>
      <ng-template suiEmptyStateActions>
        <button suiButton (click)="onRedirect()">Go To Dashboard</button>
      </ng-template>
    </sui-empty-state>
  `,
  styles: [],
})
export class AccessDeniedComponent {
  @Output()
  redirect = new EventEmitter();

  onRedirect() {
    this.redirect.emit();
  }
}
