import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ThermodialModule } from '@spog-ui/thermodial';
import { BFStaticClimateComponent } from './bf-static-climate.component';

@NgModule({
  imports: [ReactiveFormsModule, ThermodialModule],
  exports: [BFStaticClimateComponent],
  declarations: [BFStaticClimateComponent],
})
export class BFStaticClimateComponentModule {}
