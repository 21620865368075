import { Observable, Subscription } from 'rxjs';
import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SuiAnimateFab } from '@spog-ui/shared/components';
import { SceneApplication, SceneViewModel } from '@spog-ui/shared/models/scenes';
import { SequenceSceneViewModel } from '@spog-ui/shared/models/sequence-scenes';
import { ActiveSequenceSceneViewModel } from '@spog-ui/shared/models/active-sequence-scenes';
import { ScenesPageActions } from '@spog-ui/scenes/actions';

@Injectable()
export abstract class ScenesPageState {
  abstract application$: Observable<SceneApplication>;
  abstract ready$: Observable<boolean>;
  abstract scenes$: Observable<SceneViewModel[]>;
  abstract sequenceScenes$: Observable<SequenceSceneViewModel[]>;
  abstract activeSequenceScenes$: Observable<ActiveSequenceSceneViewModel[]>;
  abstract noScenesFound$: Observable<boolean>;
  abstract noSequenceScenesFound$: Observable<boolean>;
  abstract noScenesExist$: Observable<boolean>;
  abstract noSequenceScenesExist$: Observable<boolean>;
  abstract searchTerm$: Observable<string>;
  abstract urlPrefix$: Observable<string>;
  abstract dispatch(action: ScenesPageActions.Union): void;
}

@Component({
  selector: 'scn-scenes-page',
  template: `
    @if (state.application$ | async; as application) { @if (state.ready$ | async) {
    <div>
      <spog-subscription-banner></spog-subscription-banner>
      @if (state.noScenesExist$ | async) {
      <scn-no-scenes-for-application
        [application]="application"
      ></scn-no-scenes-for-application>
      } @else {
      <div class="addSequenceSceneButton">
        <a
          suiExtendedFab
          @suiAnimateFab
          color="accent"
          icon="add"
          *suiIfUserCan="['ssc:siteAdmin', 'ssc:schedule-and-scene']"
          [routerLink]="'./addSequenceScene'"
        >
          Add Sequence Scene
        </a>
      </div>
      <div class="addSceneButton">
        <a
          suiExtendedFab
          @suiAnimateFab
          color="accent"
          icon="add"
          *suiIfUserCan="['ssc:siteAdmin', 'ssc:schedule-and-scene']"
          [routerLink]="'./add'"
        >
          {{ application === 'CLIMATE' ? 'Add Routine' : 'Add Scene' }}
        </a>
      </div>
      <sui-charm-filter
        type="Scenes"
        [term]="state.searchTerm$ | async"
        (filter)="onSearch($event)"
      ></sui-charm-filter>
      @switch (application) { @case ('CLIMATE') { @for (scene of state.scenes$ | async;
      track trackBySceneId($index, scene)) {
      <scn-climate-scene
        [scene]="scene"
        [urlPrefix]="state.urlPrefix$ | async"
        (apply)="onApply(scene)"
        (delete)="onDelete(scene)"
        (duplicate)="onDuplicate(scene)"
      >
      </scn-climate-scene>
      } } @case ('LIGHTING') { @if (!(state.noSequenceScenesExist$ | async)) {
      <div>
        <scn-active-sequence-scenes
          [activeSequenceScenes]="state.activeSequenceScenes$ | async"
        ></scn-active-sequence-scenes>
        <h4>Sequence Scenes</h4>
        @if ((state.sequenceScenes$ | async)!.length > 0) { @for ( sequenceScene of
        state.sequenceScenes$ | async; track trackBySceneId($index, sequenceScene)) {
        <scn-sequence-scene
          [sequenceScene]="sequenceScene"
          (apply)="onApplySequenceScene(sequenceScene)"
          (delete)="onDeleteSequenceScene(sequenceScene)"
          (duplicate)="onDuplicateSequenceScene(sequenceScene)"
        ></scn-sequence-scene>
        } } @else {
        <sui-card class="suiNoScenesFound">
          There are no sequenced scenes found for the search term "{{
            state.searchTerm$ | async
          }}"
        </sui-card>
        }
        <h4>Scenes</h4>
      </div>
      } @for (scene of state.scenes$ | async; track trackBySceneId($index, scene)) {
      <scn-lighting-scene
        [scene]="scene"
        [sequenceScenes]="state.sequenceScenes$ | async"
        [urlPrefix]="state.urlPrefix$ | async"
        (apply)="onApply(scene)"
        (delete)="onDelete(scene)"
        (duplicate)="onDuplicate(scene)"
      ></scn-lighting-scene>
      } } @default {
      <div>Application type is not yet supported: "{{ application }}"</div>
      } } @if (state.noScenesFound$ | async) {
      <sui-card class="suiNoScenesFound">
        No result found for the search term "{{ state.searchTerm$ | async }}"
      </sui-card>
      } }
    </div>
    } @else {
    <sui-spinner></sui-spinner>
    } }
  `,
  styles: [
    `
      :host {
        display: block;
        max-width: 1100px;
        margin: 0 auto;
        padding: 24px 24px 128px;
        transition: padding-bottom 160ms;
      }

      :host a[suiButton] {
        margin: 0;
      }

      sui-spinner {
        display: block;
        width: 50px;
        margin: 0 auto;
      }

      span {
        opacity: 0.65;
        font-style: italic;
      }

      .suiNoScenesFound {
        opacity: 0.65;
        font-style: italic;
        padding: 16px;
      }

      scn-sequence-scene,
      scn-lighting-scene,
      scn-climate-scene {
        margin-bottom: 16px;
      }

      @media screen and (min-width: 500px) {
        .addSceneButton a {
          bottom: 88px !important;
        }
      }

      @media screen and (max-width: 499px) {
        .addSceneButton a {
          bottom: 88px !important;
        }
      }
    `,
  ],
  animations: [SuiAnimateFab],
})
export class ScenesPageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(public state: ScenesPageState, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.data.subscribe(data => {
        return this.state.dispatch(ScenesPageActions.enterAction(data.application));
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onApply($event: SceneViewModel): void {
    this.state.dispatch(ScenesPageActions.applySceneAction($event));
  }

  onDelete($event: SceneViewModel): void {
    this.state.dispatch(
      ScenesPageActions.deleteSceneAction($event.application, $event.id),
    );
  }

  onDuplicate($event: SceneViewModel): void {
    this.state.dispatch(ScenesPageActions.duplicateSceneAction($event));
  }

  onApplySequenceScene($event: SequenceSceneViewModel): void {
    this.state.dispatch(ScenesPageActions.applySequenceSceneAction($event));
  }

  onDeleteSequenceScene($event: SequenceSceneViewModel): void {
    this.state.dispatch(ScenesPageActions.deleteSequenceSceneAction($event.id));
  }

  onDuplicateSequenceScene($event: SequenceSceneViewModel): void {
    this.state.dispatch(ScenesPageActions.duplicateSequenceSceneAction($event));
  }

  onSearch($event: string): void {
    this.state.dispatch(ScenesPageActions.searchAction($event));
  }

  trackBySceneId(index: number, scene: SceneViewModel | SequenceSceneViewModel): string {
    return scene.id;
  }
}
