import {
  IndustrialSensorDefinedDataEnum,
  ModbusFunctionCode,
} from '@spog-ui/graphql/types';
import { Bridge485SensorDetailsViewModel } from '..';
import {
  IndieSensorBatteryLevel,
  IndieSensorDetailsViewModel,
  IndieSensorGQLModel,
  IndieSensorInternalModel,
  IndieSensorMapViewModel,
  IndieSensorStateModel,
  IndieSensorStatus,
  IndieSensorWSModel,
} from './indie-sensor.model';

export const createMockIndieSensorInternalModel = (
  indieSensor?: Partial<IndieSensorInternalModel>,
): IndieSensorInternalModel => {
  return {
    id: 'mock-indie-sensor-id',
    snapaddr: 'FEEBA5',
    name: 'Industrial Machine 1',
    dataType: {
      type: IndustrialSensorDefinedDataEnum.POWER,
    },
    units: 'kW',
    minValue: 0,
    maxValue: 50,
    hardwareType: 'Sense420Sensor',
    ...indieSensor,
  };
};

export function createMockIndieSensorInternalModels(): IndieSensorInternalModel[] {
  return [
    <IndieSensorInternalModel>{
      id: 'mock-indie-sensor-1',
      name: 'test-sensor-1',
      snapaddr: 'FEEBA5',
      minValue: 0,
      maxValue: 50,
      dataType: {
        type: IndustrialSensorDefinedDataEnum.POWER,
      },
    },
    <IndieSensorInternalModel>{
      id: 'mock-indie-sensor-2',
      name: 'test-sensor-2',
      snapaddr: 'FEEBA6',
      minValue: 0,
      maxValue: 50,
      dataType: {
        type: IndustrialSensorDefinedDataEnum.POWER,
      },
    },
  ];
}

export const createMockIndustrialSensorState = (
  state?: Partial<IndieSensorStateModel>,
): IndieSensorStateModel => {
  return {
    batteryLevel: IndieSensorBatteryLevel.LOW,
    lastValue: 0,
    lastValueRead: 'some-date',
    siteControllerId: 'omochao-controller-id',
    upgradeStatus: null,
    ...state,
  };
};

export const createMockVirtualIndustrialSensorState = (
  state?: Partial<IndieSensorStateModel>,
): IndieSensorStateModel => {
  return {
    lastValue: 0,
    lastValueRead: 'some-date',
    ...state,
  };
};

export const createMockIndieSensorMapViewModel = (
  indieSensor?: Partial<IndieSensorMapViewModel>,
): IndieSensorMapViewModel => {
  return {
    id: 'mock-indie-sensor-id',
    name: "It's dat 🙇‍♀️",
    dataType: {
      type: IndustrialSensorDefinedDataEnum.POWER,
    },
    floorPlanX: 1,
    floorPlanY: 2,
    status: IndieSensorStatus.OK,
    snapaddr: 'FEEBA5',
    ...indieSensor,
  };
};

export const createMockIndieSensorDetailsViewModel = (
  indieSensor?: Partial<IndieSensorDetailsViewModel>,
): IndieSensorDetailsViewModel => {
  return {
    id: 'mock-indie-sensor-id',
    name: "It's dat 🙇‍♀️",
    dataType: {
      type: IndustrialSensorDefinedDataEnum.POWER,
    },
    status: IndieSensorStatus.OK,
    snapaddr: 'FEEBA5',
    state: {
      lastValue: 1,
      lastValueRead: '',
    },
    hardwareType: 'Sense420Sensor',
    ...indieSensor,
  };
};

export function createMockBridge485SensorDetailsViewModel(
  updates?: Partial<Bridge485SensorDetailsViewModel>,
): Bridge485SensorDetailsViewModel {
  return {
    id: 'mock-bridge-485-id',
    name: 'Dummy Bridge 485',
    dataType: {
      type: IndustrialSensorDefinedDataEnum.POWER,
    },
    status: IndieSensorStatus.OK,
    state: {
      lastValue: 1,
      lastValueRead: '',
    },
    hardwareType: 'Bridge485Metric',

    dataFormat: '8-byte unsigned int, little endian',
    unitId: 54,
    dataAddress: 2,
    functionCode: ModbusFunctionCode.READ_HOLDING_REGISTERS,
    conversion: { polynomial: [12.1, -7] },

    ...updates,
  };
}

export function createMockIndieSensorGQLModel(): IndieSensorGQLModel {
  return {
    __typename: 'Sense420Sensor',
    id: 'mock-indie-sensor-id',
    name: 'Industrial Machine 1',
    snapaddr: 'FEEBA5',
    minValue: 0,
    maxValue: 50,
    dataType: {
      __typename: 'IndustrialSensorDefinedDataType',
      type: IndustrialSensorDefinedDataEnum.POWER,
    },
    units: 'kW',
  };
}

export function createMockIndieSensorWSModel(
  updates?: Partial<IndieSensorWSModel>,
): IndieSensorWSModel {
  return {
    id: 'mock-indie-sensor-id',
    name: 'Industrial Machine 1',
    type: 'Sense420Sensor',
    snapaddr: 'FEEBA5',
    minValue: 0,
    maxValue: 50,
    dataType: {
      type: IndustrialSensorDefinedDataEnum.POWER,
    },
    units: 'kW',
    siteId: 'mock-site-id',
    ...updates,
  };
}
