import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICON_DIRECTIVES } from './icon.component';

@NgModule({
  imports: [MatIconModule],
  declarations: [ICON_DIRECTIVES],
  exports: [ICON_DIRECTIVES],
})
export class SuiIconModule {}
export * from './icon.component';
