import {
  SceneApplication,
  SceneFormModel,
  SceneGQLModel,
  SceneInternalModel,
  SceneViewModel,
  SceneWSModel,
} from './scenes.model';
import { BehaviorType } from '@spog-ui/shared/models/behaviors';
import { createMockLightingZoneModel } from '@spog-ui/shared/models/zones';

export function createMockSceneModel(
  updates: Partial<SceneInternalModel> = {},
): SceneInternalModel {
  return {
    id: 'mock-scene-id',
    name: 'Mock Scene',
    application: SceneApplication.LIGHTING,
    zoneBehaviors: [
      {
        zoneId: 'id_builtin_zone_all',
        behaviorId: BehaviorType.On,
        behaviorParameters: null,
      },
    ],
    ...updates,
  };
}

export function createMockSceneViewModel(
  update: Partial<SceneViewModel> = {},
): SceneViewModel {
  return {
    id: 'mock-scene-view-id',
    name: 'Mock Scene View',
    state: 'ready',
    linkedZoneCount: 1,
    application: SceneApplication.LIGHTING,
    linkedBehaviorList: [
      {
        id: BehaviorType.On,
        zoneList: [{ id: 'id_builtin_zone_all', name: 'All' }],
        behaviorParameters: null,
      },
    ],
    isHidden: false,
    ...update,
  };
}

export function createMockSceneGQLModel(
  update: Partial<SceneGQLModel> = {},
): SceneGQLModel {
  return {
    id: 'mock-scene-id',
    name: 'Mock Scene',
    application: SceneApplication.LIGHTING,
    controlZones: {
      edges: [
        {
          node: { id: 'id_builtin_zone_all' },
          behavior: { id: 'On' },
          parameters: null,
        },
      ],
    },
    ...update,
  };
}

export function createMockSceneWSModel(update: Partial<SceneWSModel> = {}): SceneWSModel {
  return {
    id: 'mock-scene-id',
    name: 'Mock Scene',
    application: SceneApplication.LIGHTING,
    sceneZoneBehaviors: [
      {
        zoneId: 'id_builtin_zone_all',
        behaviorId: BehaviorType.On,
        behaviorParameters: null,
      },
    ],
    ...update,
  };
}

export function createMockSceneViewModelWithMultipleBehaviorGroups(
  update: Partial<SceneViewModel> = {},
): SceneViewModel {
  return {
    id: 'mock-scene-view-id',
    name: 'Mock Scene View',
    state: 'ready',
    linkedZoneCount: 2,
    application: SceneApplication.LIGHTING,
    linkedBehaviorList: [
      {
        id: BehaviorType.On,
        zoneList: [{ id: 'id_builtin_zone_all', name: 'All' }],
        behaviorParameters: null,
      },
      {
        id: BehaviorType.Off,
        zoneList: [{ id: 'some_other_zone_id', name: 'Something else' }],
        behaviorParameters: null,
      },
    ],
    isHidden: false,
    ...update,
  };
}

export function createMockSceneFormModel(): SceneFormModel {
  const mockZone = createMockLightingZoneModel();

  return {
    id: 'mock-scene-id',
    name: 'Mock Scene Form',
    zoneBehaviors: [
      {
        zones: [mockZone],
        behaviorId: BehaviorType.Dimmer,
        behaviorParameters: JSON.stringify({ level: 30 }),
      },
    ],
  };
}
