import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ColorPickerModule } from '@sui/color-picker';
import { BFColorFadeComponent } from './bf-color-fade.component';

@NgModule({
  imports: [ReactiveFormsModule, MatInputModule, ColorPickerModule],
  declarations: [BFColorFadeComponent],
  exports: [BFColorFadeComponent],
})
export class BFColorFadeComponentModule {}
