import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  ButtonComponent,
  SuiCardModule,
  SuiCharmFilterModule,
  SuiExtendedFabModule,
  SuiSearchModule,
  SpinnerComponent,
} from '@spog-ui/shared/components';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { NoScenesForApplicationComponentModule } from '../no-scenes-for-application';
import { LightingSceneComponentModule } from '../lighting-scene';
import { SequenceSceneComponentModule } from '../sequence-scene';
import { ClimateSceneComponentModule } from '../climate-scene';
import { ScenesPageComponent } from './scenes-page.component';
import {
  SubscriptionBannerComponentModule,
  FabSpeedDialComponent,
  FabSpeedDialActionsComponent,
  FabSpeedDialTriggerComponent,
} from '@spog-ui/shared/components';
import { ActiveSequenceScenesComponentModule } from '../active-sequence-scenes';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RemoteAccessDownBannerComponentModule } from '@spog-ui/shared/components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SuiSearchModule,
    SuiCardModule,
    SpinnerComponent,
    ButtonComponent,
    SuiExtendedFabModule,
    SuiCharmFilterModule,
    PermissionsModule,
    NoScenesForApplicationComponentModule,
    LightingSceneComponentModule,
    SequenceSceneComponentModule,
    ClimateSceneComponentModule,
    SubscriptionBannerComponentModule,
    ActiveSequenceScenesComponentModule,
    FabSpeedDialComponent,
    FabSpeedDialTriggerComponent,
    FabSpeedDialActionsComponent,
    MatIconModule,
    MatButtonModule,
    RemoteAccessDownBannerComponentModule,
  ],
  declarations: [ScenesPageComponent],
  exports: [ScenesPageComponent],
})
export class ScenesPageComponentModule {}
