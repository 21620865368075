import { Component, Input } from '@angular/core';

@Component({
  selector: 'sui-nav-drawer-account',
  template: `
    <div
      class="suiNavDrawerAccountHeader"
      (click)="open = !open"
      [class.suiNavDrawerAccountHeaderOpen]="open"
    >
      <div class="suiNavDrawerAccountUserLabel">{{ user }}</div>
      <div class="suiNavDrawerAccountRoleLabel">{{ role }}</div>
      <div
        class="suiNavDrawerAccountCaret"
        [class.suiNavDrawerAccountCaretOpen]="open"
      ></div>
    </div>

    <div
      class="suiNavDrawerAccountDropdownBackground"
      *ngIf="open"
      (click)="close()"
    ></div>

    <div
      class="suiNavDrawerAccountDropdown"
      [class.suiNavDrawerAccountDropdownOpen]="open"
      (click)="close()"
    >
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        border-bottom: 1px solid var(--color-foreground-divider);
        padding: 4px 16px 16px 16px;
        position: relative;
      }

      .suiNavDrawerAccountHeader {
        position: relative;
        cursor: pointer;
        color: var(--color-foreground-text);
        transition: color 250ms;
      }

      .suiNavDrawerAccountHeader:hover {
        color: var(--color-500);
      }

      .suiNavDrawerAccountHeaderOpen {
        z-index: 9150;
      }

      .suiNavDrawerAccountCaret {
        display: block;
        width: 0;
        height: 0;
        vertical-align: middle;
        border-top: 6px solid;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        position: absolute;
        right: 10px;
        bottom: 8px;
      }

      .suiNavDrawerAccountCaretOpen {
        transform: rotate(180deg);
      }

      .suiNavDrawerAccountUserLabel {
        box-sizing: border-box;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 2px;
        padding-right: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .suiNavDrawerAccountRoleLabel {
        font-size: 12px;
      }

      .suiNavDrawerAccountDropdownBackground {
        transform: translate3d(0, 0, 0);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0);
        z-index: 9100;
      }

      .suiNavDrawerAccountDropdown {
        display: block;
        width: calc(100% - 16px);
        box-sizing: border-box;
        padding: 8px 0;
        background-color: var(--color-background-card);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        position: absolute;
        z-index: 100;
        left: 8px;
        top: calc(100% - 14px);
        visibility: hidden;
        opacity: 0;
        transition: top 150ms, opacity 150ms;
      }

      .suiNavDrawerAccountDropdownOpen {
        top: calc(100% - 4px);
        visibility: visible;
        opacity: 1;
        transition: top 150ms, opacity 150ms, visibility 150ms;
        z-index: 9200;
      }

      .suiNavDrawerAccountDropdown ::ng-deep a {
        color: #666;
        display: block;
        text-decoration: none;
        height: 48px;
        line-height: 48px;
        padding: 0 16px;
        transition: background-color 150ms;
      }

      .suiNavDrawerAccountDropdown ::ng-deep a:hover {
        background-color: var(--color-foreground-divider);
      }
    `,
  ],
})
export class NavDrawerAccountComponent {
  @Input()
  user = '';
  @Input()
  role = '';
  open = false;

  close() {
    this.open = false;
  }
}
