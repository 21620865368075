import { Component } from '@angular/core';

@Component({
  selector: 'spog-drawer-link-beta',
  template: ` <span>Beta</span> `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        border: 1px solid var(--color-500);
        color: var(--color-500);
        border-radius: 2px;
        padding: 0px 8px;
        position: absolute;
        right: 8px;
        height: 22px;
        vertical-align: middle;
        font-size: 10px;
        font-weight: bold;
        top: 50%;
        margin-top: -11px;
        text-transform: uppercase;
      }
    `,
  ],
})
export class DrawerLinkBetaComponent {}
