import {
  DefinedAssetTypeEnum,
  DispositionLevel,
  Interval,
  ResourceGroupModelFragment,
  RunningState,
} from '@spog-ui/graphql/types';
import { DateTime } from 'luxon';
import {
  ResourceGroupFormModel,
  ResourceGroupGQLModel,
  ResourceGroupInternalModel,
  ResourceGroupType,
  ResourceGroupWSModel,
} from './resource-group.model';
import { createMockEnergyConsumptions } from '@spog-ui/shared/models/energy-consumptions';
import { createMockIndieSensorGQLModel } from '@spog-ui/shared/models/indie-sensors';
import { createMockGqlControlZone } from '@spog-ui/shared/models/zones';

export function createMockResourceGroupFormModel(
  updates: Partial<ResourceGroupFormModel> = {},
): ResourceGroupFormModel {
  return {
    name: 'Mock Resource Group',
    resourceGroupIds: [],
    industrialSensorIds: [],
    zoneIds: [],
    ...updates,
  };
}

export function createMockResourceGroup(): ResourceGroupInternalModel {
  return {
    id: 'mockResourceGroupId',
    name: 'Mock Resource Group Name',
    industrialSensorIds: ['mockIndySensor1, mockIndySensor2'],
    resourceGroupIds: [],
    zoneIds: ['mockZoneId1', 'mockZoneId2'],
    energyConsumption: {
      start: new Date('2020-10-13T00:00:00Z').toISOString(),
      end: new Date('2020-10-20T00:00:00Z').toISOString(),
      timezone: 'UTC',
      interval: Interval.HOUR,
      reports: [
        {
          timestamp: new Date('2020-10-14T00:00:00Z').toISOString(),
          energy: 1234,
          cost: '$567',
        },
      ],
    },
  };
}

export function createMockGraphData(): [DateTime, number][] {
  return [
    [DateTime.fromISO('2020-10-13T00:00:00.000'), 1234.56],
    [DateTime.fromISO('2020-10-20T09:00:00.000'), 6789.78],
  ];
}

export function createMockResourceGroups(): ResourceGroupInternalModel[] {
  return [
    {
      id: 'resource-group-1',
      name: 'Resource Group 1',
      industrialSensorIds: ['indie-sensor-1'],
      resourceGroupIds: ['resource-group-2'],
      zoneIds: ['id_builtin_zone_all'],
      energyConsumption: createMockEnergyConsumptions()[0],
    },
    {
      id: 'resource-group-2',
      name: 'Resource Group 2',
      industrialSensorIds: ['indie-sensor-2'],
      resourceGroupIds: ['resource-group-3'],
      zoneIds: ['id_builtin_zone_all'],
      energyConsumption: createMockEnergyConsumptions()[1],
    },
  ];
}

export function createMockResourceGroupWSModel(
  updates: Partial<ResourceGroupWSModel> = {},
): ResourceGroupWSModel {
  return {
    id: 'mockResourceGroupId',
    name: 'Mock Resource Group Name',
    resourceGroupType: ResourceGroupType.AssetCollection,
    siteId: 'mockSiteId',
    industrialSensorIds: ['mockIndySensor1, mockIndySensor2'],
    resourceGroupIds: [],
    zoneIds: ['mockZoneId1', 'mockZoneId2'],
    ...updates,
  };
}

export function createMockResourceGroupGQLModel(): ResourceGroupGQLModel {
  return {
    __typename: 'LegacyResourceGroup',
    id: 'mock-resource-group-id',
    name: 'Mock Resource Group',
    industrialSensors: [
      {
        __typename: 'Sense420Sensor',
        id: 'mock-indie-sensor-id',
      },
    ],
    resourceGroups: [],
    zones: [],
  };
}

export function createMockResourceGroupsGQL(): ResourceGroupModelFragment[] {
  return [
    {
      __typename: 'Asset',
      id: 'mock-asset-id-1',
      name: 'Mock Asset 1',
      type: {
        __typename: 'DefinedAssetType',
        type: DefinedAssetTypeEnum.BAGHOUSE,
      },
      resourceGroups: [createMockResourceGroupGQLModel()],
      industrialSensors: [createMockIndieSensorGQLModel()],
      runningState: {
        __typename: 'AssetRunningState',
        state: RunningState.RUNNING,
        timestamp: '',
      },
      dispositionChanges: [],
      disposition: {
        __typename: 'AssetDisposition',
        level: DispositionLevel.INFO,
        timestamp: '',
      },
    },
    {
      __typename: 'Department',
      id: 'mock-department-id-1',
      name: 'Mock Asset 1',
      resourceGroups: [createMockResourceGroupGQLModel()],
      controlZones: [createMockGqlControlZone()],
      energyProfileEquipmentIds: [],
    },
  ];
}
