import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSlider } from '@angular/material/slider';

@Component({
  selector: 'sui-slider',
  template: `
    <mat-slider
      [color]="color"
      [disableRipple]="true"
      [min]="min"
      [max]="max"
      step="step"
      [disabled]="isDisabled"
      #slider
    >
      <input matSliderThumb [value]="value" (valueChange)="onUpdate($event)" />
    </mat-slider>
  `,
  styles: [
    `
      :host(.suiSliderDisabled) {
        opacity: 0.4;
      }

      mat-slider {
        width: 100%;
      }
    `,
  ],
})
export class SliderComponent {
  @Input() color = 'accent';
  @Input() max: number;
  @Input() min: number;
  @Input() step: number;
  @Input() value: number;
  @HostBinding('class.suiSliderDisabled') @Input() isDisabled: boolean;
  @Output() update = new EventEmitter<number>();
  @ViewChild('slider', { static: true }) slider: MatSlider;

  onUpdate(value: number): void {
    this.value = value;
    this.update.emit(value);
  }
}

export const SLIDER_DIRECTIVES = [SliderComponent];
