import { Component } from '@angular/core';

@Component({
  selector: 'sui-expansion-title',
  template: `
    <mat-panel-title>
      <ng-content></ng-content>
    </mat-panel-title>
  `,
})
export class ExpansionTitleComponent {}
