import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AlarmModel,
  getAlarmDescription,
  getAlarmTitle,
  getAlarmActionLabel,
} from '@shared/alarms';

interface NonAlarm {
  title: string;
  description: string;
  ctaButtonText?: string;
}

@Component({
  selector: 'sui-alarm-card',
  template: `
    <mat-card class="alarmCard" role="alert">
      <mat-card-content>
        <mat-card-title>{{ title }}</mat-card-title>
        <p>{{ description }}</p>
        <button
          *ngIf="ctaButtonText"
          suiButton
          type="button"
          (click)="ctaButtonClick.emit()"
        >
          {{ ctaButtonText }}
        </button>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      mat-card {
        border-radius: 2px;
        background-color: var(--color-warn-500);
        color: var(--color-warn-contrast-500);
        margin-bottom: 16px;
      }

      mat-card-title {
        font-size: 16px;
        font-weight: normal;
        padding: 0px;
      }

      button {
        border: 1px solid var(--color-warn-contrast-500);
        border-radius: 2px;
        color: var(--color-warn-contrast-500);
        height: 36px;
        padding: 0px;
      }
    `,
  ],
})
export class AlarmCardComponent {
  title = '';
  description = '';
  ctaButtonText?: string;

  @Input()
  set nonAlarm(nonAlarm: NonAlarm) {
    this.title = nonAlarm.title;
    this.description = nonAlarm.description;
    this.ctaButtonText = nonAlarm.ctaButtonText;
  }

  @Input()
  set alarm(alarm: AlarmModel) {
    this.title = getAlarmTitle(alarm.type);
    this.description = getAlarmDescription(alarm);
    this.ctaButtonText = getAlarmActionLabel(alarm);
  }

  @Output() ctaButtonClick = new EventEmitter();
}
