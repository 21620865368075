import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FormInputErrorsDirective } from './form-errors.component';

@NgModule({
  imports: [CommonModule, MatInputModule],
  declarations: [FormInputErrorsDirective],
  exports: [FormInputErrorsDirective],
})
export class SuiFormsModule {}

export * from './form-errors.component';
export * from './mark-as-touched.util';
