import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BigSelectFilterComponent } from './big-select-filter.component';
import { BigSelectGlobalCheckboxComponent } from './big-select-global-checkbox.component';
import { BigSelectListComponent } from './big-select-list.component';
import { BigSelectOptionsComponent } from './big-select-options.component';
import { BigSelectTabsComponent } from './big-select-tabs.component';
import { BigSelectComponent } from './big-select.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatTabsModule,
    MatIconModule,
    ScrollingModule,
  ],
  declarations: [
    BigSelectFilterComponent,
    BigSelectGlobalCheckboxComponent,
    BigSelectListComponent,
    BigSelectOptionsComponent,
    BigSelectTabsComponent,
    BigSelectComponent,
  ],
  exports: [BigSelectListComponent, BigSelectComponent],
})
export class BigSelectModule {}
