import { Component } from '@angular/core';

@Component({
  selector: 'sui-split-form-control',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
      }

      :host ::ng-deep > :first-child {
        width: 50%;
      }

      :host ::ng-deep > :last-child {
        padding-left: 20px;
        width: 50%;
      }

      @media (max-width: 420px) {
        :host ::ng-deep > :first-child,
        :host ::ng-deep > :last-child {
          width: 100%;
          padding-left: 0;
        }
      }
    `,
  ],
})
export class SplitFormControlComponent {}
