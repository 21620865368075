import { Component } from '@angular/core';

@Component({
  selector: 'sui-dialog-footer',
  template: `
    <mat-dialog-actions>
      <ng-content></ng-content>
    </mat-dialog-actions>
  `,
  styles: [
    `
      :host ::ng-deep [suiButton] {
        padding: 0px;
      }

      mat-dialog-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    `,
  ],
})
export class DialogFooterComponent {}
