import { SerialSettingsParity } from '@spog-ui/graphql/types';
import { Bridge485WSModel } from '..';
import {
  Bridge485FormInputModel,
  Bridge485FormOutputModel,
  Bridge485GQLModel,
  Bridge485InternalModel,
} from './bridge-485.model';

export function createMockBridge485InternalModel(
  updates?: Partial<Bridge485InternalModel>,
): Bridge485InternalModel {
  return {
    id: 'dummy-bridge-485-id',
    name: 'Dummy Bridge 485',
    metricIds: [],
    serialSettings: {
      baudRate: 115200,
      stopBits: 1,
      parity: SerialSettingsParity.ODD,
    },
    samplePeriod: 10,
    ...updates,
  };
}

export function createMockBridge485WSModel(
  updates?: Partial<Bridge485WSModel>,
): Bridge485WSModel {
  return {
    id: 'dummy-bridge-485-id',
    name: 'Dummy Bridge 485',
    metricIds: [],
    serialSettings: {
      baudRate: 115200,
      stopBits: 1,
      parity: SerialSettingsParity.ODD,
    },
    samplePeriod: 10,
    ...updates,
  };
}

export function createMockBridge485FormOutputModel(
  updates?: Partial<Bridge485FormOutputModel>,
): Bridge485FormOutputModel {
  return {
    name: 'Dummy Bridge 485',

    baudRate: 19200,
    stopBits: 1,
    parity: SerialSettingsParity.NONE,

    samplePeriod: 10,

    template: 'abc-deg',
    ...updates,
  };
}

export function createMockBridge485FormInputModel(
  updates?: Partial<Bridge485FormInputModel>,
): Bridge485FormInputModel {
  return {
    name: 'Dummy Bridge 485',

    baudRate: 19200,
    stopBits: 1,
    parity: SerialSettingsParity.NONE,

    samplePeriod: 10,

    ...updates,
  };
}

export function createMockBridge485GQLModel(
  updates?: Partial<Bridge485GQLModel>,
): Bridge485GQLModel {
  return {
    __typename: 'Bridge485',
    id: 'sunflower-goddess',
    name: 'The Space Between and Its Petals',
    metrics: [],
    samplePeriod: 11,
    serialSettings: {
      baudRate: 9600,
      parity: SerialSettingsParity.NONE,
      stopBits: 1,
    },

    ...updates,
  };
}
