import { startWith } from 'rxjs/operators';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import tinycolor from 'tinycolor2';

@Component({
  selector: 'sui-color-saturation-control',
  template: `
    <sui-color-slider
      [min]="0"
      [max]="100"
      [value]="value"
      [gradient]="saturation"
      (update)="updateSaturation($event)"
    ></sui-color-slider>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ColorSaturationControlComponent,
    },
  ],
})
export class ColorSaturationControlComponent implements ControlValueAccessor, OnInit {
  onChange: (value: any) => any;
  onTouched: () => any;
  value: number;
  disabled = false;
  hue: number;
  saturation = [
    [0, 'rgb(128, 128, 128)'],
    [1, 'red'],
  ];

  @Input()
  hueControl: UntypedFormControl;

  updateSaturation(saturation: number): void {
    this.value = saturation;
    this.updateValue(this.value);
  }

  updateSaturationRange(): void {
    const hueColor = tinycolor({ h: this.hue, s: 100, l: 50 });
    this.saturation = [
      [0, 'rgb(128, 128, 128)'],
      [1, hueColor.toRgbString()],
    ];
  }

  updateValue(value: number): void {
    this.onChange(this.value);
    this.value = value;
  }

  ngOnInit(): void {
    if (this.hueControl) {
      this.hueControl.valueChanges
        .pipe(startWith(this.hueControl.value))
        .subscribe(hue => {
          this.hue = hue;
          this.updateSaturationRange();
        });
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}

export const COLOR_SATURATION_CONTROL_DIRECTIVES = [ColorSaturationControlComponent];
