import { NgModule } from '@angular/core';

import { FilterByNamePipe } from './lib/filter-by-name.pipe';
import { IncludesPipe } from './lib/includes.pipe';
import { StringContainsPipe } from './lib/string-contains.pipe';
import { RemoveBehaviorIDTokenPipe } from './lib/remove-behavior-id-token.pipe';

export const SUI_PIPES = [
  FilterByNamePipe,
  IncludesPipe,
  StringContainsPipe,
  RemoveBehaviorIDTokenPipe,
];

@NgModule({
  declarations: SUI_PIPES,
  exports: SUI_PIPES,
})
export class PipesModule {}

export * from './lib/filter-by-name.pipe';
export * from './lib/includes.pipe';
export * from './lib/remove-behavior-id-token.pipe';
export * from './lib/string-contains.pipe';
